import { EventsManager } from '../events-manager';
import { Widget } from '../widget';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetProperties } from '../widget';

export class SvgExternal implements Widget {
  private id: string | null = null;
  private currentProperties: WidgetProperties = {};
  public domElement: HTMLDivElement;

  constructor() {
    // Contenitore HTML div per l'SVG
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-svg-external-container');
    this.domElement.classList.add('svg-external');
    this.domElement.setAttribute('data-resizable', 'true');
    this.domElement.style.position = 'absolute';
    this.domElement.style.minWidth = '100px'; // Larghezza minima predefinita
    this.domElement.style.minHeight = '100px'; // Altezza minima predefinita
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }
    this.domElement.setAttribute('draggable', 'true');
    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('dragstart', (event: DragEvent) => {
      event.dataTransfer?.setData('text/plain', this.domElement.id);
    });
  }

  detach(): void {
    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.id) {
      WidgetDictionary.unregisterWidget(this.id);
    }
  }

  updateProperties(): void {
    if (this.currentProperties) {
      this.setProperties(this.currentProperties);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      const value = properties[key];

      if (key === 'id') {
        this.id = value;
        this.domElement.id = value;
        WidgetDictionary.registerWidget(value, this);
      } else if (key === 'x') {
        if (typeof value === 'number') {
          this.domElement.style.left = `${value}px`;
        }
      } else if (key === 'y') {
        if (typeof value === 'number') {
          this.domElement.style.top = `${value}px`;
        }
      } else if (key === 'svgUrl' && value.svgContent) {
        // Parsing del contenuto SVG
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(value.svgContent, 'image/svg+xml');
        const svgElement = svgDocument.documentElement;

        // Impostiamo delle dimensioni predefinite, se non già specificate
        //if (!svgElement.getAttribute('width')) {
        svgElement.setAttribute('width', '100%');
        //}
        //if (!svgElement.getAttribute('height')) {
        svgElement.setAttribute('height', '100%');
        //}

        // Aggiungiamo l'SVG come child del div
        this.domElement.appendChild(svgElement);
      } else {
        this.currentProperties[key] = value;
        this.applyProperty(key, value);
      }
    }
  }

  private applyProperty(key: string, value: any): void {
    switch (key) {
      case 'x':
        // Imposta la posizione X assoluta del div
        this.domElement.style.left = value + 'px';
        break;
      case 'y':
        // Imposta la posizione Y assoluta del div
        this.domElement.style.top = value + 'px';
        break;
      case 'rotate':
        // Applica la rotazione
        this.applyRotation(value);
        break;
      default:
        break;
    }
  }

  private applyRotation(rotation: number): void {
    const bbox = this.domElement.getBoundingClientRect();
    const centerX = bbox.width / 2;
    const centerY = bbox.height / 2;

    // Applica la trasformazione di rotazione all'intero div, centrata sul centro del div
    this.domElement.style.transform = `rotate(${rotation}deg)`;
    this.domElement.style.transformOrigin = `${centerX}px ${centerY}px`;
  }

  private sendEvent(propertyName: string, data: any) {
    if (this.id) {
      EventsManager.sendEvent(this.id, propertyName, data);
    }
  }
}

import 'primereact/resources/themes/lara-dark-teal/theme.css';
import { IJsonModel, IJsonRowNode, IJsonTabNode, IJsonTabSetNode, Layout, Model, TabNode } from 'flexlayout-react';
import { ProjectManager } from '@components/project-manager';
import { Toolbar } from '@components/ToolBar';
import { ToolbarConfig } from '@config/toolbar';
import { useEffect, useState } from 'react';
import { useLayoutStore } from '@stores/layoutStore';
import { useToolbarStore } from '@stores/toolbarStore';
import DocumentManager from '@components/DocumentManager';
import ElementsToolbar from '@components/elements-toolbar/ElementsToolbar';
import WidgetEditor from '@components/property-edit/main/WidgetEditorComponent';

const json: IJsonModel = {
  global: {
    tabEnableClose: false,
    tabEnableFloat: true,
    splitterSize: 20, // Aumenta lo spazio tra i componenti
    tabSetTabStripHeight: 50, // Aumenta l'altezza della barra dei tab
    tabSetHeaderHeight: 50, // Aumenta l'altezza dell'intestazione dei tabset
    borderBarSize: 50, // Aumenta la dimensione delle barre dei bordi
    borderSize: 250, // Aumenta la larghezza/altezza iniziale dei bordi
  },
  borders: [
    {
      type: 'border',
      location: 'bottom',
      size: 100,
      children: [
        {
          type: 'tab',
          name: 'Error',
          component: '',
        },
        {
          type: 'tab',
          name: 'Warning',
          component: '',
        },
      ],
    },
  ],
  layout: {
    type: 'row',
    children: [
      {
        type: 'tabset',
        minWidth: 400,
        width: 400,
        id: 'tabset1',
        selected: 0,
        children: [
          {
            type: 'tab',
            name: 'Progetto',
            id: 'ProjectManager',
            component: 'ProjectManager',
          },
          {
            type: 'tab',
            id: 'WidgetEditor',
            name: "Proprieta'",
            component: 'WidgetEditor',
          },
        ],
      },
      {
        type: 'tabset',
        weight: 70,
        id: 'tabset2',
        enableSingleTabStretch: true,
        enableTabStrip: false,
        children: [
          {
            type: 'tab',
            name: 'Documenti',
            id: 'DocumentManager',
            component: 'DocumentManager',
          },
        ],
      },
    ],
  },
};

function MainApp() {
  const setLayoutModel = useLayoutStore((state) => state.setLayoutModel);
  const documentToolbar: ToolbarConfig = useToolbarStore((state) => state.documentToolbar);

  const [model, setModel] = useState(Model.fromJson(json));

  setLayoutModel(model);

  const factory = (node: TabNode) => {
    const component = node.getComponent();
    if (component === 'WidgetEditor') {
      return <WidgetEditor />;
    } else if (component === 'ProjectManager') {
      return <ProjectManager />;
    } else if (component === 'DocumentManager') {
      return <DocumentManager />;
    } else if (component === 'ErrorLog') {
      return <div />;
    } else if (component === 'WarningLog') {
      return <div />;
    } else if (component === 'ElementsToolbar') {
      return <ElementsToolbar />;
    }
  };

  const findNodeById = (node: IJsonRowNode | IJsonTabNode | IJsonTabSetNode, id: string): IJsonTabNode | null => {
    if ('id' in node && node.id === id) {
      return node as IJsonTabNode;
    }
    if ('children' in node) {
      for (const child of node.children) {
        const result = findNodeById(child, id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const modelToUpdate: IJsonModel = model.toJson();
    // search the node
    const node = findNodeById(modelToUpdate.layout, 'ElementsToolbar');

    if (node) {
      // if the node is present and the toolbar mustn't be showed, remove it
      if (documentToolbar === null) {
        const tabset1 = findNodeById(modelToUpdate.layout, 'tabset1') as IJsonTabSetNode;
        if (tabset1 && 'children' in tabset1) {
          tabset1.children.pop();
        }
      } else {
        // if the node is present and the toolbar must be showed, change the name of the toolbar
        node.name = documentToolbar ? documentToolbar.caption : '';
      }
    } else {
      // if the node is not present and the toolbar must be showed, create a new node and show it
      if (documentToolbar !== null) {
        const tabset1 = findNodeById(modelToUpdate.layout, 'tabset1') as IJsonTabSetNode;
        if (tabset1 && 'children' in tabset1) {
          tabset1.children.push({
            type: 'tab',
            name: documentToolbar.caption,
            id: 'ElementsToolbar',
            component: 'ElementsToolbar',
          });
        }
      }
    }

    const newModel = Model.fromJson(modelToUpdate);
    setModel(newModel);
    setLayoutModel(newModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToolbar]);

  return (
    <>
      <Toolbar />
      <Layout model={model} factory={factory} realtimeResize={true} />
    </>
  );
}

export default MainApp;

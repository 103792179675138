export enum ConnType {
  Normal, // Panel connects to the server, a.k.a. "Cloud panels"
  Reverse, // Server connects to the panel
}

export enum ConnState {
  Unknown, // No value
  Offline, // panel is not connected and no connection attempt is in progress
  Connecting, // server is attempting to connect to the panel
  Connected, // panel is connected to the server
  Unreachable, // server has given up trying to connect to the panel
  Invalid, // connection is invalid (e.g. connURI points to a panel with mismatched serial)
}

export enum DeployStatus {
  Unknown, // Who knows?
  InProgress, // We're working on it
  Success, // All set!
  Failure, // No dice
}

export interface Panel {
  id: string; // unique identifier of the panel, either its serial (serialIsValid == true) or a placeholder (serialIsValid == false)
  deviceLabel?: string; // user-configured label of the panel. Only set upon successful connection to the panel
  connUri?: string; // connection URI of the panel (for non-cloud panels) or remote endpoint (for cloud panels)
  connState: ConnState; // connection state
  connType: ConnType; // current connection type
  projectId?: string; // optional project ID of the project association
  projectRole?: string; // optional role (group) of the project association
  metadata?: object; // server-side metadata
  cli_metadata?: object; // panel-side metadata
  serialIsValid: boolean; // true iff the id field is the actual serial of the panel
}

export interface SyncStats {
  created: number;
  updated: number;
  removed: number;
  errors: number;
}

export interface PanelManager {
  /**
   * Returns the list of relevant panels for the given project.
   * "Relevant panels" are:
   * - all panels associated with the project, whether they are connected or not
   * - all connected cloud panels belonging to the project's domain that are not currently associated with a project
   * @param projectId ID of the project
   */
  listProjectPanels(projectId: string): Promise<Panel[]>;

  /**
   * Associates a panel to a project with the given role.
   * Either the panel serial (in case of a cloud panel) or the connection URI (in case of a manually-added panel) must be given.
   * @param projectId ID of the project to associate the panel to
   * @param id: panel to add
   * @param projectRole role to assign to the panel
   * @returns the unique identifier (serial or placeholder) of the added panel
   */
  addPanelToProject(
    projectId: string,
    id: { serial?: string; connUri?: string },
    projectRole?: string
  ): Promise<string>;

  /**
   * Edits properties of the panel/project association
   * @param projectId ID of the project
   * @param panelId panel to edit
   * @param newConnUri connection URI to set on the panel entry
   * @param newRole role in the project to set on the panel
   */
  editProjectPanel(projectId: string, panelId: string, what?: { newConnUri?: string; newRole?: string }): Promise<void>;

  /**
   * Remvoves the association between a panel and a project and resets any connection URI set on the panel
   * @param projectId Project to remove the association from
   * @param panelId panel to remove
   */
  removePanelFromProject(projectId: string, panelId: string): Promise<void>;

  /**
   * Forces a new outgoing connection attempt for the given panel.
   * The panel must be have a connURI configured and must be in FAILED connection state.
   * @param panelId panel to attempt reconnecting to
   */
  retryPanelConnection(panelId: string): Promise<void>;

  /**
   * Requests a deploy of the project on a set of panels.
   * Note that the return value only reflects the validity of the parameters;
   * the results of the actual deploy process is asynchronously reported via deployStatusChanged events.
   *
   *
   * @param projectId ID of the project
   * @param panelIds List of target panels; must be all associated with the project.
   * @param testData Raw deploy data to send to the panels -- for debugging use only
   * @returns nothing on success; throws an exception if any of the preconditions is not met
   */
  deployToPanels(projectId: string, panelIds: string[], test_data?: unknown): Promise<void>;

  /**
   * Synchronizes project resources with the given panel
   * @param projectId ID of the project
   * @param serial Target panel; must be currently connected
   * @returns information about the sync process
   */
  syncResources(projectId: string, serial: string): Promise<SyncStats>;
}

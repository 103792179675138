import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import { Project } from '@api';
import { PropertiesConfig } from './interface';
import { TabName, TextPosition } from './enum';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import ImagePropertyEditor from '@components/property-edit/component/ImagePropertyEditor';
import InputTextEditor from '@components/property-edit/component/InputTextComponent';

export const textField: PropertiesConfig = {
  properties: {
    Caption: {
      index: 1,
      tab: TabName.Content,
      section: 'Testo',
      editor: InputTextEditor,
      pinType: Project.PinType.In,
      hasNotDefault: true,
      caption: 'Caption',
      captionPosition: TextPosition.Left,
    },
    HTextAlign: {
      index: 2,
      tab: TabName.Content,
      section: 'Testo',
      defaultData: AlignItems.Start,
      editorOptions: {
        options: [
          { icon: 'bi-align-start', value: AlignItems.Start },
          { icon: 'bi-align-center', value: AlignItems.Middle },
          { icon: 'bi-align-end', value: AlignItems.End },
          { icon: 'bi-distribute-horizontal', value: AlignItems.Stretch },
        ],
      },
      caption: 'Allinea testo orizzontalmente',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    VTextAlign: {
      index: 3,
      tab: TabName.Content,
      section: 'Testo',
      defaultData: AlignItems.Start,
      editorOptions: {
        options: [
          { icon: 'bi-align-top', value: AlignItems.Start },
          { icon: 'bi-align-middle', value: AlignItems.Middle },
          { icon: 'bi-align-bottom', value: AlignItems.End },
        ],
      },
      caption: 'Allinea testo verticalmente',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
  },
};

export const genericEvents: PropertiesConfig = {
  properties: {
    OnClick: {
      index: 1,
      tab: TabName.Advanced,
      section: 'Eventi',
      pinType: Project.PinType.Out,
      caption: 'OnClick',
    },
  },
};

export const nameField: PropertiesConfig = {
  properties: {
    Name: {
      tab: TabName.Advanced,
      section: 'Name',
      editor: InputTextEditor,
      hasNotDefault: true,
      pinType: Project.PinType.None,
      caption: 'Nome',
      captionPosition: TextPosition.Left,
    },
  },
};

export const imagePropertyField: PropertiesConfig = {
  properties: {
    Image: {
      tab: TabName.Advanced,
      section: 'Image',
      editor: ImagePropertyEditor,
      hasNotDefault: true,
      pinType: Project.PinType.None,
      caption: 'Immagine',
      captionPosition: TextPosition.Left,
    },
  },
};

import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import {
  FlexboxDirection,
  JustifyContent,
} from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/concrete-widgets/view-widget';
import { Project } from '@api';
import { PropertiesConfig } from './interface';
import { TabName, TextPosition } from './enum';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import MultiEdit from '@components/property-edit/component/MultiEditComponent';
import ToggleButtonBar from '@components/property-edit/component/ToggleButtonComponent';

export const containerFields: PropertiesConfig = {
  onUpdate: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any, _: unknown, fields: unknown) => {
      const vertProps = [
        { icon: 'bi-align-top', value: AlignItems.Start },
        { icon: 'bi-align-middle', value: AlignItems.Middle },
        { icon: 'bi-align-bottom', value: AlignItems.End },
        { icon: 'bi-distribute-vertical', value: AlignItems.Stretch },
      ];
      const horizProps = [
        { icon: 'bi-align-start', value: AlignItems.Start },
        { icon: 'bi-align-center', value: AlignItems.Middle },
        { icon: 'bi-align-end', value: AlignItems.End },
        { icon: 'bi-distribute-horizontal', value: AlignItems.Stretch },
      ];

      if (
        [FlexboxDirection.Right, FlexboxDirection.Left].includes(data['FlexDirection']) ||
        data['FlexDirection'] === undefined
      ) {
        fields['JustifyContent'].index = 2;
        fields['AlignItems'].index = 3;
        fields['JustifyContent'].caption = 'Distribuisci widgets orizzontalmente';
        fields['JustifyContent'].editorOptions.options = horizProps;
        fields['AlignItems'].caption = 'Allinea  widgets verticalmente';
        fields['AlignItems'].editorOptions.options = vertProps;
      } else {
        fields['JustifyContent'].index = 3;
        fields['AlignItems'].index = 2;
        fields['JustifyContent'].caption = 'Distribuisci widgets verticalmente';
        fields['JustifyContent'].editorOptions.options = vertProps;
        fields['AlignItems'].caption = 'Allinea widgets orizzontalmente';
        fields['AlignItems'].editorOptions.options = horizProps;
      }
      fields['Padding'].defaultData =
        data.type === 'page' || data.type === 'view' ? { left: 10, top: 10, right: 10, bottom: 10 } : undefined;
    },
  ],

  properties: {
    FlexDirection: {
      index: 1,
      tab: TabName.Layout,
      section: 'Contenitore',
      defaultData: FlexboxDirection.Right,
      editorOptions: {
        options: [
          { icon: 'pi pi-arrow-right', value: FlexboxDirection.Right },
          { icon: 'pi pi-arrow-down', value: FlexboxDirection.Bottom },
          { icon: 'pi pi-arrow-left', value: FlexboxDirection.Left },
          { icon: 'pi pi-arrow-up', value: FlexboxDirection.Top },
        ],
      },
      caption: 'Direzione',
      editor: ButtonBar,
      captionPosition: TextPosition.Left,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    JustifyContent: {
      index: 2,
      tab: TabName.Layout,
      section: 'Contenitore',
      defaultData: JustifyContent.Start,
      editorOptions: {
        options: [
          { icon: 'bi-align-start', value: JustifyContent.Start },
          { icon: 'bi-align-center', value: JustifyContent.Middle },
          { icon: 'bi-align-end', value: JustifyContent.End },
          { icon: 'bi-distribute-horizontal', value: JustifyContent.Stretch },
        ],
      },
      caption: 'Allinea contenuto su asse principale',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    AlignItems: {
      index: 3,
      tab: TabName.Layout,
      section: 'Contenitore',
      defaultData: AlignItems.Stretch,
      editorOptions: {
        options: [
          { icon: 'bi-align-start', value: AlignItems.Start },
          { icon: 'bi-align-center', value: AlignItems.Middle },
          { icon: 'bi-align-end', value: AlignItems.End },
          { icon: 'bi-distribute-horizontal', value: AlignItems.Stretch },
        ],
      },
      caption: 'Allinea contenuto su asse ortogonale',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    Gaps: {
      index: 4,
      tab: TabName.Layout,
      section: 'Contenitore',
      defaultData: { column: 10, row: 10 },
      editorOptions: {
        options: [
          { hint: 'Column', field: 'column', minValue: 0 },
          { hint: 'Row', field: 'row', minValue: 0 },
        ],
      },
      caption: 'Gaps',
      editor: MultiEdit,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    Wrap: {
      index: 5,
      tab: TabName.Layout,
      section: 'Contenitore',
      defaultData: false,
      editor: ToggleButtonBar,
      caption: 'Wrap attivo',
      captionPosition: TextPosition.Left,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    Padding: {
      index: 6,
      tab: TabName.Layout,
      section: 'Contenitore',
      editorOptions: {
        options: [
          { hint: 'Top', field: 'top', minValue: 0 },
          { hint: 'Right', field: 'right', minValue: 0 },
          { hint: 'Bottom', field: 'bottom', minValue: 0 },
          { hint: 'Left', field: 'left', minValue: 0 },
        ],
      },
      caption: 'Padding',
      editor: MultiEdit,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
  },
};

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { LeafProps, NodeProps, Treeview } from '@common/treeview';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { useBinaryFileStore } from '@stores/binaryFilesStore';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';
import AutoFocusDiv from '@common/AutoFocusDiv';
import React, { FC, useEffect, useRef, useState } from 'react';

export function binaryFolderToTreeView(rootFolder: Project.BinaryFolder) {
  const parseFiles = (files: Project.BinaryFile[]): (LeafProps | NodeProps)[] => {
    return files.map((file) => ({
      title: file.name,
      id: file.id,
      icon: 'pi pi-file',
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [{ icon: 'pi pi-times', code: 'deleteFile' }],
    }));
  };

  const recursivelyParseFolders = (folders: Project.BinaryFolder[], parentId?: string): (LeafProps | NodeProps)[] => {
    return folders.map((folder) => {
      const id = parentId ? `${parentId}/${folder.name}` : folder.name;

      const levelItem: LeafProps | NodeProps = {
        id,
        title: folder.name,
        icon: 'pi pi-folder',
        open: true,
        parentFound: false,
        found: false,
        hidden: false,
        editable: true,
        actions: [{ icon: 'pi pi-plus', code: 'selectFolder' }],
        children: [...recursivelyParseFolders(folder.subFolders, id), ...parseFiles(folder.files)],
      };

      return levelItem;
    });
  };

  return [
    {
      title: rootFolder.name,
      id: rootFolder.name,
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [{ icon: 'pi pi-plus', code: 'selectFolder' }],
      children: [...recursivelyParseFolders(rootFolder.subFolders, rootFolder.name), ...parseFiles(rootFolder.files)],
    },
  ];
}

const ImagePropertyEditor: FC<{ onDataChange: any; overlayPanel: any; handleOpenPanel: any; setEditingImage: any }> = ({
  onDataChange,
  overlayPanel,
  handleOpenPanel,
  setEditingImage,
}) => {
  const { files, addFile, loadFiles, deleteFile, getFile, getMaxId } = useBinaryFileStore();
  const fileUpload = useRef<FileUpload>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>();

  useEffect(() => {
    loadFiles().catch(console.warn);
  }, [loadFiles]);

  const notifyAction = async (action: string, data: string) => {
    const dataObj = JSON.parse(data);
    switch (action) {
      case 'selectFolder':
        setSelectedFolder(dataObj.id);
        break;
      case 'deleteFile':
        deleteFile(dataObj.id);
        break;
      case 'select':
        const selectedFile = await getFile(dataObj.id);
        if (selectedFile) {
          overlayPanel.current.hide();
          onDataChange(selectedFile);
        }
        break;
    }
  };

  const handleUpload = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      const newFile: Project.BinaryFile = { id: 'image-' + (getMaxId() + 1), name: file.name };
      addFile(selectedFolder, newFile);
      onDataChange(newFile);
    }
    fileUpload?.current.clear();
  };

  const handleAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditingImage(null);
    handleOpenPanel(event);
  };

  return (
    <>
      <Button
        icon="pi pi-plus"
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '2.3rem' }}
        onClick={(event) => handleAdd(event)}
      />

      <OverlayPanel ref={overlayPanel} closeOnEscape dismissable>
        {files && (
          <Treeview
            json={JSON.stringify(binaryFolderToTreeView(files))}
            selectedItems={[selectedFolder]}
            notifyAction={notifyAction}
          />
        )}

        {selectedFolder && (
          <Card title="Upload an image">
            <AutoFocusDiv style={{ width: '300px' }}>
              <FileUpload
                ref={fileUpload}
                accept="image/png, image/jpeg"
                maxFileSize={1_000_000}
                emptyTemplate={<p className="m-0">Drag and drop image files here to upload.</p>}
                customUpload
                uploadHandler={handleUpload}
                onClear={() => setSelectedFolder(undefined)}
              />
            </AutoFocusDiv>
          </Card>
        )}
      </OverlayPanel>
    </>
  );
};

export const ImageListPropertyEditor: FC<PropertyEditorComponentProps> = ({ onDataChange }) => {
  const overlayPanelMain = useRef<OverlayPanel>(null);
  const overlayPanelImageEditor = useRef<OverlayPanel>(null);
  const { editingProperties } = useWidgetPropsStore();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [editingImage, setEditingImage] = useState<string>(null); //id dell'item selezionato che si vuole modificare
  let items = [];
  const internalItems = [];

  //caricamento iniziale items
  if (editingProperties.data.Images) {
    items = editingProperties.data.Images.Items;
    for (let i = 0; i < items.length; i++) {
      internalItems.push({ id: 'item-' + i, img: { id: items[i].id, name: items[i].name } });
    }
  }

  const handleOpenImageEditorPanel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    overlayPanelImageEditor.current.toggle(event);
  };

  //click tasto apertura editor
  const handleClickMainPanel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    overlayPanelMain.current.toggle(event);
  };

  /**
   * Selezione file immagine (gestione creazione o modifica item)
   * @param image file selezionato
   */
  const handleImageSelected = (image: any) => {
    // se la modifica è attivata
    if (editingImage !== null) {
      const updatedItems = [...internalItems];
      const index = updatedItems.findIndex((item) => item.id === editingImage);

      if (index >= 0) {
        const updatedItem = { ...updatedItems[index], img: { ...image } }; //copia elemento
        updatedItems[index] = updatedItem;
        updateData(updatedItems);
      } else {
        console.error('Elemento non trovato');
      }
    } else {
      const newItem = { id: image.id, name: image.name };
      const updatedItems = [...items, newItem];
      onDataChange({ Items: updatedItems });
    }
  };

  /**
   * Click tasto modifica dell'item della lista
   * @param event evento
   * @param option item da modificare
   */
  const handleEditImage = (event: React.MouseEvent<HTMLButtonElement>, option: any) => {
    event.stopPropagation();
    setEditingImage(option.id);
    handleOpenImageEditorPanel(event);
  };

  /**
   * Cancellazione immagine
   * @param option item selezionato da cancellare
   */
  const handleDeleteImage = (option: any) => {
    const updatedItems = internalItems.filter((item) => item.id !== option.id);
    updateData(updatedItems);
  };

  /**
   * Aggiornamento effettivo proprietà Items
   * @param updatedItems dati aggiornati
   */
  const updateData = (updatedItems: any) => {
    items = [];
    updatedItems.forEach((item) => {
      items.push({ id: item.img.id, name: item.img.name });
    });
    onDataChange({ Items: items });
  };

  /**
   * Spostamento in 'alto' dell'elemento nella lista (indietro nell'array)
   * @param option elemento da muovere
   */
  const handleMoveUp = () => {
    const updatedItems = [...internalItems.map((item) => ({ ...item }))]; //copia dei dati
    const index = updatedItems.findIndex((item) => item.id === selectedImage.id);

    if (index > 0) {
      updatedItems.splice(index - 1, 0, updatedItems.splice(index, 1)[0]);
      setSelectedImage({
        ...updatedItems[index - 1],
        id: 'item-' + (parseInt(updatedItems[index - 1].id.split('-')[1]) - 1),
      });
      updateData(updatedItems);
    }
  };

  /**
   * Spostamento in 'basso' dell'elemento nella lista (avanti nell'array)
   * @param option elemento da muovere
   */
  const handleMoveDown = () => {
    const updatedItems = [...internalItems.map((item) => ({ ...item }))]; //copia dei dati
    const index = updatedItems.findIndex((item) => item.id === selectedImage.id);

    if (index < updatedItems.length - 1) {
      updatedItems.splice(index + 1, 0, updatedItems.splice(index, 1)[0]);
      setSelectedImage({
        ...updatedItems[index + 1],
        id: 'item-' + (parseInt(updatedItems[index + 1].id.split('-')[1]) + 1),
      });
      updateData(updatedItems);
    }
  };

  const itemTemplate = (option: any) => (
    <div className="flex align-items-center justify-content-between">
      <span>{option.img.name}</span>
      {selectedImage && selectedImage.id === option.id && (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button
            icon="pi pi-pencil"
            className="p-button-text"
            style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
            onClick={(event) => handleEditImage(event, option)}
          />
          <Button
            icon="pi pi-trash"
            style={{
              backgroundColor: '#f87171',
              borderColor: '#f87171',
              color: 'white',
              width: '2.3rem',
              height: '2.3rem',
              fontSize: '1.3rem',
            }}
            onClick={() => handleDeleteImage(option)}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <Button
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Bookmark"
        outlined
        onClick={(event) => handleClickMainPanel(event)}
      />

      <OverlayPanel ref={overlayPanelMain} closeOnEscape dismissable style={{ height: '320px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <ImagePropertyEditor
              onDataChange={handleImageSelected}
              overlayPanel={overlayPanelImageEditor}
              handleOpenPanel={handleOpenImageEditorPanel}
              setEditingImage={setEditingImage}
            />

            <Button
              style={{ width: '2.3rem', height: '2.3rem', fontSize: '2.3rem', marginTop: '1rem' }}
              icon="pi pi-arrow-up"
              onClick={() => handleMoveUp()}
              disabled={!selectedImage || internalItems.findIndex((item) => item.id === selectedImage?.id) <= 0}
            />
            <Button
              icon="pi pi-arrow-down"
              style={{ width: '2.3rem', height: '2.3rem', fontSize: '2.3rem', marginTop: '0.5rem' }}
              onClick={() => handleMoveDown()}
              disabled={
                !selectedImage ||
                internalItems.findIndex((item) => item.id === selectedImage?.id) >= internalItems.length - 1
              }
            />
          </div>

          <ListBox
            value={selectedImage}
            onChange={(e) => setSelectedImage(e.value)}
            options={internalItems}
            optionLabel="img.name"
            className="w-full md:w-14rem"
            itemTemplate={itemTemplate}
            style={{ height: '290px', maxHeight: '290px', overflowY: 'auto' }} //altezza massima
          />
        </div>
      </OverlayPanel>
    </>
  );
};

export default ImageListPropertyEditor;

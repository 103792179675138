import { InputText } from 'primereact/inputtext';
import { Project } from '@api';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import AlarmPriority = Project.AlarmPriority;
import AlarmType = Project.AlarmType;
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TreeLink } from '@components/tree-link/TreeLink';
import AutoFocusDiv from '@components/common/AutoFocusDiv';

type Props = {
  documentData: Project.DocumentData;
  alarmItem: Project.ResourceTreeElement;
  onChange: (resource: Project.AlarmMetaDataItem) => void;
  onDocumentDataChange: (document: Project.DocumentData) => void;
  documentId: Project.ProjectNodeKey;
};

export const AlarmCreationForm: FC<Props> = ({
  documentData,
  alarmItem,
  onChange,
  documentId,
  onDocumentDataChange,
}) => {
  const linkOverlay = useRef<OverlayPanel>(null);
  const [startResourcePoint, setStartResourcePoint] = useState<
    (Project.ResourceLinkPoint & { pinType: Project.PinType }) | undefined
  >();
  const [formData, setFormData] = useState<Project.AlarmMetaDataItem>({
    message: '',
    priority: Project.AlarmPriority.Error,
    alarmType: Project.AlarmType.ISA,
  });

  useEffect(() => {
    console.log(documentData, alarmItem);
    const existingMetadata = documentData.metadata?.[alarmItem.data.id];
    if (existingMetadata) {
      setFormData(existingMetadata);
    } else {
      setFormData({
        message: '',
        priority: Project.AlarmPriority.Error,
        alarmType: Project.AlarmType.ISA,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmItem.data.id, documentData.metadata]);

  const priorities = Object.values(AlarmPriority).map((value) => ({
    label: value,
    value: value,
  }));

  const alarmTypes = Object.values(AlarmType).map((value) => ({
    label: value,
    value: value,
  }));

  const handlePriorityChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      const updatedFormData = {
        ...formData,
        priority: e.value,
      };
      setFormData(updatedFormData);
      onChange(updatedFormData);
    },
    [formData, onChange]
  );

  const handleAlarmTypeChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      const updatedFormData = {
        ...formData,
        alarmType: e.value,
      };
      setFormData(updatedFormData);
      onChange(updatedFormData);
    },
    [formData, onChange]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedFormData = {
        ...formData,
        message: e.target.value,
      };
      setFormData(updatedFormData);
      onChange(updatedFormData);
    },
    [formData, onChange]
  );

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDocumentDataChange({ ...documentData, resourceLinks });
  };

  const handleEventLinkEdit = (e: React.MouseEvent, name: string) => {
    setStartResourcePoint({
      documentId,
      path: [{ idx: 'events' }, { idx: name }],
      pinType: Project.PinType.InOut,
    });
    linkOverlay.current.toggle(e);
  };

  return (
    <div className="flex flex-column gap-4">
      <div className="p-inputgroup flex-1">
        <label className="p-inputgroup-addon" htmlFor="message">
          Message
        </label>
        <InputText
          id="message"
          value={formData.message}
          onChange={handleInputChange}
          name="message"
          placeholder="Alarm message"
        />
      </div>
      <div className="p-inputgroup flex-1 gap-4">
        <label className="text-xl mt-3" htmlFor="priority">
          Priority
        </label>
        <SelectButton value={formData.priority} onChange={handlePriorityChange} options={priorities} />
      </div>
      <div className="p-inputgroup flex-1 gap-4">
        <label className="text-xl mt-3" htmlFor="alarmType">
          Alarm Type
        </label>
        <SelectButton value={formData.alarmType} onChange={handleAlarmTypeChange} options={alarmTypes} />
      </div>
      <Fieldset legend="Events" style={{ minHeight: '340px', maxHeight: '675px', marginTop: '60px' }}>
        {alarmItem.children[0].children.map((event) => (
          <div key={event.name} className="flex flex-row items-center gap-4">
            <div className="mt-3">{event.name}</div>
            <Button
              type="button"
              icon="pi pi-link"
              onClick={(e) => handleEventLinkEdit(e, event.name)}
              text
              aria-label="Resolution"
            />
          </div>
        ))}
      </Fieldset>

      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          {startResourcePoint && (
            <TreeLink
              startResourcePoint={{ documentId: startResourcePoint.documentId, path: startResourcePoint.path }}
              startResourcePinType={startResourcePoint.pinType}
              resourceLinks={documentData.resourceLinks}
              onChangeData={handleLinkChange}
            />
          )}
        </AutoFocusDiv>
      </OverlayPanel>
    </div>
  );
};

export interface TimerProperties {
  description: string;
  tickMode: object;
  type: object;
  costantInterval: boolean;
  enableStartup: boolean;
  interval: string;
}

export const emptyTimerProperties: TimerProperties = {
  description: '',
  tickMode: { name: 'Normal', code: 'Normal' },
  type: { name: 'Client', code: 'Client' },
  costantInterval: false,
  enableStartup: false,
  interval: '',
};

import { InputText } from 'primereact/inputtext';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import React from 'react';

export const InputTextEditor = ({ data, onDataChange }: PropertyEditorComponentProps) => {
  const prova = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange(e.target.value);
  };

  return <InputText type="text" value={data || ''} onChange={(e) => prova(e)} />; // onDataChange(e.target.value)} />;
};

export default InputTextEditor;

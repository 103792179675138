import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { LeafProps, NodeProps, Treeview } from '@common/treeview';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { useBinaryFileStore } from '@stores/binaryFilesStore';
import AutoFocusDiv from '@common/AutoFocusDiv';
import React, { FC, useEffect, useRef, useState } from 'react';

export function binaryFolderToTreeView(rootFolder: Project.BinaryFolder) {
  const parseFiles = (files: Project.BinaryFile[]): (LeafProps | NodeProps)[] => {
    return files.map((file) => ({
      title: file.name,
      id: file.id,
      icon: 'pi pi-file',
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [{ icon: 'pi pi-times', code: 'deleteFile' }],
    }));
  };

  const recursivelyParseFolders = (folders: Project.BinaryFolder[], parentId?: string): (LeafProps | NodeProps)[] => {
    return folders.map((folder) => {
      const id = parentId ? `${parentId}/${folder.name}` : folder.name;

      const levelItem: LeafProps | NodeProps = {
        id,
        title: folder.name,
        icon: 'pi pi-folder',
        open: true,
        parentFound: false,
        found: false,
        hidden: false,
        editable: true,
        actions: [{ icon: 'pi pi-plus', code: 'selectFolder' }],
        children: [...recursivelyParseFolders(folder.subFolders, id), ...parseFiles(folder.files)],
      };

      return levelItem;
    });
  };

  return [
    {
      title: rootFolder.name,
      id: rootFolder.name,
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [{ icon: 'pi pi-plus', code: 'selectFolder' }],
      children: [...recursivelyParseFolders(rootFolder.subFolders, rootFolder.name), ...parseFiles(rootFolder.files)],
    },
  ];
}

export const ImagePropertyEditor: FC<PropertyEditorComponentProps> = ({ onDataChange }) => {
  const { files, addFile, loadFiles, deleteFile, getFile, getMaxId } = useBinaryFileStore();

  const button = useRef<Button>(null);
  const overlayPanel = useRef<OverlayPanel>(null);
  const fileUpload = useRef<FileUpload>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>();

  useEffect(() => {
    loadFiles().catch(console.warn);
  }, [loadFiles]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    overlayPanel.current.toggle(event);
  };

  const notifyAction = async (action: string, data: string) => {
    const dataObj = JSON.parse(data);
    switch (action) {
      case 'selectFolder':
        setSelectedFolder(dataObj.id);
        break;

      case 'deleteFile':
        deleteFile(dataObj.id);
        break;

      case 'select':
        const selectedFile = await getFile(dataObj.id);
        if (selectedFile) {
          overlayPanel.current.hide();
          onDataChange(selectedFile);
        }
        break;
    }
  };

  const handleUpload = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        const newFile: Project.BinaryFile = { id: 'image-' + (getMaxId() + 1), name: file.name };
        addFile(selectedFolder, newFile);
        onDataChange(newFile);
      };
      reader.readAsDataURL(file); // Legge il contenuto del file immagine
    } else {
      console.warn('Solo file immagine sono supportati');
    }
    fileUpload?.current.clear(); // Pulisce l'area di upload
  };

  return (
    <>
      <Button
        ref={button}
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Bookmark"
        outlined
        onClick={(event) => handleClick(event)}
      />

      <OverlayPanel ref={overlayPanel} closeOnEscape dismissable>
        {files && (
          <Treeview
            json={JSON.stringify(binaryFolderToTreeView(files))}
            selectedItems={[selectedFolder]}
            notifyAction={notifyAction}
          />
        )}

        {selectedFolder && (
          <Card title={`Upload an image`}>
            <AutoFocusDiv style={{ width: '300px' }} onBlur={() => overlayPanel.current.hide()}>
              <FileUpload
                ref={fileUpload}
                accept="image/png, image/jpeg"
                maxFileSize={1_000_000}
                emptyTemplate={<p className="m-0">Drag and drop image files to here to upload.</p>}
                customUpload
                uploadHandler={handleUpload}
                onClear={() => setSelectedFolder(undefined)}
              />
            </AutoFocusDiv>
          </Card>
        )}
      </OverlayPanel>
    </>
  );
};

export default ImagePropertyEditor;

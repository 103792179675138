/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dimensions, XYPosition } from '@xyflow/react';

export namespace Project {
  export enum PinType {
    In,
    Out,
    InOut,
    None,
  }

  export enum ReturnType {
    Numeric = 'numeric',
    String = 'string',
  }

  export interface MethodParams {
    name: string;
    type: TagType;
    array: boolean;
  }

  export interface Method {
    returnType: ReturnType;
    params: MethodParams[];
  }

  export interface ResourceTreeElement {
    name: string;
    /** Se questo campo e' diverso da undefined allora il node e' un "metodo". Nella creazione dei link fra i node questo e' da ignorare. Serve al client */
    methodParams?: Method;
    /** numero di matrici dell'array se il nodo e' un array. Anche questo nella creazione del link va ignorato */
    arrays?: number[];
    data: any;
    type?: TagType;
    /** direzione da usarsi per il link. il valore di default è InOut */
    pinType?: PinType;
    children?: ResourceTreeElement[];
  }

  interface ResourcePathStep {
    idx: number | string | ResourcePath;
  }

  type ResourcePath = ResourcePathStep[];

  export interface ResourceLinkPoint {
    // if omitted, the id of the containing document is implied
    documentId: ProjectNodeKey;
    path: ResourcePath;
  }

  /* I collegamenti fra le risorse possono essere bidirezionali.
   * es:
   * ID:2 pippo.pluto --> ID:5 gino.beppe. Collego la risorsa pippo.pluto del documento 2 con la risorsa gino.beppe del documento 5.
   */
  export interface ResourceLink {
    source: ResourceLinkPoint;
    dest: ResourceLinkPoint;
    isInOut: boolean;
  }

  // in caso di view, metadata contiene obbligatoriamente il DOM id del contenitore
  export type ViewMetadata = {
    id: string;
    [key: string]: any;
  };

  export enum TagType {
    Numeric = 'numeric',
    String = 'string',
    Method = 'method',
    Struct = 'struct',
  }

  export enum AlarmType {
    SimpleEvent = 'SimpleEvent',
    ISA = 'ISA',
    SingleInstance = 'Single Instance',
  }

  export enum AlarmPriority {
    Warning = 'Warning',
    Error = 'Error',
  }

  export type AlarmMetaData = {
    [id: string]: AlarmMetaDataItem;
  };

  export type AlarmMetaDataItem = {
    message: string;
    priority: AlarmPriority;
    alarmType: AlarmType;
  };

  export type DatabaseMetadataItem = {
    fieldName: string;
    type: string;
    description: string;
    key: boolean;
  };

  export type DatabaseMetadata = {
    connectionString: string;
    tableName?: string;
    keys: string[];
  };

  export type ScriptMetadataPin = {
    id: string;
  };

  export type ScriptMetadata = {
    pinIn: ScriptMetadataPin[];
    pinOut: ScriptMetadataPin[];
  };

  export type DocumentData = {
    /* Questo campo non serve per la definizione dei resourcePin */
    resourceTree?: ResourceTreeElement[];

    /** Questo e' il campo da utilizzare per la creazione dei reourcePin */
    resourceLinks?: ResourceLink[];

    /* Nel caso della view qui si trova il json che la descrive */
    // copied 1:1 to the output node
    /* Usato per la rappresentazione Backend (e Frontend solo se le due rappresentazioni coincidono) */
    metadata?: ViewMetadata | DatabaseMetadata | ScriptMetadata | AlarmMetaData | FlowMetadata | any;

    /* Usato per la rappresentazione Frontend (solo se diversa dalla rappresentazione Backend)) */
    internalMetadata?: FlowInternalMetadata | any;
  };

  export interface ProjectNodeKey {
    projectId: string;
    domainId: string;
  }

  export interface Document {
    type: string;
    nodeId: ProjectNodeKey;
    name: string;
    locked?: boolean; // the document is opened by someone; only sent in the project structure
    data: DocumentData;
  }

  export type ProjectNode = Document | Folder;

  export interface Folder {
    name: string;
    nodeId: ProjectNodeKey;
    children: ProjectNode[];
  }

  export interface ProjectStructure {
    [key: string]: ProjectNode[];
  }

  export interface OpenedDocument {
    doc: Document;
    editorStatus: any;
    modified: boolean;
  }

  export interface Project {
    /**
     * Id of the project
     * Must be globally unique; currently a server-generated type 1 UUID
     */
    id: string;

    /**
     * Description of the project
     */
    description: string;

    /**
     * Structure of the project, only sent in response to openProject() and getProject() calls
     */

    structure?: ProjectStructure;

    // IDs of all open documents for this project and editor
    // Only sent in response to openProject() calls
    openDocuments?: number[];
  }

  export type BinaryFile = {
    name: string;
    id: string;
  };

  export type BinaryFolder = {
    name: string;
    subFolders?: BinaryFolder[];
    files?: BinaryFile[];
  };

  export type FlowNode = {
    id: string;
    type?: string;
    data: any;
    position: XYPosition;
    [key: string]: any;
    configType: string;
    dimensions: Dimensions;
  };

  export type FlowEdge = {
    id: string;
    source: string;
    sourceHandle?: string;
    target: string;
    targetHandle?: string;
  };

  export type FlowMetadata = {
    edges: {
      [key: string]: FlowEdge;
    };
  };

  export type FlowInternalMetadata = {
    nodes: {
      [key: string]: FlowNode;
    };
  };
}

import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { ToggleButton } from 'primereact/togglebutton';
import React from 'react';

interface ButtonBarProps extends PropertyEditorComponentProps {
  defaultValue?: boolean;
}

export const ToggleButtonBar = ({ data, defaultValue, onDataChange }: ButtonBarProps) => {
  const shownData = defaultValue !== undefined && data === undefined ? defaultValue : data;

  return <ToggleButton onLabel={'Sx'} offLabel={'Dx'} checked={!!shownData} onChange={(e) => onDataChange(e.value)} />;
};

export default ToggleButtonBar;

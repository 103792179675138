/* eslint-disable @typescript-eslint/no-explicit-any */
import './componentStyle.css';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FC, useRef, useState } from 'react';
import { PropertyEditorOptions } from './PropertyEditorComponent';
import { TabName } from '@config/widget/enum';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';
import PropertyList, { insertItem } from './PropertyListEditorComponent';

interface EditorSection {
  text: string;
  editors: PropertyEditorOptions[];
}

interface EditorTab {
  text: TabName;
  icon?: string;
  sections: EditorSection[];
}

export interface Editor {
  tabs: EditorTab[];
}

const WidgetEditor: FC = () => {
  const editingProperties = useWidgetPropsStore((state) => state.editingProperties);
  const widgetPropertyHandler = useWidgetPropsStore((state) => state.widgetPropertyHandler);

  const data = editingProperties.data;
  const parentData = editingProperties.parentData;
  const id = data['id'];
  const type = data['type'];
  const [activeAccordions, setActiveAccordions] = useState<unknown>({});
  const [tabIndex, setTabIndex] = useState(0);
  const tabEditor: Editor = { tabs: [] };

  const errorToolTipRef = useRef<TooltipRefProps>(null);
  const tabNameValues = Object.values(TabName);

  const handleAccordionChange = (tabId: string, newActiveIndexes: number) => {
    setActiveAccordions((prevState: any) => ({
      ...prevState,
      [tabId]: newActiveIndexes,
    }));
  };

  /**
   * Caricamento TabEditor contenente le varie tabs (es. Contenuto, Stile, Layout) del widget in edit
   */
  const loadTabEditor = () => {
    if (type) {
      const properties = widgetPropertyHandler.getPropertiesData(type, id);
      tabEditor.tabs = [];
      for (const callback of properties.onUpdate) {
        callback(data, parentData, properties.properties);
      }
      Object.entries(properties.properties).forEach(([field, property]) => {
        if (property?.editorOptions?.subProperties) {
          for (const callback of property.editorOptions.subProperties.onUpdate) {
            callback(data, parentData, property.editorOptions.subProperties.properties);
          }
        }
        let tab = tabEditor.tabs.find((t) => t.text === property.tab);
        if (!tab) {
          tab = { text: property.tab, sections: [] };
          tabEditor.tabs.push(tab);
        }
        let section = tab.sections.find((s) => s.text === property.section);
        if (!section) {
          section = { text: property.section, editors: [] };
          tab.sections.push(section);
        }
        insertItem(section.editors, id, field, property);
      });
      tabEditor.tabs.sort((a, b) => tabNameValues.indexOf(a.text) - tabNameValues.indexOf(b.text));
      if (tabIndex >= tabEditor.tabs.length) {
        setTabIndex(0);
      }
    }
    errorToolTipRef.current?.close();
  };

  // useEffect(() => {
  //   loadTabEditor();
  // }, [data]);

  function fetchData(): any {
    loadTabEditor();

    return Object.entries(tabEditor.tabs).map(([key, tab]) => {
      return (
        <TabPanel key={key} header={tab.text} leftIcon={tab.icon + ' p-icon' || 'p-icon'}>
          <Accordion
            style={{ minWidth: '330px' }}
            multiple
            activeIndex={activeAccordions[tab.text] || [0]}
            onTabChange={(newIndexes: any) => handleAccordionChange(tab.text, newIndexes.index)}
          >
            {Object.entries(tab.sections).map(([key, section]) => {
              return (
                <AccordionTab key={key} header={section.text}>
                  <PropertyList
                    list={section.editors}
                    errorToolTipRef={errorToolTipRef}
                    editingProperties={editingProperties}
                  />
                </AccordionTab>
              );
            })}
          </Accordion>
        </TabPanel>
      );
    });
  }

  return (
    <>
      {data['id'] ? (
        <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
          {fetchData()}
        </TabView>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '20px',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          Cliccare un Elemento nella view per vedere le sue proprietà
        </div>
      )}
      <Tooltip
        closeEvents={{ mouseleave: false }}
        ref={errorToolTipRef}
        style={{ fontSize: '1.2rem', backgroundColor: '#00bbb4', color: 'white', zIndex: '1000' }}
        border={'1px solid black'}
        opacity={1}
      />
    </>
  );
};

export default WidgetEditor;

import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import { ColorFieldConfig, PropertiesConfig } from '@config/widget/interface';
import { Project } from '@api';
import { TabName, TextPosition } from '@config/widget/enum';
import ButtonBar from './SelectButtonComponent';
import ColorPickerComponent from './ColorPickerComponent';
import NumberSlider from './NumberSliderComponent';
import ToggleButtonBar from './ToggleButtonComponent';
import ToggleCheckButtonBar from './ToggleCheckButtonComponent';
import TogglePositionBar from './TogglePositionBar';

export const captionPropertyField = (fieldConfig: ColorFieldConfig): PropertiesConfig => ({
  properties: {
    Visible: {
      tab: TabName.Layout,
      section: 'Caption',
      editor: ToggleCheckButtonBar,
      hasNotDefault: true,
      pinType: Project.PinType.None,
      caption: 'Visibile',
      captionPosition: TextPosition.Left,
    },
    CaptionPosition: {
      tab: TabName.Layout,
      section: 'Caption',
      editor: TogglePositionBar,
      hasNotDefault: true,
      pinType: Project.PinType.None,
      caption: 'Posizione',
      captionPosition: TextPosition.Left,
    },
    CaptionDistance: {
      tab: TabName.Layout,
      section: 'Caption',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Distanza da checkbox',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    CaptionWidth: {
      tab: TabName.Layout,
      section: 'Caption',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Larghezza',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    CaptionBackgroundColor: {
      tab: TabName.Layout,
      section: 'Caption',
      editor: ColorPickerComponent,
      defaultData: fieldConfig.backgroungColorDefault ?? '#000000',
      editorOptions: {
        data: fieldConfig.backgroungColorDefault ?? '#000000',
      },
      pinType: Project.PinType.None,
      caption: 'Background',
      captionPosition: TextPosition.Left,
      visible: true,
    },
    CaptionHTextAlign: {
      index: 2,
      tab: TabName.Layout,
      section: 'Caption',
      defaultData: AlignItems.Start,
      editorOptions: {
        options: [
          { icon: 'bi-align-start', value: AlignItems.Start },
          { icon: 'bi-align-center', value: AlignItems.Middle },
          { icon: 'bi-align-end', value: AlignItems.End },
        ],
      },
      caption: 'Allinea caption',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    CaptionIsMultiline: {
      tab: TabName.Layout,
      section: 'Caption',
      editor: ToggleButtonBar,
      hasNotDefault: true,
      pinType: Project.PinType.None,
      caption: 'Multilinea',
      captionPosition: TextPosition.Left,
    },
  },
});

import { ConnState, DeployStatus, Panel } from '@api';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ipcClient } from './ipcStore';

export interface DeployPanel extends Panel {
  isSelected: boolean;
  deployStatus: DeployStatus;
}

export interface PanelState {
  panelList: DeployPanel[];
  loadPanels: (projectId: string) => Promise<DeployPanel[]>;
  addPanelToProject: (
    projectId: string,
    id: { serial?: string; connUri?: string },
    projectRole?: string
  ) => Promise<void>;
  removePanelFromProject: (projectId: string, panelId: string) => Promise<void>;
  editProjectPanel: (
    projectId: string,
    panelId: string,
    what?: { newConnUri?: string; newRole?: string }
  ) => Promise<void>;
  editSelection: (projectId: string, panelId: string, selection: boolean) => Promise<void>;
  retryPanelConnection: (panelId: string) => Promise<void>;
  deployToPanels: (projectId: string) => void;
  updatePanelList: (newList: Panel[]) => Promise<void>;
  updatePanelDeployStatus: (projectId: string, panelId: string, state: DeployStatus) => Promise<void>;
}

export const usePanelStore = create(
  immer<PanelState>((set, get) => {
    const setPanelList = (newList: DeployPanel[]) => {
      set((state) => {
        state.panelList = newList;
      });
    };

    const updateDeployPanelList = (newList: Panel[]) => {
      const updatedList: DeployPanel[] = newList.map((newPanel) => {
        let selection = false;
        const existingPanel = get().panelList.find((panel) => panel.id === newPanel.id);
        if (existingPanel) {
          if (existingPanel.connState === ConnState.Connected) selection = existingPanel.isSelected;
          return { ...newPanel, isSelected: selection, deployStatus: existingPanel.deployStatus };
        }
        return { ...newPanel, isSelected: selection, deployStatus: DeployStatus.Unknown };
      });

      return updatedList;
    };

    const updateDeployPanelListForState = (projectId: string, panelId: string, newDeployState: DeployStatus) => {
      const updatedList: DeployPanel[] = get().panelList.map((panel) => {
        if (panel.projectId === projectId && panel.id === panelId) {
          return { ...panel, deployStatus: newDeployState };
        }
        return { ...panel };
      });

      return updatedList;
    };

    return {
      panelList: [],

      loadPanels: async (projectId: string) => {
        const newList: Panel[] = await ipcClient.listProjectPanels(projectId);
        const updatedList = updateDeployPanelList(newList);
        setPanelList(updatedList);

        return updatedList;
      },

      addPanelToProject: async (projectId: string, id: { serial?: string; connUri?: string }, projectRole?: string) => {
        await ipcClient.addPanelToProject(projectId, id, projectRole);
      },

      removePanelFromProject: async (projectId: string, panelId: string) => {
        await ipcClient.removePanelFromProject(projectId, panelId);
      },

      editProjectPanel: async (
        projectId: string,
        panelId: string,
        what?: { newConnUri?: string; newRole?: string }
      ) => {
        await ipcClient.editProjectPanel(projectId, panelId, what);
      },

      editSelection: async (projectId: string, panelId: string, selection: boolean) => {
        const updatedList: DeployPanel[] = get().panelList.map((panel) => {
          if (panel.projectId === projectId && panel.id === panelId) {
            let newDeployStatus = panel.deployStatus;
            if (selection === false) {
              newDeployStatus = DeployStatus.Unknown;
            }
            return { ...panel, isSelected: selection, deployStatus: newDeployStatus };
          }
          return { ...panel };
        });
        setPanelList(updatedList);
      },

      retryPanelConnection: async (panelId: string) => {
        await ipcClient.retryPanelConnection(panelId);
      },

      deployToPanels: (projectId: string) => {
        return new Promise<void>(async (resolve, reject) => {
          try {
            // reset old deploy status
            const updatedList: DeployPanel[] = get().panelList.map((panel) => {
              return { ...panel, deployStatus: DeployStatus.Unknown };
            });
            setPanelList(updatedList);

            // deploy
            const { panelList } = get();
            const panelIds: string[] = [];
            const deployPromises = panelList.filter((panel) => panel.isSelected);
            deployPromises.forEach((panel) => panelIds.push(panel.id));

            await ipcClient.deployToPanels(projectId, panelIds);
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      },

      updatePanelList: async (newList: Panel[]) => {
        const updatedList = updateDeployPanelList(newList);
        setPanelList(updatedList);
      },

      updatePanelDeployStatus: async (projectId: string, panelId: string, state: DeployStatus) => {
        const updatedList = updateDeployPanelListForState(projectId, panelId, state);
        setPanelList(updatedList);
      },
    };
  })
);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropData, WidgetDragHandler, WidgetPropertyHandler } from '@stores/widgetPropsStore';
import { EventsCollection, SupportDivHandler } from './support-div';
import { Project } from '@api';
import { PropertiesConfig } from '@config/widget/interface';
import { WidgetHandler } from './widget-handler/widget-handler';
import { WidgetProperties } from './widget-handler/widget';
import { propertyData } from '@config/widget/widgetData';

interface CompleteWidgetProperties extends WidgetProperties {
  type: string;
  id: string;
}

type TreeNode = ViewProperties | CompleteWidgetProperties;

type ContainerProperties = RootProperties | ViewProperties;

export interface ViewProperties extends CompleteWidgetProperties {
  children: TreeNode[];
}

export interface RootProperties {
  children: ViewProperties[];
}

export interface DropPosition {
  elementId: string;
  isParent: boolean;
}

export type ViewMetadata = ViewProperties;

export class ViewManager implements WidgetPropertyHandler, WidgetDragHandler {
  private eventsCollection: EventsCollection = new EventsCollection();
  private canvasEventsCollection: EventsCollection = new EventsCollection();
  private documentData: Project.DocumentData | null = null;
  private uniqueId: number;
  private currentDroppingData: DropData | null;
  private widgetHandler: WidgetHandler = null;

  private currentDropX: number | null = null;
  private currentDropY: number | null = null;

  private editWidgetProperties: (node: TreeNode, parentNode: ContainerProperties, widgetName: string) => void;
  private removeWidgetProperties: () => void;
  private updateData: () => void;
  private updateLinks: (links: Project.ResourceLink[]) => void;
  private selectionChange: (elementId: string) => void;

  private supportDivHandler: SupportDivHandler = new SupportDivHandler(
    {
      icons: [
        { class: 'fa-solid fa-pencil', actionId: 'edit' },
        { class: 'fa-solid fa-trash', actionId: 'delete' },
      ],
      onClick: this.onIconClick.bind(this),
      onStartDrag: this.onMenuStartDrag.bind(this),
    },
    JSON.stringify(this.documentId)
  );

  private onMenuStartDrag(elementId: string) {
    this.startElementDrag(document.getElementById(elementId));
  }

  private startElementDrag(element: HTMLElement) {
    this.supportDivHandler.setDraggingElement(element);
    this.startDrag({ sourceId: element.id });
  }

  private onIconClick(actionId: string, elementId: string) {
    switch (actionId) {
      case 'delete':
        this.onDeleteWidget(elementId);
        break;
      case 'edit':
        this.selectionChange?.(elementId);
        this.setSelectedElement(elementId);
        break;
    }
  }

  private manageWidgetEditRequest(id: string) {
    const [element, parent, _] = this.findNode(id);
    if (element && this.editWidgetProperties) {
      const ele = this.documentData.resourceTree.find((ele) => ele.data.id === id);
      this.editWidgetProperties(element, parent, ele?.name);
    }
  }

  private onDeleteWidget(id: string) {
    const [__element, parent, index] = this.findNode(id);
    if (parent) {
      this.removeChildren(__element);
      this.removeNode(parent, index);
      this.refreshView();
      this.updateData?.();
      this.removeWidgetProperties();
    }
  }

  private onMoveWidget(sourceId: string, target: DropPosition) {
    const [source, sourceParent, sourceIndex] = this.findNode(sourceId);
    if (source && sourceParent && sourceId !== target.elementId) {
      this.removeNode(sourceParent, sourceIndex);
      const container = document.getElementById(sourceId) as HTMLDivElement;
      if (container) {
        container.style.left = `${this.currentDropX}px`;
        container.style.top = `${this.currentDropY}px`;
      }
      this.setNode(source, target);
      this.updateData?.();
    }
  }

  private onCreateWidget(type: string, target: DropPosition, dropX: number, dropY: number) {
    const id = `wdg${++this.uniqueId}-${this.documentId.domainId}`;
    const name = `${type}${this.uniqueId}`;
    const newElement: TreeNode = { type, id };

    if (propertyData[type].config.properties['Caption']) {
      newElement['Caption'] = name;
    }

    const targetElement = document.getElementById(target.elementId);
    const parentSvg = targetElement.querySelector('svg') || targetElement.closest('svg');

    if (parentSvg) {
      const svgPoint = this.getSvgCoordinates(parentSvg, dropX, dropY);
      newElement['x'] = svgPoint.x;
      newElement['y'] = svgPoint.y;
    }
    requestAnimationFrame(() => {
      const elements = document.querySelectorAll('[data-resizable]');

      elements.forEach((elementDom) => {
        if (elementDom && (type === 'svgRect' || type === 'svgText' || type === 'svgExternal')) {
          // Applica i resize handles ad ogni elemento
          this.supportDivHandler.attachResizeHandles(elementDom as HTMLElement);
        }
      });
    });

    this.documentData.resourceTree.push({ name: name, data: { id: id, type } });
    this.setNode(newElement, target);
    this.updateData?.();
  }

  private isViewElement(element: HTMLElement): boolean {
    return element.classList.contains('fast-view') || element.classList.contains('fast-page');
  }

  // Add a named function for the click event handler
  private plusIconClickHandler = (element: HTMLElement): ((e: Event) => void) => {
    return (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      this.removePlusIconFromView(element);
    };
  };

  // Cancel
  private canvasKeyDown(e: KeyboardEvent) {
    // Verifica se il tasto premuto è il tasto Canc (KeyCode 46)
    if (e.key === 'Delete') {
    }
  }

  private canvasClick(__e: Event) {}

  private canvasDragOver(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    // Salva le coordinate del mouse per usarle durante il drop
    this.currentDropX = e.clientX;
    this.currentDropY = e.clientY;
    this.supportDivHandler.dragOver(e.clientX, e.clientY);
    const rootElement = document.getElementById(`page-${this.documentId.domainId}`);

    // Se draggo fuori dalla view principale interrompo il drag
    if (this.supportDivHandler.dropPosition() && !rootElement.contains(e.currentTarget as HTMLElement)) {
      this.supportDivHandler.hideDropIcon();
    }
  }

  private isContainer(obj: TreeNode | ContainerProperties): obj is ContainerProperties {
    return 'children' in obj;
  }

  private isView(obj: TreeNode): obj is ViewProperties {
    return (
      typeof obj.type == 'string' &&
      typeof obj.id == 'string' &&
      ['view', 'page', 'accordion', 'tabs', 'svg'].includes(obj.type)
    );
  }

  private isWidget(obj: TreeNode): obj is CompleteWidgetProperties {
    return typeof obj.type == 'string' && typeof obj.id == 'string';
  }

  private findNode(id: string): [TreeNode, ContainerProperties | null, number] {
    const find = (node: ContainerProperties, id: string): [TreeNode, ContainerProperties | null, number] | null => {
      for (let i = 0; i < node.children?.length; i++) {
        const n = node.children[i];
        if (n.id === id) {
          return [n, node, i];
        }
        if (this.isContainer(n)) {
          const result = find(n, id);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    if (this.documentData) {
      if (this.documentData.metadata.id === id) {
        return [this.documentData.metadata, { children: [this.documentData.metadata] }, 0];
      } else {
        const ret = find(this.documentData.metadata, id);
        if (ret) {
          return ret;
        } else {
          return [null, null, null];
        }
      }
    } else {
      return [null, null, null];
    }
  }

  private refreshView() {
    this.detachFromScreen();
    this.widgetHandler.setProperties('root', {
      'active-page': this.documentData.metadata,
    });
    this.attachToScreen();
  }

  private setNode(node: TreeNode, target: DropPosition) {
    const [element, parent, index] = this.findNode(target.elementId);

    const targetElement = document.getElementById(target.elementId);
    if (targetElement instanceof SVGElement) {
      const svgPoint = this.getSvgCoordinates(targetElement, this.currentDropX, this.currentDropY);

      node['x'] = svgPoint.x;
      node['y'] = svgPoint.y;
    }

    if (target.isParent && this.isView(element)) {
      element.children = [...(element.children ?? []), node];
    } else {
      if (!parent) {
        console.log('error');
      }
      parent.children?.splice(index, 0, node);
    }
    this.refreshView();
  }

  private getSvgCoordinates(svgElement: SVGElement, clientX: number, clientY: number) {
    const svgRect = svgElement.getBoundingClientRect();
    const x = clientX - svgRect.left;
    const y = clientY - svgRect.top;
    return { x, y };
  }

  private removeNode(parent: ContainerProperties, index: number) {
    parent.children?.splice(index, 1);
    if (!parent.children.length) {
      delete parent.children;
    }
  }

  /**
   * Rimozione ricorsiva dei children
   * @param element elemento da eliminare
   */
  private removeChildren(element: any) {
    if (element.children && element.children.length > 0) {
      element.children.forEach((child: any) => {
        this.removeChildren(child);
      });
    }

    const eleIdx = this.documentData.resourceTree.findIndex((ele) => ele.data.id === element.id);
    if (eleIdx !== -1) {
      this.documentData.resourceTree.splice(eleIdx, 1);
    }
  }

  private getViewMaxId(node: TreeNode): number {
    let max = 0;
    const regex = /^wdg(\d+)-/;
    const match = node.id.match(regex);

    if (match && match[1]) {
      // Converte il match in numero intero
      max = parseInt(match[1], 10);
    }
    if (this.isView(node)) {
      node.children?.forEach((ele) => {
        max = Math.max(max, this.getViewMaxId(ele));
      });
    }
    return max;
  }

  private recursiveAttachToDiv(div: HTMLElement) {
    if (div) {
      this.attach(div);
      div.childNodes.forEach((child) => {
        if (child instanceof HTMLElement) this.recursiveAttachToDiv(child);
      });
    }
  }

  private attach(element: HTMLElement) {
    // draggable
    element.setAttribute('draggable', 'true');

    if (element.id === `page-${this.documentId.domainId}` && !element.firstChild) {
      element.classList.add('fast-view-empty-root');
    } else {
      if (this.isViewElement(element) && !element.firstChild) {
        element.classList.add('fast-view-empty');
      }
    }

    // add plus element into an empty view
    if (element.classList.contains('fast-view-empty')) {
      this.addPlusIconToEmptyView(element, this.plusIconClickHandler);
    }

    this.eventsCollection.register({
      event: 'dragenter',
      element: element,
      handler: (e: DragEvent) => {
        e.preventDefault();
      },
    });

    this.eventsCollection.register({
      event: 'dragleave',
      element: element,
      handler: (e: DragEvent) => {
        e.preventDefault();
      },
    });

    if (this.isViewElement(element)) {
      this.eventsCollection.register({
        event: 'dragover',
        element: element,
        handler: (e: DragEvent) => {
          if (this.currentDroppingData) {
            e.preventDefault();
            e.stopPropagation();

            this.supportDivHandler.dragOver(e.clientX, e.clientY);
            const overElement = e.currentTarget as HTMLElement;
            this.supportDivHandler.checkDropPosition(overElement, e.clientX, e.clientY);
          }
        },
      });

      // Drop

      this.eventsCollection.register({
        event: 'drop',
        element: element,
        handler: (e: DragEvent) => {
          e.preventDefault();
          e.stopPropagation();
          const [dropPosition, dropData] = this.stopDrag();

          if (dropPosition && dropData) {
            // Ottenere le coordinate del mouse dall'evento DragEvent
            const dropX = e.clientX;
            const dropY = e.clientY;

            if (!dropData.sourceId) {
              this.onCreateWidget(dropData.type, dropPosition, dropX, dropY);
            }
            if (dropData.sourceId) {
              this.onMoveWidget(dropData.sourceId, dropPosition);
            }
          }
        },
      });
    }

    // DragStart
    this.eventsCollection.register({
      event: 'dragstart',
      element: element,

      handler: (e: DragEvent) => {
        e.stopPropagation();
        if ((e.currentTarget as HTMLElement).id === `page-${this.documentId.domainId}`) {
          e.preventDefault();
        } else {
          e.dataTransfer.setDragImage(new Image(), 0, 0);
          this.startElementDrag(e.currentTarget as HTMLElement);
        }
      },
    });
  }

  private detachFromScreen() {
    this.recursiveDetachFromDiv(document.getElementById(`page-${this.documentId.domainId}`));
    this.supportDivHandler.detachFromScreen();
  }

  private recursiveDetachFromDiv(div: HTMLElement) {
    if (div) {
      this.detach(div);
      div.childNodes.forEach((child) => {
        if (child instanceof HTMLElement) this.recursiveDetachFromDiv(child);
      });
    }
  }

  private detach(__element: HTMLElement) {
    this.eventsCollection.unregister();
  }

  private removePlusIconFromView(__view: HTMLElement) {
    return;

    /*
    const spanElements = view.getElementsByClassName("spanIconPlus");
    if (spanElements.length > 0) {
      for (let i = spanElements.length - 1, imin = 0; i >= imin; i--) {
        // Remove click event before removing the element
        const plusIiconElement = spanElements[i] as HTMLElement;
        plusIiconElement.removeEventListener(
          "click",
          this.plusIconClickHandler(view).bind(this),
        );

        // remove element
        view.removeChild(spanElements[i]);
      }
    }
    */
  }

  private addPlusIconToEmptyView(__view: HTMLElement, __clickCallback: any) {
    return;
    // new icon
    /*
    const plusIiconElement = document.createElement("span");
    plusIiconElement.className = "spanIconPlus";
    plusIiconElement.innerText = "+";
    plusIiconElement.style.position = "absolute";
    plusIiconElement.style.top = "50%";
    plusIiconElement.style.left = "50%";
    plusIiconElement.style.transform = "translate(-50%, -50%)"; // Trasla l'elemento indietro da metà della sua larghezza e altezza
    plusIiconElement.style.display = "block";
    plusIiconElement.style.cursor = "pointer";
    view.style.position = "relative"; // Imposta la posizione relativa sull'elemento padre
    view.appendChild(plusIiconElement);

    // Add click event to iconElement with the clickHandler
    plusIiconElement.addEventListener("click", clickCallback(view).bind(this));
    */
  }

  constructor(private rootElement: HTMLElement, private documentId: Project.ProjectNodeKey) {
    this.widgetHandler = new WidgetHandler(this.rootElement);

    this.canvasEventsCollection.register({
      event: 'dragleave',
      element: document.body,
      handler: (e: DragEvent) => {
        e.preventDefault();
      },
    });

    this.canvasEventsCollection.register({
      event: 'dragenter',
      element: document.body,
      handler: (e: DragEvent) => {
        e.preventDefault();
      },
    });

    this.canvasEventsCollection.register({
      event: 'dragend',
      element: document.body,
      handler: (e: DragEvent) => {
        e.preventDefault();
        if (!this.rootElement.contains(document.elementFromPoint(e.clientX, e.clientY))) {
          this.supportDivHandler.updateBorders();
        }
      },
    });

    this.canvasEventsCollection.register({
      event: 'drop',
      element: document.body,
      handler: (e: DragEvent) => {
        e.preventDefault();
        this.supportDivHandler.updateBorders();
        this.stopDrag();
      },
    });

    this.canvasEventsCollection.register({
      event: 'click',
      element: document.body,
      handler: this.canvasClick.bind(this),
    });

    this.canvasEventsCollection.register({
      event: 'keydown',
      element: document.body,
      handler: this.canvasKeyDown.bind(this),
    });

    this.canvasEventsCollection.register({
      event: 'dragover',
      element: document.body,
      handler: this.canvasDragOver.bind(this),
    });
  }
  getPropertiesData(type: string, id: string): PropertiesConfig {
    return propertyData[type].config;
  }

  startDrag(dropData: DropData) {
    this.currentDroppingData = dropData;
  }

  stopDrag(): [DropPosition, DropData] {
    if (this.supportDivHandler.getDraggingElement()) {
      this.supportDivHandler.setDraggingElement(null);
      this.supportDivHandler.updateBorders();
    }
    const dropData = this.currentDroppingData;
    this.currentDroppingData = null;
    return [this.supportDivHandler.hideDropIcon(), dropData];
  }

  setData(data: Project.DocumentData) {
    this.documentData = data;
    if (!this.documentData.resourceTree) {
      this.documentData.resourceTree = [];
    }
    this.uniqueId = this.getViewMaxId(data.metadata);
    this.refreshView();
  }

  detachFromDocument() {
    this.detachFromScreen();
    this.canvasEventsCollection.unregister();
  }

  attachToScreen() {
    this.recursiveAttachToDiv(document.getElementById(`page-${this.documentId.domainId}`));
    this.supportDivHandler.attachToScreen();
  }

  dataAreCorrect(view: ViewProperties): boolean {
    const _dataAreCorrect = (children: TreeNode[]): boolean => {
      if (children) {
        for (const ele of children) {
          if (!this.isWidget(ele)) {
            return false;
          }
          if (this.isView(ele) && !_dataAreCorrect(ele.children)) {
            return false;
          }
        }
      }
      return true;
    };

    return this.isView(view) && _dataAreCorrect(view.children);
  }

  onEditWidgetProperties(event: (node: TreeNode, parentData: ContainerProperties, widgetName: string) => void) {
    this.editWidgetProperties = event;
  }

  onRemoveWidgetProperties(event: () => void) {
    this.removeWidgetProperties = event;
  }

  updateResourceLinks(links: Project.ResourceLink[]): void {
    this.updateLinks?.(links);
  }

  onUpdateResourceLinks(event: (links: Project.ResourceLink[]) => void) {
    this.updateLinks = event;
  }

  onUpdateData(event: () => void) {
    this.updateData = event;
  }

  onSelectionChange(event: (elementId: string) => void) {
    this.selectionChange = event;
  }

  setProperty(widgetId: string, sectionId: string, fieldKey: string, value: any) {
    const [element, __parent, _] = this.findNode(widgetId);
    if (fieldKey === 'Name') {
      const ele = this.documentData.resourceTree.find((ele) => ele.data.id === widgetId);
      if (ele) {
        ele.name = value;
      }
    } else if (fieldKey === 'Headers') {
      const newHeaders: string[] = value.Items;
      let currentHeaders: string[] = [];

      const metadataElement = this.findChildren(this.documentData.metadata, widgetId);

      const currentElements: any[] = metadataElement.children || [];

      if (currentElements.length === 0) {
        metadataElement.children = [];
      } else {
        currentHeaders = currentElements.map((item) => item.caption);
      }

      //creazione
      if (newHeaders !== undefined && newHeaders.length > currentHeaders.length) {
        newHeaders.forEach((newHeader: string, index: number) => {
          //se esiste in newHeaders ma NON esiste in currentHeaders, effettua la creazione
          if (!currentHeaders.some((currentHeader) => currentHeader === newHeader)) {
            const i = this.getMaxId(currentElements);

            //inserimento header alla posizione corretta
            currentHeaders.splice(index, 0, newHeader);

            //aggiornamento metadata
            const newMetadataEntry = {
              id: metadataElement.id + '.hdr-' + i,
              type: 'view',
              caption: newHeader,
              children: [] as any[],
            };
            metadataElement.children.splice(index, 0, newMetadataEntry);

            //aggiornamento resourcetree
            this.documentData.resourceTree.push({
              name: 'header' + i,
              data: { id: metadataElement.id + '.hdr-' + i, type: 'view' },
            });
          }
        });
      }

      //modifica/cancellazione
      const modifiedItems = currentHeaders.filter((item) => !newHeaders.includes(item));
      if (modifiedItems.length > 0) {
        //un elemento è stato rimosso
        if (currentHeaders.length > newHeaders.length) {
          //effettua cancellazione
          modifiedItems.forEach((captionToRemove) => {
            //rimozione da resourcetree
            //rimozione children della view
            const viewChildren = metadataElement.children.find(
              (child: any) => child.caption === captionToRemove
            ).children;

            viewChildren.forEach((child: any) => {
              this.documentData.resourceTree = this.documentData.resourceTree.filter((ele) => ele.data.id !== child.id);
            });
            //rimozione view
            this.documentData.resourceTree = this.documentData.resourceTree.filter(
              (ele) => ele.data.id !== metadataElement.children.find((ele2: any) => ele2.caption === captionToRemove).id
            );

            //rimozione da metadata
            metadataElement.children = metadataElement.children.filter(
              (child: any) => child.caption !== captionToRemove
            );
          });
        } else {
          //effettua modifica
          for (let i = 0; i < currentHeaders.length; i++) {
            //trovato componente modificato
            if (currentHeaders.at(i) !== newHeaders.at(i)) {
              //modifica metadata
              metadataElement.children.find((child: any) => child.caption === currentHeaders.at(i)).caption =
                newHeaders.at(i);
            }
          }
        }
      }
      element[fieldKey] = value;
      this.setData(this.documentData);
    } else {
      if (value === undefined || null) {
        delete element[fieldKey];
      } else {
        if (element) element[fieldKey] = value;
      }
      this.widgetHandler?.setProperties(widgetId, { [fieldKey]: value });
      if (['AlignSelf', 'Margin', 'AlignEnd', 'AlignPage', 'Margins', 'Gaps', 'Padding'].includes(fieldKey)) {
        this.updateSelectionBorder();
      }
    }
    this.updateData?.();
  }

  //ricerca il children all'interno del container
  private findChildren(root: any, widgetId: string): any {
    if (root.id === widgetId) {
      return root;
    }

    if (root.children && root.children.length > 0) {
      for (const child of root.children) {
        const found = this.findChildren(child, widgetId);
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  //ricerca dell'id massimo per la creazione
  private getMaxId = (items: any[]) => {
    const maxId = items.reduce((max, item) => {
      const match = item.id.match(/\d+$/);
      const num = match ? parseInt(match[0], 10) : 0;
      return num > max ? num : max;
    }, 0);
    return maxId + 1;
  };

  checkNameProperty(widgetId: string, data: string): string {
    const regex = /^[A-Za-z][A-Za-z0-9_]*$/;
    if (!regex.test(data)) {
      return "Formato del campo 'Name' non valido";
    }

    const ele = this.documentData.resourceTree.find((value) => value.name === data);
    if (ele && ele.data.id !== widgetId) {
      return "Questo nome e' gia' stato usato";
    }

    return null;
  }

  checkProperty(widgetId: string, fieldKey: string, value: any): string {
    if (fieldKey === 'Name') {
      return this.checkNameProperty(widgetId, value);
    } else {
      return null;
    }
  }

  updateSelectionBorder() {
    this.supportDivHandler.updateSelectionBorder();
  }

  setSelectedElement(elementId: string) {
    this.manageWidgetEditRequest(elementId);
    this.supportDivHandler.setSelectedElement(elementId);
  }
}

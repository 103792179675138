import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class Checkbox extends SimpleWidget {
  inputElement: HTMLInputElement;
  labelElement: HTMLLabelElement;

  constructor(public domElement: HTMLElement) {
    super(domElement);

    this.inputElement = document.createElement('input');
    this.inputElement.className = 'checkbox-input';

    this.labelElement = document.createElement('label');
    this.labelElement.className = 'checkbox-label';
    this.setCaptionStartingProperties();
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-checkbox');
    this.domElement.innerText = '';
    this.domElement.appendChild(this.inputElement);
    this.domElement.appendChild(this.labelElement);
    super.attach();
  }

  setCaptionStartingProperties(): void {
    Object.assign(this.labelElement.style, {
      display: 'initial',
      marginLeft: '0',
      marginRight: '0',
      order: '1',
      width: '100px',
      backgroundColor: 'transparent',
      textAlign: 'left',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      wordBreak: 'normal',
    });
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'type':
          this.inputElement.type = data;
          break;
        case 'Caption':
          this.labelElement.textContent = data;
          break;
        case 'Color':
          this.labelElement.style['color'] = data;
          break;
        case 'Visible':
          this.labelElement.style['display'] = !data ? 'initial' : 'none';
          break;
        case 'CaptionPosition':
          if (!data) {
            //checkbox a sinistra, label a destra
            this.inputElement.style['order'] = '0';
            this.labelElement.style['order'] = '1';
          } else {
            //label a sinistra, checkbox a destra
            this.inputElement.style['order'] = '1';
            this.labelElement.style['order'] = '0';
          }

          //inversione distanza tra caption e textbox
          if (this.labelElement.style['marginLeft'] > '0' && this.labelElement.style['order'] === '0') {
            this.labelElement.style['marginRight'] = this.labelElement.style['marginLeft'];
            this.labelElement.style['marginLeft'] = '0';
          } else if (this.labelElement.style['marginRight'] > '0' && this.labelElement.style['order'] === '1') {
            this.labelElement.style['marginLeft'] = this.labelElement.style['marginRight'];
            this.labelElement.style['marginRight'] = '0';
          }
          break;
        case 'CaptionDistance':
          const umDistance = this.getUm(data);
          if (this.labelElement.style['order'] === '1') {
            this.labelElement.style['marginLeft'] = data + umDistance;
          } else {
            this.labelElement.style['marginRight'] = data + umDistance;
          }
          break;
        case 'CaptionWidth':
          const umWidth = this.getUm(data);
          this.labelElement.style['width'] = data + umWidth;
          break;
        case 'CaptionBackgroundColor':
          this.labelElement.style['backgroundColor'] = data;
          break;
        case 'CaptionHTextAlign':
          if (data === 1) {
            this.labelElement.style['textAlign'] = 'center';
          } else if (data === 2) {
            this.labelElement.style['textAlign'] = 'right';
          } else {
            this.labelElement.style['textAlign'] = 'left';
          }
          break;
        case 'CaptionIsMultiline':
          this.labelElement.style['whiteSpace'] = data ? 'normal' : 'nowrap';
          this.labelElement.style['wordBreak'] = data ? 'break-word' : 'normal';
          break;
        default:
          if (key.includes('Font')) {
            this.setFontProperties(properties, this.labelElement, 'Label');
          } else {
            this.setDefaultProperties(properties);
          }
      }
    }
    super.setProperties(properties);
  }
}

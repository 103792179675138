/* eslint-disable @typescript-eslint/no-explicit-any */
import './MultiPropertyArray.css';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { PropertyEditorOptions } from '../main/PropertyEditorComponent';
import { TabName, TextPosition } from '@config/widget/enum';
import { deepCopy, deepEqual } from '@services/utils';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';

import AutoFocusDiv from '@components/common/AutoFocusDiv';
import NumberSlider from './NumberSliderComponent';
import PropertyList from '../main/PropertyListEditorComponent';
import React, { useEffect, useRef, useState  } from 'react';

export interface MultiPropertyArrayItemProps {
  widgetId?: string;
  itemId: string;
  label: string;
  display: boolean;
  autoedit?: boolean;
  upVisible?: boolean;
  downVisible?: boolean;
  list: PropertyEditorOptions[];
  sendMessageToParent?: any;
}




 export interface MultiPropertyEditorProps extends PropertyEditorComponentProps {
 
  customOverlayPosition?: boolean;
}

 let MultiPropertyEditorOverlayCounter = 0;

export const MultiPropertyArrayEditorItem = ({
  itemId: itemId,
  label,
  display,
  autoedit,
  upVisible, 
  downVisible,
  list,
  sendMessageToParent,
 

 }: MultiPropertyArrayItemProps) => {
  const editingProperties = useWidgetPropsStore((state) => state.editingProperties);
  const widgetId = editingProperties.data?.['id'];
  const [ editMode, setEditMode] = useState(autoedit);
  const  [ editText, setEditText] = useState(label);
  const currentLabel = useRef<string>(label);
  const [ confirmDelete, setConfirmDelete] = useState(false);
  useEffect(() => {
    setEditMode(autoedit);
    
  }, [autoedit]);

  const bt = useRef<Button>(null);
  const op = useRef<OverlayPanel>(null);
  const overlayId = useRef<string>('MultiPropertyEditorOverlay' + (++MultiPropertyEditorOverlayCounter).toString());

  const editorOptions: PropertyEditorOptions[] = [];

  function onShowOverlay() {
  
  }

  const editTextKeyDownHandler = (event:any) => {

    if(editMode){
     
      if(event.key==="Enter")
        saveEditText();
      else if(event.key==="Escape")
        setEditMode(false);
      
    }
  }

  const saveEditText = () => {
    sendMessageToParent('edit-label', JSON.stringify({ itemId: itemId, newLabel: editText }));
    currentLabel.current = editText;
    setEditMode(false);
  };

  function closeEditText() {
    setEditText(currentLabel.current);
    setEditMode(false);
  }

  const openEditBox = () =>{
    setEditText(currentLabel.current);
    setEditMode(true);
    setConfirmDelete(false);

    setTimeout(() => {
        
      let editText = document.getElementById('editText-'+itemId) as HTMLInputElement;
       editText.select();  editText.focus();
    }, 500);

}


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onclickHandler(ev: any) {
    op.current.toggle(ev); /*
		let x = (ev as MouseEvent).pageX;
		let y = (ev as MouseEvent).pageY;
		let overlayElem = document.getElementById(overlayId.current);
		//overlayElem.style.top = (y.toString() + " px");

		*/
  }

  const mouseOverHandler = (event: any) => {
    event.currentTarget.classList.add('multi-property-array-mouseOver');
  };

  const mouseLeaveHandler = (event: any) => {
    event.currentTarget.classList.remove('multi-property-array-mouseOver');
  };
 

  return (
    <div className="grid" >
      <div  className="col-10" 
            onMouseOver={mouseOverHandler}
            onMouseLeave={mouseLeaveHandler}
            onMouseOut={mouseLeaveHandler} >
       <div  className={editMode ? "" : "hidden "} style={{  padding:'5px',  textAlign: 'left'}}> 
        <InputText id={'editText-'+itemId} style={{maxWidth:'100%', padding:'0px' }} value={editText} onChange={ (ev)=>setEditText(ev.target.value)} className={"p-inputtext-sm padding-1"} onClick={ (ev)=>{ ev.stopPropagation();  } }  onKeyDown={ (ev)=>{ ev.stopPropagation(); editTextKeyDownHandler(ev); } }/>
        &nbsp;
          <Button
            label="Salva"
            className={'padding-2'}
            icon="pi pi-check"
            severity="success"
            rounded
            size="small"
            onClick={(ev) => {
              ev.stopPropagation();
              saveEditText();
            }}
          />
          &nbsp;
          <Button
            label="Annulla"
            className={'padding-2'}
            icon="pi pi-times"
            severity="danger"
            rounded
            size="small"
            onClick={(ev) => {
              ev.stopPropagation();
              closeEditText();
            }}
          />
       </div>
       <div  className={editMode ? " hidden " : ""}  >
          <label htmlFor={editText} className="ml-2" style={{ width: '65%' }}>{editText}</label>
          <span  className={' multi-property-array-actions'} > 
                          &nbsp;&nbsp;&nbsp;<i className="pi pi-arrow-up border-action" style={{ fontSize: '1rem', visibility: (upVisible ? 'visible' : 'hidden') }}  onClick={(ev) => { ev.stopPropagation(); sendMessageToParent("upAuthorization",JSON.stringify({itemId:itemId}))  }}></i>
                          &nbsp;&nbsp;<i className="pi pi-arrow-down border-action" style={{ fontSize: '1rem', visibility: (downVisible ? 'visible' : 'hidden')  }}  onPointerDown={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()} onClick={ (ev)=>{ ev.stopPropagation(); sendMessageToParent("downAuthorization",JSON.stringify({itemId:itemId}))  } }></i> 
                          &nbsp;&nbsp;<i className="pi pi-pencil" style={{ fontSize: '1rem' }}  onPointerDown={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()} onClick={ (ev)=>{ ev.stopPropagation(); openEditBox() } }></i>
                          &nbsp;&nbsp;<i className="pi pi-times" style={{ fontSize: '1rem' , color:'red' }}  onClick={ (ev)=>{ ev.stopPropagation();  setConfirmDelete(true) } }></i>
          </span>
        </div>
        <div className={"align-items-left " +(confirmDelete ? "" : " hidden ") }>
                <span  className="error" >Vuoi davvero eliminare questo item?&nbsp;&nbsp; 
                       <Button  label="Si" size="small" severity="danger" className={"padding-2"}  onClick={ (ev)=>{ ev.stopPropagation(); sendMessageToParent("delete-item",JSON.stringify({itemId:itemId})); setConfirmDelete(false); } } />&nbsp;&nbsp;
                       <Button label="No" size="small" className={"padding-2"}  onClick={ (ev)=>{ ev.stopPropagation();  setConfirmDelete(false) } } /></span>
        </div>
      </div>
      <div  className="col-2"  >
      <Button
        ref={bt}
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Bookmark"
        outlined
        onClick={(e) => {
          onclickHandler(e);
        }}
      />
      <OverlayPanel
        ref={op}
        closeOnEscape
        dismissable={true}
        id={overlayId.current}
        onShow={onShowOverlay}
        className={ 'multi-properties-overlay' }
      >
        <AutoFocusDiv style={{ width: '300px' }} onBlur={() => op.current.hide()}>
          <PropertyList
            list={list}
            addResetChildFunction={null}
            editingProperties={editingProperties}
          />
        </AutoFocusDiv>
      </OverlayPanel>
      </div>
      <div  className="col-11"   style={{ borderBottom: '1px solid white', marginLeft:'10px'}} ></div>
      <div  className="col-12"  ><br/></div>
      </div>
  );
};



export interface MultiPropertyArrayEditorProps extends PropertyEditorComponentProps {
  subProperties: MultiPropertyArrayItemProps[];
}

export const MultiPropertyArrayEditor = ({
  subProperties,
  addResetChildrenFc,
  data,
  onDataChange,
}: MultiPropertyArrayEditorProps) => {
  /*
    const itemTemplate = (item:any) => {
        return <PropertyList list={editorOptions} addResetChildFunction={addResetChildrenFc} />;
    };
    const [state, setState] = useState<MultiPropertyArrayItemProps[]>(subProperties);  */
 
  const newElementCreated = useRef(false);
  const newElementId = useRef(null);
  
  const widgetId = useWidgetPropsStore((state) => state.editingProperties.data?.['id']);
  const initializeEditor = () => {
    
      let itemId = 0;
      let res:Array<MultiPropertyArrayItemProps> = [];
      
      if (![undefined, null].includes(data)) {
      if (![undefined, null].includes(data.Items)) {
       data.Items.forEach(item => {
        ++itemId;
        res.push({
          itemId: itemId.toString(),
          widgetId: widgetId,
          display: false,
          autoedit: false,
          label: item,
          list: createEditorOptions(itemId.toString()),
        });
       });
      }
     }
    
      return res;
  }
  const editorOptions = useRef<MultiPropertyArrayItemProps[]>([]);
  useEffect(() => {
    editorOptions.current = initializeEditor();
    
  }, []);


  

  /*
    Object.entries(subProperties.properties).map(([key, editorConfig]) => {
        insertItem(editorOptions, widgetId, key, editorConfig);
    });  */

  const fontFamilySelectedItemTemplate = (option: any, props: any) => {
    if (option) {
      return <span style={{ fontFamily: option.value }}>{option.label}</span>;
    } else return <span>{props.placeholder}</span>;
  };

  const fontFamilyItemTemplate = (option: any) => {
    return <span style={{ fontFamily: option.value }}>{option.label}</span>;
  };

  const createEditorOptions = (itemId: string) => {
    const editorOptions: PropertyEditorOptions[] = [];
    editorOptions.push({
      field: 'Dimensione-' + itemId,
      widgetId: widgetId,
      sectionId: itemId,
      property: {
        index: parseInt(itemId),
        defaultData: 20,
        editorOptions: {
          minValue: 0,
          maxValue: 1000,
        },
        caption: 'Dimensione',
        editor: NumberSlider,
        captionPosition: TextPosition.Up,
        pinType: Project.PinType.None,
        hasUM: true,
      },
    });

    /*
                        editorOptions.push({ field:"BorderRadius", 
                        widgetId: widgetId+"-"+itemId, 
                       
                        property:{
                            section: 'Border',
                            defaultData:  0,
                            editorOptions: {
                              minValue: 0,
                              maxValue: 1000,
                            },
                            caption: 'Border Radius',
                            editor: NumberSlider,
                            captionPosition: TextPosition.Up,
                            isResponsive: true,
                            pinType: Project.PinType.None,
                            hasUM: true,
                       }});  */

    /*
    editorOptions.push({ field:"Famiglia", 
                         widgetId: widgetId, 
                         property:{
                            
                            section: 'Font',
                            hasUM: false,
                            caption: 'Famiglia',
                            editor: EsaDropdown,
                            captionPosition: TextPosition.Left,
                            pinType: Project.PinType.None,
                            defaultData:  'Arial',
                            editorOptions: {
                              placeholder: ' -Seleziona- ',
                              filterPresent: true,
                              itemTemplate: fontFamilyItemTemplate,
                              valueTemplate: fontFamilySelectedItemTemplate,
                              options: [
                                { label: 'Arial', value: 'Arial' },
                                { label: 'Baskerville', value: 'Baskerville' },
                                { label: 'Brush Script', value: 'Brush Script' },
                                { label: 'Calibri', value: 'Calibri' },
                                { label: 'Cambria', value: 'Cambria' },
                                { label: 'Consolas', value: 'Consolas' },
                                { label: 'Courier', value: 'Courier' },
                                { label: 'Garamond', value: 'Garamond' },
                                { label: 'Georgia', value: 'Georgia' },
                                { label: 'Helvetica', value: 'Helvetica' },
                                { label: 'Palatino', value: 'Palatino' },
                                { label: 'Tahoma', value: 'Tahoma' },
                                { label: 'Times New Roman', value: 'Times New Roman' },
                                { label: 'Trebuchet MS', value: 'Trebuchet MS' },
                                { label: 'Verdana', value: 'Verdana' },
                              ],
                            },
                       }})

                       editorOptions.push({ 
                        field:"Peso", 
                        widgetId: widgetId, 
                        property:{
                          
              hasUM: false,
              editor: EsaDropdown<string>,
              pinType: Project.PinType.None,
              caption: 'Peso',
              captionPosition: TextPosition.Left,
              defaultData:  400,
              editorOptions: {
                filterPresent: true,
                options: [
                  { label: 'Thin', value: 100 },
                  { label: 'Extra Light', value: 200 },
                  { label: 'Light', value: 300 },
                  { label: 'Normal ', value: 400 },
                  { label: 'Medium', value: 500 },
                  { label: 'Semi Bold', value: 600 },
                  { label: 'Bold', value: 700 },
                  { label: 'Extra Bold', value: 800 },
                  { label: 'Black', value: 900 },
                ],
              },
                       }})    */

    return editorOptions;
  };

  const addItem_ = (itemId: string, label: string) => {
    editorOptions.current.push({
      itemId: itemId,
      widgetId: widgetId,
      display: false,
      autoedit: true,
      label: label,
      list: createEditorOptions(itemId),
    });
  };

  const addItem = () => {
    //stabilisco itemId in base al valore massimo degli item preesistenti
    let itemId = 1;
    editorOptions.current.forEach((opt) => {
      if (parseInt(opt.itemId) >= itemId) itemId = parseInt(opt.itemId) + 1;
      opt.autoedit = false;
    });

    addItem_(itemId.toString(), 'Item ' + itemId.toString());
    let itemsArray: Array<string> = [];
    editorOptions.current.forEach((opt) => {
      itemsArray.push(opt.label);
    });
    const obj = { Items: itemsArray };
    onDataChange(obj);
   
  };

 

  function getMessageFromChild(action: string, jsonData: string, callback?: any) {
    const data = JSON.parse(jsonData ?? '{}');
    
    if (action !== 'edit-textbox-special-key' && action !== 'child-click' && action !== 'open-edit-textbox') {
      newElementCreated.current = false;
      newElementId.current = null;
    } 

    switch (action) {
      
      case 'edit-label':
        if (data.hasOwnProperty('itemId') && data.hasOwnProperty('newLabel')) {
          const itemId = data.itemId;
          const newLabel = data.newLabel;
          for (let i = 0; i < editorOptions.current.length; ++i) {
            if(editorOptions.current[i].itemId == itemId){
               editorOptions.current[i].label = newLabel;
              let itemsArray: Array<string> = [];
              editorOptions.current.forEach((opt) => {
               itemsArray.push(opt.label);
              });
              const obj = { Items: itemsArray };
              onDataChange(obj);
            }
          }
        }
        break;
      
      case 'delete-item':
       if (data.hasOwnProperty('itemId')) {
        const itemId = data.itemId;
        for (let i = 0; i < editorOptions.current.length; ++i) {
          if (editorOptions.current[i].itemId == itemId) {
            editorOptions.current.splice(i, 1);
            break;
          }
        }
        let itemsArray: Array<string> = [];
        editorOptions.current.forEach((opt) => {
          itemsArray.push(opt.label);
        });
        const obj = { Items: itemsArray };
        onDataChange(obj);
       }
        break;
     

      /*

      case 'notify-action':
        if (data.hasOwnProperty('action')) {
          if (data.action === 'newDocument') newElementCreated.current = true;
          props.notifyAction(data.action, jsonData, callback);
        }
        break; */


    }
  }

  

  return (
    <div className="grid multi-property-array-container mt-2">
      <div className="col-4 mt-2 ml-2">
       
        <Button
            label="New Item"
           
            icon="pi pi-plus"
            rounded
            size="small"
            onClick={(ev) => {
              addItem();
            }}
          />
       
      
      </div>

     
      <div  className="col-12"  >
       <Fieldset>
        {editorOptions.current.map((opt, index) => 
         
       
           < MultiPropertyArrayEditorItem    
                key={index} 
                label={opt.label} 
                display={true} 
                autoedit={opt.autoedit}
                widgetId={opt.widgetId} 
                itemId={opt.itemId}  
                list={opt.list} 
                sendMessageToParent={getMessageFromChild}
                
                />
           
          )}
         </Fieldset>
        </div>
    </div>
  );
};

export default MultiPropertyArrayEditor;

import { Accordion, AccordionTab } from 'primereact/accordion';
import { ToolbarElement, ToolbarSection } from '@config/toolbar';
import { useToolbarStore } from '@stores/toolbarStore';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';
import EsaCard from './EsaCard';
import React, { useEffect, useState } from 'react';

/*const ElementsToolbar: React.FC <{
  data: ToolbarSection[];
}> = ({ data }) =>  { */
const ElementsToolbar = () => {
  let startDrag = false;
  const [listOfItems, setListOfItems] = useState<ToolbarSection[]>([]);

  const [draggingIdx, setDraggingIdx] = useState<{ sectionIndex?: number; itemIndex?: number }>({});

  const widgetDragHandler = useWidgetPropsStore((state) => state.widgetDragHandler);
  const documentToolbar = useToolbarStore((state) => state.documentToolbar);

  // search/add correct section
  function getToolbarSection(secName: string, newList: ToolbarSection[]): ToolbarSection {
    // search if the section is already present
    if (newList.length > 0) {
      for (let i = 0, imax = newList.length; i < imax; i++) {
        // section present
        if (newList[i].text === secName) {
          return newList[i];
        }
      }
    }

    // if no section is present, create it
    const newSection: ToolbarSection = {
      text: secName,
      list: [],
    };

    // add the section to the toolbar
    newList.push(newSection);

    // return
    return newSection;
  }

  // create toolbar
  function createToolbar() {
    if (documentToolbar === null) {
      setListOfItems([]);
    } else {
      // new values
      const newList: ToolbarSection[] = [];

      documentToolbar.definition.forEach((element: ToolbarSection) => {
        if (element.text) {
          const newSection = getToolbarSection(element.text, newList);
          // create the new widget for each element in the section
          element.list.forEach((item: ToolbarElement) => {
            const newWidget: ToolbarElement = {
              type: item.type,
              caption: item.caption,
              icon: item.icon,
            };

            // add widget to the section list
            newSection.list.push(newWidget);
          });
        }
      });

      setListOfItems(newList);
    }
  }

  // Start Drag
  function handleOnDrag(widgetType: string, event: React.DragEvent<HTMLDivElement>) {
    event.dataTransfer.setDragImage(new Image(), 0, 0); // Centra l'immagine di trascinamento
    widgetDragHandler?.startDrag({ type: widgetType });
    startDrag = true;
  }

  // End Drag
  function handleOnDragEnd() {
    if (startDrag) {
      startDrag = false;
    }
  }

  // start
  useEffect(() => {
    createToolbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToolbar]);

  return (
    <div>
      <Accordion multiple activeIndex={[0]}>
        {listOfItems.map((section, sectionIndex) => (
          <AccordionTab key={sectionIndex} header={section.text}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {section.list.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  draggable
                  onDragStart={(event) => {
                    handleOnDrag(item.type, event);
                    setDraggingIdx({ sectionIndex: sectionIndex, itemIndex: itemIndex });
                  }}
                  onDragEnd={() => {
                    handleOnDragEnd();
                    setDraggingIdx({});
                  }}
                >
                  <EsaCard
                    item={item}
                    highlight={draggingIdx.sectionIndex === sectionIndex && draggingIdx.itemIndex === itemIndex}
                  />
                </div>
              ))}
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};

export default ElementsToolbar;

import { Actions, Model } from 'flexlayout-react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface LayoutState {
  layoutModel: Model;
  setLayoutModel: (model: Model) => void;
  activateTab: (id: string) => void;
}

export const useLayoutStore = create(
  immer<LayoutState>((set, get) => ({
    layoutModel: null,
    setLayoutModel: (model: Model) => {
      set((state) => {
        state.layoutModel = model;
      });
    },
    activateTab: (id: string) => {
      get().layoutModel.doAction(Actions.selectTab(id));
    },
  }))
);

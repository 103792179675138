import { PropertiesManager } from '../properties-manager';
import { WidgetContainer } from '../widget-container';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetFactory } from '../widget-factory';
import { WidgetProperties } from '../widget';

export class Accordion extends WidgetContainer {
  private openedIndex: number | null = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-accordion');
  }

  /**
   * chiusura di tutti gli header
   */
  closeHeaders() {
    const accordionItems = this.domElement.querySelectorAll('.accordion-content');
    const arrows = this.domElement.querySelectorAll('.accordion-arrow');

    accordionItems.forEach((item: HTMLElement) => {
      item.style.maxHeight = '0';
      item.style.display = 'none';
    });

    arrows.forEach((arrow: HTMLElement) => {
      arrow.style.transform = 'rotate(0deg)';
    });
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);

      switch (key) {
        case 'OpenedItem':
          this.openedIndex =
            data !== undefined && !isNaN(parseInt(data)) && parseInt(data) > 0 ? parseInt(data) - 1 : null;
          this.closeHeaders();
          this.domElement.querySelectorAll('.accordion-item').forEach((accordionItem: Element, index: number) => {
            const contentDiv = accordionItem.querySelector('.accordion-content') as HTMLElement;
            const arrowDiv = accordionItem.querySelector('.accordion-arrow') as HTMLElement;

            if (this.openedIndex === index) {
              contentDiv.style.display = 'block';
              contentDiv.style.maxHeight = '160px';
              arrowDiv.style.transform = 'rotate(90deg)';
            }
          });
          break;
        case 'AccordionIsHorizontal':
          if (data) {
            this.domElement.classList.add('horizontal');
          } else {
            this.domElement.classList.remove('horizontal');
          }
          break;
        default:
          if (key.includes('Font')) {
            this.setFontProperties(properties, this.domElement, 'Accordion');
          } else {
            this.setDefaultProperties(properties);
          }
      }
    }
    this.setWidgetContainerProperties(properties);
  }

  setWidgetContainerProperties(properties: WidgetProperties): void {
    super.setProperties(properties);

    if ('children' in properties) {
      const viewArray = properties['children'];
      if (Array.isArray(viewArray)) {
        while (this.domElement.firstChild) this.domElement.removeChild(this.domElement.firstChild);

        this.detachChildren();

        viewArray.forEach((viewProperties, index) => {
          //creazione header obbligatoria
          if (/hdr-\d+$/.test(viewProperties['id'])) {
            //inizializzazione elementi
            const accordionItem = document.createElement('div');
            accordionItem.classList.add('accordion-item');

            const headerDiv = document.createElement('div');
            headerDiv.classList.add('accordion-header');

            const textDiv = document.createElement('div');
            textDiv.textContent = viewProperties['caption'];
            textDiv.classList.add('accordion-text');

            if ('HTextAlign' in properties) {
              if (properties['HTextAlign'] === 1) {
                textDiv.style['textAlign'] = 'center';
              } else if (properties['HTextAlign'] === 2) {
                textDiv.style['textAlign'] = 'right';
              } else {
                textDiv.style['textAlign'] = 'left';
              }
            }

            const arrowDiv = document.createElement('span');
            arrowDiv.classList.add('accordion-arrow');
            arrowDiv.innerHTML = '&#9654;';
            arrowDiv.style.transform = 'rotate(0deg)';

            headerDiv.appendChild(textDiv);
            headerDiv.appendChild(arrowDiv);

            const contentDiv = document.createElement('div');
            contentDiv.classList.add('accordion-content');
            contentDiv.style.display = 'none';

            //evento click
            const toggleHeader = () => {
              const isOpen = this.openedIndex === index;

              if (!isOpen) {
                this.closeHeaders();
                contentDiv.style.display = 'block';
                arrowDiv.style.transform = 'rotate(90deg)';
                contentDiv.style.maxHeight = '160px';
                this.openedIndex = index;
                this.sendEvent('ItemClick', this.openedIndex + 1, true); //modifica proprietà
              }
            };
            headerDiv.addEventListener('click', toggleHeader);

            //unione elementi
            accordionItem.appendChild(headerDiv);
            accordionItem.appendChild(contentDiv);

            //creazione view
            const widget = WidgetFactory.buildWidget(viewProperties['type']);
            widget.setProperties(viewProperties);
            widget.attach();
            if (widget.domElement) contentDiv.appendChild(widget.domElement);
            this.pushWidget(widget);

            //se non c'è nessun item aperto, apre il primo
            if (this.openedIndex === null) {
              contentDiv.style.display = 'block';
              contentDiv.style.maxHeight = '160px';
              arrowDiv.style.transform = 'rotate(90deg)';
              this.openedIndex = 0;
              this.sendEvent('ItemClick', this.openedIndex + 1, true); //modifica proprietà default
            } else if (this.openedIndex === index) {
              contentDiv.style.display = 'block';
              contentDiv.style.maxHeight = '160px';
              arrowDiv.style.transform = 'rotate(90deg)';
            }

            this.domElement.appendChild(accordionItem);
          }
        });
      }
    } else if ('HTextAlign' in properties) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.domElement.querySelectorAll('.accordion-text').forEach((element: any) => {
        if (properties['HTextAlign'] === 1) {
          element.style['textAlign'] = 'center';
        } else if (properties['HTextAlign'] === 2) {
          element.style['textAlign'] = 'right';
        } else {
          element.style['textAlign'] = 'left';
        }
      });
    }
  }

  setBaseWidgetProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      if (![undefined, null].includes(properties[key])) {
        if (key === 'id') {
          this.domElement.id = properties['id'];
          WidgetDictionary.registerWidget(properties['id'], this);
        } else if (key !== 'type') {
          this.setCurrentProperties(properties, key);
        }
      }
    }
  }
}

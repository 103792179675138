import { InputTextarea } from 'primereact/inputtextarea';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import React, { useEffect, useState } from 'react';

export const ListItemsComponent = ({ data, onDataChange }: PropertyEditorComponentProps) => {
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    setTextValue(data ? data.Items.join('\n') : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    const itemsArray = e.target.value.split('\n').filter((item: string) => item.trim() !== '');
    const obj = { Items: itemsArray };
    onDataChange(obj);
  };

  return <InputTextarea value={textValue} onChange={handleChange} rows={5} />;
};

export default ListItemsComponent;

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  onChange: (value: string) => void;
  connectionString: string;
};

export const StringConnectionForm: FC<Props> = ({ connectionString, onChange }) => {
  const [host, setHost] = useState('');
  const [port, setPort] = useState<number | null>(null);
  const [database, setDatabase] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleGenerate = () => {
    onChange(`${username}:${password}@${host}${port ? `:${port}` : ''}/${database}`);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (connectionString !== undefined) {
      const parseConnectionString = (connectionString: string) => {
        const parts = connectionString.split(/[:@/]/);
        const [username, password, host, port, database] = parts;

        setUsername(username || '');
        setPassword(password || '');
        setHost(host || '');
        setPort(port ? parseInt(port, 10) : null);
        setDatabase(database || '');
      };
      parseConnectionString(connectionString);
    }
  }, [connectionString]);

  return (
    <div className="card flex flex-column gap-4">
      <Fieldset legend="Server">
        <div className="card flex flex-column gap-3">
          <div className="card grid">
            <div className="p-inputgroup col-8">
              <label htmlFor="host" className="p-inputgroup-addon">
                Host
              </label>
              <InputText id="host" placeholder="Host" value={host} onChange={(e) => setHost(e.target.value)} />
            </div>

            <div className="p-inputgroup col-4">
              <label htmlFor="port" className="p-inputgroup-addon">
                Port
              </label>
              <InputNumber id="port" placeholder="Port" value={port} onValueChange={(e) => setPort(e.value ?? null)} />
            </div>
          </div>

          <div className="card">
            <div className="p-inputgroup flex-1">
              <label htmlFor="database" className="p-inputgroup-addon">
                Database
              </label>
              <InputText
                id="database"
                placeholder="Database"
                value={database}
                onChange={(e) => setDatabase(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Fieldset>

      <Fieldset legend="Authentication">
        <div className="card flex flex-column gap-3">
          <div className="p-inputgroup flex-1">
            <label htmlFor="username" className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </label>
            <InputText
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="p-inputgroup">
            <label htmlFor="password" className="p-inputgroup-addon">
              <i className="pi pi-key"></i>
            </label>
            <InputText
              id="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button icon={showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'} onClick={handleTogglePassword} />
          </div>
        </div>
      </Fieldset>

      <div className="flex flex-row-reverse gap-2">
        <Button label="Generate" onClick={handleGenerate} />
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { LeafProps } from './Interfaces';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useRef, useState } from 'react';
import React, { useEffect } from 'react';

export function Leaf({
  id,
  title,
  icon,
  found,
  childFound,
  hidden,
  editable,
  selected,
  actions,
  showModal,
  sendMessageToTreeview,
  autoedit,
  hint,
  data,
}: LeafProps) {
  const [editMode, setEditMode] = useState(autoedit);
  const [editText, setEditText] = useState(title);

  const op = useRef<OverlayPanel>(null);
  let actionToDo = '';
  let actionDataId = '';

  const mouseOverHandler = (event: any) => {
    event.currentTarget.classList.add('mouseOver');
  };

  const mouseLeaveHandler = (event: any) => {
    event.currentTarget.classList.remove('mouseOver');
  };
  useEffect(() => {
    setEditMode(autoedit);
    if (autoedit) {
      sendMessageToTreeview('child-click', JSON.stringify({ id: id }));
      sendMessageToTreeview('open-edit-textbox', JSON.stringify({ id: id }), closeEditText);
      setTimeout(() => {
        const editText = document.getElementById('editText-' + id) as HTMLInputElement;
        editText.select();
        editText.focus();
      }, 500);
    }
  }, [autoedit]);

  const clickHandler = (event: any) => {
    sendMessageToTreeview('child-click', JSON.stringify({ id: id }));

    if (event.ctrlKey) {
      sendMessageToTreeview('notify-action', JSON.stringify({ action: 'multiselect', id: id }));
    } else {
      if (selected) sendMessageToTreeview('notify-action', JSON.stringify({ action: 'unselect', id: id }));
      //selezioniamo la foglia
      else sendMessageToTreeview('notify-action', JSON.stringify({ action: 'select', id: id, data: data }));
    }
  };

  const doubleClickHandler = (event: any) => {
    sendMessageToTreeview('notify-action', JSON.stringify({ action: 'leaf-double-click', id: id }));
  };

  const iconClickHandler = (e: React.MouseEvent, action: string) => {
    sendMessageToTreeview('child-click', JSON.stringify({ id: id }));

    if (action === 'edit') {
      setEditMode(true);
      sendMessageToTreeview('open-edit-textbox', JSON.stringify({ id: id }), closeEditText);
      //impostiamo il focus ed il select sul testo da modificare
      setTimeout(() => {
        const editText = document.getElementById('editText-' + id) as HTMLInputElement;
        editText.select();
        editText.focus();
      }, 500);
    } else if (action.startsWith('delete')) {
      actionToDo = action;
      actionDataId = id;
      op.current.show(e, e.target);
    } else sendMessageToTreeview('notify-action', JSON.stringify({ action: action, id: id }), e);
  };

  const saveEditText = () => {
    sendMessageToTreeview('notify-action', JSON.stringify({ action: 'edit', id: id, newTitle: editText }));
    setEditMode(false);
  };

  function closeEditText() {
    setEditText(title);
    setEditMode(false);
  }

  const dragStartHandler = (event: any) => {
    sendMessageToTreeview('drag-start', JSON.stringify({ id: id, type: 'leaf' }));
  };

  const editTextKeyDownHandler = (event: any) => {
    if (editMode) {
      if (event.key === 'Enter') saveEditText();
      else if (event.key === 'Escape') setEditMode(false);

      if (event.key === 'Enter' || event.key === 'Escape')
        sendMessageToTreeview('edit-textbox-special-key', JSON.stringify({ id: id, key: event.key }));
    }
  };

  const dragOverHandler = (event: any) => {
    event.currentTarget.classList.add('dragOver');
    event.stopPropagation();
    event.preventDefault();
  };

  const dragLeaveHandler = (event: any) => {
    event.currentTarget.classList.remove('dragOver');
  };

  const dropHandler = (event: any) => {
    event.currentTarget.classList.remove('dragOver');
    const parentId = sendMessageToTreeview('get-parent-id', JSON.stringify({ id: id }));
    sendMessageToTreeview('drop', JSON.stringify({ id: parentId }));
  };

  function getItemClass(st: any) {
    let classes = '';

    if (st.selected) classes += 'selected ';

    if (st.hidden) {
      classes += 'hidden base-style ';
      return classes;
    }

    if (st.childFound) classes += 'child ';
    else if (st.found) classes += 'found ';
    else classes += 'base-style ';

    return classes;
  }

  const clName: any = { found: found, hidden: hidden, selected: selected, childFound: childFound };
  const iconClassName = icon;
  actions = actions ?? [];

  return (
    <li id={id} className={getItemClass(clName)}>
      <div
        className={'leaf-container'}
        draggable="true"
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        onMouseOut={mouseLeaveHandler}
        onClick={clickHandler}
        onDoubleClick={doubleClickHandler}
        onDragStart={dragStartHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={hint}
      >
        <i className={iconClassName} style={{ fontSize: '1rem' }}></i>
        &nbsp;<span className={editMode ? 'hidden' : ''}>{title}</span>
        <span className={editMode ? '' : 'hidden '}>
          <InputText
            id={'editText-' + id}
            value={editText}
            onChange={(ev) => setEditText(ev.target.value)}
            className={'p-inputtext-sm padding-1'}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
            onKeyDown={(ev) => {
              editTextKeyDownHandler(ev);
            }}
          />
          &nbsp;
          <Button
            label="Salva"
            className={'padding-2'}
            icon="pi pi-check"
            severity="success"
            rounded
            size="small"
            onClick={(ev) => {
              ev.stopPropagation();
              saveEditText();
            }}
          />
          &nbsp;
          <Button
            label="Annulla"
            className={'padding-2'}
            icon="pi pi-times"
            severity="danger"
            rounded
            size="small"
            onClick={(ev) => {
              ev.stopPropagation();
              closeEditText();
            }}
          />
        </span>
        <span className={'leaf-actions'}>
          {' '}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {actions.map((action, index) => {
            const classExtra = action.code === 'edit' && editMode ? ' hidden' : '';
            return (
              <React.Fragment key={index}>
                <i
                  className={action.icon + classExtra}
                  style={{ fontSize: '1rem' }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    iconClickHandler(ev, action.code);
                  }}
                ></i>
                &nbsp;&nbsp;
              </React.Fragment>
            );
          })}
        </span>
      </div>
      {hint !== '' && <ReactTooltip id="my-tooltip" place="bottom" style={{ backgroundColor: 'rgb(33, 68, 77)' }} />}
      <OverlayPanel ref={op}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Sei sicuro di voler procedere?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button
              label="No"
              onClick={() => {
                op.current.hide();
              }}
            />
            <Button
              label="Si"
              onClick={() => {
                op.current.hide();
                sendMessageToTreeview('notify-action', JSON.stringify({ action: actionToDo, id: actionDataId }));
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </li>
  );
}

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Project } from '@api';
import TagType = Project.TagType;
import Method = Project.Method;
import ReturnType = Project.ReturnType;
import {
  Cell,
  CellChange,
  CellTemplate,
  Column,
  Compatible,
  DefaultCellTypes,
  ReactGrid,
  Row,
  TextCell,
  Uncertain,
} from '@silevis/reactgrid';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { deleteButtonStyle, noBorderCellStyle } from '@services/reactGridStyles';

interface TypeCell extends Cell {
  type: 'typeCell';
  value: Project.MethodParams;
  rowId: number;
}

interface ArrayCell extends Cell {
  type: 'arrayCell';
  value: Project.MethodParams;
  rowId: number;
}

interface ActionCell extends Cell {
  type: 'actionCell';
  value: Project.MethodParams;
}

type CustomCellTypes = DefaultCellTypes | TypeCell | ArrayCell | ActionCell;

const returnTypeOptions: SelectItemOptionsType = [
  {
    value: TagType.Numeric,
  },
  {
    value: TagType.String,
  },
];

const emptyMethod: Method = {
  returnType: ReturnType.Numeric,
  params: [],
};

const columns: Column[] = [
  { columnId: 'name', width: 300 },
  { columnId: 'type', width: 300 },
  { columnId: 'array', width: 200 },
  { columnId: 'events', width: 155 },
];

const headerRow: Row<CustomCellTypes> = {
  rowId: 'header',
  cells: [
    { type: 'header', text: 'Name', style: noBorderCellStyle },
    { type: 'header', text: 'Type', style: noBorderCellStyle },
    { type: 'header', text: 'Array', style: noBorderCellStyle },
    { type: 'header', text: '', style: noBorderCellStyle },
  ],
};

type Props = {
  resource: Project.ResourceTreeElement;
  onChange: (resource: Project.ResourceTreeElement) => void;
};

export const TagMethodEditor: FC<Props> = ({ resource, onChange }) => {
  const method = resource.methodParams ?? emptyMethod;

  const gridContainerRef = useRef(null);
  const [rows, setRows] = useState<Row<CustomCellTypes>[]>([]);

  const _handleTableCellChange = (field: string, newValue: string | boolean, index: number) => {
    const _params = [...method.params];
    const currParam: Project.MethodParams = { ..._params[index] }; // Clona l'oggetto

    // change value
    switch (field) {
      case 'array':
        if (typeof newValue === 'boolean') {
          currParam.array = newValue;
        }
        break;
      case 'type':
        if (typeof newValue === 'string') {
          if (typeof newValue === 'string' && Object.values(TagType).includes(newValue as TagType)) {
            currParam.type = newValue as TagType;
          }
        }
        break;
      case 'name':
        if (typeof newValue === 'string') {
          currParam.name = newValue;
        }
        break;
    }

    // Aggiorna l'array con il nuovo oggetto
    _params[index] = currParam;

    setMethod({
      returnType: method.returnType,
      params: _params,
    });
  };

  useEffect(() => {
    setRows(getRows(method.params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gridContainerRef.current) {
      gridContainerRef.current.scrollTop = gridContainerRef.current.scrollHeight;
    }
  }, [rows.length]);

  const setMethod = useCallback(
    (method: Method) => {
      onChange({
        ...resource,
        methodParams: method,
      });
      setRows(getRows(method.params));
    },
    [onChange, resource]
  );

  // Handlers
  const handleAddParam = useCallback(() => {
    setMethod({
      ...method,
      params: [
        ...method.params,
        {
          name: 'Param_' + method.params.length,
          type: TagType.Numeric,
          array: false,
        },
      ],
    });
  }, [method, setMethod]);

  const handleRemove = (rowData: Project.MethodParams) => {
    setMethod({
      ...method,
      params: method.params.filter(
        (param) => !(param.name === rowData.name && param.type === rowData.type && param.array === rowData.array)
      ),
    });
  };

  const handleTypeCell = (typeValue: string, index: number) => {
    _handleTableCellChange('type', typeValue, index);
  };

  const handleArrayCell = (arrayValue: boolean, index: number) => {
    _handleTableCellChange('array', arrayValue, index);
  };

  const handleOnCellChange = (cellChanges: CellChange[]) => {
    if (cellChanges.length > 0 && cellChanges[0].columnId === 'name') {
      // search the panel in use
      const rowIndex: number = cellChanges[0].rowId as number; // 0 base
      _handleTableCellChange('name', (cellChanges[0].newCell as TextCell).text, rowIndex);
    }
  };

  // Templates
  const TypeCellTemplate: CellTemplate<Compatible<TypeCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<TypeCell>): Compatible<TypeCell> => {
      const cell = {
        ...uncertainCell,
        type: 'typeCell',
        value: uncertainCell.value,
        rowId: uncertainCell.rowId,
      };
      return cell as Compatible<TypeCell>;
    },
    render: (cell: Compatible<TypeCell>) => {
      return (
        <>
          <Dropdown
            value={cell.value.type}
            onChange={(e) => handleTypeCell(e.value, cell.rowId)}
            options={returnTypeOptions}
            optionLabel="value"
            placeholder="Select a type"
            className="w-full md:w-14rem"
          />
        </>
      );
    },
    isFocusable: () => false,
  };

  const ArrayCellTemplate: CellTemplate<Compatible<ArrayCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<ArrayCell>): Compatible<ArrayCell> => {
      const cell = {
        ...uncertainCell,
        type: 'arrayCell',
        value: uncertainCell.value,
        rowId: uncertainCell.rowId,
      };
      return cell as Compatible<ArrayCell>;
    },
    render: (cell: Compatible<ArrayCell>) => {
      return (
        <>
          <InputSwitch checked={cell.value.array} onChange={(e) => handleArrayCell(e.value, cell.rowId)} />
        </>
      );
    },
    isFocusable: () => false,
  };

  const ActionCellTemplate: CellTemplate<Compatible<ActionCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<ActionCell>): Compatible<ActionCell> => {
      const cell = {
        ...uncertainCell,
        type: 'actionCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<ActionCell>;
    },
    render: (cell: Compatible<ActionCell>) => {
      return (
        <>
          <Button style={deleteButtonStyle} icon="pi pi-trash" onClick={() => handleRemove(cell.value)}></Button>
        </>
      );
    },
    isFocusable: () => false,
  };

  const myCellTemplates: Record<string, CellTemplate> = {
    typeCell: TypeCellTemplate,
    arrayCell: ArrayCellTemplate,
    actionCell: ActionCellTemplate,
  };

  // ReactGrid
  const getRows = (params: Project.MethodParams[]): Row<CustomCellTypes>[] => [
    headerRow,
    ...params.map<Row<CustomCellTypes>>((param, idx) => ({
      rowId: idx,
      height: 50,
      cells: [
        { type: 'text', text: param.name, style: noBorderCellStyle },
        { type: 'typeCell', value: param, style: noBorderCellStyle, rowId: idx },
        { type: 'arrayCell', value: param, style: noBorderCellStyle, rowId: idx },
        { type: 'actionCell', value: param, nonEditable: true, style: noBorderCellStyle },
      ],
    })),
  ];

  return (
    <div className="card flex flex-column gap-3">
      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">
          <i className="pi pi-arrow-left"></i>
        </span>

        <Dropdown
          value={method.returnType}
          onChange={(e) => {
            setMethod({ ...method, returnType: e.target.value });
          }}
          options={returnTypeOptions}
          optionLabel="value"
          placeholder="Return type"
          className="w-full md:w-14rem"
        />
      </div>

      <div className="card" id="div_methodParams">
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
          <span className="text-xl text-900 font-bold">Parameters</span>
          <Button icon="pi pi-plus" raised onClick={handleAddParam} style={{ color: 'white' }} />
        </div>
        <div style={{ maxHeight: '500px', overflow: 'auto', marginTop: '5px' }} ref={gridContainerRef}>
          <ReactGrid
            rows={rows}
            columns={columns}
            stickyTopRows={1}
            customCellTemplates={myCellTemplates}
            onCellsChanged={handleOnCellChange}
          />
        </div>
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Project } from '@api';
import React from 'react';

export interface DocumentEditorProps {
  data: Project.DocumentData;
  status: any;
  documentId: Project.ProjectNodeKey;
  onDataChange: (document: Project.DocumentData) => void;
  onStatusChange: (data: any) => void;
}

export type DocumentEditorComponent = React.FC<DocumentEditorProps>;

type DocumentDictionary = {
  [key: string]: DocumentEditorComponent;
};

export class DocumentEditorFactory {
  private static builders: DocumentDictionary = {};

  // Registra una nuova callback per un componente
  static registerDocumentBuilder(key: string, builder: DocumentEditorComponent) {
    DocumentEditorFactory.builders[key] = builder;
  }

  // Restituisce un componente React basato sulla chiave
  static getDocumentBuilder(key: string): DocumentEditorComponent | null {
    const builder = DocumentEditorFactory.builders[key];

    if (!builder) {
      console.warn(`Componente con chiave "${key}" non trovato.`);
      return null;
    }
    return builder;
  }
}

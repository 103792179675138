import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class FrameHTML extends SimpleWidget {
  constructor(public domElement: HTMLIFrameElement) {
    super(domElement);
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-framehtml');
    super.attach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'Url':
          this.domElement.src = data;
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }
}

import { BaseWidget } from './base-widget';
import { Widget, WidgetProperties } from './widget';
import { WidgetFactory } from './widget-factory';

export abstract class WidgetContainer extends BaseWidget {
  private widgetList: Widget[] = [];

  domElement: HTMLElement;

  constructor(domElement?: HTMLElement) {
    super();
    if (domElement) this.domElement = domElement;
    else this.domElement = document.createElement('div');
  }

  pushWidget(widget: Widget) {
    this.widgetList.push(widget);
  }

  setProperties(properties: WidgetProperties) {
    super.setProperties(properties);

    if ('children' in properties) {
      const viewArray = properties['children'];
      if (Array.isArray(viewArray)) {
        while (this.domElement.firstChild) this.domElement.removeChild(this.domElement.firstChild);

        this.detachChildren();

        for (const viewProperties of viewArray) {
          const widget = WidgetFactory.buildWidget(viewProperties['type']);
          widget.setProperties(viewProperties);
          widget.attach();
          if (widget.domElement) this.domElement.appendChild(widget.domElement);
          this.widgetList.push(widget);
        }
      }
    }
  }

  detachChildren() {
    for (const widget of this.widgetList) widget.detach();
  }

  detach() {
    super.detach();
    this.detachChildren();
  }
}

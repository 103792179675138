import { BorderFieldConfig, ColorFieldConfig, PropertiesConfig } from './interface';
import { Project } from '@api';
import { TabName, TextPosition } from './enum';
import ColorPickerComponent from '@components/property-edit/component/ColorPickerComponent';
import EsaDropdown from '@components/property-edit/component/DropdownComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';

export const colorField = (fieldConfig: ColorFieldConfig): PropertiesConfig => ({
  properties: {
    Color: {
      tab: TabName.Layout,
      section: 'Color',
      editor: ColorPickerComponent,
      defaultData: fieldConfig.colorDefault ?? '#000000',
      editorOptions: {
        data: fieldConfig.colorDefault ?? '#000000',
      },
      pinType: Project.PinType.None,
      caption: 'Text',
      captionPosition: TextPosition.Left,
      visible: fieldConfig.colorVisible ?? true,
    },
    BackgroundColor: {
      tab: TabName.Layout,
      section: 'Color',
      editor: ColorPickerComponent,
      defaultData: fieldConfig.backgroungColorDefault ?? '#000000',
      editorOptions: {
        data: fieldConfig.backgroungColorDefault ?? '#000000',
      },
      pinType: Project.PinType.None,
      caption: 'Background',
      captionPosition: TextPosition.Left,
      visible: fieldConfig.backgroundColorVisible ?? true,
    },
  },
});

export const borderField = (fieldConfig: BorderFieldConfig): PropertiesConfig => ({
  properties: {
    BorderColor: {
      tab: TabName.Layout,
      section: 'Border',
      editor: ColorPickerComponent,
      defaultData: fieldConfig.color ?? '#000000',
      editorOptions: {
        data: fieldConfig.color ?? '#000000',
      },
      pinType: Project.PinType.None,
      caption: 'Color',
      captionPosition: TextPosition.Left,
    },

    BorderStyle: {
      tab: TabName.Layout,
      section: 'Border',
      editor: EsaDropdown,
      pinType: Project.PinType.None,
      defaultData: fieldConfig.style ?? 'hidden',
      editorOptions: {
        placeholder: '-Select-',
        options: [
          { label: 'solid', value: 'solid' },
          { label: 'dashed', value: 'dashed' },
          { label: 'dotted', value: 'dotted' },
          { label: 'double', value: 'double' },
          { label: 'groove ', value: 'groove' },
          { label: 'ridge', value: 'ridge' },
          { label: 'inset', value: 'inset' },
          { label: 'outset', value: 'outset' },
          { label: 'none', value: 'none' },
          { label: 'hidden', value: 'hidden' },
        ],
      },
      caption: 'Style',
      captionPosition: TextPosition.Left,
    },

    BorderWidth: {
      tab: TabName.Layout,
      section: 'Border',
      defaultData: fieldConfig.width ?? 0,
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Width',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },

    BorderRadius: {
      tab: TabName.Layout,
      section: 'Border',
      defaultData: fieldConfig.radius ?? 0,
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Radius',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
  },
});

import './SelectButton.css';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import React from 'react';

export type iconButton = {
  icon?: string;
  svg?: string;
  value: string;
};

export interface ButtonProps extends PropertyEditorComponentProps {
  options: iconButton[];
}

export const ButtonBar = ({ data, options, onDataChange }: ButtonProps) => {
  return (
    <div className="select-button-container">
      {options.map((option, index) => (
        <button
          key={index}
          className={`option-button ${data === option.value ? 'selected' : ''}`}
          onMouseDown={() => onDataChange(option.value)}
        >
          <i className={option.icon}></i>
        </button>
      ))}
    </div>
  );
};

export default ButtonBar;

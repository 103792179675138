import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class Textbox extends SimpleWidget {
  inputElement: HTMLInputElement;

  onInputChange: () => void;

  constructor(domElement: HTMLElement) {
    super(domElement);
    this.inputElement = domElement as HTMLInputElement;
  }

  detach() {
    super.detach();
    this.inputElement.removeEventListener('input', this.onInputChange);
  }

  attach(): void {
    this.domElement.classList.add('fast-textbox');
    super.attach();
    this.onInputChange = () => {
      this.sendEvent('text', this.inputElement.value, true);
    };
    this.inputElement.addEventListener('input', this.onInputChange);
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'text':
          this.inputElement.value = data;
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }
}

import { DocumentEditorConfig } from '@config/documentEditor';
import { ToolbarConfig } from '@config/toolbar';
import { create } from 'zustand';
import { documentEditorsConfig } from '@config/documentEditor';
import { immer } from 'zustand/middleware/immer';

export interface ToolbarState {
  documentToolbar: ToolbarConfig | null;
  setDocumentToolbar: (toolbarType: string) => void;
}

export const useToolbarStore = create(
  immer<ToolbarState>((set) => ({
    documentToolbar: null,
    setDocumentToolbar: (toolbarType: string) => {
      set((state) => {
        if (toolbarType === 'close') {
          state.documentToolbar = null;
        } else {
          // search the new toolbar
          let found = false;
          documentEditorsConfig.forEach((config: DocumentEditorConfig) => {
            if (!found) {
              if (config.type === toolbarType) {
                if (config.toolbar) {
                  found = true;
                  state.documentToolbar = config.toolbar;
                }
              }
            }
          });
          // if no tooolbar is defined, nothing to show
          if (!found) state.documentToolbar = null;
        }
      });
    },
  }))
);

import { Project } from '@api';
import { create } from 'zustand';
import { deepEqual } from '@services/utils';
import { immer } from 'zustand/middleware/immer';
import { propertyData } from '@config/widget/widgetData';
export interface LinkState {
  linkResourceTree: Project.ProjectStructure;
  updateResourceTree: (tree: Project.ProjectStructure) => void;
  loadLinkResourceTreeFiltredByPinType: (
    type: Project.PinType,
    structure: Project.ProjectStructure
  ) => Promise<Project.ProjectStructure>;
  getResourceTreeElement: (id: string) => Promise<Project.ResourceTreeElement>;
  getResourceTreeElementId: (element: Project.ResourceLinkPoint) => Promise<string>;
}

export const useLinkStore = create(
  immer<LinkState>((set, get) => {
    const setLinkResourceTree = (newStructure: Project.ProjectStructure) => {
      set((state) => {
        state.linkResourceTree = newStructure;
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isDocument = (obj: any): obj is Project.Document => {
      return obj.type !== undefined;
    };

    const isValidPinType = (elementPinType: Project.PinType, type: Project.PinType): boolean => {
      switch (type) {
        case Project.PinType.In:
          return elementPinType === Project.PinType.Out || elementPinType === Project.PinType.InOut;
        case Project.PinType.Out:
          return elementPinType === Project.PinType.In || elementPinType === Project.PinType.InOut;
        case Project.PinType.InOut:
          return elementPinType !== Project.PinType.None;
        default:
          return false;
      }
    };

    const addPropertiesOfView = (
      resourceTree: Project.ResourceTreeElement[],
      tree: Project.Document
    ): Project.ResourceTreeElement[] => {
      const pageProperties = propertyData[tree.type]?.config.properties;
      if (pageProperties) {
        Object.keys(pageProperties).forEach((key) => {
          const property = pageProperties[key];
          const newElement: Project.ResourceTreeElement = {
            name: key,
            pinType: property.pinType,
            data: {
              id: tree.nodeId.domainId + '_' + key,
            },
          };
          if (property.pinType !== Project.PinType.None) {
            resourceTree.push(newElement);
          }
        });
      }
      return resourceTree;
    };

    const updateProperties = (tree: Project.ProjectNode): Project.ProjectNode => {
      if (isDocument(tree)) {
        let resourceTree = tree.data.resourceTree;
        if (!resourceTree) {
          resourceTree = [];
          const currTreeData: Project.DocumentData = {
            resourceTree: [],
          };
          tree = { ...tree, data: currTreeData };
        }

        const processResourceTree = (elements: Project.ResourceTreeElement[]): Project.ResourceTreeElement[] => {
          return elements.map((element) => {
            if (element.children) {
              element = { ...element, children: processResourceTree(element.children) };
            }

            const elementType = element.data.type;
            const elementProperties = propertyData[elementType]?.config.properties;
            if (elementProperties) {
              const newChildren = Object.keys(elementProperties)
                .map((key) => {
                  const property = elementProperties[key];
                  return {
                    name: key,
                    pinType: property.pinType,
                    data: {
                      id: element.data.id + '_' + key,
                    },
                  };
                })
                .filter((ele) => ele.pinType !== Project.PinType.None);
              element = { ...element, children: [...(element.children || []), ...newChildren] };
            }

            return element;
          });
        };

        if (tree.type === 'page') {
          resourceTree = processResourceTree(resourceTree);
          resourceTree = addPropertiesOfView(resourceTree, tree);
        }
        return { ...tree, data: { ...tree.data, resourceTree } };
      } else {
        const children = tree.children.map((t) => updateProperties(t));
        return { ...tree, children };
      }
    };

    const filterResources = (tree: Project.ProjectNode, type: Project.PinType): Project.ProjectNode => {
      if (isDocument(tree)) {
        const resourceTree = tree.data.resourceTree;
        if (resourceTree) {
          const processResourceTree = (elements: Project.ResourceTreeElement[]): Project.ResourceTreeElement[] => {
            return elements
              .map((element) => {
                if (element.children) {
                  // Crea un nuovo elemento con i figli filtrati
                  return { ...element, children: processResourceTree(element.children) };
                }
                return element;
              })
              .filter((element) => element.children?.length || isValidPinType(element.pinType, type));
          };
          return { ...tree, data: { ...tree.data, resourceTree: processResourceTree(resourceTree) } };
        }
        return tree;
      } else {
        const children = tree.children.map((t) => filterResources(t, type));
        return { ...tree, children };
      }
    };

    const searchResourceTreeElement = (id: string): Project.ResourceTreeElement => {
      const resourceElement: Project.ResourceTreeElement = null;

      const searchInView = (
        tree: Project.ProjectNode,
        id: string,
        resourceElementRef: { value: Project.ResourceTreeElement }
      ) => {
        if (isDocument(tree)) {
          const resourceTree = tree.data.resourceTree;
          if (resourceTree) {
            // Add properties of the widgets
            const processResourceTree = (elements: Project.ResourceTreeElement[]) => {
              for (const element of elements) {
                if (element.data.id === id) {
                  resourceElementRef.value = element;
                  return true; // Element found
                }
                // If the element has children, process them recursively
                if (element.children && processResourceTree(element.children)) {
                  return true; // Element found in children
                }
              }
              return false; // Element not found
            };
            // Process the existing resourceTree elements first
            if (processResourceTree(resourceTree)) {
              return;
            }
          }
        } else {
          for (const child of tree.children) {
            searchInView(child, id, resourceElementRef);
            if (resourceElementRef.value) {
              return; // Stop searching if element is found
            }
          }
        }
      };

      const tree = get().linkResourceTree;
      const resourceElementRef = { value: resourceElement };

      for (const key of Object.keys(tree)) {
        tree[key].forEach((t) => {
          if (!resourceElementRef.value) {
            searchInView(t, id, resourceElementRef);
          }
        });
        if (resourceElementRef.value) break;
      }

      return resourceElementRef.value;
    };

    const searchResourceTreeElementId = (elementToSearch: Project.ResourceLinkPoint): string => {
      const idToReturn = '';

      if (elementToSearch.path.length <= 0) return idToReturn;

      const searchInView = (
        tree: Project.ProjectNode,
        elementToSearch: Project.ResourceLinkPoint,
        idRef: { value: string },
        pathIdx: number
      ) => {
        if (isDocument(tree)) {
          const resourceTree = tree.data.resourceTree;
          if (resourceTree && deepEqual(tree.nodeId, elementToSearch.documentId)) {
            const processResourceTree = (elements: Project.ResourceTreeElement[], pathIdx: number): boolean => {
              for (const element of elements) {
                if (element.name === elementToSearch.path[pathIdx].idx) {
                  // Se ci sono ulteriori livelli nel path, cerca nei figli
                  if (element.children && element.children.length > 0 && pathIdx < elementToSearch.path.length - 1) {
                    return processResourceTree(element.children, pathIdx + 1);
                  } else {
                    // Se è l'ultimo livello, assegna l'id
                    idRef.value = element.data.id;
                    return true;
                  }
                }
              }
              return false; // Elemento non trovato
            };

            // Inizia a processare il resourceTree
            if (processResourceTree(resourceTree, pathIdx)) {
              return;
            }
          }
        } else {
          // Cerca nei figli del nodo corrente
          for (const child of tree.children) {
            searchInView(child, elementToSearch, idRef, pathIdx);
            if (idRef.value !== '') {
              return; // Stop alla ricerca se l'elemento è trovato
            }
          }
        }
      };

      const tree = get().linkResourceTree;
      const idRef = { value: idToReturn };

      // Cicla su tutti gli alberi disponibili
      for (const key of Object.keys(tree)) {
        tree[key].forEach((t) => {
          if (idRef.value === '') {
            searchInView(t, elementToSearch, idRef, 0); // Il percorso parte dall'indice 0
          }
        });
        if (idRef.value !== '') break;
      }

      return idRef.value;
    };

    const updateLinkResourceTree = (tree: Project.ProjectStructure): Project.ProjectStructure => {
      // Aggiungi le proprietà ai widget in modo immutabile
      const updatedTree = { ...tree };

      Object.keys(updatedTree).forEach((key) => {
        updatedTree[key] = updatedTree[key].map((t) => updateProperties(t));
      });

      return updatedTree;
    };

    return {
      linkResourceTree: null,

      loadLinkResourceTreeFiltredByPinType: async (type: Project.PinType, structure: Project.ProjectStructure) => {
        const updateeResourceTree = updateLinkResourceTree(structure);

        // Salva la nuova struttura aggiornata
        setLinkResourceTree(updateeResourceTree);

        const newResourceTree: Project.ProjectStructure = {};

        Object.keys(updateeResourceTree).forEach((key) => {
          newResourceTree[key] = updateeResourceTree[key].map((t) => filterResources(t, type));
        });
        return updateeResourceTree;
      },

      updateResourceTree: async (tree: Project.ProjectStructure) => {
        // Aggiungi le proprietà ai widget in modo immutabile
        const updatedTree = updateLinkResourceTree(tree);

        // Salva la nuova struttura aggiornata
        setLinkResourceTree(updatedTree);
      },

      getResourceTreeElement: async (id: string) => {
        return searchResourceTreeElement(id);
      },

      getResourceTreeElementId: async (element: Project.ResourceLinkPoint) => {
        return searchResourceTreeElementId(element);
      },
    };
  })
);

import { EventsManager } from '../events-manager';
import { PreviewMediaEventManager, PropertiesManager } from '../properties-manager';
import { WidgetContainer } from '../widget-container';
import { WidgetFactory } from '../widget-factory';
import { WidgetProperties } from '../widget';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';

enum TabPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

interface Tab {
  id: string;
  title: string;
  contentWidget?: HTMLElement | SVGElement;
  caption?: string;
}

export class Tabs extends WidgetContainer {
  private tabsContainer: HTMLElement;
  private contentContainer: HTMLElement;
  private tabs: Tab[] = [];
  private selectedTab: string | null = null;
  private position: TabPosition = TabPosition.Top;
  private readonly mediaHandler: PreviewMediaEventManager;

  constructor() {
    super();
    this.domElement.classList.add('fast-tabs');
    this.tabsContainer = document.createElement('div');
    this.tabsContainer.classList.add('tabs-container');
    this.contentContainer = document.createElement('div');
    this.contentContainer.classList.add('content-container');
    this.buildTabsStructure();
    this.mediaHandler = new PreviewMediaEventManager();
    PropertiesManager.setMediaEventManager(this.mediaHandler);
    EventsManager.setOnEvent((event) => {
      if (event.propertyName === 'ItemClick' && event.widgetId === this.domElement.id) {
        this.selectTab(event.data);
      }
      console.log(JSON.stringify(event));
    });
  }

  private buildTabsStructure(): void {
    this.domElement.innerHTML = '';
    const wrapper = document.createElement('div');
    wrapper.classList.add('tabs-wrapper');
    if (this.position === TabPosition.Top || this.position === TabPosition.Bottom) {
      wrapper.classList.add(this.position === TabPosition.Top ? 'tabs-wrapper-top' : 'tabs-wrapper-bottom');
      wrapper.style.flexDirection = 'column';
      if (this.position === TabPosition.Top) {
        wrapper.appendChild(this.tabsContainer);
        wrapper.appendChild(this.contentContainer);
      } else {
        wrapper.appendChild(this.contentContainer);
        wrapper.appendChild(this.tabsContainer);
      }
    } else if (this.position === TabPosition.Left || this.position === TabPosition.Right) {
      wrapper.classList.add(this.position === TabPosition.Left ? 'tabs-wrapper-left' : 'tabs-wrapper-right');
      wrapper.style.flexDirection = 'row';
      if (this.position === TabPosition.Left) {
        wrapper.appendChild(this.tabsContainer);
        wrapper.appendChild(this.contentContainer);
      } else {
        wrapper.appendChild(this.contentContainer);
        wrapper.appendChild(this.tabsContainer);
      }
    }
    this.domElement.appendChild(wrapper);
  }

  attach(): void {
    this.buildTabsStructure();
    this.renderTabs();
  }

  setProperties(properties: WidgetProperties): void {
    this.setWidgetContainerProperties(properties);
    for (const key in properties) {
      switch (key) {
        case 'Position':
          this.setPosition(properties.Position);
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
  }

  setPosition(position: TabPosition): void {
    if (Object.values(TabPosition).includes(position)) {
      this.position = position;
      this.buildTabsStructure();
      this.renderTabs();
      this.renderTabContent();
    }
  }

  setWidgetContainerProperties(properties: WidgetProperties): void {
    super.setProperties(properties);
    if ('children' in properties) {
      const viewArray = properties['children'];
      if (Array.isArray(viewArray)) {
        while (this.domElement.firstChild) this.domElement.removeChild(this.domElement.firstChild);
        this.detachChildren();
        this.tabs = [];
        viewArray.forEach((viewProperties, index) => {
          const tabId = `tab-${index}`;
          const tabTitle = viewProperties['caption'] || `Tab ${index + 1}`;
          const tabElement = document.createElement('div');
          tabElement.classList.add('tab');
          tabElement.setAttribute('data-tab-id', tabId);
          tabElement.textContent = tabTitle;
          tabElement.onclick = () => {
            this.selectTab(tabId);
          };
          this.tabsContainer.appendChild(tabElement);
          const widget = WidgetFactory.buildWidget(viewProperties['type']);
          widget.setProperties(viewProperties);
          widget.attach();
          this.tabs.push({
            id: tabId,
            title: tabTitle,
            contentWidget: widget.domElement,
            caption: viewProperties['caption'],
          });
          this.pushWidget(widget);
        });
        this.renderTabs();
        const openedItem = properties['OpenedItem'];
        if (openedItem && this.tabs.some((tab) => tab.id === openedItem)) {
          this.selectTab(openedItem);
        } else if (this.tabs.length > 0) {
          this.selectTab(this.tabs[0].id);
        }
        this.renderTabContent();
      }
    }
  }

  selectTab(id: string): void {
    if (this.selectedTab === id) {
      return;
    }
    this.selectedTab = id;
    useWidgetPropsStore.getState().setEditingProp('OpenedItem', id, null);
    useWidgetPropsStore.getState().widgetPropertyHandler.setProperty(this.domElement.id, '', 'OpenedItem', id);
    this.renderTabs();
    this.renderTabContent();
  }

  renderTabs(): void {
    this.tabsContainer.innerHTML = '';
    this.tabs.forEach((tab) => {
      const tabElement = document.createElement('div');
      tabElement.classList.add('tab');
      tabElement.setAttribute('data-tab-id', tab.id);
      tabElement.textContent = tab.caption || tab.title;
      if (tab.id === this.selectedTab) {
        tabElement.classList.add('active');
      }
      tabElement.onclick = () => {
        this.selectTab(tab.id);
      };
      this.tabsContainer.appendChild(tabElement);
    });
  }

  renderTabContent(): void {
    this.tabs.forEach((tab) => {
      if (tab.contentWidget) {
        if (!this.contentContainer.contains(tab.contentWidget)) {
          this.contentContainer.appendChild(tab.contentWidget);
        }
        if (tab.id === this.selectedTab) {
          tab.contentWidget.style.display = 'block';
        } else {
          tab.contentWidget.style.display = 'none';
        }
      }
    });
  }
}

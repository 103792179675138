/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'primereact/button';
import { ColorPicker, ColorPickerRGBType } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import OverlayComponent from '../utils/OverlayComponent';
import React, { useEffect, useRef, useState } from 'react';

interface ColorPickerProps extends PropertyEditorComponentProps {
  type: string;
  isResponsive: boolean;
}

enum AvailableColorFormat {
  Hex = 'hex',
  Rgb = 'rgb',
}

// color default values
const rgbDefault: ColorPickerRGBType = { r: 0, g: 0, b: 0 };

//**********************************************************************************
// ColorPickerComponent
//**********************************************************************************
const ColorPickerComponent = ({ data, type, isResponsive, onDataChange }: ColorPickerProps) => {
  // const setWidgetProps = useWidgetPropsStore((state) => state.setEditingProps);
  let hexColor: string = data;
  const hexToRgb = (color: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color as string);
    const rgb: ColorPickerRGBType = rgbDefault;
    if (result) {
      rgb.r = parseInt(result[1], 16);
      rgb.g = parseInt(result[2], 16);
      rgb.b = parseInt(result[3], 16);
    }
    return rgb;
  };

  const [colorHEX, setColorHEX] = useState<string>(data);
  const colorRGB = useRef<ColorPickerRGBType>(hexToRgb(data));
  const [colorFormat, setColorFormat] = useState<AvailableColorFormat>(AvailableColorFormat.Hex);
  useEffect(() => {
    setColorHEX(data);
  }, [data]);

  // overlay
  const op = useRef<any>(null);

  // rgb color representation
  let rgbString = `r:${colorRGB.current.r}, g:${colorRGB.current.g}, b:${colorRGB.current.b}`;

  const getColorFromFormat = (format: AvailableColorFormat = null): any => {
    const colFormat = format == null ? colorFormat : format;
    return colFormat !== AvailableColorFormat.Rgb ? colorHEX : rgbString;
  };
  const [displayString, setDisplayString] = useState<string>(getColorFromFormat());

  const doUpdateColor = (value: string | ColorPickerRGBType, format: AvailableColorFormat = null) => {
    let rgb: ColorPickerRGBType;

    const colFormat = format == null ? colorFormat : format;
    switch (colFormat) {
      case AvailableColorFormat.Rgb:
        if (typeof value == 'string' && value.length > 0) {
          const rgbColor = value.replace(/r:|g:|b:/g, '');
          const rgbArray = rgbColor.split(',').map((v) => parseInt(v, 10));
          rgb = { r: rgbArray[0] || 0, g: rgbArray[1] || 0, b: rgbArray[2] || 0 };
        } else {
          rgb = value as ColorPickerRGBType;
        }
        colorRGB.current = rgb;
        hexColor = rgbToHex(rgb);
        setColorHEX(hexColor);
        rgbString = `r:${rgb.r}, g:${rgb.g}, b:${rgb.b}`;
        break;
      default:
        hexColor = value as string;
        rgb = hexToRgb(hexColor);
        setColorHEX(hexColor);
        colorRGB.current = rgb;
        rgbString = `r:${rgb.r}, g:${rgb.g}, b:${rgb.b}`;
    }
    setDisplayString(getFormattedColor(hexColor, colFormat));
  };

  const updateValueExternal = () => {
    if (hexColor) {
      // setWidgetProps(hexColor,{key:type,isResponsive})
    }
  };

  //**********************************************************************************
  // Events
  //**********************************************************************************

  const handleColorChange = (event: any) => {
    console.log(event);
    const formattedColor = getFormattedColor(event?.value, colorFormat);
    doUpdateColor(formattedColor);
    const prefix = ![undefined, null].includes(event?.value) ? '#' : '';
    onDataChange(prefix + event?.value);
    updateValueExternal();
  };

  const handleColorFormatChange = (format: AvailableColorFormat) => {
    setColorFormat(format);
    setDisplayString(getFormattedColor(colorHEX, format));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    doUpdateColor(event.target?.value);
    setTimeout(() => onDataChange(event.target?.value), 100);
    updateValueExternal();
  };

  //**********************************************************************************
  // Utility
  //**********************************************************************************

  const rgbToHex = (color: ColorPickerRGBType) => {
    return ((1 << 24) | (color.r << 16) | (color.g << 8) | color.b).toString(16).slice(1);
  };

  const getFormattedColor = (hexColor: string, format: AvailableColorFormat): string => {
    switch (format) {
      case AvailableColorFormat.Rgb:
        const rgb: ColorPickerRGBType = hexToRgb(hexColor);
        return `r:${rgb.r}, g:${rgb.g}, b:${rgb.b}`;
        break;

      default:
        return hexColor;
    }
  };

  // const getFormat = (): any =>
  //   colorFormat !== AvailableColorFormat.Rgb ? AvailableColorFormat.Hex : AvailableColorFormat.Rgb;

  const getInputIdFromFormat = (): any => (colorFormat !== AvailableColorFormat.Rgb ? 'cp-hex' : 'cp-rgb');

  return (
    <>
      <ColorPicker
        style={{ marginTop: '5px' }}
        inputId={getInputIdFromFormat()}
        onClick={(e) => op.current.toggle(e)}
        format={AvailableColorFormat.Hex}
        value={colorHEX as string}
        className="mb-3"
      />
      <OverlayComponent title="Color Picker" customRef={op}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <ColorPicker
              style={{ marginTop: '5px' }}
              inputId={getInputIdFromFormat()}
              format={AvailableColorFormat.Hex}
              value={colorHEX as string}
              // value={colorFormat && colorFormat !== AvailableColorFormat.Rgb ? colorHEX as string : colorRGB}
              onChange={handleColorChange}
              className="mb-3"
              inline
            />
          </div>
          <div>
            <InputText
              style={{ marginBottom: '5px', width: '195px' }}
              value={displayString}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex gap-2 flex-row-reverse">
            {Object.values(AvailableColorFormat).map((acf, index) => {
              return (
                <Button
                  key={index}
                  size="small"
                  disabled={colorFormat === acf}
                  label={acf}
                  onClick={() => handleColorFormatChange(acf)}
                />
              );
            })}
          </div>
        </div>
      </OverlayComponent>
    </>
  );
};

export default ColorPickerComponent;

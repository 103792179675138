/* eslint-disable @typescript-eslint/no-explicit-any */
import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import { Project } from '@api';
import { PropertiesConfig, Property } from './interface';
import { PropertyEditorComponentProps } from '@components/property-edit/utils/PropertyEditorFactory';
import { TabName, TextPosition } from './enum';
import { borderField, colorField } from './colorBorderField';
import { captionPropertyField } from '@components/property-edit/component/CheckboxComponent';
import { containerFields } from './containerField';
import { dimAndPosFields } from './dimAndPosFields';
import { fontField } from './fontField';
import { genericEvents, imagePropertyField, nameField, textField } from '@config/widget/genericField';
import { listField } from './listField';
import { transformField } from './transformField';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import ColorPickerComponent from '@components/property-edit/component/ColorPickerComponent';
import EsaDropdown from '@components/property-edit/component/DropdownComponent';
import ImageListPropertyEditor from '@components/property-edit/component/ImageListPropertyEditor';
import ImagePropertyEditor from '@components/property-edit/component/ImagePropertyEditor';
import InputTextEditor from '@components/property-edit/component/InputTextComponent';
import ListItemsComponent from '@components/property-edit/component/ListItemsComponent';
import MultiPropertyArrayEditor from '@components/property-edit/component/MultiPropertyArrayComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';
import NumericInput from '@components/property-edit/component/NumericInputComponent';
import SvgPropertyEditor from '@components/property-edit/component/SvgPropertyEditor';
import ToggleButtonBar from '@components/property-edit/component/ToggleButtonComponent';

export type WidgetProperties = { [field: string]: Property };
export type WidgetUpdateCallback = (data: any, parentData: any, properties: WidgetProperties) => void;
export type ComponentGenerator = (props: PropertyEditorComponentProps) => React.JSX.Element;
export type WidgetData = Record<
  string,
  {
    /*  icon?: string;
    caption?: string;
    section?: string; */
    config: PropertiesConfig;
  }
>;

export function merge(...configs: (PropertiesConfig | WidgetProperties)[]): PropertiesConfig {
  function isConfig(prop: PropertiesConfig | WidgetProperties): prop is PropertiesConfig {
    return prop.properties !== undefined;
  }
  const mergedConfig: PropertiesConfig = {
    properties: {},
    onUpdate: [],
  };

  for (const config of configs) {
    // Unisce le proprietà. Le proprietà con lo stesso nome verranno sovrascritte
    // dall'ultima configurazione fornita che le contiene.
    mergedConfig.properties = {
      ...mergedConfig.properties,
      ...(isConfig(config) ? config.properties : config),
    };

    // Unisce gli array di callback 'onUpdate', se presenti.
    if (config.onUpdate && isConfig(config)) {
      mergedConfig.onUpdate = [...(mergedConfig.onUpdate || []), ...config.onUpdate];
    }
  }

  return mergedConfig;
}

export const propertyData: WidgetData = {
  page: {
    config: merge(
      {
        onUpdate: [
          (data: any, __parentData: any, properties: WidgetProperties) => {
            properties['AlignPage'].visible = data.FullScreen === undefined || !data.FullScreen;
          },
        ],
        properties: {
          FullScreen: {
            tab: TabName.Layout,
            section: 'Pagina',
            defaultData: false,
            editor: ToggleButtonBar,
            pinType: Project.PinType.None,
            caption: 'Schermo intero',
            captionPosition: TextPosition.Left,
          },
          AlignPage: {
            tab: TabName.Layout,
            section: 'Pagina',
            defaultData: AlignItems.Start,
            editorOptions: {
              options: [
                { icon: 'bi-align-top', value: AlignItems.Start },
                { icon: 'bi-align-middle', value: AlignItems.Middle },
                { icon: 'bi-align-bottom', value: AlignItems.End },
              ],
            },
            caption: 'Allinea la pagina',
            editor: ButtonBar,
            captionPosition: TextPosition.Up,
            isResponsive: true,
            pinType: Project.PinType.None,
          },
          Show: {
            index: 1,
            tab: TabName.Advanced,
            section: 'Azioni',
            pinType: Project.PinType.In,
            caption: 'Show',
          },
        },
      },
      containerFields
    ),
  },
  view: {
    config: merge(nameField, containerFields, dimAndPosFields),
  },
  button: {
    config: merge(
      genericEvents,
      nameField,
      textField,
      colorField({ colorDefault: 'ffffff', backgroungColorDefault: '00bbb4' }),
      borderField({ color: '00bbb4', style: 'hidden', width: 3, radius: 3 }),
      dimAndPosFields,
      fontField({ prefix: 'Main' }),
      transformField(),
      imagePropertyField
    ),
  },
  textBox: {
    config: merge(nameField, dimAndPosFields, {
      properties: {
        Caption: {
          index: 1,
          tab: TabName.Content,
          section: 'Testo',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          hasNotDefault: true,
          caption: 'Text',
          captionPosition: TextPosition.Left,
        },
      },
    }),
  },

  label: {
    config: merge(
      nameField,
      textField,
      dimAndPosFields,
      colorField({ colorDefault: '000000', backgroundColorVisible: false }),
      fontField({ prefix: 'Main' }),
      transformField()
    ),
  },

  videoPlayer: {
    config: merge(
      {
        properties: {
          Video: {
            tab: TabName.Advanced,
            section: 'Video',
            editor: InputTextEditor,
            pinType: Project.PinType.InOut,
            caption: 'Video',
            captionPosition: TextPosition.Left,
          },
          Play: {
            tab: TabName.Advanced,
            section: 'Commands',
            pinType: Project.PinType.InOut,
            caption: 'Play',
          },
          Pause: {
            tab: TabName.Advanced,
            section: 'Commands',
            pinType: Project.PinType.InOut,
            caption: 'Pause',
          },
          CurrentTime: {
            tab: TabName.Advanced,
            section: 'Commands',
            editor: InputTextEditor,
            caption: 'CurrentTime',
          },
        },
      },
      nameField,
      dimAndPosFields
    ),
  },
  checkbox: {
    config: merge(
      nameField,
      textField,
      dimAndPosFields,
      transformField(),
      colorField({ colorDefault: '000000', backgroundColorVisible: true }),
      fontField({ prefix: 'Label' }),
      captionPropertyField({ backgroungColorDefault: '00bbb4' })
    ),
  },
  radio: {
    config: merge(
      nameField,
      textField,
      dimAndPosFields,
      transformField(),
      colorField({ colorDefault: '000000', backgroundColorVisible: true }),
      fontField({ prefix: 'Label' }),
      captionPropertyField({ backgroungColorDefault: '00bbb4' })
    ),
  },
  frameHtml: {
    config: merge(
      {
        properties: {
          Url: {
            tab: TabName.Content,
            section: 'URL',
            editor: InputTextEditor,
            pinType: Project.PinType.InOut,
            hasNotDefault: true,
            caption: 'Url',
            captionPosition: TextPosition.Left,
          },
        },
      },
      nameField,
      dimAndPosFields
    ),
  },
  listBox: {
    config: merge(
      nameField,
      dimAndPosFields,
      listField,
      {
        properties: {
          ListIsMultiline: {
            tab: TabName.Content,
            section: 'List',
            editor: ToggleButtonBar,
            hasNotDefault: true,
            pinType: Project.PinType.None,
            caption: 'Multilinea',
            captionPosition: TextPosition.Left,
          },
        },
      },
      fontField({ prefix: 'List' }),
      colorField({ colorDefault: '000000', backgroundColorVisible: true })
    ),
  },
  accordion: {
    config: merge(
      nameField,
      dimAndPosFields,
      borderField({ color: '000000', style: 'hidden', width: 3, radius: 3 }),
      fontField({ prefix: 'Accordion' }),
      colorField({ colorDefault: '000000', backgroundColorVisible: true }),
      {
        properties: {
          Headers: {
            tab: TabName.Content,
            section: 'List',
            editor: MultiPropertyArrayEditor,
            isResponsive: false,
            pinType: Project.PinType.InOut,
            hasNotDefault: true,
            draggable: false,
            caption: 'Headers',
            captionPosition: TextPosition.Up,
          },
          HTextAlign: {
            index: 2,
            tab: TabName.Content,
            section: 'List',
            defaultData: AlignItems.Start,
            editorOptions: {
              options: [
                { icon: 'bi-align-start', value: AlignItems.Start },
                { icon: 'bi-align-center', value: AlignItems.Middle },
                { icon: 'bi-align-end', value: AlignItems.End },
              ],
            },
            caption: 'Allinea testo orizzontalmente',
            editor: ButtonBar,
            captionPosition: TextPosition.Up,
            isResponsive: true,
            pinType: Project.PinType.None,
          },
          AccordionIsHorizontal: {
            tab: TabName.Content,
            section: 'List',
            editor: ToggleButtonBar,
            hasNotDefault: true,
            pinType: Project.PinType.None,
            caption: 'Orizzontale',
            captionPosition: TextPosition.Left,
          },
          OpenedItem: {
            tab: TabName.Content,
            section: 'List',
            editor: InputTextEditor,
            pinType: Project.PinType.In,
            hasNotDefault: true,
            caption: 'Item aperto',
            captionPosition: TextPosition.Left,
          },
        },
      }
    ),
  },
  slider: {
    config: merge(nameField, dimAndPosFields, {
      properties: {
        MinValue: {
          index: 1,
          tab: TabName.Advanced,
          section: 'Values',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'MinValue',
        },
        MaxValue: {
          index: 2,
          tab: TabName.Advanced,
          section: 'Values',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'MaxValue',
        },
        Value: {
          index: 3,
          tab: TabName.Advanced,
          section: 'Values',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'Value',
        },
        IntOrFloat: {
          index: 4,
          tab: TabName.Advanced,
          section: 'Values',
          editor: ToggleButtonBar,
          PinType: Project.PinType.InOut,
          caption: 'IntOrFloat',
        },
        Step: {
          index: 5,
          tab: TabName.Advanced,
          section: 'Values',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'StepMode',
        },
        BarColor: {
          index: 6,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: ColorPickerComponent,
          PinType: Project.PinType.InOut,
          caption: 'Bar Color',
        },
        BarThickness: {
          index: 7,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'Bar Thickness',
        },
        ThumbBorderColor: {
          index: 8,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: ColorPickerComponent,
          PinType: Project.PinType.InOut,
          caption: 'Thumb Border Color',
        },
        ThumbBackgroundColor: {
          index: 9,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: ColorPickerComponent,
          PinType: Project.PinType.InOut,
          caption: 'Thumb Color',
        },
        ThumbSize: {
          index: 10,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: InputTextEditor,
          PinType: Project.PinType.InOut,
          caption: 'Thumb Size',
        },
        Vertical: {
          index: 11,
          tab: TabName.Advanced,
          section: 'Custom',
          editor: ToggleButtonBar,
          PinType: Project.PinType.InOut,
          caption: 'Vertical',
        },
      },
    }),
  },
  svg: {
    config: merge(nameField, containerFields, dimAndPosFields),
  },
  svgText: {
    config: {
      properties: {
        x: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione X',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Deve rimanere un valore numerico
        },
        y: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione Y',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Deve rimanere un valore numerico
        },
        // Le altre proprietà come fontSize, fill, text
        fontSize: {
          tab: TabName.Content,
          section: 'Stile Testo',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Dimensione Font',
          captionPosition: TextPosition.Left,
          defaultData: 24, // Valore numerico per la dimensione del font
        },
        fill: {
          tab: TabName.Content,
          section: 'Colore',
          editor: ColorPickerComponent,
          pinType: Project.PinType.InOut,
          caption: 'Colore del Testo',
          captionPosition: TextPosition.Left,
          defaultData: '000000', // Colore predefinito
        },
        text: {
          tab: TabName.Content,
          section: 'Testo',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Testo',
          captionPosition: TextPosition.Left,
          hasNotDefault: true,
        },
        positionX: {
          tab: TabName.Layout,
          section: 'Posizionamento',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione X Assoluta',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Valore numerico per la posizione X
        },
        positionY: {
          tab: TabName.Layout,
          section: 'Posizionamento',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione Y Assoluta',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Valore numerico per la posizione Y
        },
        rotate: {
          tab: TabName.Layout,
          section: 'Rotazione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Rotazione',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Default rotation angle in degrees
        },
      },
      onUpdate: [
        (data: any, __parentData: any, properties: WidgetProperties) => {
          const rotation = data.rotate || 0;
          const svgElement = document.getElementById(data.id);

          // Assicuriamoci che l'elemento sia effettivamente un SVGTextElement
          if (svgElement instanceof SVGTextElement) {
            const bbox = svgElement.getBBox();
            const centerX = bbox.x + bbox.width / 2;
            const centerY = bbox.y + bbox.height / 2;

            // Imposta la trasformazione di rotazione
            svgElement.setAttribute('transform', `rotate(${rotation} ${centerX} ${centerY})`);
          }
        },
      ],
    },
  },
  svgRect: {
    config: {
      properties: {
        x: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione X',
          captionPosition: TextPosition.Left,
          defaultData: 0,
        },
        y: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione Y',
          captionPosition: TextPosition.Left,
          defaultData: 0,
        },
        width: {
          tab: TabName.Layout,
          section: 'Dimensioni',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Larghezza',
          captionPosition: TextPosition.Left,
          defaultData: 100,
        },
        height: {
          tab: TabName.Layout,
          section: 'Dimensioni',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Altezza',
          captionPosition: TextPosition.Left,
          defaultData: 100,
        },
        fill: {
          tab: TabName.Content,
          section: 'Stile',
          editor: ColorPickerComponent,
          pinType: Project.PinType.InOut,
          caption: 'Colore di Riempimento',
          captionPosition: TextPosition.Left,
          defaultData: '0000ff',
        },
        stroke: {
          tab: TabName.Content,
          section: 'Bordo',
          editor: ColorPickerComponent,
          pinType: Project.PinType.InOut,
          caption: 'Colore del Bordo',
          captionPosition: TextPosition.Left,
          defaultData: '000000',
        },
        strokeWidth: {
          tab: TabName.Content,
          section: 'Bordo',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Spessore del Bordo',
          captionPosition: TextPosition.Left,
          defaultData: 2,
        },
      },
      onUpdate: [],
    },
  },
  svgExternal: {
    config: {
      properties: {
        svgUrl: {
          tab: TabName.Content,
          section: 'SVG',
          editor: SvgPropertyEditor, // Usa l'editor SvgPropertyEditor
          pinType: Project.PinType.InOut,
          caption: "URL dell'SVG",
          captionPosition: TextPosition.Left,
          hasNotDefault: true, // Non ha un valore di default, l'utente dovrà caricare l'SVG
        },
        x: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione X',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Valore predefinito per la posizione X
        },
        y: {
          tab: TabName.Layout,
          section: 'Posizione',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Posizione Y',
          captionPosition: TextPosition.Left,
          defaultData: 0, // Valore predefinito per la posizione Y
        },
        width: {
          tab: TabName.Layout,
          section: 'Dimensioni',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Larghezza',
          captionPosition: TextPosition.Left,
          defaultData: 100, // Larghezza predefinita
        },
        height: {
          tab: TabName.Layout,
          section: 'Dimensioni',
          editor: InputTextEditor,
          pinType: Project.PinType.InOut,
          caption: 'Altezza',
          captionPosition: TextPosition.Left,
          defaultData: 100, // Altezza predefinita
        },
      },
      onUpdate: [
        (data: any, __parentData: any, properties: WidgetProperties) => {
          const svgElement = document.getElementById(data.id);
          // Assicurati che l'elemento sia un contenitore di tipo SVG
          if (svgElement instanceof SVGElement) {
            svgElement.setAttribute('x', data.x || 0);
            svgElement.setAttribute('y', data.y || 0);
            svgElement.setAttribute('width', data.width || 100);
            svgElement.setAttribute('height', data.height || 100);
            svgElement.setAttribute('href', data.svgUrl || '');
          }
        },
      ],
    },
  },
  tabs: {
    config: merge(
      nameField,
      dimAndPosFields,
      borderField({ color: '000000', style: 'hidden', width: 3, radius: 3 }),
      colorField({ colorDefault: '000000', backgroundColorVisible: true }),
      {
        properties: {
          Headers: {
            tab: TabName.Content,
            section: 'Tabs',
            editor: ListItemsComponent,
            isResponsive: false,
            pinType: Project.PinType.InOut,
            hasNotDefault: true,
            draggable: false,
            caption: 'Headers',
            captionPosition: TextPosition.Left,
          },
          Position: {
            tab: TabName.Content,
            section: 'Tabs',
            editor: EsaDropdown,
            editorOptions: {
              options: [
                { label: 'top', value: 'top' },
                { label: 'right', value: 'right' },
                { label: 'bottom', value: 'bottom' },
                { label: 'left', value: 'left' },
              ],
            },
            caption: 'Position',
          },
        },
      }
    ),
  },
  image: {
    config: merge(
      nameField,
      dimAndPosFields,
      borderField({ color: '000000', style: 'hidden', width: 1 }),
      transformField(),
      {
        properties: {
          Image: {
            index: 1,
            tab: TabName.Content,
            section: 'Image',
            editor: ImagePropertyEditor,
            pinType: Project.PinType.In,
            defaultData: 'https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg',
            caption: 'Image',
            captionPosition: TextPosition.Left,
          },
        },
      }
    ),
  },
  imageList: {
    config: merge(
      nameField,
      dimAndPosFields,
      borderField({ color: '000000', style: 'hidden', width: 1 }),
      transformField(),
      {
        properties: {
          Images: {
            tab: TabName.Content,
            section: 'Images',
            editor: ImageListPropertyEditor,
            pinType: Project.PinType.In,
            defaultData: 'https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg',
            caption: 'Image',
            captionPosition: TextPosition.Left,
          },
          Index: {
            tab: TabName.Content,
            section: 'Images',
            editor: NumericInput,
            pinType: Project.PinType.None,
            caption: 'Index',
            captionPosition: TextPosition.Left,
            isResponsive: false,
            hasUM: false,
          },
        },
      }
    ),
  },
};

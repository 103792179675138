/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Project } from '@api';
import { PropertiesManager } from '../PageDocumentEditor/view-manager/widget-handler/properties-manager';
import { WidgetProperties } from '@config/widget/widgetData';
import { XYPosition } from '@xyflow/react';
import { blockData } from '@config/blockData';
import { deepCopy } from '@services/utils';
import { findResourceById } from '@services/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface NodeProperties {
  [key: string]: any;
}

type Props = {
  documentData: Project.DocumentData;
  onDocumentDataChange: (document: Project.DocumentData) => void;
  documentId: string;
};

let idInputHandleIndex = 0;
let idOutputHandleIndex = 0;

const linkButtonWidth = 20;
const deleteButtonWidth = 24;
const handleWidth = 13;
const handleHeight = 20;
const nodeLabelHeight = 20;
const nodeAddHandleHeight = 24;

export const useFlowDocumentDataUtils = ({ documentData: _documentData, onDocumentDataChange, documentId }: Props) => {
  const [localDocumentData, setLocalDocumentData] = useState(_documentData);
  const [idNodeIndex, setIdNodeIndex] = useState(0);
  const [idEdgeIndex, setIdEdgeIndex] = useState(0);

  const memorizedDocumentData = useMemo(() => deepCopy(localDocumentData), [localDocumentData]);

  const searchNewNodeAndHandlesIndex = useCallback((resourceTree: Project.ResourceTreeElement[]) => {
    resourceTree.forEach((element) => {
      const elementId: string = element.data.id;

      if (elementId.startsWith('node')) {
        setIdNodeIndex((prev) => Math.max(prev, parseInt(elementId.split('-')[1])));
      } else if (elementId.startsWith('in') && elementId.split('_').length > 1) {
        idInputHandleIndex = Math.max(idInputHandleIndex, parseInt(elementId.split('_').pop().split('-')[1]));
      } else if (elementId.startsWith('out') && elementId.split('_').length > 1) {
        idOutputHandleIndex = Math.max(idOutputHandleIndex, parseInt(elementId.split('_').pop().split('-')[1]));
      }

      if (element.children) {
        searchNewNodeAndHandlesIndex(element.children);
      }
    });
  }, []);

  const searchNewEdgeIndex = useCallback((metadata: Project.FlowMetadata) => {
    if (metadata.edges) {
      Object.keys(metadata.edges).forEach((key) => {
        setIdEdgeIndex((prev) => Math.max(prev, parseInt(key.split('-')[1])));
      });
    }
  }, []);

  const createHandleString = (dynamic: boolean) => (dynamic ? '_Handle_Dynamic-' : '_Handle-');

  const createHandleId = useCallback(
    (type: string, nodeId: number, dynamic: boolean) => {
      const handleIndex = type === 'input' ? idInputHandleIndex : idOutputHandleIndex;
      return `${type === 'input' ? 'in' : 'out'}-${nodeId}${createHandleString(dynamic)}${handleIndex}`;
    },
    [idInputHandleIndex, idOutputHandleIndex]
  );

  const createHandle = useCallback(
    (type: string, name: string, nodeId: number, dynamic: boolean): Project.ResourceTreeElement => {
      if (type === 'input') idInputHandleIndex++;
      else idOutputHandleIndex++;

      const handleId = createHandleId(type, nodeId, dynamic);
      return {
        name,
        data: { id: handleId },
        pinType: type === 'input' ? Project.PinType.In : Project.PinType.Out,
      };
    },
    [createHandleId]
  );

  const getExistingHeaderNumber = useCallback((arr: Project.ResourceTreeElement[], prefix: string) => {
    if (arr) {
      const existingNumbers = arr
        .filter((item) => item.name.startsWith(prefix))
        .map((item) => parseInt(item.name.replace(prefix, '')))
        .filter((num) => !isNaN(num));

      return existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;
    }
    return 1;
  }, []);

  const getHandleMinWidth = (configType: string): number => {
    const nodeConfig = blockData[configType];

    if (!nodeConfig) return 0;

    const maxLengthInput = Math.max(
      ...nodeConfig.inputs.map((input) => input.length),
      nodeConfig.inCaption?.length || 0
    );

    const maxLengthOutput = Math.max(
      ...nodeConfig.outputs.map((output) => output.length),
      nodeConfig.outCaption?.length || 0
    );

    return (maxLengthInput + maxLengthOutput) * 5 + handleWidth + linkButtonWidth + deleteButtonWidth + 50; //50 extra for margins and space between input and output
  };

  const getHandleMinHeight = (configType: string): number => {
    const nodeConfig = blockData[configType];

    if (!nodeConfig) return 0;

    return (
      Math.max(nodeConfig.inputs.length, nodeConfig.outputs.length) * handleHeight +
      nodeAddHandleHeight + // botton to add dynamic handles
      nodeLabelHeight + // caption of the node
      20 // extra for margins and space between label and handles
    );
  };

  useEffect(() => {
    setLocalDocumentData(_documentData);
  }, [_documentData]);

  useEffect(() => {
    if (memorizedDocumentData.resourceTree) {
      searchNewNodeAndHandlesIndex(memorizedDocumentData.resourceTree);
    }
    if (memorizedDocumentData.metadata) {
      searchNewEdgeIndex(memorizedDocumentData.metadata);
    }
  }, [memorizedDocumentData, searchNewEdgeIndex, searchNewNodeAndHandlesIndex]);

  const createNode = useCallback(
    (nodePosition: XYPosition, label: string, type: string, inputs: string[], outputs: string[]) => {
      const documentData = deepCopy(memorizedDocumentData);
      const newIdNodeIndex = idNodeIndex + 1;
      setIdNodeIndex(newIdNodeIndex);

      const id = `node-${newIdNodeIndex}-${documentId}`;
      const name = `block${newIdNodeIndex}`;
      const nodeProps: Project.ResourceTreeElement[] = [];
      const inputsHandles: Project.ResourceTreeElement[] = [];
      const outputsHandles: Project.ResourceTreeElement[] = [];

      const p = blockData[type].config.properties;
      const nodeProperties = Object.keys(p);
      nodeProperties.forEach((prop) => {
        if (prop !== 'Name') {
          nodeProps.push({
            name: prop,
            data: { id: `prop-${newIdNodeIndex}_${prop}` },
            pinType: blockData[type].config.properties[prop].pinType,
          });
        }
      });

      inputs.forEach((input) => {
        inputsHandles.push(createHandle('input', input, parseInt(id.split('-')[1]), false));
      });

      outputs.forEach((output) => {
        outputsHandles.push(createHandle('output', output, parseInt(id.split('-')[1]), false));
      });

      const newRTNode: Project.ResourceTreeElement = {
        name,
        data: { id },
        pinType: Project.PinType.InOut,
        children: [
          { name: 'Props', data: { id: `prop-${newIdNodeIndex}` }, children: nodeProps },
          { name: 'Ins', data: { id: `in-${idNodeIndex}` }, children: inputsHandles },
          { name: 'Outs', data: { id: `out-${idNodeIndex}` }, children: outputsHandles },
        ],
      };

      documentData.resourceTree = documentData.resourceTree || [];
      documentData.resourceTree.push(newRTNode);

      const newIMNode: Project.FlowNode = {
        id,
        type: 'CustomNode',
        data: { label: label || 'node to show' },
        position: nodePosition,
        Caption: blockData[type].config.properties['Caption'] ? label : undefined,
        configType: type,
        dimensions: {
          width: getHandleMinWidth(type),
          height: getHandleMinHeight(type),
        },
      };

      documentData.internalMetadata = documentData.internalMetadata || { nodes: {} };
      documentData.internalMetadata.nodes[newIMNode.id] = newIMNode;

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);

      return newIMNode;
    },
    [memorizedDocumentData, idNodeIndex, documentId, onDocumentDataChange]
  );

  const removeNode = useCallback(
    (nodeIdToDelete: string) => {
      const documentData = deepCopy(memorizedDocumentData);

      documentData.resourceTree = documentData.resourceTree.filter((item) => item.data.id !== nodeIdToDelete);

      if (documentData.internalMetadata?.nodes) {
        delete documentData.internalMetadata.nodes[nodeIdToDelete];
      }

      if (documentData.metadata?.edges) {
        Object.keys(documentData.metadata.edges).forEach((edgeKey) => {
          const edge = documentData.metadata.edges[edgeKey];
          if (edge.source === nodeIdToDelete || edge.target === nodeIdToDelete) {
            delete documentData.metadata.edges[edgeKey];
          }
        });
      }

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);
    },
    [memorizedDocumentData, onDocumentDataChange]
  );

  const moveNode = useCallback(
    (nodeIdToMove: string, newPosition: XYPosition) => {
      const documentData = deepCopy(memorizedDocumentData);
      const nodeToUpdate = documentData.internalMetadata?.nodes[nodeIdToMove];

      if (nodeToUpdate) nodeToUpdate.position = newPosition;

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);
    },
    [memorizedDocumentData, onDocumentDataChange]
  );

  const renameNode = useCallback(
    (nodeIdToRename: string, newName: string) => {
      setLocalDocumentData((prevDocumentData) => {
        const documentData = deepCopy(prevDocumentData);

        documentData.resourceTree = documentData.resourceTree.map((item) =>
          item.data.id === nodeIdToRename ? { ...item, name: newName } : item
        );

        onDocumentDataChange(documentData);
        return documentData;
      });
    },
    [onDocumentDataChange]
  );

  const updateProperty = useCallback(
    (nodeIdToUpdate: string, properties: NodeProperties) => {
      setLocalDocumentData((prevDocumentData) => {
        const documentData = deepCopy(prevDocumentData);

        const nodeToUpdate = documentData.internalMetadata?.nodes[nodeIdToUpdate];
        if (nodeToUpdate) {
          Object.keys(properties).forEach((key) => {
            const data = PropertiesManager.getData(properties, key);
            if (data === undefined) {
              delete nodeToUpdate[key];
            } else {
              nodeToUpdate[key] = data;
            }
            if (key === 'Caption') {
              nodeToUpdate.data.label = data;
            }
          });
        }

        onDocumentDataChange(documentData);
        return documentData;
      });
    },
    [onDocumentDataChange]
  );

  const createHandlesForNode = useCallback(
    (nodeIdToUpdate: string, type: string, caption: string, dynamicProperties: WidgetProperties) => {
      const documentData = deepCopy(memorizedDocumentData);

      const nodeToUpdate = findResourceById(documentData.resourceTree, nodeIdToUpdate);
      if (nodeToUpdate) {
        const childrenIndex = type === 'input' ? 1 : 2;
        const index = getExistingHeaderNumber(nodeToUpdate.children[childrenIndex]?.children, caption);
        const newHandleName = `${caption}${index}`;
        nodeToUpdate.children[childrenIndex]?.children.push(
          createHandle(type, newHandleName, parseInt(nodeIdToUpdate.split('-')[1]), true)
        );

        // update node height
        if (documentData.internalMetadata?.nodes) {
          const node: Project.FlowNode = documentData.internalMetadata.nodes[nodeIdToUpdate];
          if (node) {
            const inHandles = nodeToUpdate.children[1].children.length;
            const outHandles = nodeToUpdate.children[2].children.length;
            // nodeAddHandleHeight, nodeLabelHeight and 20 are for add buttons, label and extra space
            const handlesHeight = node.dimensions.height - nodeAddHandleHeight - nodeLabelHeight - 20;
            if (handlesHeight < Math.max(inHandles, outHandles) * handleHeight) {
              node.dimensions.height += handleHeight;
            }
          }
        }

        if (dynamicProperties) {
          const idHandle = type === 'input' ? idInputHandleIndex : idOutputHandleIndex;
          const keyHandle = type === 'input' ? 'in' : 'out';
          Object.keys(dynamicProperties).forEach((key) => {
            nodeToUpdate.children[0]?.children.push({
              name: key + index,
              data: { id: `prop-${(nodeToUpdate.data.id as string).split('-')[1]}_${key}-${keyHandle}${idHandle}` },
              pinType: dynamicProperties[key].pinType,
            });
          });
        }

        setLocalDocumentData(documentData);
        onDocumentDataChange(documentData);
      }
    },
    [memorizedDocumentData, onDocumentDataChange, createHandle]
  );

  const getNodeHandles = useCallback(
    (nodeId: string, handleKey: string) => {
      const currNode = memorizedDocumentData.resourceTree?.find((node) => node.data.id === nodeId);

      if (currNode?.children?.length === 3) {
        const childrenIndex = handleKey === 'input' ? 1 : 2;
        return currNode.children[childrenIndex].children.map((child) => child.name);
      }
      return [];
    },
    [memorizedDocumentData]
  );

  const isDynamicHandle = useCallback(
    (nodeId: string, handleKey: string, index: number) => {
      const currNode = memorizedDocumentData.resourceTree?.find((node) => node.data.id === nodeId);

      if (currNode?.children?.length === 3) {
        const childrenIndex = handleKey === 'input' ? 1 : 2;
        const handleChildren = currNode.children[childrenIndex]?.children;
        if (handleChildren && index <= handleChildren.length - 1) {
          return handleChildren[index].data.id.includes('Dynamic');
        }
      }
      return false;
    },
    [memorizedDocumentData]
  );

  const removeHandleFromNode = useCallback(
    (nodeId: string, type: string, index: number) => {
      const documentData = deepCopy(memorizedDocumentData);

      const nodeToUpdate = findResourceById(documentData.resourceTree, nodeId);
      if (nodeToUpdate) {
        const childrenIndex = type === 'input' ? 1 : 2;
        const handleKey = type === 'input' ? 'in' : 'out';
        const handleIndex =
          handleKey + (nodeToUpdate.children[childrenIndex]?.children[index].data.id as string).split('-').pop();
        // remove properties
        nodeToUpdate.children[0].children = nodeToUpdate.children[0]?.children.filter((item) => {
          return (item.data.id as string).split('-').pop() !== handleIndex;
        });

        // remove from resourceTree
        nodeToUpdate.children[childrenIndex]?.children.splice(index, 1);

        // update node height
        if (documentData.internalMetadata?.nodes) {
          const node: Project.FlowNode = documentData.internalMetadata.nodes[nodeId];
          if (node) {
            const inHandles = nodeToUpdate.children[1].children.length;
            const outHandles = nodeToUpdate.children[2].children.length;
            // nodeAddHandleHeight, nodeLabelHeight and 20 are for add buttons, label and extra space
            const handlesHeight = node.dimensions.height - nodeAddHandleHeight - nodeLabelHeight - 20;
            if (handlesHeight > Math.max(inHandles, outHandles) * handleHeight) {
              node.dimensions.height -= handleHeight;
            }
          }
        }
      }

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);
    },
    [memorizedDocumentData, onDocumentDataChange]
  );

  const createEdge = useCallback(
    (edgeItem: any) => {
      const documentData = deepCopy(memorizedDocumentData);

      const id = `conn-${idEdgeIndex + 1}-${documentId}`;
      setIdEdgeIndex((prev) => prev + 1);

      const newMEdge: Project.FlowEdge = {
        id,
        source: edgeItem.source,
        target: edgeItem.target,
        sourceHandle: edgeItem.sourceHandle,
        targetHandle: edgeItem.targetHandle,
      };

      documentData.metadata = documentData.metadata || { edges: {} };
      documentData.metadata.edges[newMEdge.id] = newMEdge;

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);
    },
    [memorizedDocumentData, documentId, onDocumentDataChange, idEdgeIndex]
  );

  const removeEdge = useCallback(
    (edgeIdToDelete: string) => {
      const documentData = deepCopy(memorizedDocumentData);

      if (documentData.metadata?.edges) {
        delete documentData.metadata.edges[edgeIdToDelete];
      }

      setLocalDocumentData(documentData);
      onDocumentDataChange(documentData);
    },
    [memorizedDocumentData, onDocumentDataChange]
  );

  return {
    createNode,
    removeNode,
    moveNode,
    renameNode,
    updateProperty,
    createHandlesForNode,
    getNodeHandles,
    isDynamicHandle,
    removeHandleFromNode,
    createEdge,
    removeEdge,
  };
};

import { FlexboxDirection } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/concrete-widgets/view-widget';
import { Project } from '@api';
import { PropertiesConfig } from './widget/interface';
import { TabName, TextPosition } from './widget/enum';
import { WidgetProperties, merge } from './widget/widgetData';
import { nameField } from './widget/genericField';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import InputTextEditor from '@components/property-edit/component/InputTextComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';

export type BlockData = Record<
  string,
  {
    config: PropertiesConfig;
    inputs: string[];
    outputs: string[];
    inCaption?: string;
    outCaption?: string;
    inputProperties?: WidgetProperties;
    outputProperties?: WidgetProperties;
  }
>;

export const blockData: BlockData = {
  baseNode: {
    config: merge(nameField, {
      properties: {
        Caption: {
          index: 1,
          tab: TabName.Content,
          section: 'Testo',
          editor: InputTextEditor,
          pinType: Project.PinType.In,
          hasNotDefault: true,
          caption: 'Caption',
          captionPosition: TextPosition.Left,
        },
      },
    }),
    inputs: ['in'],
    outputs: ['out'],
  },
  ifCondition: {
    config: merge(nameField, {
      properties: {
        Caption: {
          index: 1,
          tab: TabName.Content,
          section: 'Testo',
          editor: InputTextEditor,
          pinType: Project.PinType.In,
          hasNotDefault: true,
          caption: 'Caption',
          captionPosition: TextPosition.Left,
        },
        Pippo: {
          tab: TabName.Layout,
          section: 'Dimensione e posizione',
          editorOptions: {
            minValue: 0,
            maxValue: 1000,
          },
          caption: 'Altezza minima',
          editor: NumberSlider,
          captionPosition: TextPosition.Up,
          isResponsive: true,
          pinType: Project.PinType.None,
          hasUM: true,
        },
        Pluto: {
          tab: TabName.Layout,
          section: 'Contenitore',
          defaultData: FlexboxDirection.Right,
          editorOptions: {
            options: [
              { icon: 'pi pi-arrow-right', value: FlexboxDirection.Right },
              { icon: 'pi pi-arrow-down', value: FlexboxDirection.Bottom },
              { icon: 'pi pi-arrow-left', value: FlexboxDirection.Left },
              { icon: 'pi pi-arrow-up', value: FlexboxDirection.Top },
            ],
          },
          caption: 'Direzione',
          editor: ButtonBar,
          captionPosition: TextPosition.Left,
          isResponsive: true,
          pinType: Project.PinType.None,
        },
      },
    }),
    outputs: ['true', 'false'],
    inputs: ['first', 'second'],
    inCaption: 'captionInput',
    outCaption: 'captionOutput',
    inputProperties: {
      Paperino: {
        index: 1,
        tab: TabName.Content,
        section: 'Testo',
        editor: InputTextEditor,
        pinType: Project.PinType.In,
        hasNotDefault: true,
        caption: 'Paperino',
        captionPosition: TextPosition.Left,
      },
      QuiQuoQua: {
        tab: TabName.Layout,
        section: 'Dimensione e posizione',
        editorOptions: {
          minValue: 0,
          maxValue: 1000,
        },
        caption: 'QuiQuoQua',
        editor: NumberSlider,
        captionPosition: TextPosition.Up,
        isResponsive: true,
        pinType: Project.PinType.None,
        hasUM: true,
      },
    },
  },
};

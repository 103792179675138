import { EventsManager } from '..//events-manager';
import { Widget, WidgetProperties } from '../widget';
import { WidgetDictionary } from '../widget-dictionary';

export class SvgText implements Widget {
  private id: string | null = null;
  private currentProperties: WidgetProperties = {};
  public domElement: HTMLDivElement;
  private textElement: SVGTextElement;
  private svgElement: SVGElement;

  constructor() {
    // Contenitore HTML div per il testo SVG
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-svg-text-container');
    this.domElement.classList.add('svg-text');
    this.domElement.setAttribute('data-resizable', 'true');
    this.domElement.style.position = 'absolute';
    this.domElement.style.minWidth = '50px';
    this.domElement.style.minHeight = '20px';

    // elemento SVG all'interno del div
    this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.svgElement.setAttribute('width', '100%');
    this.svgElement.setAttribute('height', '100%');

    // elemento SVG <text>
    this.textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    this.textElement.setAttribute('x', '0');
    this.textElement.setAttribute('y', '24');
    this.textElement.setAttribute('font-size', '24');
    this.textElement.setAttribute('fill', 'black');
    this.textElement.textContent = 'Testo di base';
    this.svgElement.appendChild(this.textElement);
    this.domElement.appendChild(this.svgElement);
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }
    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });
    this.domElement.setAttribute('draggable', 'true');

    this.domElement.addEventListener('dragstart', (event: DragEvent) => {
      event.dataTransfer?.setData('text/plain', this.domElement.id);
    });
  }

  detach(): void {
    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.id) {
      WidgetDictionary.unregisterWidget(this.id);
    }
  }

  updateProperties(): void {
    if (this.currentProperties) {
      this.setProperties(this.currentProperties);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      const value = properties[key];

      if (key === 'id') {
        this.id = value;
        this.domElement.id = value;
        WidgetDictionary.registerWidget(value, this);
      } else if (key === 'x') {
        if (typeof value === 'number') {
          this.domElement.style.left = `${value}px`;
        }
      } else if (key === 'y') {
        if (typeof value === 'number') {
          this.domElement.style.top = `${value}px`;
        }
      } else {
        this.currentProperties[key] = value;
        this.applyProperty(key, value);
      }
    }
    this.adjustDivSize();
  }

  private applyProperty(key: string, value: any): void {
    switch (key) {
      case 'x':
        // Imposta la posizione X assoluta del div
        this.domElement.style.left = value + 'px';
        break;
      case 'y':
        // Imposta la posizione Y assoluta del div
        this.domElement.style.top = value + 'px';
        break;
      case 'fontSize':
        this.textElement.setAttribute('font-size', value.toString());
        this.adjustDivSize(); // Aggiorna la dimensione del div in base alla dimensione del testo
        break;
      case 'fill':
        this.textElement.setAttribute('fill', value.toString());
        break;
      case 'text':
        this.textElement.textContent = value;
        this.adjustDivSize();
        break;
      case 'rotate':
        this.applyRotation(value);
        break;
      default:
        break;
    }
  }

  private applyRotation(rotation: number): void {
    const bbox = this.domElement.getBoundingClientRect();
    const centerX = bbox.width / 2;
    const centerY = bbox.height / 2;

    // Applica la trasformazione di rotazione all'intero div, centrata sul centro del div
    this.domElement.style.transform = `rotate(${rotation}deg)`;
    this.domElement.style.transformOrigin = `${centerX}px ${centerY}px`;
  }

  private adjustDivSize(): void {
    requestAnimationFrame(() => {
      const bbox = this.textElement.getBBox(); // Ottieni le dimensioni del testo SVG
      this.domElement.style.width = `${bbox.width + 20}px`; // Aggiungi un margine per contenere il testo
      this.domElement.style.height = `${bbox.height + 20}px`; // Aggiungi un margine per contenere il testo
    });
  }

  sendEvent(propertyName: string, data?: any, isEvent?: boolean) {
    if (this.id) {
      EventsManager.sendEvent(this.id, propertyName, data, isEvent);
    }
  }
}

import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class Label extends SimpleWidget {
  constructor(public domElement: HTMLElement) {
    super(domElement);
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-label');
    super.attach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      switch (key) {
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }
}

import { Project } from '@api';
import { deepCopy, moveNodeInternalTree } from '@services/utils';
import { useCallback } from 'react';

export function getPathReference(
  objects: Project.ResourceTreeElement[],
  path: string[]
): Project.ResourceTreeElement | null {
  const [currentPath, ...remainingPath] = path;
  const object = objects.find(({ data }) => data.id === currentPath);
  if (remainingPath.length === 0 || !object) {
    return object;
  } else {
    return object.children ? getPathReference(object.children, remainingPath) : null;
  }
}

type Props = {
  documentData: Project.DocumentData;
  onDocumentDataChange: (document: Project.DocumentData) => void;
  documentId: string;
};

let idAlarmIndex = 0;

export const useAlarmDocumentDataUtils = ({ documentData: _documentData, onDocumentDataChange, documentId }: Props) => {
  const searchNewAlarmIndex = (resourceTree: Project.ResourceTreeElement[]) => {
    for (const element of resourceTree) {
      if (element.data.id.split('-')[0].substr(3) > idAlarmIndex) {
        idAlarmIndex = parseInt(element.data.id.split('-')[0].substr(3));
      }
      if (element.children) {
        searchNewAlarmIndex(element.children);
      }
    }
  };
  if (_documentData.resourceTree) {
    searchNewAlarmIndex(_documentData.resourceTree);
  }

  const createItem = useCallback(
    (parent: string, itemType: string) => {
      const documentData = deepCopy(_documentData);
      let id = '';
      let name = '';
      switch (itemType) {
        case 'alarm':
          id = `alarm${idAlarmIndex + 1}-${documentId}`;
          name = 'newAlarm';
          break;
        case 'structure':
          id = `structure${idAlarmIndex + 1}-${documentId}`;
          name = 'newStructure';
          break;
      }
      idAlarmIndex++;

      const newItem: Project.ResourceTreeElement = {
        name: name,
        data: { id },
        children: [
          {
            name: 'Events',
            data: { id: 'evt-' + idAlarmIndex + '-' + documentId },
            children: [
              {
                name: 'onAlarmOn',
                data: { id: 'evt-' + idAlarmIndex + '-' + documentId + '_OnAlarmOn' },
                pinType: Project.PinType.InOut,
              },
              {
                name: 'onAlarmOff',
                data: { id: 'evt-' + idAlarmIndex + '-' + documentId + '_OnAlarmOff' },
                pinType: Project.PinType.InOut,
              },
              {
                name: 'onAlarmAck',
                data: { id: 'evt-' + idAlarmIndex + '-' + documentId + '_OnAlarmAck' },
                pinType: Project.PinType.InOut,
              },
            ],
          },
        ],
      };

      if (itemType === 'structure') {
        newItem.children = [];
      } else {
        newItem.pinType = Project.PinType.InOut;
      }

      if (parent === 'root') {
        documentData.resourceTree?.push(newItem);
      } else {
        const parentRef = getPathReference(documentData.resourceTree, parent.split('.'));
        if (!parentRef.children) {
          parentRef.children = [];
        }
        parentRef.children.push(newItem);
      }
      onDocumentDataChange(documentData);
      return id;
    },
    [_documentData, documentId, onDocumentDataChange]
  );

  const removeItem = useCallback(
    (alarmIdToDelete: string) => {
      const documentData = deepCopy(_documentData);
      const alarmPath = alarmIdToDelete.split('.');
      const leafAlarmPath = alarmPath.pop();

      if (alarmPath.length < 1) {
        documentData.resourceTree = documentData.resourceTree.filter((item) => item.data.id !== alarmIdToDelete);
      } else {
        const parentRef = getPathReference(documentData.resourceTree, alarmPath);
        if (parentRef) {
          parentRef.children = parentRef.children.filter((item) => item.data.id !== leafAlarmPath);
        }
      }

      onDocumentDataChange(documentData);
    },
    [_documentData, onDocumentDataChange]
  );

  const renameItem = useCallback(
    (alarmIdToRename: string, newName: string) => {
      const documentData = deepCopy(_documentData);

      const alarmPath = alarmIdToRename.split('.');

      if (alarmPath.length < 1) {
        documentData.resourceTree = documentData.resourceTree.map((item: Project.ResourceTreeElement) => {
          if (item.data.id === alarmIdToRename) {
            return {
              ...item,
              name: newName,
            };
          } else {
            return item;
          }
        });
      } else {
        const itemRef = getPathReference(documentData.resourceTree, alarmPath);
        if (itemRef) {
          itemRef.name = newName;
        }
      }

      onDocumentDataChange(documentData);
    },
    [_documentData, onDocumentDataChange]
  );

  const moveItem = useCallback(
    (nodeId: string, destinationId: string) => {
      const newDocDataResources = moveNodeInternalTree(_documentData, nodeId, destinationId);

      const newDocData = { ..._documentData, resourceTree: newDocDataResources };
      onDocumentDataChange(newDocData);
    },
    [_documentData, onDocumentDataChange]
  );

  return {
    createItem,
    removeItem,
    renameItem,
    moveItem,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { FC, useCallback, useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Project } from '@api';

const fieldTypeOptions = [Project.TagType.Numeric, Project.TagType.String];

type Props = {
  selectedField: string;
  databaseItem: Project.DocumentData;
  onChange: (item: Project.DatabaseMetadata) => void;
  onDocumentDataChange: (item: Project.DocumentData) => void;
};

export const RecipeItemEditor: FC<Props> = ({ selectedField, databaseItem, onChange, onDocumentDataChange }) => {
  const [fieldType, setFieldType] = useState<string>('string');

  /**
   * Caricamento del tipo selezionato
   */
  useEffect(() => {
    const field = selectedField.split('.')[1];
    const currentField = databaseItem.resourceTree[1].children.find((f: any) => f.data?.id.split('_')[1] === field);

    if (currentField) {
      setFieldType(currentField.type || 'string');
    }
  }, [selectedField, databaseItem]);

  /**
   * Controllo dell'attributo key del campo
   */
  const controlRecipeKey = useCallback((): boolean => {
    const field = selectedField.split('.')[1];
    return databaseItem.metadata?.keys.includes(field);
  }, [selectedField, databaseItem]);

  /**
   * Aggiorna campo
   */
  const handleChange = useCallback(
    (_item: Project.DatabaseMetadata) => {
      onChange(_item);
    },
    [onChange]
  );

  /**
   * Modifica del campo chiave del campo
   */
  const handleKeyChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const _keys = [...databaseItem.metadata?.keys];
      const field = selectedField.split('.')[1];
      const index = _keys.indexOf(field);
      if (event.target.checked) {
        if (index < 0) {
          _keys.push(field);
        }
      } else {
        if (index >= 0) {
          _keys.splice(index, 1);
        }
      }
      const newItem: Project.DatabaseMetadata = {
        ...databaseItem.metadata,
        keys: _keys,
      };
      handleChange(newItem);
    },
    [selectedField, databaseItem, handleChange]
  );

  /**
   * Cambiamento tipo del campo
   */
  const handleTypeChange = useCallback(
    (event: DropdownChangeEvent) => {
      const newType = event.value;
      const field = selectedField.split('.')[1];

      //aggiornamento nel resourcetree
      const updatedResourceTree = [...databaseItem.resourceTree];
      const updatedChildren = updatedResourceTree[1].children.map((f: any) =>
        f.data?.id.split('_')[1] === field ? { ...f, type: newType } : f
      );

      updatedResourceTree[1] = {
        ...updatedResourceTree[1],
        children: updatedChildren,
      };

      const updatedDocumentData = {
        ...databaseItem,
        resourceTree: updatedResourceTree,
      };

      onDocumentDataChange(updatedDocumentData);
    },
    [selectedField, databaseItem, onDocumentDataChange]
  );

  return (
    <div className="card flex flex-column gap-3">
      <Fieldset legend="Field">
        <div className="p-inputgroup flex-1" style={{ marginBottom: '5px' }}>
          <span className="p-inputgroup-addon" style={{ minWidth: '73px' }}>
            Type{' '}
          </span>
          <Dropdown
            inputId="Type"
            value={fieldType}
            onChange={handleTypeChange}
            options={fieldTypeOptions}
            placeholder="Select a type"
          />
        </div>
        <div className="p-inputgroup">
          <span
            className="p-inputgroup-addon mr-4"
            style={{
              minWidth: '73px',
              borderRight: '1px solid #424b57',
              borderTopRightRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
          >
            Key
          </span>
          <Checkbox inputId="key" checked={controlRecipeKey()} onChange={(e) => handleKeyChange(e)} className="mt-2" />
        </div>
      </Fieldset>
    </div>
  );
};

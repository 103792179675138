/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventsManager } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/events-manager';
import {
  MediaStates,
  PreviewMediaEventManager,
  PropertiesManager,
} from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/properties-manager';
import { Project } from '@api';
import { PropertiesConfig } from '@config/widget/interface';
import { create } from 'zustand';
import { deepCopy, deepEqual } from '@services/utils';
import { immer } from 'zustand/middleware/immer';
import { useLayoutStore } from './layoutStore';

export interface WidgetPropertyHandler {
  setProperty(widgetId: string, sectionId: string, fieldKey: string, value: any): void;
  checkProperty(widgetId: string, fieldKey: string, value: any): string;
  updateResourceLinks(links: Project.ResourceLink[]): void;
  getPropertiesData(type: string, id: string): PropertiesConfig;
}

export interface DropData {
  type?: string;
  sourceId?: string;
}

export interface WidgetDragHandler {
  startDrag(dropData: DropData): void;
}

export interface EditingProperties {
  data: Record<string, any>;
  widgetName: string;
  parentData?: Record<string, any>;
  documentId: Project.ProjectNodeKey;
  resourceLinks?: Project.ResourceLink[];
}

export interface WidgetPropsState {
  _nameEditingDebounce: any;
  editingProperties: EditingProperties;
  widgetPropertyHandler: WidgetPropertyHandler;
  device: MediaStates;
  widgetDragHandler: WidgetDragHandler;
  setEditingProps: (
    value: any,
    parentData: any,
    widgetName: string,
    documentId: Project.ProjectNodeKey,
    resourceLinks: Project.ResourceLink[]
  ) => void;
  setEditingProp: (field: string, data: any, parentData: any) => void;
  stopEditingProps: () => void;
  setDevice: (device: MediaStates) => void;
  setWidgetDragHandler: (handler: WidgetDragHandler) => void;
  setWidgetPropertyHandler: (handler: WidgetPropertyHandler) => void;
}

const mediaHandler = new PreviewMediaEventManager();
PropertiesManager.setMediaEventManager(mediaHandler);
EventsManager.setOnEvent((event) => {
  if (event.propertyName === 'ItemClick') {
    useWidgetPropsStore.getState().setEditingProp('OpenedItem', event.data, null);
    useWidgetPropsStore.getState().widgetPropertyHandler.setProperty(event.widgetId, '', 'OpenedItem', event.data);
  } else if (event.propertyName === 'ImageIndex') {
    useWidgetPropsStore.getState().setEditingProp('Index', event.data, null);
    useWidgetPropsStore.getState().widgetPropertyHandler.setProperty(event.widgetId, '', 'Index', event.data);
  }
  console.log(JSON.stringify(event));
});

export const useWidgetPropsStore = create(
  immer<WidgetPropsState>((set) => ({
    _nameEditingDebounce: null,
    widgetDragHandler: null,
    editingProperties: {
      data: {},
      widgetName: null,
      parentData: null,
      documentId: null,
    }, // senza id non carica nessun componente
    modifiedProperty: null,
    device: MediaStates.Desktop,
    widgetPropertyHandler: null,
    setWidgetDragHandler: (handler: WidgetDragHandler) => {
      set((state) => {
        state.widgetDragHandler = handler;
      });
    },
    stopEditingProps: () =>
      set((state) => {
        state.editingProperties = {
          data: {},
          widgetName: null,
          parentData: null,
          documentId: null,
        };
      }),
    setEditingProps: (
      data: any,
      parentData: any,
      widgetName: string,
      documentId: Project.ProjectNodeKey,
      resourceLinks: Project.ResourceLink[]
    ) =>
      set((state) => {
        const source: EditingProperties = {
          data,
          widgetName,
          parentData: { ...parentData },
          documentId,
          resourceLinks,
        };
        if (!deepEqual(source, state.editingProperties)) {
          state.editingProperties = deepCopy<EditingProperties>(source);
        }
        useLayoutStore.getState().activateTab('WidgetEditor');
      }),

    setEditingProp: (fieldName: string, data: any, parentData: any) =>
      set((state) => {
        if (fieldName === 'Name') {
          state.editingProperties.widgetName = deepCopy(data);
        } else {
          state.editingProperties.data[fieldName] = deepCopy(data);
          state.editingProperties.parentData = { ...parentData };
        }
      }),

    setDevice: (device: MediaStates) => {
      set((state) => {
        state.device = device;
      });
      mediaHandler.setMedia(device);
    },

    setWidgetPropertyHandler: (handler: WidgetPropertyHandler) => {
      set((state) => {
        state.widgetPropertyHandler = handler;
      });
    },
  }))
);

import { WidgetDictionary } from './widget-dictionary';
import { WidgetProperties } from './widget';

export enum MediaStates {
  Desktop,
  Tablet,
  Phone,
}

export abstract class MediaEventManager {
  abstract getMedia(): MediaStates;

  onMediaChange?: () => void;
}

export class ScreenMediaEventManager extends MediaEventManager {
  phoneQuery = window.matchMedia('(max-width: 600px)');
  tabletQuery = window.matchMedia('(min-width: 601px) and (max-width: 900px)');
  desktopQuery = window.matchMedia('(min-width: 901px)');

  mediaState: MediaStates;

  getMedia(): MediaStates {
    return this.mediaState;
  }

  handlePhoneChangeBound = (e: MediaQueryListEvent) => this.handlePhoneChange(e);
  handleTabletChangeBound = (e: MediaQueryListEvent) => this.handleTabletChange(e);
  handleDesktopChangeBound = (e: MediaQueryListEvent) => this.handleDesktopChange(e);

  handlePhoneChange(e: MediaQueryListEvent): void {
    if (e.matches) {
      this.mediaState = MediaStates.Phone;
      this.onMediaChange?.();
    }
  }

  handleTabletChange(e: MediaQueryListEvent): void {
    if (e.matches) {
      this.mediaState = MediaStates.Tablet;
      this.onMediaChange?.();
    }
  }

  handleDesktopChange(e: MediaQueryListEvent): void {
    if (e.matches) {
      this.mediaState = MediaStates.Desktop;
      this.onMediaChange?.();
    }
  }

  constructor() {
    super();
    this.phoneQuery.addEventListener('change', this.handlePhoneChangeBound);
    this.tabletQuery.addEventListener('change', this.handleTabletChangeBound);
    this.desktopQuery.addEventListener('change', this.handleDesktopChangeBound);

    if (this.phoneQuery.matches) this.mediaState = MediaStates.Phone;
    else if (this.tabletQuery.matches) this.mediaState = MediaStates.Tablet;
    else this.mediaState = MediaStates.Desktop;
  }

  destroy() {
    this.phoneQuery.removeEventListener('change', this.handlePhoneChangeBound);
    this.tabletQuery.removeEventListener('change', this.handleTabletChangeBound);
    this.desktopQuery.removeEventListener('change', this.handleDesktopChangeBound);
  }
}

export class PreviewMediaEventManager extends MediaEventManager {
  mediaState: MediaStates = MediaStates.Desktop;

  getMedia(): MediaStates {
    return this.mediaState;
  }

  public setMedia(newMedia: MediaStates) {
    this.mediaState = newMedia;
    this.onMediaChange?.();
  }
}

export class PropertiesManager {
  private static mediaEventManager: MediaEventManager = null;

  static setMediaEventManager(mediaEventManager: MediaEventManager) {
    this.mediaEventManager = mediaEventManager;
    this.mediaEventManager.onMediaChange = () => {
      WidgetDictionary.updateAllWidgets();
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getData(properties: WidgetProperties, key: string): any {
    if (!Array.isArray(properties[key])) {
      return properties[key];
    } else {
      return properties[key][Math.min(this.mediaEventManager?.getMedia(), properties[key].length - 1)];
    }
  }
}

import { Project } from '@api';
import { create } from 'zustand';
import { deepCopy } from '@services/utils';
import { immer } from 'zustand/middleware/immer';
import { ipcClient } from '@stores/ipcStore';

export interface BinaryFileState {
  files?: Project.BinaryFolder;
  loadFiles: () => Promise<void>;
  addFile: (path: string, file: Project.BinaryFile) => void;
  deleteFile: (fileId: string) => void;
  getFile: (fileId: string) => Promise<Project.BinaryFile>;
  getMaxId: () => number;
}

export const useBinaryFileStore = create(
  immer<BinaryFileState>((set, get) => ({
    files: undefined,

    loadFiles: async () => {
      const files = await ipcClient.getBinaryFiles();
      set((state) => {
        state.files = deepCopy(files);
      });
    },

    addFile: async (path: string, file: Project.BinaryFile) => {
      const result = await ipcClient.addBinaryFile(path, file);

      if (result) {
        const { loadFiles } = get();
        return loadFiles();
      }
    },

    deleteFile: async (fileId: string) => {
      const result = await ipcClient.removeBinaryFile(fileId);

      if (result) {
        const { loadFiles } = get();
        return loadFiles();
      }
    },

    getFile: async (fileId: string) => {
      return await ipcClient.getSelectedFile(fileId);
    },

    getMaxId: () => {
      return ipcClient.getMaxFileId();
    },
  }))
);

import './NumericInput.css';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { useRef } from 'react';

export interface EditProps extends PropertyEditorComponentProps {
  minValue?: number;
  maxValue?: number;
  step?: number;
}

export const NumericInput = ({ data, minValue, maxValue, step, onDataChange }: EditProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const setSpinVisible = (status: string) => {
    const list = divRef.current.querySelectorAll('.p-inputnumber-button-group .p-inputnumber-button');
    list.forEach((ele) => ((ele as HTMLElement).style.visibility = status));
  };

  return (
    <div
      className={'numeric-input'}
      ref={divRef}
      onMouseEnter={() => setSpinVisible('visible')}
      onMouseLeave={() => setSpinVisible('hidden')}
      onClick={() => onDataChange(data)}
    >
      <InputNumber
        value={data}
        max={maxValue}
        min={minValue}
        step={step ?? 1}
        showButtons
        useGrouping={false}
        onChange={(e: InputNumberChangeEvent) => {
          onDataChange(e.value);
        }}
      />
    </div>
  );
};

export default NumericInput;

/* eslint-disable @typescript-eslint/no-explicit-any */
import './Project.css';
import 'primeicons/primeicons.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { LeafProps, NodeProps, Treeview, TreeviewProps } from '@components/common/treeview';
import { Project } from '@api';
import { Toast } from 'primereact/toast';
import { documentEditorsConfig } from '@config/documentEditor';
import { ipcClient } from '@stores/ipcStore';
import { useEffect, useRef } from 'react';
import { useProjectStore } from '@stores/projectStore';
import { useState } from 'react';
import useDocumentStore from '@stores/documentStore';

export function ProjectManager() {
  const { openDocument, selectingDoc, closeOpenedDocument, openedDocuments } = useDocumentStore();
  const openedProjects = useProjectStore((state) => state.openedProjects);
  const [tree, setTree] = useState<{ [key: string]: Project.Project }>(openedProjects);
  const [rename, setRename] = useState<string>();
  const toast = useRef<any>();

  function isDocument(node: Project.ProjectNode): node is Project.Document {
    return (node as Project.Document).type !== undefined;
  }

  function isFolder(node: Project.ProjectNode): node is Project.Folder {
    return (node as Project.Folder).children !== undefined;
  }

  function getProject(projectId: string): Project.Project {
    if (openedProjects[projectId]) {
      return openedProjects[projectId];
    }
    return null;
  }

  function convertProjectNodeToTreeNode(
    projectNode: Project.Document | Project.Folder,
    key: string
  ): NodeProps | LeafProps {
    if ('children' in projectNode) {
      // Tratta come Folder
      return {
        title: projectNode.name,
        id: JSON.stringify(projectNode.nodeId),
        found: false,
        hidden: false,
        icon: 'pi pi-folder',
        open: true,
        autoedit: projectNode.nodeId.domainId === rename,
        editable: false,
        parentFound: false,
        actions: [
          { icon: 'pi pi-plus', code: getNewDocumentCode(key) },
          { icon: 'pi pi-folder', code: 'newFolder' },
          { icon: 'pi pi-pencil', code: 'edit' },
          { icon: 'pi pi-times', code: 'deleteFolder' },
        ],

        children: projectNode.children
          .filter((child) => child)
          .map((child) => convertProjectNodeToTreeNode(child, key)) as Array<LeafProps | NodeProps>,
      };
    } else {
      // Tratta come Document
      return {
        id: JSON.stringify(projectNode.nodeId),
        title: projectNode.name,
        found: false,
        selected: false,
        childFound: false,
        autoedit: projectNode.nodeId.domainId === rename,
        editable: false,
        hidden: false,
        icon: 'pi pi-book',
        actions: [
          { icon: 'pi pi-pencil', code: 'edit' },
          // { icon: 'pi pi-plus-circle', code: 'new' },
          { icon: 'pi pi-times', code: 'deleteDocument' },
        ],
      };
    }
  }

  function getChildrenForTreeNodeProject(
    singleStructure: Project.ProjectNode[],
    key: string
  ): (NodeProps | LeafProps)[] {
    const newList: (NodeProps | LeafProps)[] = singleStructure
      .filter((child) => child)
      .map((child) => convertProjectNodeToTreeNode(child, key));

    return newList;
  }

  function getNewDocumentCode(key: string): string {
    let newAction = '';
    documentEditorsConfig.forEach((config) => {
      if (newAction === '') {
        if (config.section === key) {
          newAction = 'newDocument_' + config.type;
        }
      }
    });

    return newAction;
  }

  function createChildrenFofTreeNodeProject(singleProject: Project.Project): (NodeProps | LeafProps)[] {
    const newList: (NodeProps | LeafProps)[] = [];

    if (singleProject && singleProject.structure) {
      for (const key of Object.keys(singleProject.structure)) {
        const newItem = {
          title: key.toUpperCase(),
          id: JSON.stringify({ projectId: singleProject.id, domainId: key }),
          found: false,
          hidden: false,
          editable: false,
          icon: 'pi pi-sitemap',
          open: true,
          forceOpen: true,
          actions: [
            { icon: 'pi pi-plus', code: getNewDocumentCode(key) },
            { icon: 'pi pi-folder', code: 'newFolder' },
          ],
          parentFound: false,
          children: getChildrenForTreeNodeProject(singleProject.structure[key], key),
        };

        newList.push(newItem);
      }
    }

    return newList;
  }

  function createTreeNodeProject(treeElement: (NodeProps | LeafProps)[], singleProject: Project.Project) {
    const newElement: NodeProps | LeafProps = {
      title: singleProject.description,
      id: singleProject.id,
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      icon: undefined,
      children: createChildrenFofTreeNodeProject(singleProject),
    };

    treeElement.push(newElement);
  }

  function closeFolderOpenedDocuments(nodeKey: Project.ProjectNodeKey) {
    const closeDocumentsInFolder = async (folder: Project.Folder) => {
      for (const child of folder.children) {
        if (isDocument(child)) {
          if (openedDocuments.includes(JSON.stringify(child.nodeId))) {
            await closeOpenedDocument(JSON.stringify(child.nodeId));
          }
        } else if (isFolder(child)) {
          await closeDocumentsInFolder(child);
        }
      }
    };

    const findAndClose = async (node: Project.ProjectNode) => {
      if (isFolder(node)) {
        if (node.nodeId.domainId === nodeKey.domainId) {
          await closeDocumentsInFolder(node);
        } else {
          for (const child of node.children) {
            await findAndClose(child);
          }
        }
      }
    };

    const project: Project.Project = getProject(nodeKey.projectId);
    if (project && project.structure) {
      for (const key of Object.keys(project.structure)) {
        project.structure[key].forEach(findAndClose);
      }
    }
  }

  //*
  useEffect(() => {
    if (tree) {
      const treeviewObj: NodeProps[] = [
        {
          title: 'Projects',
          id: 0,
          open: true,
          parentFound: false,
          found: false,
          hidden: false,
          editable: false,
          icon: undefined,
          children: [],
        },
      ];

      Object.entries(openedProjects).forEach(([key, element]) => {
        createTreeNodeProject(treeviewObj[0].children, element);
      });

      //setTreeJSON(treeviewObj);
      setState(JSON.stringify(treeviewObj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, rename]);

  useEffect(() => {
    setTree(openedProjects);
  }, [openedProjects]);

  useEffect(() => {
    setSelectedItems([selectingDoc as any]);
  }, [selectingDoc]);

  const treeviewObj: NodeProps[] = [];

  // Conversione e stampa della struttura dell'albero
  const [treeviewJson, setState] = useState(JSON.stringify(treeviewObj));
  const [selectedItems, setSelectedItems] = useState(Array<string>());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showSpinner, setshowSpinner] = useState(false);

  async function notifyAction(action: string, data: string) {
    const dt = data ? JSON.parse(data) : {};
    let newDocAction = action;
    let actionArray: string[] = [];
    if (action.startsWith('new')) {
      if (action === 'newFolder') {
        newDocAction = action;
      } else if (action.startsWith('newDocument_')) {
        actionArray = action.split('_');
        newDocAction = 'newDocument';
      }
    }
    switch (newDocAction) {
      case 'select':
        if (dt.hasOwnProperty('id')) {
          //openDocument(dt.id)
          // await ipcCLient.openDocument(dt.id);
          setSelectedItems([dt.id]);
        }
        break;
      case 'leaf-double-click':
        if (dt.hasOwnProperty('id')) {
          openDocument(dt.id);
        }
        break;
      case 'newFolder':
      case 'newDocument':
        if (dt.hasOwnProperty('id')) {
          try {
            const nodeKey: Project.ProjectNodeKey = JSON.parse(dt.id);
            if (nodeKey) {
              const project = getProject(nodeKey.projectId);
              if (project) {
                let resp = '';

                switch (newDocAction) {
                  case 'newFolder':
                    resp = await ipcClient.createFolder(project.id, 'Folder', nodeKey.domainId);
                    break;

                  default:
                    if (actionArray.length === 2) {
                      resp = await ipcClient.createDocument(
                        project.id,
                        actionArray[1],
                        actionArray[1].charAt(0).toUpperCase() + actionArray[1].slice(1).toLowerCase(),
                        nodeKey.domainId
                      );
                    }
                    break;
                }

                setRename(resp);
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Creata con successo',
                });
              }
            }
          } catch (ex: any) {
            toast.current.show({
              severity: 'error',
              summary: 'error',
              detail: ex.message,
            });
            console.log('due', ex);
          }
        }
        break;
      case 'deleteFolder':
      case 'deleteDocument':
        if (dt.hasOwnProperty('id')) {
          try {
            const nodeKey: Project.ProjectNodeKey = JSON.parse(dt.id);
            if (action === 'deleteFolder') {
              // search if the documents in the folder are opened. if so, close them
              closeFolderOpenedDocuments(nodeKey);
              await ipcClient.removeFolder(nodeKey);
            } else {
              await closeOpenedDocument(dt.id);
              await ipcClient.removeDocument(nodeKey);
            }
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Rimossa con successo',
            });
          } catch (ex: any) {
            toast.current.show({
              severity: 'error',
              summary: 'error',
              detail: ex.message,
            });
            console.log('due', ex);
          }
        }
        break;
      case 'edit':
        if (dt.hasOwnProperty('id') && dt.hasOwnProperty('newTitle')) {
          const nodeKey: Project.ProjectNodeKey = JSON.parse(dt.id);
          await ipcClient.renameNode(nodeKey, dt.newTitle);
        }
        break;
      case 'drag-&-drop':
        if (dt.hasOwnProperty('startId') && dt.hasOwnProperty('endId')) {
          try {
            const startId: Project.ProjectNodeKey = JSON.parse(dt.startId);
            const endId: Project.ProjectNodeKey = JSON.parse(dt.endId);
            await ipcClient.moveNode(startId, endId, 0);
          } catch (ex: any) {
            // nothing to do
          }
        }
        break;
    }
  }

  const pr: TreeviewProps = {
    json: treeviewJson,
    selectedItems: selectedItems,
    notifyAction: notifyAction,
    utility: { spinner: showSpinner },
  };

  return (
    <>
      {tree && Object.keys(tree).length > 0 ? (
        <Treeview {...pr} />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '20px',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          Caricare un progetto
        </div>
      )}
      <ConfirmDialog />
      <Toast ref={toast} />
    </>
  );
}

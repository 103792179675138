import { Button } from 'primereact/button';
import { Dimensions, NodeProps, NodeResizer, Position, XYPosition, useUpdateNodeInternals } from '@xyflow/react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { WidgetProperties } from '@config/widget/widgetData';
import BaseHandle from './CustomHandle';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactImages from '@components/common/images/ReactImages';

export interface CustomNodeProps extends NodeProps {
  data: {
    position: XYPosition;
    dimensions: Dimensions;
    label: string;
    isSelected: boolean;
    inputs: string[];
    outputs: string[];
    inCaption?: string;
    outCaption?: string;
    inputProperties?: WidgetProperties;
    outputProperties?: WidgetProperties;
    onNodeDelete: (id: string) => void;
    onSelectNode: (id: string) => void;
    onLinkHandle: (e: React.SyntheticEvent, index: string, nodeId: string) => void;
    onHandlesCreated: (nodeId: string, type: string, caption: string, dynamicProperties: WidgetProperties) => void;
    onGetNodeHandles: (nodeId: string, handleKey: string, defaultHandles: string[]) => string[];
    onIsDynamicHandle: (nodeId: string, handleKey: string, index: number) => boolean;
    onHandleDelete: (nodeId: string, handleKey: string, index: number) => void;
  };
}


const CustomNode: React.FC<CustomNodeProps> = React.memo(
  ({ data, id }) => {
    const [showButtons, setShowButtons] = useState(false);
    const [showAllButtons, setShowAllButtons] = useState(false);
    const [divHeight, setDivHeight] = useState(0);
    const [inHandles, setInHandles] = useState(data.inputs);
    const [outHandles, setOutHandles] = useState(data.outputs);

    const op = useRef<OverlayPanel>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const updateNodeInternals = useUpdateNodeInternals();

    const newInHandles = useMemo(() => data.onGetNodeHandles(id, 'input', data.inputs), [id, data]);
    const newOutHandles = useMemo(() => data.onGetNodeHandles(id, 'output', data.outputs), [id, data]);

    useEffect(() => {
      if (JSON.stringify(newInHandles) !== JSON.stringify(inHandles)) {
        setInHandles(newInHandles);
      }

      if (JSON.stringify(newOutHandles) !== JSON.stringify(outHandles)) {
        setOutHandles(newOutHandles);
      }
    }, [newInHandles, newOutHandles, inHandles, outHandles]);

    useLayoutEffect(() => {
      if (divRef.current) {
        setDivHeight(divRef.current.clientHeight);
      }
      updateNodeInternals(id);
    }, [inHandles, outHandles, id, data, updateNodeInternals]);

    const handleSelection = useCallback(() => {
      data.onSelectNode(id);
    }, [data, id]);

    const handleAddHandle = useCallback(
      (type: string, caption: string, dynamicProperties: WidgetProperties) => {
        data.onHandlesCreated(id, type, caption, dynamicProperties);
      },
      [data, id]
    );

    return (
      <div
        ref={divRef}
        id={'div_' + id}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        style={{
          padding: '5px',
          border: data.isSelected ? '2px solid purple' : showButtons ? '1px solid red' : '1px solid #ddd',
          background: '#f9f7f4',
          borderRadius: '3px',
          width: data.dimensions.width + 'px',
          height: data.dimensions.height + 'px',
        }}
      >
        <div style={{ color: 'black', position: 'relative', fontWeight: 'bold', textAlign: 'center' }}>
          {data.label}
        </div>

        {showButtons && (
          <>
            <div
              style={{
                position: 'absolute',
                top: '1px',
                left: '1px',
                display: 'flex',
                border: '1px solid black',
              }}
              onMouseEnter={() => setShowAllButtons(true)}
              onMouseLeave={() => setShowAllButtons(false)}
            >
              <button
                className="fa-solid fa-pencil"
                style={{
                  background: '#D4AF37',
                  cursor: 'pointer',
                  color: showAllButtons ? 'red' : 'black',
                  border: 'none',
                }}
                onClick={handleSelection}
              />
              {showAllButtons && (
                <button
                  className="fa-solid fa-trash"
                  onClick={(e) => op.current.show(e, e.target)}
                  style={{
                    background: '#D4AF37',
                    cursor: 'pointer',
                    color: 'black',
                    border: 'none',
                  }}
                />
              )}
            </div>

            {data.inCaption && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '1px',
                  left: '1px',
                  display: 'flex',
                  border: '1px solid black',
                }}
              >
                <button
                  className="fa-solid"
                  onClick={() => handleAddHandle('input', data.inCaption || 'input', data.inputProperties)}
                  style={{
                    background: '#D4AF37',
                    cursor: 'pointer',
                    color: 'black',
                    border: 'none',
                  }}
                >
                  {ReactImages.getReactImage('input')}
                </button>
              </div>
            )}

            {data.outCaption && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '1px',
                  right: '1px',
                  display: 'flex',
                  border: '1px solid black',
                }}
              >
                <button
                  className="fa-solid"
                  onClick={() => handleAddHandle('output', data.outCaption || 'output', data.outputProperties)}
                  style={{
                    background: '#D4AF37',
                    cursor: 'pointer',
                    color: 'black',
                    border: 'none',
                  }}
                >
                  {ReactImages.getReactImage('output')}
                </button>
              </div>
            )}
          </>
        )}

        {inHandles.length > 0 &&
          inHandles.map((_, index) => (
            <BaseHandle
              key={`in-${index}`}
              id={id}
              data={{
                index,
                handleType: 'input',
                divHeight,
                inHandles,
                outHandles,
                onLinkHandle: data.onLinkHandle,
                onIsDynamicHandle: data.onIsDynamicHandle,
                onHandleDelete: data.onHandleDelete,
              }}
              type={'target'}
              position={Position.Left}
            />
          ))}

        {outHandles.length > 0 &&
          outHandles.map((_, index) => (
            <BaseHandle
              key={`out-${index}`}
              id={id}
              data={{
                index,
                handleType: 'output',
                divHeight,
                inHandles,
                outHandles,
                onLinkHandle: data.onLinkHandle,
                onIsDynamicHandle: data.onIsDynamicHandle,
                onHandleDelete: data.onHandleDelete,
              }}
              type={'source'}
              position={Position.Right}
            />
          ))}

        <OverlayPanel ref={op}>
          <div className="flex flex-column gap-2">
            <div className="flex flex-row gap-2">
              <span className="text-2xl pi pi-exclamation-circle" />
              <span className="font-bold">Sei sicuro di voler procedere?</span>
            </div>
            <div className="flex flex-row-reverse gap-2">
              <Button
                label="No"
                onClick={() => {
                  op.current.hide();
                  setShowAllButtons(true);
                }}
              />
              <Button
                label="Si"
                onClick={() => {
                  op.current.hide();
                  data.onNodeDelete(id);
                }}
              />
            </div>
          </div>
        </OverlayPanel>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.data === nextProps.data &&
      prevProps.data.inputs === nextProps.data.inputs &&
      prevProps.data.outputs === nextProps.data.outputs &&
      prevProps.data.inCaption === nextProps.data.inCaption &&
      prevProps.data.outCaption === nextProps.data.outCaption
    );
  }
);

export default CustomNode;

import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { Treeview } from '@common/treeview';
import { useRef, useState } from 'react';
import React, { FC } from 'react';

// Funzione per creare una struttura ad albero per gli SVG caricati localmente
function createLocalTreeView(svgFiles: { name: string; content: string }[]) {
  return [
    {
      title: 'Local SVGs',
      id: 'local-svgs',
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [{ icon: 'pi pi-plus', code: 'selectFolder' }], // Azione per il pulsante "+", per selezionare una cartella
      children: svgFiles.map((file, index) => ({
        title: file.name,
        id: `local-svg-${index}`,
        icon: 'pi pi-file',
        open: true,
        parentFound: false,
        found: false,
        hidden: false,
        editable: false,
        actions: [{ icon: 'pi pi-times', code: 'deleteFile' }],
      })),
    },
  ];
}

export const SvgPropertyEditor: FC<PropertyEditorComponentProps> = ({ data, onDataChange }) => {
  const overlayPanel = useRef<OverlayPanel>(null);
  const fileUpload = useRef<FileUpload>(null);

  // Stato per i file SVG caricati localmente
  const [localSvgFiles, setLocalSvgFiles] = useState<{ name: string; content: string }[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>();

  // Funzione per mostrare/nascondere il pannello Overlay
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    overlayPanel.current.toggle(event);
  };

  // Funzione per gestire le azioni come selezione file o cancellazione
  const notifyAction = (action: string, data: string, event: any) => {
    const dataObj = JSON.parse(data);
    switch (action) {
      case 'selectFolder': // Aggiungi una nuova logica per la selezione della cartella
        setSelectedFolder(dataObj.id);
        break;

      case 'deleteFile': // Cancella il file SVG selezionato
        setLocalSvgFiles((prevFiles) => prevFiles.filter((_, idx) => `local-svg-${idx}` !== dataObj.id));
        break;

      case 'select': // Seleziona il file SVG da visualizzare
        const selectedFile = localSvgFiles.find((_, idx) => `local-svg-${idx}` === dataObj.id);
        if (selectedFile) {
          overlayPanel.current.hide();
          // Aggiorna il contenuto dell'SVG nel widget
          onDataChange({ svgContent: selectedFile.content });
        }
        break;
    }
  };

  // Funzione per gestire l'upload di un file SVG e inserirlo direttamente nel widget
  const handleUpload = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];

    if (file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        setLocalSvgFiles((prevFiles) => [...prevFiles, { name: file.name, content }]); // Aggiunge il file SVG alla lista locale
        onDataChange({ svgContent: content }); // Inserisce subito l'SVG nel widget
      };
      reader.readAsText(file); // Legge il contenuto del file SVG
    }

    fileUpload?.current.clear(); // Pulisce l'area di upload
  };

  return (
    <>
      <Button
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Bookmark"
        outlined
        onClick={handleClick}
      />

      <OverlayPanel ref={overlayPanel} closeOnEscape dismissable>
        {localSvgFiles && (
          <Treeview
            json={JSON.stringify(createLocalTreeView(localSvgFiles))} // Crea un albero dei file SVG caricati localmente
            selectedItems={[selectedFolder]} // Cartella o file selezionato
            notifyAction={notifyAction} // Gestisce le azioni come selezione e cancellazione file
          />
        )}

        {selectedFolder && (
          <Card title={`Upload an SVG`}>
            <FileUpload
              ref={fileUpload}
              accept="image/svg+xml" // Accetta solo file SVG
              maxFileSize={1_000_000} // Limite dimensione file 1MB
              emptyTemplate={<p className="m-0">Drag and drop SVG files here to upload.</p>} // Messaggio per area drag & drop
              customUpload
              uploadHandler={handleUpload} // Gestisce l'upload del file SVG
              onClear={() => setSelectedFolder(undefined)} // Reset della selezione cartella
            />
          </Card>
        )}
      </OverlayPanel>
    </>
  );
};

export default SvgPropertyEditor;

import { EventsManager } from '../events-manager';
import { Widget, WidgetProperties } from '../widget';
import { WidgetDictionary } from '../widget-dictionary';

export class SvgRect implements Widget {
  private id: string | null = null;
  private currentProperties: WidgetProperties = {};
  public domElement: HTMLDivElement;
  private rectElement: SVGRectElement;
  private svgElement: SVGElement;

  constructor() {
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-svg-rect-container');
    this.domElement.classList.add('svg-rect');
    this.domElement.setAttribute('data-resizable', 'true');
    this.domElement.style.position = 'absolute';
    this.domElement.style.minWidth = '50px';
    this.domElement.style.minHeight = '50px';

    this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.svgElement.setAttribute('width', '100%');
    this.svgElement.setAttribute('height', '100%');

    this.rectElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    this.rectElement.setAttribute('x', '0');
    this.rectElement.setAttribute('y', '0');
    this.rectElement.setAttribute('width', '100');
    this.rectElement.setAttribute('height', '100');
    this.rectElement.setAttribute('fill', 'blue');
    this.rectElement.setAttribute('stroke', 'black');
    this.rectElement.setAttribute('stroke-width', '2');

    this.svgElement.appendChild(this.rectElement);
    this.domElement.appendChild(this.svgElement);
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }
    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });

    this.domElement.setAttribute('draggable', 'true');

    this.domElement.addEventListener('dragstart', (event: DragEvent) => {
      event.dataTransfer?.setData('text/plain', this.domElement.id);
    });
  }

  detach(): void {
    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.id) {
      WidgetDictionary.unregisterWidget(this.id);
    }
  }

  updateProperties(): void {
    if (this.currentProperties) {
      this.setProperties(this.currentProperties);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      const value = properties[key];

      if (key === 'id') {
        this.id = value;
        this.domElement.id = value;
        WidgetDictionary.registerWidget(value, this);
      } else if (key === 'x') {
        if (typeof value === 'number') {
          this.domElement.style.left = `${value}px`;
        }
      } else if (key === 'y') {
        if (typeof value === 'number') {
          this.domElement.style.top = `${value}px`;
        }
      } else {
        this.currentProperties[key] = value;
        this.applyProperty(key, value);
      }
    }
    this.adjustDivSize();
  }

  private applyProperty(key: string, value: any): void {
    switch (key) {
      case 'x':
        this.domElement.style.left = value + 'px';
        break;
      case 'y':
        this.domElement.style.top = value + 'px';
        break;
      case 'width':
        this.rectElement.setAttribute('width', value.toString());
        this.adjustDivSize();
        break;
      case 'height':
        this.rectElement.setAttribute('height', value.toString());
        this.adjustDivSize();
        break;
      case 'fill':
        this.rectElement.setAttribute('fill', value.toString());
        break;
      case 'stroke':
        this.rectElement.setAttribute('stroke', value.toString());
        break;
      case 'strokeWidth':
        this.rectElement.setAttribute('stroke-width', value.toString());
        break;
      default:
        console.warn(`Proprietà ${key} non gestita.`);
    }
  }

  private adjustDivSize(): void {
    requestAnimationFrame(() => {
      const bbox = this.rectElement.getBBox(); // Ottieni le dimensioni del rettangolo SVG
      this.domElement.style.width = `${bbox.width}px`;
      this.domElement.style.height = `${bbox.height}px`;
    });
  }

  sendEvent(propertyName: string, data?: any, isEvent?: boolean) {
    if (this.id) {
      EventsManager.sendEvent(this.id, propertyName, data, isEvent);
    }
  }
}

import { BaseWidget } from './base-widget';
import { EventsManager } from './events-manager';
import { Widget, WidgetProperties } from './widget';
import { WidgetFactory } from './widget-factory';

export class RootWidget extends BaseWidget {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  attach(): void {}
  private currentPage?: Widget;

  constructor(public domElement: HTMLElement) {
    super();
  }

  setProperties(properties: WidgetProperties) {
    super.setProperties(properties);

    if ('active-page' in properties && typeof properties['active-page'] === 'object') {
      this.domElement.innerHTML = '';
      this.currentPage?.detach();
      const pageProps = properties['active-page'];

      EventsManager.setPageId(pageProps['id']);

      const widget = WidgetFactory.buildWidget(pageProps['type']);
      widget.setProperties(pageProps);
      widget.attach();
      if (widget.domElement) this.domElement.appendChild(widget.domElement);
      this.currentPage = widget;
    }
  }
}

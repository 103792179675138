/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './Treeview.css';
import 'primeicons/primeicons.css';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { LeafProps, NodeProps, TreeviewProps, isLeafProps } from './Interfaces';
import { Menubar } from 'primereact/menubar';
import { Node } from './Node';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from 'react';

export function Treeview(props: TreeviewProps) {
  //const searchData = useRef(buildSearchData([cloneObject(inSt[0])]));
  const searchEnabled = useRef(false);
  const isFirstRender = useRef(true);
  const openNodes = useRef([]);
  const initState = getStateFromJSON(props.json, props.selectedItems);
  const [state, setState] = useState(initState);

  const [searchState, setSearchState] = useState({ searchEnabled: false, found: 0, parentFound: 0, childFound: 0 });
  const initialState = useRef(cloneObject(state));
  const [showTreeview, setShowTreeview] = useState(true);
  const searchData = useRef(buildSearchData([cloneObject(state)]));
  const [prevState, setPrevState] = useState(cloneObject(state));
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [editItemData, setEditData] = useState({ id: '', title: '', icon: '' });
  const closeEditTextFc = useRef<(() => void) | null>(null);
  const openEditTextId = useRef(null);
  const dragStartItem = useRef<{ id: any; type: string } | null>(null);
  const dragEndItem = useRef(null);
  const newElementCreated = useRef(false);
  const newElementId = useRef(null);

  const contentRef = useRef(null);
  const menubarRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current && menubarRef.current) {
        // Trova il .flexlayout__tab o .project-treeview più vicino
        let parentElement = contentRef.current.closest('.flexlayout__tab');
        if (!parentElement) {
          parentElement = contentRef.current.closest('.project-treeview');
        }

        const menubarHeight = menubarRef.current.offsetHeight;
        let outerParentPadding = 0;
        let tabviewHeight = 0;

        // Search the .p-tabview into the .flexlayout__tab
        // This is to search other element's height to calculate the max-height to set
        const tabviewElement = parentElement ? parentElement.querySelector('.p-tabview') : null;
        if (tabviewElement !== null) {
          tabviewHeight = tabviewElement.offsetHeight;
          const projectTreeView = contentRef.current.closest('.project-treeview');
          if (projectTreeView) {
            // Search the parent for check padding
            const outerParent = projectTreeView.parentElement;
            if (outerParent) {
              const outerParentPaddingTop = parseFloat(getComputedStyle(outerParent).paddingTop);
              const outerParentPaddingBottom = parseFloat(getComputedStyle(outerParent).paddingBottom);
              outerParentPadding = outerParentPaddingTop + outerParentPaddingBottom;
            }
          }
        }

        if (parentElement) {
          contentRef.current.style.maxHeight = `${
            parentElement.offsetHeight - menubarHeight - tabviewHeight - outerParentPadding
          }px`;
        }
      }
    };

    // Trova il .flexlayout__tab o .project-treeview più vicino
    let parentElement = contentRef.current ? contentRef.current.closest('.flexlayout__tab') : null;
    if (!parentElement) {
      parentElement = contentRef.current ? contentRef.current.closest('.project-treeview') : null;
    }

    /*  if (parentElement) {
      // Crea un ResizeObserver per monitorare i cambiamenti di dimensione
      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      // Osserva il .flexlayout__tab o .project-treeview per cambiamenti di dimensione
      resizeObserver.observe(parentElement);

      // Pulisce l'osservatore al dismontaggio del componente
      return () => {
        resizeObserver.disconnect();
      };
    } */

    // Aggiorna l'altezza inizialmente
    updateHeight();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const newState = getStateFromJSON(props.json, props.selectedItems);
    setState(newState);

    //ricostruiamo i dati utili per la ricerca
    searchData.current = buildSearchData([cloneObject(newState)]);
    initialState.current = newState;
    //ripristiniamo lo stato di ricerca se era attivato
    if (searchEnabled) {
      const searchText = document.getElementById('searchText') as HTMLInputElement;
      triggerSearch(searchText.value);
    }

    // open the tree and show the selected item
    props.selectedItems.forEach((selectedId) => {
      if (selectedId && !isProjectNodeKey(selectedId)) {
        const result = findAndOpenNode(initialState.current, selectedId);
        if (result) {
          // scroll to the selected item
          result.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    });
  }, [props.json, props.selectedItems]);
  useEffect(() => {
    if (props.utility != null) setShowModal(props.utility.spinner);
  }, [props.utility?.spinner]);

  function getStateFromJSON(json: string, selectedItems: string[]): NodeProps {
    function initializeState(state: NodeProps) {
      state.found = false;
      state.hidden = false;
      state.parentFound = false;
      if (!state.open)
        //se non forziamo l'apertura del nodo nel json, verifichiamo se il nodo era già aperto in precedenza
        state.open = openNodes.current.includes(state.id);
      state.actions = state.actions ?? [];
      state.editable = state.editable ?? true; //viene impostata a true se non è nel json
      if (state.editable) {
        state.actions.unshift({ code: 'edit', icon: 'pi pi-pencil' });
      }

      state.children?.forEach((el) => {
        if (isLeafProps(el)) {
          el.found = false;
          el.hidden = false;
          (el as LeafProps).selected = selectedItems.includes(el.id);
          (el as LeafProps).childFound = false;
          el.actions = el.actions ?? [];
          el.editable = el.editable ?? true;
          if (el.editable) {
            el.actions?.unshift({ code: 'edit', icon: 'pi pi-pencil' });
          }
        } else initializeState(el as NodeProps);
      });
    }

    try {
      const np: NodeProps[] = JSON.parse(json);
      if (Array.isArray(np)) {
        const state = cloneObject(np[0]);
        initializeState(state);
        state.open = true; //il nodo root deve essere sempre aperto
        if (state.children.length > 0) (state.children[0] as NodeProps).open = true;
        openNodes.current.push(state.id);
        return state;
      } else
        return {
          open: false,
          hidden: true,
          autoedit: false,
          editable: true,
          found: false,
          children: [],
          id: null,
          icon: null,
          title: '',
          parentFound: false,
        };
    } catch (e) {
      return {
        open: false,
        hidden: true,
        autoedit: false,
        editable: true,
        found: false,
        children: [],
        id: null,
        icon: null,
        title: '',
        parentFound: false,
      };
    }
  }

  function isProjectNodeKey(str: string) {
    // check if the id is a ProjectNodeKey id or not
    try {
      const obj = JSON.parse(str);
      return obj && typeof obj === 'object' && 'projectId' in obj && 'domainId' in obj;
    } catch (e) {
      return false;
    }
  }

  function openNode(nodeId: string) {
    const nodeElements = document.querySelectorAll(`[id="${nodeId}"]`);
    nodeElements.forEach((nodeElement) => {
      const spanElement = nodeElement.querySelector('span.caret'); // seleziona il span con la classe 'caret'
      const nestedElement = nodeElement.querySelector('.nested');

      if (spanElement && nestedElement) {
        spanElement.classList.add('caret-down'); // aggiunge la classe per indicare che è aperto
        nestedElement.classList.add('active'); // mostra i figli
        setOpenNodes(nodeId, true); // comunica al parent lo stato aperto
      }
    });
  }

  function findAndOpenNode(node: NodeProps | LeafProps, id: string, parents: NodeProps[] = []): HTMLElement | null {
    if (node.id === id) {
      parents.forEach((parent) => {
        if (!parent.open) {
          parent.open = true;
          openNode(parent.id);
        }
      });
      // dom element for the scroll
      const nodeElement = document.querySelector(`[id='${id}']`);
      return nodeElement as HTMLElement;
    }

    if (!isLeafProps(node) && (node as NodeProps).children) {
      for (const child of (node as NodeProps).children) {
        const result = findAndOpenNode(child, id, [...parents, node as NodeProps]);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  function setOpenNodes(id: string, add: boolean) {
    const index = openNodes.current.indexOf(id);
    if (add && index === -1)
      //aggiungiamo un nodo non presente
      openNodes.current.push(id);
    else if (!add && index !== -1)
      //rimuoviamo un nodo già presente
      openNodes.current.splice(index, 1);
  }

  function showActionModal(action?: string, param?: any) {
    // {id:id, title:title, icon:icon}
    switch (action) {
      case 'edit':
        if (param !== null) {
          const id = param.hasOwnProperty('id') ? param.id : null;
          const title = param.hasOwnProperty('title') ? param.title : null;
          const icon = param.hasOwnProperty('icon') ? param.icon : '';
          setEditData({ id: id, title: title, icon: icon });
          setModalAction('edit');
        }
        break;
      case 'delete':
        if (param !== null) {
          const id = param.hasOwnProperty('id') ? param.id : null;
          const title = param.hasOwnProperty('title') ? param.title : null;
          const icon = param.hasOwnProperty('icon') ? param.icon : '';
          setEditData({ id: id, title: title, icon: icon });
          setModalAction('delete');
        }
        break;
    }
    if (action !== null) setShowModal(true);
  }

  function restoreOpenState() {
    function restoreOpenState_(prevSt: NodeProps) {
      prevSt.open = openNodes.current.includes(prevSt.id);
      prevSt.children.forEach((el) => {
        if (!isLeafProps(el)) restoreOpenState_(el as NodeProps);
      });
    }

    const prevState = cloneObject(initialState.current);
    restoreOpenState_(prevState);
    prevState.open = true; // il nodo radice è sempre aperto
    setState(prevState);
  }

  function buildSearchData(nodes: NodeProps[]) {
    const titles: {
      nodeTitles: string;
      leafTitles: string;
      nodeIds: any[]; // Sostituisci 'any' con il tipo appropriato, se noto
      leafIds: any[]; // Sostituisci 'any' con il tipo appropriato, se noto
      elemPaths: any[]; // Sostituisci 'any' con il tipo appropriato, se noto
    } = { nodeTitles: '', leafTitles: '', nodeIds: [], leafIds: [], elemPaths: [] };
    const currentPath: any[] = [];
    //getNodeTitles_(titles, nodes, currentPath.slice(0));
    getElemTitles(titles, nodes[0], currentPath.slice(0));
    return titles;
  }

  // function getLeafTitles(titles: any, leaves: LeafProps[], currentPath: any[]) {
  //   const separator = '<*>';
  //   if (leaves.length > 0) {
  //     leaves.forEach((el) => {
  //       titles.leafIds.push(el.id);
  //       titles.leafTitles += el.title.toLowerCase() + separator;
  //       titles.elemPaths[el.id] = currentPath;
  //     });
  //   }
  // }

  // function getNodeTitles_(titles: any, np: NodeProps[], currentPath: any[]) {
  //   const separator = '<*>';
  //   if (np.length > 0) {
  //     const nodePath = currentPath.slice(0);
  //     np.forEach((el) => {
  //       titles.nodeIds.push(el.id);
  //       titles.nodeTitles += el.title.toLowerCase() + separator;
  //       titles.elemPaths[el.id] = nodePath;
  //       const inputPath = nodePath.slice(0);
  //       inputPath.push(el.id);
  //       // getLeafTitles(titles, el.leafChildren, inputPath.slice(0));
  //       //getNodeTitles_(titles,el.nodeChildren, inputPath.slice(0));
  //     });
  //   }
  // }

  function getElemTitles(titles: any, np: NodeProps, currentPath: any[]) {
    const separator = '<*>';

    const nodePath = currentPath.slice(0);

    titles.nodeIds.push(np.id);
    titles.nodeTitles += np.title.toLowerCase() + separator;
    titles.elemPaths[np.id] = nodePath;
    const inputPath = nodePath.slice(0);
    inputPath.push(np.id);

    np.children?.forEach((child) => {
      if (isLeafProps(child)) {
        titles.leafIds.push(child.id);
        titles.leafTitles += child.title.toLowerCase() + separator;
        titles.elemPaths[child.id] = inputPath.slice(0);
      } else getElemTitles(titles, child as NodeProps, inputPath.slice(0));
    });
  }

  function cloneObject(p: NodeProps) {
    const ret: NodeProps = {
      open: false,
      autoedit: false,
      hidden: true,
      editable: true,
      found: false,
      children: [],
      id: null,
      icon: null,
      title: '',
      actions: [],
      parentFound: false,
    };

    ret.found = p.found;
    ret.hidden = p.hidden;
    ret.editable = p.editable;
    ret.id = p.id;
    ret.title = p.title;
    ret.icon = p.icon;
    ret.autoedit = p.autoedit;
    ret.open = p.open;
    p.actions?.forEach((action) => {
      ret.actions?.push({ icon: action.icon, code: action.code });
    });

    return structuredClone(p);
    return ret;
  }

  function triggerSearch(text: string) {
    if (text.length === 0) {
      searchEnabled.current = false;
      setSearchState({ searchEnabled: false, found: 0, parentFound: 0, childFound: 0 });
      restoreOpenState();
      /*
     setShowTreeview(true);
     setState(prevState);*/
    } else {
      if (!searchEnabled.current) {
        searchEnabled.current = true;
        setPrevState(cloneObject(state));
      }
      text = text.trim().toLowerCase();
      const f = searchData.current;
      const separator = '<*>';
      let startIndex = 0,
        index,
        found = false,
        nodes: any[] = [],
        leaves: any[] = [],
        paths: any[] = [],
        inPathNodes: any[] = [];

      //prima valutiamo la ricerca padre-figlio
      let childFound = 0;
      let parentFound = 0;
      const searchWords = text.split(' ');
      if (searchWords.length > 1) {
        let parentChildFound = false;
        const parentIds: any[] = [];
        const childIds: any[] = [];
        let openNodeIds: any[] = [];
        const parentWord = searchWords[0];
        const childWord = searchWords[1];
        const pIds: any[] = [];
        const cIds: any[] = [];
        if (f.nodeTitles.includes(parentWord) && f.leafTitles.includes(childWord)) {
          while ((index = f.nodeTitles.indexOf(parentWord, startIndex)) > -1) {
            startIndex = index + parentWord.length;
            const g: any[] = f.nodeTitles.substring(0, startIndex).split(separator);
            const nodeId = f.nodeIds[g.length - 1];
            pIds.push(nodeId);
          }

          startIndex = 0;
          while ((index = f.leafTitles.indexOf(childWord, startIndex)) > -1) {
            startIndex = index + childWord.length;
            const g: any[] = f.leafTitles.substring(0, startIndex).split(separator);
            const leafId = f.leafIds[g.length - 1];
            cIds.push(leafId);
          }

          //verifichiamo adesso se nel path della foglie c'è uno dei nodi parent
          cIds.forEach((cId) => {
            const childPath: any[] = f.elemPaths[cId];
            pIds.forEach((pId) => {
              if (childPath.includes(pId)) {
                parentChildFound = true;
                if (!parentIds.includes(pId)) {
                  parentIds.push(pId);
                  ++parentFound;
                }
                if (!childIds.includes(cId)) {
                  childIds.push(cId);
                  ++childFound;
                  openNodeIds = openNodeIds.concat(childPath);
                }
              }
            });
          });
          //let leafPath = f.elemPaths[leafId];
        }

        if (parentChildFound) {
          setShowTreeview(true);
          const clone = cloneObject(state);
          SearchParentChild(clone, parentIds, childIds, openNodeIds);
          clone.hidden = false; //la root viene sempre mostrata
          clone.open = true;
          setState(clone);
        } else setShowTreeview(false);

        setSearchState({ searchEnabled: true, found: 0, parentFound: parentFound, childFound: childFound });
        return; //la ricerca padre-figlio è disgiunta da quella semplice
      }

      //ricerca singola parola
      let nodeFound = 0;
      let leafFound = 0;
      //ricerca sui nodi
      if (f.nodeTitles.includes(text)) {
        found = true;
        while ((index = f.nodeTitles.indexOf(text, startIndex)) > -1) {
          ++nodeFound;
          startIndex = index + text.length;
          const g: any[] = f.nodeTitles.substring(0, startIndex).split(separator);
          const nodeId = f.nodeIds[g.length - 1];
          nodes.push(nodeId);
          const arr: any[] = f.elemPaths[nodeId];
          arr.forEach((el) => {
            paths.push(el);
            inPathNodes.push(el);
          });
          // paths.concat(arr);
        }
      }

      //ricerca sulle foglie
      startIndex = 0;
      if (f.leafTitles.includes(text)) {
        found = true;
        while ((index = f.leafTitles.indexOf(text, startIndex)) > -1) {
          ++leafFound;
          startIndex = index + text.length;
          const g: any[] = f.leafTitles.substring(0, startIndex).split(separator);
          const nodeId = f.leafIds[g.length - 1];
          leaves.push(nodeId);
          const arr: any[] = f.elemPaths[nodeId];
          arr.forEach((el) => {
            paths.push(el);
            inPathNodes.push(el);
          });
        }
      }

      if (found) {
        //  var clone2 = Object.assign({}, state); //Object.assign({},JSON.parse(JSON.stringify(state))); //Object.assign({}, state);
        const clone2 = cloneObject(state);

        SearchStateObj(clone2, nodes, leaves, paths, inPathNodes);
        clone2.hidden = false;
        setShowTreeview(true);
        setState(clone2);
      } else {
        //restoreOpenState();
        setShowTreeview(false);
      }
      setSearchState({ searchEnabled: true, found: nodeFound + leafFound, parentFound: 0, childFound: 0 });
    }
  }

  // function triggerSearch2(text: string) {
  //   if (text.length === 0) {
  //     searchEnabled.current = false;
  //     setSearchState({ searchEnabled: false, found: 0, parentFound: 0, childFound: 0 });
  //     restoreOpenState();
  //     /*
  //    setShowTreeview(true);
  //    setState(prevState);*/
  //   } else {
  //     if (!searchEnabled.current) {
  //       searchEnabled.current = true;
  //       setPrevState(cloneObject(state));
  //     }
  //     text = text.trim().toLowerCase();
  //     const f = searchData.current;
  //     const separator = '<*>';
  //     let startIndex = 0,
  //       index,
  //       found = false,
  //       nodes: any[] = [],
  //       leaves: any[] = [],
  //       paths: any[] = [],
  //       inPathNodes: any[] = [];

  //     //prima valutiamo la ricerca padre-figlio
  //     let childFound = 0;
  //     let parentFound = 0;
  //     const searchWords = text.split(' ');
  //     if (searchWords.length > 1) {
  //       let parentChildFound = false;
  //       const parentIds: any[] = [];
  //       const childIds: any[] = [];
  //       let openNodeIds: any[] = [];
  //       const parentWord = searchWords[0];
  //       const childWord = searchWords[1];
  //       const pIds: any[] = [];
  //       const cIds: any[] = [];
  //       if (f.nodeTitles.includes(parentWord) && f.leafTitles.includes(childWord)) {
  //         while ((index = f.nodeTitles.indexOf(parentWord, startIndex)) > -1) {
  //           startIndex = index + parentWord.length;
  //           const g: any[] = f.nodeTitles.substring(0, startIndex).split(separator);
  //           const nodeId = f.nodeIds[g.length - 1];
  //           pIds.push(nodeId);
  //         }

  //         startIndex = 0;
  //         while ((index = f.leafTitles.indexOf(childWord, startIndex)) > -1) {
  //           startIndex = index + childWord.length;
  //           const g: any[] = f.leafTitles.substring(0, startIndex).split(separator);
  //           const leafId = f.leafIds[g.length - 1];
  //           cIds.push(leafId);
  //         }

  //         //verifichiamo adesso se nel path della foglie c'è uno dei nodi parent
  //         cIds.forEach((cId) => {
  //           const childPath: any[] = f.elemPaths[cId];
  //           pIds.forEach((pId) => {
  //             if (childPath.includes(pId)) {
  //               parentChildFound = true;
  //               if (!parentIds.includes(pId)) {
  //                 parentIds.push(pId);
  //                 ++parentFound;
  //               }
  //               if (!childIds.includes(cId)) {
  //                 childIds.push(cId);
  //                 ++childFound;
  //                 openNodeIds = openNodeIds.concat(childPath);
  //               }
  //             }
  //           });
  //         });
  //         //let leafPath = f.elemPaths[leafId];
  //       }

  //       if (parentChildFound) {
  //         setShowTreeview(true);
  //         const clone = cloneObject(state);
  //         SearchParentChild(clone, parentIds, childIds, openNodeIds);
  //         clone.hidden = false; //la root viene sempre mostrata
  //         clone.open = true;
  //         setState(clone);
  //       } else setShowTreeview(false);

  //       setSearchState({ searchEnabled: true, found: 0, parentFound: parentFound, childFound: childFound });
  //       return; //la ricerca padre-figlio è disgiunta da quella semplice
  //     }

  //     //ricerca singola parola
  //     let nodeFound = 0;
  //     let leafFound = 0;
  //     //ricerca sui nodi
  //     if (f.nodeTitles.includes(text)) {
  //       found = true;
  //       while ((index = f.nodeTitles.indexOf(text, startIndex)) > -1) {
  //         ++nodeFound;
  //         startIndex = index + text.length;
  //         const g: any[] = f.nodeTitles.substring(0, startIndex).split(separator);
  //         const nodeId = f.nodeIds[g.length - 1];
  //         nodes.push(nodeId);
  //         const arr: any[] = f.elemPaths[nodeId];
  //         arr.forEach((el) => {
  //           paths.push(el);
  //           inPathNodes.push(el);
  //         });
  //         // paths.concat(arr);
  //       }
  //     }

  //     //ricerca sulle foglie
  //     startIndex = 0;
  //     if (f.leafTitles.includes(text)) {
  //       found = true;
  //       while ((index = f.leafTitles.indexOf(text, startIndex)) > -1) {
  //         ++leafFound;
  //         startIndex = index + text.length;
  //         const g: any[] = f.leafTitles.substring(0, startIndex).split(separator);
  //         const nodeId = f.leafIds[g.length - 1];
  //         leaves.push(nodeId);
  //         const arr: any[] = f.elemPaths[nodeId];
  //         arr.forEach((el) => {
  //           paths.push(el);
  //           inPathNodes.push(el);
  //         });
  //       }
  //     }

  //     if (found) {
  //       //  var clone2 = Object.assign({}, state); //Object.assign({},JSON.parse(JSON.stringify(state))); //Object.assign({}, state);
  //       const clone2 = cloneObject(state);

  //       SearchStateObj(clone2, nodes, leaves, paths, inPathNodes);
  //       clone2.hidden = false;
  //       setShowTreeview(true);
  //       setState(clone2);
  //     } else {
  //       //restoreOpenState();
  //       setShowTreeview(false);
  //     }
  //     setSearchState({ searchEnabled: true, found: nodeFound + leafFound, parentFound: 0, childFound: 0 });
  //   }
  // }

  function SearchStateObj(
    stateObj: NodeProps,
    nodeIds: any[],
    leafIds: any[],
    paths: any[],
    inPathNodes: any[],
    forceAllVisible = false
  ) {
    stateObj.parentFound = false;
    const found = nodeIds.includes(stateObj.id);
    const pathOpen = paths.includes(stateObj.id);
    stateObj.found = found ? true : false;
    stateObj.open = pathOpen ? true : false;
    stateObj.hidden = !found && !pathOpen ? true : false;
    if (forceAllVisible) stateObj.hidden = false;
    if (found) {
      if (!forceAllVisible) {
        //rendiamo visibili tutti gli elementi di un nodo trovato che non possied altri elementi trovati
        forceAllVisible = inPathNodes.includes(stateObj.id) ? false : true;
      }
    }
    //per preservare lo stato di apertura dell'albero
    /*
    let showChildren = openNodes.current.includes(stateObj.id);
    if(!stateObj.open && showChildren)
         stateObj.open = true;  */
    // if(stateObj.hidden && forceVisible)
    //      stateObj.hidden = false;

    stateObj.children?.forEach((el) => {
      if (isLeafProps(el)) {
        (el as LeafProps).childFound = false;
        const found = leafIds.includes(el.id);
        el.found = found ? true : false;
        el.hidden = !found ? true : false;
        if (forceAllVisible) el.hidden = false;
      } else SearchStateObj(el as NodeProps, nodeIds, leafIds, paths, inPathNodes, forceAllVisible);
    });
  }

  function SearchParentChild(stateObj: NodeProps, parentIds: any[], childIds: any[], openNodeIds: any[]) {
    stateObj.found = false;
    const parentFound = parentIds.includes(stateObj.id) ? true : false;
    stateObj.parentFound = parentFound ? true : false;
    stateObj.open = parentFound ? true : false;
    stateObj.hidden = parentFound ? false : true;
    const openNode = openNodeIds.includes(stateObj.id);
    stateObj.open = openNode ? true : false;
    stateObj.hidden = openNode ? false : true;
    if (!stateObj.open && openNodes.current.includes(stateObj.id)) stateObj.open = true;

    stateObj.children?.forEach((el) => {
      if (isLeafProps(el)) {
        const childFound = childIds.includes(el.id) ? true : false;
        (el as LeafProps).childFound = childFound ? true : false;
        el.found = false;
        el.hidden = childFound ? false : true;
      } else SearchParentChild(el as NodeProps, parentIds, childIds, openNodeIds);
    });
  }

  const menubarStart = (
    <span className="p-input-icon-right" style={{ fontSize: '0.8rem' }}>
      <i className="pi pi-search" />
      <InputText id="searchText" placeholder="Cerca" onChange={(e) => triggerSearch(e.target.value)} />
    </span>
  );

  // function getModalHeader(action: string) {
  //   switch (action) {
  //     case 'edit':
  //       return (
  //         <>
  //           <i className="pi pi-pencil" style={{ fontSize: '1rem', color: '#374151' }}></i>&nbsp;
  //           <span>Richiesta di modifica</span>
  //         </>
  //       );
  //       break;
  //     case 'delete':
  //       return (
  //         <>
  //           <i className="pi pi-times" style={{ fontSize: '1rem', color: '#374151' }}></i>&nbsp;
  //           <span>Richiesta di eliminazione</span>
  //         </>
  //       );
  //       break;
  //     default:
  //       return (
  //         <>
  //           <i className="pi pi-pencil" style={{ fontSize: '1rem', color: '#374151' }}></i>&nbsp;
  //           <span>Richiesta di modifica</span>
  //         </>
  //       );
  //   }
  // }

  function getMessageFromChild(action: string, jsonData: string, callback?: any) {
    const data = JSON.parse(jsonData ?? '{}');
    if (action !== 'edit-textbox-special-key' && action !== 'child-click' && action !== 'open-edit-textbox') {
      newElementCreated.current = false;
      newElementId.current = null;
    }

    switch (action) {
      case 'child-click':
        if (data.hasOwnProperty('id') && closeEditTextFc.current != null) {
          const childId = data.id;
          if (openEditTextId.current !== childId) {
            closeEditTextFc.current();
            closeEditTextFc.current = null;
          }
        }
        break;

      case 'open-edit-textbox':
        closeEditTextFc.current = callback;
        openEditTextId.current = data.hasOwnProperty('id') ? data.id : null;
        if (newElementCreated.current) {
          if (newElementId.current == null) newElementId.current = data.id;
          else {
            newElementCreated.current = false;
            newElementId.current = null;
          }
        }
        break;

      case 'edit-textbox-special-key':
        if (newElementCreated.current === true) {
          if (data.hasOwnProperty('key') && data.hasOwnProperty('id'))
            if (data.key === 'Escape')
              //cancelliamo l'elemento se si clicca esc dopo averlo creato
              props.notifyAction('deleteDocument', JSON.stringify({ id: data.id }), callback);
        }

        break;

      case 'drag-start':
        if (data.hasOwnProperty('id') && data.hasOwnProperty('type'))
          dragStartItem.current = { id: data.id, type: data.type };
        break;

      case 'drop':
        if (data.hasOwnProperty('id') && dragStartItem.current != null) {
          dragEndItem.current = data.id;
          props.notifyAction(
            'drag-&-drop',
            JSON.stringify({
              startId: dragStartItem.current.id,
              startType: dragStartItem.current.type,
              endId: dragEndItem.current,
            }),
            callback
          );
          dragStartItem.current = null;
          dragEndItem.current = null;
        }
        break;

      case 'notify-action':
        if (data.hasOwnProperty('action')) {
          if (data.action === 'newDocument') newElementCreated.current = true;
          props.notifyAction(data.action, jsonData, callback);
        }
        break;

      case 'get-parent-id':
        const getParentId = (node: NodeProps, leafId: string, result: any = {}) => {
          /*
        let found = false;
        node.leafChildren.forEach(leaf=>{ if(leaf.id==leafId){ found = true; }})
        if(found){
          if(result.hasOwnProperty('id') )
            result.id = node.id;
        }
        else
         node.nodeChildren.forEach( no=>getParentId(no, leafId, result) );*/
        };

        const getParentId2 = (node: NodeProps, leafId: string, result: any = {}) => {
          if (result.id !== 0)
            //abbiamo trovato il parentId in un passaggio precedente, usciamo dalla funzione
            return;
          node.children?.forEach((child) => {
            if (isLeafProps(child)) {
              if (child.id === leafId) {
                if (result.hasOwnProperty('id')) result.id = node.id;
              }
            } else getParentId2(child as NodeProps, leafId, result);
          });
        };

        if (data.hasOwnProperty('id')) {
          const parentId = { id: 0 };
          const parentId2 = { id: 0 };
          getParentId(state, data.id, parentId);
          getParentId2(state, data.id, parentId2);
          return parentId.id;
        }

        break;
    }
  }

  function clickHandler(event: any) {
    if (closeEditTextFc.current != null) {
      closeEditTextFc.current();
      closeEditTextFc.current = null;
    }
  }

  return (
    <>
      <div ref={menubarRef} className="fixed-menubar">
        <Menubar start={menubarStart} />
      </div>
      <div ref={contentRef} className="scrollable-content">
        <Panel onClick={clickHandler}>
          <Dialog
            blockScroll={true}
            visible={props.utility?.spinner}
            style={{ maxHeight: '600px', overflow: 'hidden' }}
            onHide={() => setShowModal(false)}
            content={({ hide }) => (
              <div
                className="flex flex-column gap-2 cl-tmp padding-2"
                style={{ backgroundColor: 'white', textAlign: 'center' }}
              >
                <b>Operazione in corso...</b>

                <ProgressSpinner style={{ width: '60px', height: '60px' }} />
                <br />
              </div>
            )}
          ></Dialog>
          <span className={searchState.searchEnabled ? '' : 'hidden'}>
            <b>Risultati di ricerca:</b>
            <span className={searchState.found > 0 ? 'found' : 'hidden'}>&nbsp;{searchState.found} elementi</span>
            <span className={searchState.parentFound > 0 ? '' : 'hidden'}>
              &nbsp;
              <span className="parent">
                {searchState.parentFound} {searchState.parentFound > 1 ? 'padri' : 'padre'}
              </span>
              &nbsp;&nbsp;
              <span className="child">
                {searchState.childFound} {searchState.childFound > 1 ? 'figli' : 'figlio'}
              </span>
            </span>
            <span
              className={searchState.found + searchState.parentFound + searchState.childFound === 0 ? '' : 'hidden'}
            >
              {' '}
              Nessuna occorrenza trovata
            </span>
          </span>

          <ul id="mainUL" className={showTreeview ? '' : 'hidden'}>
            <Node
              id={state.id}
              found={state.found}
              hidden={state.hidden}
              autoedit={state.autoedit}
              editable={state.editable}
              open={state.open}
              forceOpen={state.forceOpen}
              title={state.title}
              icon={state.icon}
              actions={state.actions}
              children={state.children}
              parentFound={state.parentFound}
              setOpenNodes={setOpenNodes}
              showModal={showActionModal}
              sendMessageToTreeview={getMessageFromChild}
            />
          </ul>
        </Panel>
      </div>
    </>
  );
}

// src/Login.tsx
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

interface LoginProps {
  onLoginSuccess: (account: string, username: string, password: string) => void;
  savedUsername: string;
  savedAccount: string;
  savedPassword: string;
  errorMessage: string;
}

export const Login: React.FC<LoginProps> = ({
  onLoginSuccess,
  savedPassword,
  savedAccount,
  savedUsername,
  errorMessage,
}) => {
  const [account, setAccount] = useState(savedAccount);
  const [username, setUsername] = useState(savedUsername);
  const [password, setPassword] = useState(savedPassword);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.setItem('userAccount', account);
    localStorage.setItem('userName', username);
    localStorage.setItem('password', password);
    onLoginSuccess(account, username, password); // In a real app, you'd also verify the password
  };

  useEffect(() => {
    setAccount(savedAccount);
    setUsername(savedUsername);
    setPassword(savedPassword);
  }, [savedAccount, savedUsername, savedPassword]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form-container">
      <form onSubmit={handleSubmit} className="login-form">
        <div>
          <label htmlFor="account">Account</label>
          <InputText id="account" value={account || ''} onChange={(e) => setAccount(e.target.value)} />
        </div>
        <div>
          <label htmlFor="username">Username</label>
          <InputText id="username" value={username || ''} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <label htmlFor="password" className="mb-0">
          Password
        </label>
        <div className="p-inputgroup">
          <InputText
            id="password"
            type="password"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
          />
          {showPassword ? (
            <Button icon="pi pi-eye" onClick={handleTogglePassword} />
          ) : (
            <Button icon="pi pi-eye-slash" onClick={handleTogglePassword} />
          )}
        </div>
        <Button label="Login" />
        <div className="error-message">{errorMessage}</div>
      </form>
    </div>
  );
};

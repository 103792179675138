import { AlarmDocumentEditor } from '@components/document-editors/AlarmDocumentEditor';
import { DatabaseDocumentEditor } from '@components/document-editors/DatabaseDocumentEditor';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { DummyDocumentEditor } from '@components/document-editors/DummyDocumentEditor/DummyDocumentEditor';
import { FlowDocumentEditor } from '@components/document-editors/FlowDocumentEditor';
import { PageDocumentEditor } from '@components/document-editors/PageDocumentEditor/PageDocumentEditor';
import { RecipeDocumentEditor } from '@components/document-editors/RecipeDocumentEditor/RecipeDocumentEditor';
import { ScriptDocumentEditor } from '@components/document-editors/ScriptDocumentEditor/ScriptDocumentEditor';
import { TagDocumentEditor } from '@components/document-editors/TagDocumentEditor';
import { TimerDocumentEditor } from '@components/document-editors/TimerDocumentEditor/TimerDocumentEditor';
import { ToolbarConfig, flowToolbar, widgetToolbar } from './toolbar';

export interface DocumentEditorConfig {
  type: string;
  section: string;
  editor: DocumentEditorComponent;
  toolbar?: ToolbarConfig;
}

export const documentEditorsConfig: DocumentEditorConfig[] = [
  {
    type: 'page',
    section: 'pages',
    editor: PageDocumentEditor,
    toolbar: widgetToolbar,
  },
  {
    type: 'timer',
    section: 'timers',
    editor: TimerDocumentEditor,
  },
  {
    type: 'tag',
    section: 'tags',
    editor: TagDocumentEditor,
  },
  {
    type: 'alarm',
    section: 'alarms',
    editor: AlarmDocumentEditor,
  },
  {
    type: 'db',
    section: 'db',
    editor: DatabaseDocumentEditor,
  },
  {
    type: 'script',
    section: 'scripts',
    editor: ScriptDocumentEditor,
  },
  {
    type: 'dummy',
    section: 'test',
    editor: DummyDocumentEditor,
  },
  {
    type: 'recipe',
    section: 'recipes',
    editor: RecipeDocumentEditor,
  },
  {
    type: 'flow',
    section: 'flows',
    editor: FlowDocumentEditor,
    toolbar: flowToolbar,
  },
];

export enum ResourceType {
  ANY = 0x00,
  Image = 0x01,
  Audio = 0x02,
  Video = 0x03,
  Data = 0x04,
}

export interface Resource {
  id: number; // Unique ID of the resource
  type: ResourceType; // Type of the resource
  label: string; // Name of the resource
  size: number; // Byte size of the resource
  hash: string; // hex-encoded SHA256 hash
  editorUri: string; // URI to access the resource. Note that this is only valid in the context of the editor application!
}

export interface ResourceManager {
  /**
     * Creates a new resource
     * @param projectId ID of the project
     * @param type Type of the resource.
     * @param contents Contents of the resource.
     * @param label User-friendly name of the resource. Optional, defaults to the original filename

     * @returns an object describing the new resource
     */
  createResource(projectId: string, type: ResourceType, contents: File, label?: string): Promise<Resource>;

  /**
   * Modifies the name and contents of an existing resource
   * @param projectId ID of the project
   * @param id Id of the resource to modify
   * @param contents Set this to change the contents of the resource
   *
   * @returns an object describing the updated resource
   */
  updateResource(projectId: string, id: number, what: { label?: string; contents?: Blob }): Promise<Resource>;

  /**
   * Lists available resources for a project, with optional type and name filters
   * @param projectId ID of the project
   * @param typeFilter if specified, only return resources of a specific type
   * @param nameFilter if specified, only return resources whose name contains a specific string
   */
  listResources(projectId: string, options?: { typeFilter?: ResourceType; nameFilter?: string }): Promise<Resource[]>;

  /**
   * Removes an existing resource from the current project
   * @param projectId ID of the project
   * @param id ID of the resource to remove
   */
  removeResource(projectId: string, id: number): Promise<void>;
}

import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class VideoPlayer extends SimpleWidget {
  inputElement: HTMLInputElement;
  private currentTimeToSet: number | null = null;
  constructor(public domElement: HTMLVideoElement) {
    super(domElement);
    this.inputElement = document.createElement('input');
    this.inputElement.type = 'number';
    this.inputElement.min = '0';
    this.inputElement.placeholder = 'Inserisci secondi';
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-videoPlayer');
    if (this.domElement.parentElement) {
      this.domElement.parentElement.appendChild(this.inputElement);
    }
    this.inputElement.addEventListener('input', () => {
      const seconds = Number(this.inputElement.value);
      if (!isNaN(seconds) && seconds >= 0 && seconds <= this.domElement.duration) {
        this.domElement.currentTime = seconds;
      }
    });
    this.domElement.addEventListener('loadedmetadata', () => {
      if (this.currentTimeToSet !== null) {
        this.domElement.currentTime = this.currentTimeToSet;
        this.currentTimeToSet = null;
      }
    });

    super.attach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'Video':
          this.domElement.src = data;
          this.domElement.autoplay = true;
          this.domElement.controls = true;
          this.domElement.width = 400;
          break;
        case 'Play':
          this.domElement.play();
          break;
        case 'Pause':
          this.domElement.pause();
          break;
        case 'CurrentTime':
          const time = Number(data);
          if (!isNaN(time)) {
            if (this.domElement.readyState >= 1) {
              this.domElement.currentTime = time;
            } else {
              this.currentTimeToSet = time;
            }
          }
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }

  public playVideo(): void {
    this.domElement.play();
  }

  public pauseVideo(): void {
    this.domElement.pause();
  }
}

import { ListItemsComponent } from '@components/property-edit/component/ListItemsComponent';
import { Project } from '@api';
import { PropertiesConfig } from './interface';
import { TabName, TextPosition } from './enum';

export const listField: PropertiesConfig = {
  properties: {
    Items: {
      tab: TabName.Content,
      section: 'List',
      editor: ListItemsComponent,
      isResponsive: false,
      pinType: Project.PinType.InOut,
      hasNotDefault: true,
      caption: 'Items',
      captionPosition: TextPosition.Left,
    },
  },
};

import { Project, ProjectTree } from '@api';
import { create } from 'zustand';
import { deepCopy } from '@services/utils';
import { immer } from 'zustand/middleware/immer';
import { ipcClient } from './ipcStore';

export interface ProjectState {
  accountProjectsTree: ProjectTree.RootFolder;
  openedProjects: { [key: string]: Project.Project };
  setOpenedProjects: (project: Project.Project) => void;
  setProjectStructure: (projectId: string, project: Project.ProjectStructure) => void;
  setProjectTree: (tree: ProjectTree.RootFolder) => void;
  loadAccountProjectsList: () => Promise<void>;
  deleteProject: (projectId: string) => Promise<void>;
  updateProject: (projectId: string, project: Project.Project) => Promise<void>;
}

export const useProjectStore = create(
  immer<ProjectState>((set, _get) => ({
    openedProjects: {},
    accountProjectsTree: [],

    setOpenedProjects: (project: Project.Project) => {
      set((state) => {
        state.openedProjects[project.id] = project;
      });
    },

    setProjectStructure: (projectId: string, structure: Project.ProjectStructure) => {
      set((status) => {
        status.openedProjects[projectId].structure = deepCopy(structure);
      });
    },

    loadAccountProjectsList: async () => {
      const accountProjectsList = await ipcClient.getProjectTree();
      set((state) => {
        state.accountProjectsTree = deepCopy(accountProjectsList);
      });
    },

    deleteProject: async (projectId: string) => {
      set((state) => {
        delete state.openedProjects[projectId];
      });
    },

    updateProject: async (projectId: string, project: Project.Project) => {
      set((state) => {
        state.openedProjects[projectId] = project;
      });
    },

    setProjectTree: (tree: ProjectTree.RootFolder) => {
      set((state) => {
        state.accountProjectsTree = deepCopy(tree);
      });
    },
  }))
);

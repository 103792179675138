export interface SendPacket {
  pageId: string;
  widgetId: string; // Nome del widget "label1,textbox2,ecc"
  propertyName: string;
  isEvent?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class EventsManager {
  private static onEvent: (packet: SendPacket) => void;
  private static pageId = '';

  public static setOnEvent(onEvent: (packet: SendPacket) => void) {
    this.onEvent = onEvent;
  }

  public static setPageId(pageId: string) {
    this.pageId = pageId;
  }

  public static sendEvent(widgetId: string, propertyName: string, data?: unknown, isEvent?: boolean) {
    this.onEvent?.({
      pageId: this.pageId,
      data,
      widgetId,
      propertyName,
      isEvent,
    });
  }
}

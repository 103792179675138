import 'bootstrap-icons/font/bootstrap-icons.css';
import 'flexlayout-react/style/dark.css';
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import './App.css';
import './index.css';
import 'primeicons/primeicons.css';
import { IpcClientConnector } from './IpcClientConnector';
import { PrimeReactProvider } from 'primereact/api';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <IpcClientConnector>
        <App />
      </IpcClientConnector>
    </PrimeReactProvider>
  </React.StrictMode>
);

import { Widget } from './widget';

export type WidgetBuilder = () => Widget;

export class WidgetFactory {
  private static builderDictionary: { [key: string]: WidgetBuilder } = {};

  static buildWidget(name: string): Widget {
    if (name in this.builderDictionary) return this.builderDictionary[name]();
    else throw new Error("Widget '" + name + "' not found in 'buildWidget'");
  }

  static registerBuilder(key: string, callback: WidgetBuilder) {
    this.builderDictionary[key] = callback;
  }
}

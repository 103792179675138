import { NumericInput } from './NumericInputComponent';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { Slider } from 'primereact/slider';
import React from 'react';

export interface NumberSliderProps extends PropertyEditorComponentProps {
  minValue?: number;
  maxValue?: number;
  step?: number;
}
export const NumberSlider = ({ data, minValue, maxValue, step, onDataChange }: NumberSliderProps) => {
  //data={ (step < 1) ? data : Math.ceil(data)  }

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Slider
        value={Number(data) === 0 ? 0.0001 : data}
        onChange={(e) => {
          onDataChange(e.value);
        }}
        pt={{ root: { style: { alignSelf: 'center', flexGrow: '1' } } }}
        min={minValue}
        max={maxValue}
        step={step ?? 5}
      />
      <NumericInput
        data={data}
        minValue={minValue}
        maxValue={maxValue}
        step={step}
        onDataChange={(data) => {
          onDataChange(data);
        }}
      />
    </div>
  );
};

export default NumberSlider;

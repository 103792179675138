import React from 'react';
import ReactImages from '@common/images/ReactImages';

const EsaCard: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  highlight?: boolean;
}> = ({ item, highlight }) => {
  return (
    <div style={{ width: '130px', height: '85px', margin: '20px' }}>
      <div
        style={{
          textAlign: 'center',
          border: '1px solid lightgray',
          borderColor: highlight ? 'red' : 'white',
          borderRadius: '3px',
          cursor: 'move',
          position: 'relative',
        }}
      >
        <div style={{ fontSize: '28px', paddingBlockStart: '15px', textAlign: 'center', cursor: 'move' }}>
          {ReactImages.getReactImage(item.icon)}
        </div>
        <div style={{ display: 'flex', height: '40px', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ fontSize: '12px', textAlign: 'center', cursor: 'move' }}>{item.caption}</div>
        </div>
      </div>
    </div>
  );
};

export default EsaCard;

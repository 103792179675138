// @ts-check
import { Button } from 'primereact/button';
import { Fragment, useEffect, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

//definisco i tipi che utilizzo nel componente
type Option = { key: number; icon?: string; label?: string };
//parametri del componente: data={icons} selected={key} callback={testFunc}
type myProps = {
  options: Option[];
  selected: number;
  onSelected?: (key: number) => void;
};

//differenti notazioni valide per la dichiarazione del componente in TypeScript
//const DropDownBox: FC<myProps> = ({data, selected, callback}) => {
//const DropDownBox: FC<myProps> = (props: myProps) => {
const DropDownBox = (props: myProps) => {
  //memorizza l'intero elemento selezionato della lista
  const [localSelected, setLocalSelected] = useState(props.options[props.selected]);

  //creo un riferimento che userò nell'overlay
  const op = useRef<OverlayPanel>(null);

  //funzione chiamata alla selezione dell'elemento
  const valueSelected = (item: Option) => {
    /*non aggiorno lo stato dall'interno del componente, ma demando alla callback tale decisione
    //aggiorna l'elemento scelto
    setSelectedResolution(item);
    */
    //nasconde l'overlay utilizzando il riferimento assciato
    if (op.current) {
      op.current.hide();
    }
    //chiama la callback e restituisce l'indice dell'elemento selezionato
    if (item.key >= 0 && props.onSelected) {
      props.onSelected(item.key);
    }
  };

  //useEffect mi permette di eseguire l'aggiornamento della selezione decisa nella callback esternamente al componente, monitorando il cambiamento del parametro props.selected aggiornato dalla callback
  useEffect(() => {
    (() => {
      setLocalSelected(props.options[props.selected]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected]);

  //rendering del componente
  return (
    <Fragment>
      <Button
        size="small"
        type="button"
        icon={localSelected.icon}
        label={localSelected.label}
        onClick={(e) => {
          if (op.current) op.current.toggle(e);
        }}
        onBlur={() => op.current?.hide()}
        text
        aria-label="Resolution"
      />
      <OverlayPanel ref={op} closeOnEscape dismissable={true}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {props.options.map((item: Option) => {
            return (
              <li key={item.key}>
                <Button
                  style={{ listStyleType: 'none', padding: 4, margin: 0 }}
                  tabIndex={-1}
                  type="button"
                  icon={item.icon}
                  label={item.label}
                  onClick={() => valueSelected(item)}
                  text
                  size="small"
                  raised={item.key === props.selected ? true : false}
                  severity={item.key === props.selected ? 'success' : 'secondary'}
                />
              </li>
            );
          })}
        </ul>
      </OverlayPanel>
    </Fragment>
  );
};

export default DropDownBox;

import { AuthData, DeployStatus, EsaWebIpcApiClient, EventHandler, Panel, Project } from '@api';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useLinkStore } from './linkStore';
import { usePanelStore } from './panelStore';
import { useProjectStore } from './projectStore';

export const ipcClient: EsaWebIpcApiClient = new EsaWebIpcApiClient(`wss://fast.esa-automation.com/ws`, {});

export interface IpcState {
  authData: AuthData;
  setAuthData: (authData: AuthData) => void;
  eventHandler: EventHandler;
  registerEvents: () => void;
}

export const useIpcStore = create(
  immer<IpcState>((set, get) => ({
    project: null,
    authData: null,
    accountProjectsTree: [],
    eventHandler: {
      projectStructureChanged: async (e: { id: string; structure: Project.ProjectStructure }) => {
        useProjectStore.getState().setProjectStructure(e.id, e.structure);
        useLinkStore.getState().updateResourceTree(e.structure);
        return Promise.resolve();
      },
      projectPanelsChanged: (e: Panel[]) => {
        usePanelStore.getState().updatePanelList(e);
        return Promise.resolve();
      },
      deployStatusChanged: (e: { projectId: string; panelId: string; status: DeployStatus }) => {
        usePanelStore.getState().updatePanelDeployStatus(e.projectId, e.panelId, e.status);
      },
    },
    setAuthData: (authData: AuthData) => {
      set({ authData });
    },

    registerEvents: () => {
      ipcClient.setEventHandler(get().eventHandler);
    },
  }))
);

import { EditingProperties, useWidgetPropsStore } from '@stores/widgetPropsStore';
import { Property } from '@config/widget/interface';
import { TooltipRefProps } from 'react-tooltip';
import { deepCopy } from '@services/utils';
import PropertyEditor, { PropertyEditorOptions } from './PropertyEditorComponent';
import React from 'react';

interface PropertyListProps {
  list: PropertyEditorOptions[];
  errorToolTipRef?: React.MutableRefObject<TooltipRefProps>;
  addResetChildFunction?: unknown;
  editingProperties: EditingProperties;
}

export const insertItem = (
  array: PropertyEditorOptions[],
  widgetId: string,
  field: string,
  property: Property
): PropertyEditorOptions[] => {
  // Trova la posizione corretta per l'inserimento
  const element: PropertyEditorOptions = {
    field,
    widgetId,
    property: deepCopy(property),
    sectionId: ''
  };

  const index = array.findIndex((el) => el.property.index > element.property.index);

  // Se l'elemento deve essere inserito alla fine dell'array
  if (index === -1) {
    array.push(element);
  } else {
    // Altrimenti, inseriscilo nella posizione trovata
    array.splice(index, 0, element);
  }

  return array;
};

export const PropertyList = ({
  list,
  errorToolTipRef,
  addResetChildFunction,
  editingProperties,
}: PropertyListProps) => {
  const data = editingProperties.data;
  const parentData = editingProperties.parentData;
  const device = useWidgetPropsStore((state) => state.device);

  return (
    <>
      {Object.entries(list).map(([key, editorConfig]) => {
        return (
          (editorConfig.property.visible ?? true) && (
            <PropertyEditor
              data={editorConfig.field === 'Name' ? editingProperties.widgetName : data[editorConfig.field]}
              defaultData={
                editorConfig.property.hasUM
                  ? { um: 0, value: editorConfig.property.defaultData }
                  : editorConfig.property.defaultData
              }
              parentData={parentData}
              key={`${key}-${device}`}
              options={editorConfig}
              onDataChange={(_data: unknown) => {}}
              showError={(message: string) => {
                if (errorToolTipRef) {
                  if (message) {
                    errorToolTipRef.current?.open({
                      anchorSelect: `#editor-${editorConfig.field}`,
                      content: message,
                      place: 'bottom',
                    });
                  } else {
                    errorToolTipRef.current?.close();
                  }
                }
              }}
              addResetChildFc={addResetChildFunction}
              editingProperties={editingProperties}
            />
          )
        );
      })}
    </>
  );
};

export default PropertyList;

import { Button } from 'primereact/button';
import { Handle, HandleProps } from '@xyflow/react';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useCallback, useMemo, useRef, useState } from 'react';

export interface CustomHandleProps extends HandleProps {
  data: {
    index: number;
    handleType: string;
    divHeight: number;
    inHandles: string[];
    outHandles: string[];
    onLinkHandle: (e: React.SyntheticEvent, index: string, nodeId: string) => void;
    onIsDynamicHandle: (nodeId: string, handleKey: string, index: number) => boolean;
    onHandleDelete: (nodeId: string, handleKey: string, index: number) => void;
  };
}

const CustomHandle: React.FC<CustomHandleProps> = ({ data, id, type, position }) => {
  const [showDeleteButton, setShowDeleteButton] = useState<Record<number, boolean>>({});
  const [activeHandleIndex, setActiveHandleIndex] = useState<number | null>(null);

  const op = useRef<OverlayPanel>(null);

  const handleColor = data.handleType === 'input' ? '#007bff' : '#e98d01';

  const getHandleTopPosition = useMemo(() => {
    if (!data.divHeight) return 0;

    const handleLength = data.handleType === 'input' ? data.inHandles.length : data.outHandles.length;
    if (handleLength === 1) {
      return data.divHeight / 2;
    } else {
      return 2 + (data.divHeight / (handleLength + 1)) * (data.index + 1); //2 is just a margin
    }
  }, [data.divHeight, data.handleType, data.index, data.inHandles.length, data.outHandles.length]);

  const getHandleString = useMemo((): string => {
    const handleArray = data.handleType === 'input' ? data.inHandles : data.outHandles;
    if (data.index <= handleArray.length - 1) {
      return handleArray[data.index];
    }
    return (data.handleType === 'input' ? 'Input' : 'Output') + (data.index + 1);
  }, [data.handleType, data.index, data.inHandles, data.outHandles]);

  const handleLink = useCallback(
    (e: React.SyntheticEvent, index: string) => {
      data.onLinkHandle(e, index, id);
    },
    [data, id]
  );

  const handleMouseEnter = useCallback(() => {
    if (activeHandleIndex !== data.index && data.onIsDynamicHandle(id, data.handleType, data.index)) {
      setActiveHandleIndex(data.index);
      setShowDeleteButton((prev) => ({
        ...prev,
        [data.index]: true,
      }));
    }
  }, [activeHandleIndex, data, id]);

  const handleMouseLeave = useCallback(() => {
    if (activeHandleIndex === data.index) {
      setShowDeleteButton((prev) => ({
        ...prev,
        [data.index]: false,
      }));
      setActiveHandleIndex(null);
    }
  }, [activeHandleIndex, data.index]);

  const ButtonDelete = useCallback(() => {
    return (
      <button
        className="fa-solid fa-trash"
        onClick={(e) => op.current.show(e, e.target)}
        style={{
          background: '#D4AF37',
          cursor: 'pointer',
          color: 'black',
          border: 'none',
        }}
      />
    );
  }, []);

  const HandleLabel = useCallback(() => {
    return <label style={{ color: 'black' }}>{getHandleString}</label>;
  }, [getHandleString]);

  const HandleButton = useCallback(() => {
    return (
      <Button
        type="button"
        style={{ padding: 0, width: '20px' }}
        icon="pi pi-link"
        onClick={(e) => handleLink(e, `${data.handleType === 'input' ? 'in-' : 'out-'}${data.index + 1}`)}
        text
        aria-label="Resolution"
      />
    );
  }, [data.handleType, data.index, handleLink]);

  const InputTemplate = useMemo(() => {
    return (
      <div style={{ top: `${getHandleTopPosition - 9}px`, position: 'absolute' }}>
        <HandleLabel />
        <HandleButton />
        {showDeleteButton[data.index] && <ButtonDelete />}
      </div>
    );
  }, [getHandleTopPosition, showDeleteButton, data.index, HandleLabel, HandleButton, ButtonDelete]);

  const OutputTemplate = useMemo(() => {
    return (
      <div style={{ top: `${getHandleTopPosition - 9}px`, position: 'absolute', right: '8px' }}>
        {showDeleteButton[data.index] && <ButtonDelete />}
        <HandleButton />
        <HandleLabel />
      </div>
    );
  }, [getHandleTopPosition, showDeleteButton, data.index, HandleButton, HandleLabel, ButtonDelete]);

  return (
    <div key={`${data.handleType}${data.index}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Handle
        type={type}
        position={position}
        id={`${data.handleType === 'input' ? 'Input' : 'Output'}${data.index + 1}`}
        isConnectable={true}
        style={{
          top: `${getHandleTopPosition}px`,
          borderLeft: `12px solid ${handleColor}`,
          borderTop: '7px solid transparent',
          borderBottom: '7px solid transparent',
          backgroundColor: 'transparent',
          borderRadius: '0px',
        }}
      />
      {data.handleType === 'input' ? InputTemplate : OutputTemplate}

      <OverlayPanel ref={op}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Sei sicuro di voler procedere?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button
              label="No"
              onClick={() => {
                op.current.hide();
                setShowDeleteButton((prev) => ({
                  ...prev,
                  [data.index]: false,
                }));
              }}
            />
            <Button
              label="Si"
              onClick={() => {
                op.current.hide();
                setShowDeleteButton((prev) => ({
                  ...prev,
                  [data.index]: false,
                }));
                data.onHandleDelete(id, data.handleType, data.index);
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default React.memo(CustomHandle);

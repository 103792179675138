/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api'; // Aggiusta il percorso secondo la tua struttura
import { Skeleton } from 'primereact/skeleton';
import { TabPanel, TabPanelHeaderTemplateOptions, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import useDocumentStore, { DocumentInfo } from '@stores/documentStore';

interface PanelContentProps {
  showLoading: boolean;
  onStatusChange: (data: Project.DocumentData) => void;
  onDataChange: (data: Project.DocumentData) => void;
}

interface PanelTab {
  desc: string;
  documentId: Project.ProjectNodeKey;
}

const PanelContent: React.FC<PanelContentProps> = ({ onDataChange, onStatusChange, showLoading }) => {
  const { documentsInfo, selectedDoc, oldOpenedDoc } = useDocumentStore();

  const docInfo: DocumentInfo = documentsInfo[selectedDoc] ?? oldOpenedDoc;

  let tabContent: React.JSX.Element;
  if (showLoading)
    tabContent = (
      <div className="fastide-document-skeleton">
        <Skeleton style={{ marginBottom: '2rem' }}></Skeleton>
        <Skeleton width="10rem"></Skeleton>
        <Skeleton width="5rem"></Skeleton>
        <Skeleton height="2rem"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    );
  else if (docInfo?.builder) {
    const DocumentEditor = docInfo.builder;
    tabContent = (
      <DocumentEditor
        status={docInfo.document.editorStatus}
        data={docInfo.document.doc.data}
        documentId={docInfo.document.doc.nodeId}
        onDataChange={onDataChange}
        onStatusChange={onStatusChange}
      />
    );
  } else tabContent = <div />;
  return <div>{tabContent}</div>;
};

const DocumentManager = () => {
  const {
    documentsInfo,
    openedDocuments,
    openDocument,
    closeOpenedDocument,
    saveOpenedDocument,
    selectedDoc,
    selectingDoc,
    loading,
    stopLoading,
    saveStatus,
    saveDocumentData,
    saving,
  } = useDocumentStore();
  const [selectingIndex, setSelectingIndex] = useState(openedDocuments.findIndex((key) => key === selectingDoc));

  const [showLoading, setShowLoading] = useState(false);
  const [panelTabs, setPanelTabs] = useState<PanelTab[]>([]);
  const isNewDoc = useRef(false);

  const loadTimeout = useRef(0);

  const op = useRef<OverlayPanel>(null);

  let IdDocumentoToClose = '';

  useEffect(() => {
    if (!loading[selectingDoc]) {
      window.clearTimeout(loadTimeout.current);
      loadTimeout.current = null;
      setShowLoading(false);
    }
  }, [loading, selectingDoc]);

  useEffect(() => {
    if (openedDocuments.length > 0) {
      const newSelecting = openedDocuments.findIndex((key) => key === selectingDoc);
      isNewDoc.current = newSelecting === -1;
      if (!isNewDoc.current) {
        setSelectingIndex(newSelecting);
      }
    }
  }, [selectingDoc, openedDocuments]);

  useEffect(() => {
    if (loading[selectingDoc]) {
      loadTimeout.current = window.setTimeout(() => {
        setShowLoading(true);
        if (isNewDoc.current) {
          const newList = [...panelTabs, { documentId: null, desc: null }];
          setPanelTabs(newList);
          setSelectingIndex(newList.length - 1);
        }
      }, 200); // n = 100 msec
    }
    return () => window.clearTimeout(loadTimeout.current);
  }, [selectingDoc, loading]);

  useEffect(() => {
    setPanelTabs(
      openedDocuments.map((id) => ({
        desc: documentsInfo[id].document.doc.name,
        documentId: JSON.parse(id),
      }))
    );
  }, [openedDocuments]);

  const handleTabChange = (e: TabViewTabChangeEvent) => {
    window.clearTimeout(loadTimeout.current);
    openedDocuments.forEach((key) => stopLoading(JSON.parse(key)));
    openDocument(openedDocuments[e.index]);
  };

  const handleCloseTab = (e: React.MouseEvent, openedDocument: Project.OpenedDocument) => {
    e.preventDefault();
    e.stopPropagation();

    if (!openedDocument.modified) closeOpenedDocument(JSON.stringify(openedDocument.doc.nodeId));
    else {
      op.current.show(e, e.target);
    }
  };

  const handleDataChange = (id: string, data: Project.DocumentData): void => {
    saveDocumentData(id, data);
  };

  const handleStatusChange = (id: string, data: Project.DocumentData): void => {
    saveStatus(id, data);
  };

  return (
    <div className={'fastide-document-tabview'}>
      {panelTabs.length !== 0 ? (
        <div>
          <TabView activeIndex={selectingIndex} onTabChange={handleTabChange}>
            {panelTabs.map((tab) => {
              let fontStyle = {};
              if (documentsInfo[JSON.stringify(tab.documentId)]?.document.modified) fontStyle = { color: 'red' };
              if (saving[JSON.stringify(tab.documentId)]) fontStyle = { color: 'yellow' };

              const tabHeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
                return (
                  <div
                    className="flex align-items-center gap-2 p-3"
                    style={{ cursor: 'pointer' }}
                    onClick={options.onClick}
                  >
                    {tab.desc ? (
                      <span className="font-bold white-space-nowrap" style={fontStyle}>
                        {' '}
                        {tab.desc}
                      </span>
                    ) : (
                      <span className="text-xl pi pi-hourglass" />
                    )}

                    {tab.documentId && (
                      <span
                        className="text-xl pi pi-times"
                        onClick={(e: React.MouseEvent) => {
                          IdDocumentoToClose = JSON.stringify(tab.documentId);
                          handleCloseTab(e, documentsInfo[IdDocumentoToClose]?.document);
                        }}
                      />
                    )}
                  </div>
                );
              };
              return <TabPanel key={tab.documentId.domainId} headerTemplate={tabHeaderTemplate} />;
            })}
          </TabView>
          <PanelContent
            key={selectedDoc}
            onStatusChange={(data) => handleStatusChange(selectedDoc, data)}
            onDataChange={(data) => handleDataChange(selectedDoc, data)}
            showLoading={showLoading}
          />
        </div>
      ) : (
        <div />
      )}
      <OverlayPanel ref={op}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Il documento e' stato modificato. Vuoi salvarlo?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button
              label="No"
              onClick={() => {
                op.current.hide();
                saveOpenedDocument(IdDocumentoToClose, false);
                closeOpenedDocument(IdDocumentoToClose);
              }}
            />
            <Button
              label="Si"
              onClick={() => {
                op.current.hide();
                saveOpenedDocument(IdDocumentoToClose, true);
                closeOpenedDocument(IdDocumentoToClose);
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DocumentManager;

import { BaseWidget } from './base-widget';
import { WidgetProperties } from './widget';

export class SimpleWidget extends BaseWidget {
  constructor(public domElement: HTMLElement) {
    super();
  }

  detach() {
    super.detach();
  }

  attach(): void {}

  setProperties(properties: WidgetProperties): void {
    super.setProperties(properties);
  }
}

export enum TextPosition {
  Left,
  Up,
}

export enum TabName {
  Content = 'Contenuto',
  Style = 'Stile',
  Layout = 'Layout',
  Advanced = 'Avanzato',
}

/* eslint-disable react-hooks/exhaustive-deps */
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { MediaStates } from './view-manager/widget-handler/properties-manager';
import { Project } from '@api';
import { ViewManager } from './view-manager/view-manager';
import { WidgetPropsState, useWidgetPropsStore } from '@stores/widgetPropsStore';
import { deepCopy, deepEqual } from '@services/utils';
import { useEffect, useRef, useState } from 'react';
import DeviceChangeComponent from '@components/property-edit/utils/DeviceChangeComponent';

interface ViewEditorStatus {
  selectedId: string;
}

export const PageDocumentEditor: DocumentEditorComponent = ({
  data,
  status,
  onDataChange,
  onStatusChange,
  documentId,
}) => {
  // Crea un ref
  const divRef = useRef(null);
  const previewManagerRef = useRef<ViewManager>(null);
  const [documentIsInvalid, setDocumentIsInvalid] = useState(false);
  const localDocumentData = useRef<Project.DocumentData | null>(null);

  const device = useWidgetPropsStore((state: WidgetPropsState) => state.device);

  const setWidgetDragHandler = useWidgetPropsStore((state) => state.setWidgetDragHandler);
  const setWidgetPropertyHandler = useWidgetPropsStore((state) => state.setWidgetPropertyHandler);

  const setProperties = useWidgetPropsStore((state) => state.setEditingProps);
  const stopEditingProps = useWidgetPropsStore((state) => state.stopEditingProps);
  useEffect(() => {
    // Accedi all'HTMLElement tramite divRef.current dopo che il componente è montato
    if (divRef.current) {
      const previewManager = new ViewManager(divRef.current, documentId);
      previewManager.onEditWidgetProperties((node, parentData, widgetName) => {
        setProperties(node, parentData, widgetName, documentId, localDocumentData.current.resourceLinks);
      });
      previewManager.onRemoveWidgetProperties(() => {
        stopEditingProps();
      });
      previewManager.onUpdateData(() => {
        console.log(JSON.stringify(localDocumentData.current, null, 2));
        onDataChange(localDocumentData.current);
      });
      previewManager.onUpdateResourceLinks((links) => {
        localDocumentData.current.resourceLinks = deepCopy(links);
        onDataChange(localDocumentData.current);
      });
      previewManager.onSelectionChange((elementId) => {
        onStatusChange({ selectedId: elementId });
      });
      previewManagerRef.current = previewManager;
      setWidgetDragHandler(previewManager);
      setWidgetPropertyHandler(previewManager);
    }
    return () => {
      previewManagerRef.current.detachFromDocument();
      previewManagerRef.current = null;
      stopEditingProps();
      setWidgetDragHandler(null);
      setWidgetPropertyHandler(null);
    };
  }, []); // L'array vuoto come seconda argomentazione significa che useEffect viene eseguito solo dopo il primo render

  const updateCanvas = (newDocumentData: Project.DocumentData) => {
    if (!newDocumentData || !newDocumentData.metadata || Object.keys(newDocumentData.metadata).length === 0) {
      newDocumentData = {
        metadata: {
          id: `page-${documentId.domainId}`,
          type: 'page',
          children: [],
        },
      };
    }
    if (previewManagerRef.current.dataAreCorrect(newDocumentData.metadata)) {
      previewManagerRef.current.setData(newDocumentData);
    } else {
      setDocumentIsInvalid(true);
    }
    localDocumentData.current = newDocumentData;
  };

  useEffect(() => {
    if (!deepEqual(data, localDocumentData.current) || !data) {
      const newDocumentData = deepCopy(data);
      updateCanvas(newDocumentData);
    }
  }, [data]);

  useEffect(() => {
    const viewStatus = status as ViewEditorStatus;
    if (viewStatus.selectedId) {
      previewManagerRef?.current.setSelectedElement(viewStatus.selectedId);
    }
  }, [status]);

  useEffect(() => {
    previewManagerRef?.current.updateSelectionBorder();
    updateCanvas(localDocumentData.current);
  }, [device]);

  const deviceClass = () => {
    switch (device) {
      case MediaStates.Desktop:
        return 'view-desktop-screen';
      case MediaStates.Tablet:
        return 'view-tablet-screen';
      case MediaStates.Phone:
        return 'view-phone-screen';
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        <DeviceChangeComponent />
      </div>
      <div style={{ flexGrow: '1', padding: '10px' }}>
        <div id="view-screen" className={deviceClass()}>
          {documentIsInvalid ? (
            <div style={{ color: 'black' }}>Document format is invalid</div>
          ) : (
            <div id="screen-root" ref={divRef} />
          )}
        </div>
      </div>
    </div>
  );
};

import { Dropdown } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Project } from '@api';
import { SelectItemOptionsType } from 'primereact/selectitem';
import React, { FC, useCallback, useMemo } from 'react';
import TagType = Project.TagType;
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { TagMethodEditor } from '@components/document-editors/TagDocumentEditor/TagItemEditor/TagMethodEditor';
import { deepCopy } from '@services/utils';

const tagTypeOptions: SelectItemOptionsType = [
  { value: TagType.Numeric },
  { value: TagType.String },
  { value: TagType.Method },
];

type Props = {
  resource: Project.ResourceTreeElement;
  onChange: (resource: Project.ResourceTreeElement) => void;
};

export const TagItemEditor: FC<Props> = ({ resource, onChange }) => {
  const handleChange = useCallback(
    (_resource: Project.ResourceTreeElement = resource) => {
      onChange(_resource);
    },
    [onChange, resource]
  );

  const handleTypeChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const _resource = deepCopy(resource);
      switch (e.value) {
        case TagType.Numeric:
        case TagType.String:
          _resource.methodParams = undefined;
          _resource.children = undefined;
          break;
        case TagType.Method:
          _resource.children = undefined;
          break;
      }

      handleChange({
        ...resource,
        type: e.value,
      });
    },
    [handleChange, resource]
  );

  const handleArrayChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const newResource: Project.ResourceTreeElement = {
        ...resource,
        arrays: event.target.checked ? [0] : undefined,
      };
      handleChange(newResource);
    },
    [handleChange, resource]
  );

  const handleArraySizeChange = useCallback(
    (event: InputNumberChangeEvent) => {
      const newResource: Project.ResourceTreeElement = {
        ...resource,
        arrays: event.value === null ? undefined : [event.value],
      };
      handleChange(newResource);
    },
    [handleChange, resource]
  );

  const propsForType = useMemo(() => {
    switch (resource.type) {
      case TagType.Numeric:
      case TagType.String:
        return (
          <div className="card flex flex-column md:flex-row gap-3">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon mr-4"
                style={{
                  borderRight: '1px solid #424b57',
                  minWidth: '73px',
                  borderTopRightRadius: '6px',
                  borderBottomRightRadius: '6px',
                }}
              >
                Array
              </span>
              <Checkbox
                inputId="ingredient1"
                value={true}
                onChange={handleArrayChange}
                checked={!!resource.arrays}
                className="mt-2"
              />
            </div>

            <div className="p-inputgroup flex-1" style={{ marginBottom: '5px' }}>
              <span className="p-inputgroup-addon" style={{ minWidth: '73px' }}>
                Size{' '}
              </span>
              <InputNumber
                placeholder="Size"
                value={resource.arrays?.[0] ?? undefined}
                onChange={handleArraySizeChange}
              />
            </div>
          </div>
        );
      case TagType.Method:
        return <TagMethodEditor resource={resource} onChange={(methodResource) => handleChange(methodResource)} />;
    }
  }, [handleArrayChange, handleArraySizeChange, handleChange, resource]);

  return (
    <div className="card flex flex-column gap-3">
      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">
          <i className="pi pi-hashtag"></i>
        </span>
        <Dropdown
          value={resource.type}
          onChange={handleTypeChange}
          options={tagTypeOptions}
          optionLabel="value"
          placeholder="Select a type"
          className="w-full md:w-14rem"
        />
      </div>
      <div className="card flex flex-column gap-3">{propsForType}</div>
    </div>
  );
};

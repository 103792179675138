import { Widget } from './widget';

export class WidgetDictionary {
  private static dictionary: { [key: string]: Widget } = {};

  static registerWidget(id: string, widget: Widget) {
    this.dictionary[id] = widget;
  }

  static unregisterWidget(id: string) {
    delete this.dictionary[id];
  }

  static updateAllWidgets() {
    for (const key of Object.keys(this.dictionary)) this.dictionary[key].updateProperties();
  }
  static findWidget(name: string): Widget | null {
    if (name in this.dictionary) return this.dictionary[name];
    else return null;
  }
}

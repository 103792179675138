/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'primereact/button';
import { DatabaseInfos, Project, Table } from '@api';
import { DatabaseItemEditor } from '@components/document-editors/DatabaseDocumentEditor/DatabaseItemEditor';
import { Dialog } from 'primereact/dialog';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { StringConnectionForm } from '@components/document-editors/DatabaseDocumentEditor/StringConnectionForm';
import { Toast } from 'primereact/toast';
import { TreeLink } from '@components/tree-link/TreeLink';
import { Treeview } from '@common/treeview';
import { deepCopy } from '@services/utils';
import { ipcClient } from '@stores/ipcStore';
import AutoFocusDiv from '@common/AutoFocusDiv';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const Events = ['OnLoad', 'OnSave', 'OnDelete'];

export const DatabaseDocumentEditor: DocumentEditorComponent = ({
  data: documentData,
  onDataChange: onDocumentDataChange,
  documentId,
}) => {
  const toast = useRef<any>();
  const linkOverlay = useRef<OverlayPanel>(null);
  const changeTableOverlay = useRef<OverlayPanel>(null);
  const refreshConnectionOverlay = useRef<OverlayPanel>(null);
  const [showConnectionStringWizard, setShowConnectionStringWizard] = useState<boolean>(false);
  const [currentTable, setCurrentTable] = useState<Table | undefined>();
  const [selectedField, setSelectedField] = useState<string | undefined>();
  const [databaseInfos, setDatabaseInfos] = useState<DatabaseInfos | undefined>();
  const [connectionString, setConnectionString] = useState('');
  const [visualConnectionString, setVisualConnectionString] = useState('');
  const [startResourcePoint, setStartResourcePoint] = useState<
    (Project.ResourceLinkPoint & { pinType: Project.PinType }) | undefined
  >();
  const inputRef = useRef(null);
  let newTable: any = null;
  const dropdownRef = useRef(null);

  const syncDatabaseInfo = useCallback(async (connectionString: string, refresh: boolean, tableName?: string) => {
    const newDatabaseInfos = await ipcClient.getDatabaseStructureData(connectionString);
    setDatabaseInfos(newDatabaseInfos);
    // select the first table
    if (newDatabaseInfos?.tables && newDatabaseInfos?.tables.length > 0) {
      const currTable =
        newDatabaseInfos?.tables.find((item) => item.tableName === tableName) ?? newDatabaseInfos?.tables[0];
      setCurrentTable(currTable);
      // force the update of the table list
      if (refresh) {
        handleTableSelect(currTable, connectionString);
      }
    }
  }, []);

  const handleStringGeneration = async (newString: string) => {
    setShowConnectionStringWizard(false);
    setConnectionString(newString);
    setVisualConnectionString(hidePsw(newString));
    await syncDatabaseInfo(newString, true);
  };

  function hidePsw(str: string) {
    const passwordRegex = /:([^@]+)@/g;
    const replaceWithAsterisks = (match: string, p1: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, capturedPassword] = match.split(':'); // Destructure to extract the captured password
      return `:${'*'.repeat(capturedPassword.length)}@`;
    };
    const maskedString = str.replace(passwordRegex, replaceWithAsterisks);
    return maskedString;
  }

  useEffect(() => {
    const render = async () => {
      if (documentData.metadata?.connectionString !== undefined) {
        setConnectionString(documentData.metadata?.connectionString);
        setVisualConnectionString(hidePsw(documentData.metadata?.connectionString));
        await syncDatabaseInfo(documentData.metadata?.connectionString, false, documentData.metadata.tableName);
      }
    };
    render();
  }, []);

  const getCorrectFieldType = (dbFieldType: string): Project.TagType => {
    switch (dbFieldType) {
      case 'INTEGER':
      case 'FLOAT':
      case 'DOUBLE':
        return Project.TagType.Numeric;
      case 'DATE':
      case 'TIME':
        return Project.TagType.String;
      default:
        if (dbFieldType.startsWith('VARCHAR')) {
          return Project.TagType.String;
        }
        return Project.TagType.Numeric;
    }
  };

  const handleTableSelect = useCallback(
    (table: Table, connString?: string) => {
      setTimeout(() => {
        if (table !== null) {
          const fieldsResourceTree: Project.ResourceTreeElement[] = table.fields.map((field) => ({
            name: field.fieldName,
            data: { id: 'fld-' + documentId.domainId + '_' + field.fieldName },
            pinType: Project.PinType.InOut,
            type: getCorrectFieldType(field.type),
          }));

          const copyResourceTree = deepCopy(documentData.resourceTree);
          const findResourceByName = (
            resourceTree: Project.ResourceTreeElement[],
            name: string
          ): Project.ResourceTreeElement | undefined => {
            for (const element of resourceTree) {
              if (element.name === name) {
                return element;
              }
              if (element.children) {
                const found = findResourceByName(element.children, name);
                if (found) {
                  return found;
                }
              }
            }
            return undefined;
          };
          const currentReosurce = findResourceByName(copyResourceTree, 'Fields');
          if (currentReosurce) {
            currentReosurce.children = fieldsResourceTree;
          }
          const keyList: string[] = [];
          if (table !== null) {
            table.fields.forEach((field) => {
              if (field.key) {
                keyList.push(field.fieldName);
              }
            });
          }
          const newMetadata: Project.DatabaseMetadata = {
            tableName: table.tableName,
            keys: keyList,
            connectionString: connString || connectionString,
          };
          setCurrentTable(table);
          onDocumentDataChange({
            ...documentData,
            metadata: newMetadata,
            resourceTree: copyResourceTree,
          });
        }
        setSelectedField(undefined);
      }, 3);
    },
    [documentData, onDocumentDataChange]
  );

  const tableTreeItems = useMemo(
    () =>
      currentTable
        ? [
            {
              title: '\\',
              id: currentTable.tableName,
              open: true,
              parentFound: false,
              found: false,
              hidden: false,
              editable: false,
              actions: [],
              children: currentTable.fields.map((field) => ({
                id: `fields.${field.fieldName}`,
                title: field.fieldName,
                icon: 'pi pi-file',
                open: false,
                parentFound: false,
                found: false,
                hidden: false,
                editable: true,
                selected: false,
                childFound: false,
                actions: [{ icon: 'pi pi-link', code: 'link' }],
              })),
            },
          ]
        : [],
    [currentTable]
  );

  const selectedDatabaseItem = useMemo(() => (selectedField ? documentData : undefined), [documentData, selectedField]);

  const notifyAction = (action: string, data: string, event: any) => {
    const dataObj = JSON.parse(data);

    switch (action) {
      case 'select':
        setSelectedField(dataObj.id);
        break;
      case 'link':
        const resourcePathArray = dataObj.id.split('.');
        setStartResourcePoint({
          documentId,
          path: resourcePathArray.map((part: string) => ({ idx: part })),
          pinType: Project.PinType.InOut,
        });
        linkOverlay.current.toggle(event);
        break;
    }
  };

  const handleItemChange = useCallback(
    (item: Project.DatabaseMetadata) => {
      if (selectedField) {
        onDocumentDataChange({
          ...documentData,
          metadata: item,
        });
      }
    },
    [documentData, onDocumentDataChange, selectedField]
  );

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDocumentDataChange({ ...documentData, resourceLinks });
  };

  const handleEventLinkEdit = (e: React.MouseEvent, name: string) => {
    setStartResourcePoint({
      documentId,
      path: [{ idx: 'events' }, { idx: name }],
      pinType: Project.PinType.InOut,
    });
    linkOverlay.current.toggle(e);
  };

  return (
    <>
      <div className="col-12">
        <Fieldset legend="Configuration" style={{ marginTop: '5px' }}>
          <div className="card flex justify-content-center gap-1">
            <InputText className="flex-1" value={visualConnectionString || ''} ref={inputRef} disabled={true} />
            <Button icon="pi pi-cog" onClick={() => setShowConnectionStringWizard(true)} />
            <Button
              icon="pi pi-refresh"
              disabled={connectionString === ''}
              onClick={(event) => refreshConnectionOverlay.current.show(event, this)}
            />

            <Dialog
              header="Connection string wizard"
              visible={showConnectionStringWizard}
              style={{ width: '50vw' }}
              onHide={() => setShowConnectionStringWizard((prevState) => !prevState)}
            >
              <StringConnectionForm connectionString={connectionString} onChange={handleStringGeneration} />
            </Dialog>
          </div>
        </Fieldset>
      </div>

      <div style={{ display: 'grid', height: '100%', gridTemplateColumns: '1fr 2fr', padding: '1rem', gap: '1em' }}>
        <div className="flex flex-column">
          <Fieldset legend="Tables" style={{ minHeight: '340px', opacity: !currentTable ? 0.5 : 1 }}>
            <div className="card flex flex-column">
              <div className="p-inputgroup ">
                <span className="p-inputgroup-addon">Table </span>
                <Dropdown
                  ref={dropdownRef}
                  value={currentTable}
                  onChange={(event) => {
                    const dropdownElement = dropdownRef.current.getElement();
                    if (dropdownElement) {
                      const dropdownLabelElement = dropdownElement.querySelector('.p-dropdown-label');
                      if (dropdownLabelElement) {
                        newTable = event.value;
                        changeTableOverlay.current.show(event.originalEvent, dropdownLabelElement);
                      }
                    }
                  }}
                  options={databaseInfos?.tables}
                  optionLabel="tableName"
                  placeholder="Select a table"
                  className="flex-1"
                />
              </div>

              <Treeview
                json={JSON.stringify(tableTreeItems)}
                selectedItems={[selectedField]}
                notifyAction={notifyAction}
              />
            </div>
          </Fieldset>

          <Fieldset legend="Events" style={{ marginTop: '10px', opacity: !currentTable ? 0.5 : 1 }}>
            <div className="flex-col">
              {Events.map((event, index) => (
                <div className="flex flex-row items-center gap-4" key={index}>
                  <div className="mt-3">{event}</div>
                  <Button
                    type="button"
                    icon="pi pi-link"
                    onClick={(e) => handleEventLinkEdit(e, event)}
                    text
                    aria-label="Resolution"
                  />
                </div>
              ))}
            </div>
          </Fieldset>
        </div>

        {!!selectedDatabaseItem && (
          <DatabaseItemEditor selectedField={selectedField} databaseItem={documentData} onChange={handleItemChange} />
        )}
      </div>

      <Toast ref={toast} />

      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          {startResourcePoint && (
            <TreeLink
              startResourcePoint={{ documentId: startResourcePoint.documentId, path: startResourcePoint.path }}
              startResourcePinType={startResourcePoint.pinType}
              resourceLinks={documentData.resourceLinks}
              onChangeData={handleLinkChange}
            />
          )}
        </AutoFocusDiv>
      </OverlayPanel>

      <OverlayPanel ref={changeTableOverlay}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">
              Cambiando tabella, verranno persi tutti i link dei field. Sei sicuro di voler procedere?
            </span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button label="No" onClick={() => changeTableOverlay.current.hide()} />
            <Button
              label="Si"
              onClick={() => {
                changeTableOverlay.current.hide();
                handleTableSelect(newTable);
              }}
            />
          </div>
        </div>
      </OverlayPanel>

      <OverlayPanel ref={refreshConnectionOverlay}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Sei sicuro di voler resettare tutte le informazioni?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button label="No" onClick={() => refreshConnectionOverlay.current.hide()} />
            <Button
              label="Si"
              onClick={() => {
                refreshConnectionOverlay.current.hide();
                syncDatabaseInfo(connectionString, true);
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

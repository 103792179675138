import '@silevis/reactgrid/styles.css';
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import './Deploy.css';
import { Button } from 'primereact/button';
import {
  Cell,
  CellTemplate,
  Column,
  Compatible,
  DefaultCellTypes,
  ReactGrid,
  Row,
  Uncertain,
} from '@silevis/reactgrid';
import { Panel, Project } from '@api';
import { PanelState, usePanelStore } from '@stores/panelStore';
import { noBorderCellStyle } from '@services/reactGridStyles';
import React, { useEffect, useState } from 'react';

interface IncludeButtonCell extends Cell {
  type: 'includeButtonCell';
  value: string;
}

type CustomCellTypes = DefaultCellTypes | IncludeButtonCell;

const columns: Column[] = [
  { columnId: 'Mac', width: 120 },
  { columnId: 'IPAddress', width: 120 },
  { columnId: 'DeviceId', width: 120 },
  { columnId: 'Include', width: 10 },
];

const headerRow: Row<CustomCellTypes> = {
  rowId: 'header',
  cells: [
    { type: 'header', text: 'Mac', style: noBorderCellStyle },
    { type: 'header', text: 'Ip Address', style: noBorderCellStyle },
    { type: 'header', text: 'DeviceId', style: noBorderCellStyle },
    { type: 'header', text: 'Include', style: noBorderCellStyle },
  ],
};

export const Cloud: React.FC<{
  projectInUse: Project.Project | null;
}> = ({ projectInUse }) => {
  const storePanelList = usePanelStore((state: PanelState) => state.panelList);
  const loadPanels = usePanelStore((state: PanelState) => state.loadPanels);
  const addPanelToProject = usePanelStore((state: PanelState) => state.addPanelToProject);

  const [rows, setRows] = useState<Row<CustomCellTypes>[]>([]);

  useEffect(() => {
    if (projectInUse !== null) {
      loadPanels(projectInUse.id).then((panels) => {
        const filteredList = panels.filter((panel: Panel) => panel.projectId === undefined);
        setRows(getRows(filteredList));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storePanelList.length > 0) {
      // update list
      const filteredList = storePanelList.filter((panel: Panel) => panel.projectId === undefined);
      setRows(getRows(filteredList));
    }
  }, [storePanelList]);

  const handleAddNewPanel = (panelId: string) => {
    addPanelToProject(projectInUse.id, { serial: panelId }, 'main');
  };

  const IncludeButtonCellTemplate: CellTemplate<Compatible<IncludeButtonCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<IncludeButtonCell>): Compatible<IncludeButtonCell> => {
      const cell = {
        ...uncertainCell,
        type: 'includeButtonCell',
        value: uncertainCell.value || '',
      };
      return cell as Compatible<IncludeButtonCell>;
    },
    render: (cell: Compatible<IncludeButtonCell>) => {
      return <Button style={{ color: 'white' }} icon="pi pi-plus" onClick={() => handleAddNewPanel(cell.value)} />;
    },
    isFocusable: () => false,
  };

  const myCellTemplates: Record<string, CellTemplate> = {
    includeButtonCell: IncludeButtonCellTemplate,
  };

  const getRows = (panels: Panel[]): Row<CustomCellTypes>[] => [
    headerRow,
    ...panels.map<Row<CustomCellTypes>>((panel, idx) => ({
      rowId: idx,
      height: 50,
      cells: [
        { type: 'text', text: panel.id, nonEditable: true, style: noBorderCellStyle },
        { type: 'text', text: panel.connUri, nonEditable: true, style: noBorderCellStyle },
        { type: 'text', text: panel.deviceLabel, nonEditable: true, style: noBorderCellStyle },
        { type: 'includeButtonCell', value: panel.id, nonEditable: true, style: noBorderCellStyle },
      ],
    })),
  ];

  return (
    <div className="card toolbar-container">
      <ReactGrid rows={rows} columns={columns} customCellTemplates={myCellTemplates} />
    </div>
  );
};

import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Project } from '@api';
import { findResourceByName } from '@services/utils';
import React, { FC, useCallback } from 'react';

type Props = {
  selectedField: string;
  databaseItem: Project.DocumentData;
  onChange: (item: Project.DatabaseMetadata) => void;
};

export const DatabaseItemEditor: FC<Props> = ({ selectedField, databaseItem, onChange }) => {
  const controlTableKey = useCallback((): boolean => {
    const field = selectedField.split('.')[1];
    return databaseItem.metadata?.keys.includes(field);
  }, [selectedField, databaseItem]);

  const getFieldType = useCallback((): string => {
    const field = selectedField.split('.')[1];
    const currResourceTreeElement = findResourceByName(databaseItem.resourceTree, field);
    if (currResourceTreeElement) return currResourceTreeElement.type;
    else return '';
  }, [selectedField, databaseItem]);

  return (
    <div className="card flex flex-column gap-3">
      <Fieldset legend="Field">
        <div className="p-inputgroup flex-1" style={{ marginBottom: '5px' }}>
          <span className="p-inputgroup-addon" style={{ minWidth: '73px' }}>
            Type{' '}
          </span>
          <InputText value={getFieldType()} disabled />
        </div>
        <div className="p-inputgroup">
          <span
            className="p-inputgroup-addon mr-4"
            style={{
              minWidth: '73px',
              borderRight: '1px solid #424b57',
              borderTopRightRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
          >
            Key
          </span>
          <Checkbox inputId="key" disabled checked={controlTableKey()} className="mt-2" />
        </div>
      </Fieldset>
    </div>
  );
};

import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class Image extends SimpleWidget {
  constructor(public domElement: HTMLElement) {
    super(domElement);
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-image');

    super.attach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'Image':
          this.domElement.textContent = `Id: ${data.id}, Name: ${data.name}`;
          this.domElement.style.backgroundImage = `url(https://foremanbrosinc.com/wp-content/uploads/2017/05/1c0d0f0cb8b7f2fb2685da9798efe42b_big-image-png-image-placeholder-clipart_2400-2400.png)`;
          break;
        default:
          this.setDefaultProperties(properties);
          break;
      }
    }
    super.setProperties(properties);
  }
}

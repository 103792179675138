/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import React from 'react';

interface OverlayComponentProps {
  customRef?: React.MutableRefObject<any>;
  title?: string;
  actions?: ButtonProps[];
  children?: any;
}

const OverlayComponent = ({ customRef, title, actions, children }: OverlayComponentProps) => {
  const actionButtons = actions?.map((action, index) => (
    <button key={index} onClick={action.onClick}>
      {action.label}
    </button>
  ));

  return (
    <OverlayPanel ref={customRef} showCloseIcon>
      {title ? (
        <div className="lineaSfondo">
          <h3>{title}</h3>
          {actionButtons}
        </div>
      ) : null}
      <div>{children}</div>
    </OverlayPanel>
  );
};

export default OverlayComponent;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { LeafProps, NodeProps, Treeview, TreeviewProps } from '@components/common/treeview';
import { LinkState, useLinkStore } from '@stores/linkStore';
import { ListBox } from 'primereact/listbox';
import { Project } from '@api';
import { deepEqual } from '@services/utils';
import { propertyData } from '@config/widget/widgetData';
import { useProjectStore } from '@stores/projectStore';
import React, { useEffect, useState } from 'react';
import ReactImages from '@components/common/images/ReactImages';

interface TreeLinkProps {
  startResourcePoint: Project.ResourceLinkPoint;
  startResourcePinType: Project.PinType;
  resourceLinks?: Project.ResourceLink[];
  onChangeData?: (data: Project.ResourceLink[]) => void;
}

interface localLink extends Project.ResourceLink {
  id: number;
}

export const TreeLink: React.FC<TreeLinkProps> = ({
  startResourcePoint,
  startResourcePinType,
  resourceLinks,
  onChangeData,
}) => {
  const [links, setLinks] = useState<localLink[]>([]);
  const [linkIndex, setLinkIndex] = useState(1);
  const [selectedLink, setSelectedLink] = useState<localLink | null>(null);
  const [cutterLinkList, setCutterLinkList] = useState<Project.ResourceLink[]>([]);

  const project = useProjectStore((state) => state.openedProjects[startResourcePoint.documentId.projectId]);
  const loadLinkResourceTreeFiltredByPinType = useLinkStore(
    (state: LinkState) => state.loadLinkResourceTreeFiltredByPinType
  );
  const getResourceTreeElementId = useLinkStore((state: LinkState) => state.getResourceTreeElementId);
  const [selectedItems, setSelectedItems] = useState(Array<string>());
  const [treeviewJson, setState] = useState(JSON.stringify(''));

  function _extractIdx(path: any[]): string {
    return path
      .map((step) => {
        if (Array.isArray(step.idx)) {
          // Se idx è un ResourcePath, chiamare la funzione ricorsivamente
          return _extractIdx(step.idx);
        }
        // Altrimenti, restituire il valore di idx come stringa
        return step.idx.toString();
      })
      .join('.'); // Unire i valori con '.'
  }

  function _getHintForProperty(element: Project.ResourceTreeElement, projectNode: Project.ProjectNode): string {
    let hint = '';

    const checkSingleResourceTeeeElement = (resource: Project.ResourceTreeElement, type: string): void => {
      if (resource.children) {
        if (resource.children.length > 0) {
          for (const child of resource.children) {
            if (hint === '') {
              checkSingleResourceTeeeElement(child, resource.data.type);
            }
          }
        }
      } else {
        if (resource.data.id === element.data.id) {
          const elementProperties = propertyData[resource.data.type || type]?.config.properties;
          if (elementProperties) {
            const property = elementProperties[element.name];
            if (property) {
              hint = property.caption;
            }
          }
        }
      }
    };

    if ('type' in projectNode) {
      switch (projectNode.type) {
        case 'page':
          const resourceTree: Project.ResourceTreeElement[] = projectNode.data.resourceTree;
          if (resourceTree && resourceTree.length > 0) {
            for (const resource of resourceTree) {
              if (hint === '') {
                checkSingleResourceTeeeElement(resource, projectNode.type);
              }
            }
          }
          break;
        default:
          break;
      }
    }

    return hint;
  }

  function _getDataForProperty(element: Project.ResourceTreeElement, projectNode: Project.ProjectNode): any {
    const data: {
      linkPoint: { documentId: Project.ProjectNodeKey; path: { idx: string }[] };
    } = null;

    // id of widget's property: widgetId_property
    // id of page's property: pageId_property
    let id = element.data.id;
    if (element.data.id.indexOf('-') >= 0) id = element.data.id.split('_')[0];

    if ('type' in projectNode) {
      const resourceTree: Project.ResourceTreeElement[] = projectNode.data.resourceTree;
      if (resourceTree && resourceTree.length > 0) {
        for (const resource of resourceTree) {
          const foundResource = findElementInTree(resource, id, element.name);
          if (foundResource) {
            return {
              linkPoint: {
                documentId: { projectId: project.id, domainId: projectNode.nodeId.domainId },
                path: foundResource.path,
              },
            };
          }
        }
      }
    }

    return data;
  }

  // Funzione ricorsiva per cercare l'elemento in tutti i children
  function findElementInTree(
    resource: Project.ResourceTreeElement,
    id: string,
    elementName: string
  ): { path: { idx: string }[] } | null {
    if (resource.data.id === id) {
      const pathArray: { idx: string }[] = [];
      if (elementName !== resource.name) {
        pathArray.push({ idx: resource.name });
      }
      pathArray.push({ idx: elementName });
      return { path: pathArray };
    }

    // Se l'elemento non viene trovato, continua la ricerca nei children
    if (resource.children && resource.children.length > 0) {
      for (const child of resource.children) {
        const foundInChild = findElementInTree(child, id, elementName);
        if (foundInChild) {
          const pathArray = [{ idx: resource.name }, ...foundInChild.path];
          return { path: pathArray };
        }
      }
    }

    return null;
  }

  function _convertResourceTreeElementToTreeNode(
    resourceElement: Project.ResourceTreeElement,
    projectNode: Project.ProjectNode
  ): NodeProps | LeafProps | null {
    if ('children' in resourceElement) {
      const children = resourceElement.children
        .filter((child: Project.ResourceTreeElement) => child)
        .map((child) => _convertResourceTreeElementToTreeNode(child, projectNode))
        .filter((child): child is LeafProps | NodeProps => child !== null) as Array<LeafProps | NodeProps>;

      if (children.length === 0) {
        return null;
      }

      return {
        title: resourceElement.name,
        id: resourceElement.data.id,
        found: false,
        hidden: false,
        icon: 'pi pi-folder',
        open: false,
        forceOpen: true,
        editable: false,
        parentFound: false,
        children: children,
      };
    } else {
      const data = _getDataForProperty(resourceElement, projectNode);

      // not add start property
      if (data && data.linkPoint.documentId === startResourcePoint.documentId.domainId) {
        if (deepEqual(data.linkPoint.path, startResourcePoint.path)) {
          return null;
        }
      }

      return {
        id: 'link_' + resourceElement.data.id,
        title: resourceElement.name,
        hint: _getHintForProperty(resourceElement, projectNode),
        data,
        found: false,
        selected: false,
        childFound: false,
        editable: false,
        hidden: false,
        icon: 'pi pi-tag',
      };
    }
  }

  function _convertProjectNodeToTreeNode(projectNode: Project.Document | Project.Folder): NodeProps | LeafProps {
    if ('children' in projectNode) {
      // Tratta come Folder
      return {
        title: projectNode.name,
        id: projectNode.nodeId.domainId,
        found: false,
        hidden: false,
        icon: 'pi pi-folder',
        open: false,
        forceOpen: true,
        editable: false,
        parentFound: false,

        children: projectNode.children.filter((child: unknown) => child).map(_convertProjectNodeToTreeNode) as Array<
          LeafProps | NodeProps
        >,
      };
    } else {
      // Tratta come Document
      return {
        id: projectNode.nodeId.domainId,
        title: projectNode.name,
        found: false,
        selected: false,
        childFound: false,
        editable: false,
        hidden: false,
        icon: 'pi pi-book',

        children: projectNode.data.resourceTree
          ? (projectNode.data.resourceTree
              .filter((child: Project.ResourceTreeElement) => child)
              .map((child) => _convertResourceTreeElementToTreeNode(child, projectNode))
              .filter((child): child is LeafProps | NodeProps => child !== null) as Array<LeafProps | NodeProps>)
          : [],
      };
    }
  }

  function _onChangeDataToReturn(newList: localLink[]): void {
    // update the list of the decument
    const newDocumentListToReturn: Project.ResourceLink[] = [
      ...cutterLinkList,
      ...newList.map((link) => ({
        source: link.source,
        dest: link.dest,
        isInOut: link.isInOut,
      })),
    ];

    // call the callback
    onChangeData(newDocumentListToReturn);
  }

  function _createChildrenForLinkResourceTree(tree: Project.ProjectStructure): (NodeProps | LeafProps)[] {
    const newList: (NodeProps | LeafProps)[] = [];

    if (tree) {
      for (const key of Object.keys(tree)) {
        const newItem = {
          title: key.toUpperCase(),
          id: key,
          found: false,
          hidden: false,
          editable: false,
          icon: 'pi pi-sitemap',
          open: false,
          forceOpen: true,
          parentFound: false,
          children: tree[key].filter((child: unknown) => child).map(_convertProjectNodeToTreeNode) as Array<
            LeafProps | NodeProps
          >,
        };

        newList.push(newItem);
      }
    }

    return newList;
  }

  useEffect(() => {
    // update link list
    if (resourceLinks === undefined) setLinks([]);
    else {
      const newLinkList: localLink[] = [];
      let remainingResourceLinks = [...resourceLinks]; // Crea una copia di resourceLinks

      let localLinkIndex = linkIndex;

      resourceLinks.forEach((link) => {
        if (deepEqual(link.dest, startResourcePoint) || deepEqual(link.source, startResourcePoint)) {
          // create a new link
          const newLink: localLink = {
            source: link.source,
            dest: link.dest,
            isInOut: link.isInOut,
            id: localLinkIndex,
          };
          // add to list
          newLinkList.push(newLink);

          // Rimuovi il link dalla lista remainingResourceLinks
          remainingResourceLinks = remainingResourceLinks.filter((remainingLink) => remainingLink !== link);

          // Increase index
          localLinkIndex += 1;
        }
      });

      // save new index
      setLinkIndex(localLinkIndex);

      // update list
      setLinks(newLinkList);

      // Aggiorna cutterLinkList con la lista rimanente di resourceLinks
      setCutterLinkList(remainingResourceLinks);
    }

    // load filtered resource tree
    if (project) {
      loadLinkResourceTreeFiltredByPinType(startResourcePinType, project.structure).then((tree) => {
        // create treeviewObject
        const treeviewObj: NodeProps[] = [
          {
            title: project.description,
            id: project.id,
            open: true,
            parentFound: false,
            found: false,
            hidden: false,
            editable: false,
            icon: undefined,

            children: _createChildrenForLinkResourceTree(tree),
          },
        ];

        //setTreeJSON(treeviewObj);
        setState(JSON.stringify(treeviewObj));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceLinks, startResourcePoint]);

  async function notifyAction(action: string, data: string) {
    const dt = data ? JSON.parse(data) : {};
    switch (action) {
      case 'select':
        if (dt.hasOwnProperty('id')) {
          setSelectedItems([dt.id]);

          // update selectedLink
          const updatedSelectedLink = { ...selectedLink }; // Create a copy of selectedLink

          switch (startResourcePinType) {
            case Project.PinType.In:
              updatedSelectedLink.dest = startResourcePoint;
              updatedSelectedLink.isInOut = false;
              updatedSelectedLink.source = dt.data.linkPoint;
              break;
            case Project.PinType.Out:
              updatedSelectedLink.dest = dt.data.linkPoint;
              updatedSelectedLink.isInOut = false;
              updatedSelectedLink.source = startResourcePoint;
              break;
            case Project.PinType.InOut:
              updatedSelectedLink.dest = startResourcePoint;
              updatedSelectedLink.isInOut = true;
              updatedSelectedLink.source = dt.data.linkPoint;
              break;
            default:
              // nothing to do
              break;
          }

          // Find the index of the element to replace
          const indexToUpdate = links.findIndex((link) => link.id === updatedSelectedLink.id);

          // Create a new array with the updated link
          const newLinks = [...links];
          if (indexToUpdate !== -1) {
            newLinks[indexToUpdate] = updatedSelectedLink;
          }

          // Update the state with the new list
          setLinks(newLinks);
          setSelectedLink(updatedSelectedLink);

          // event
          _onChangeDataToReturn(newLinks);
        }
        break;
    }
  }

  const pr: TreeviewProps = { json: treeviewJson, selectedItems: selectedItems, notifyAction: notifyAction };

  const header = _extractIdx(startResourcePoint.path);

  /* const listButtonStyle = {
    marginTop: '5px',
    padding: 2,
    width: '48px',
    height: '46px',
    alignItems: 'center',
    justifyContent: 'center',
  }; */

  const addLink = () => {
    const newLink: localLink = {
      source: undefined,
      dest: undefined,
      isInOut: false,
      id: linkIndex,
    };

    const newLinks = [...links];
    newLinks.push(newLink);

    // Increase index
    setLinkIndex((prevIndex) => prevIndex + 1);

    // Add the new link to the list
    setLinks(newLinks);

    selectLinkItem(newLink);
  };

  const selectLinkItem = (item: localLink) => {
    // select the current link
    setSelectedLink(item);

    if (item !== null) {
      // change the tree selection
      let elementToSearch = null;
      switch (startResourcePinType) {
        case Project.PinType.In:
          elementToSearch = item.source;
          break;
        case Project.PinType.Out:
          elementToSearch = item.dest;
          break;
        case Project.PinType.InOut:
          // check if the startResourcePin is the source or the destination and use the other
          if (deepEqual(startResourcePoint, item.source)) {
            elementToSearch = item.dest;
          } else {
            elementToSearch = item.source;
          }
          break;
        default:
          // nothing to do
          break;
      }

      if (elementToSearch !== undefined) {
        getResourceTreeElementId(elementToSearch).then((id) => {
          if (id !== '') {
            setSelectedItems(['link_' + id]);
          }
        });
      }
    }
  };

  const deleteLink = (item: localLink) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Prevent the default behavior of the button which might cause form submission
    e.preventDefault();
    e.stopPropagation();

    // Filter out the deleted item from the links list
    const updatedLinks = links.filter((link) => link.id !== item.id);

    // Update the state with the updated links list
    setLinks(updatedLinks);

    // event
    _onChangeDataToReturn(updatedLinks);
  };

  const linkTemplate = (item: localLink) => {
    // in/out icon
    let linkImage = null;
    if (item.dest !== undefined && item.source !== undefined) {
      if (item.isInOut) linkImage = ReactImages.getReactImage('arrowRightLeft');
      else {
        if (startResourcePinType === Project.PinType.In) linkImage = ReactImages.getReactImage('arrowLeft');
        else linkImage = ReactImages.getReactImage('arrowRight');
      }
    }

    return (
      <div className="flex flex-wrap align-items-center gap-3" style={{ display: 'flex', alignItems: 'center' }}>
        {/* Image in/out */}
        {linkImage}
        <div style={{ flexGrow: 1 }}></div>
        {/* Delete */}
        <Button
          size="small"
          style={{ marginLeft: 'auto', backgroundColor: '#f87171', borderColor: '#f87171', color: 'white' }}
          onClick={deleteLink(item)}
        >
          {ReactImages.getReactImage('delete')}
        </Button>
      </div>
    );
  };

  /* const moveUp = (index: number) => {
    if (index > 0) {
      const newLinks = [...links];
      [newLinks[index - 1], newLinks[index]] = [newLinks[index], newLinks[index - 1]];
      setLinks(newLinks);

      // event
      _onChangeDataToReturn(newLinks)
    }
  };

  const moveDown = (index: number) => {
    if (index < links.length - 1) {
      const newLinks = [...links];
      [newLinks[index], newLinks[index + 1]] = [newLinks[index + 1], newLinks[index]];
      setLinks(newLinks);

      // event
      _onChangeDataToReturn(newLinks)
    }
  };

  const moveToFirst = (index: number) => {
    if (index > 0) {
      const newLinks = [...links];
      const [item] = newLinks.splice(index, 1);
      newLinks.unshift(item);
      setLinks(newLinks);

      // event
      _onChangeDataToReturn(newLinks)
    }
  };

  const moveToLast = (index: number) => {
    if (index < links.length - 1) {
      const newLinks = [...links];
      const [item] = newLinks.splice(index, 1);
      newLinks.push(item);
      setLinks(newLinks);

      // event
      _onChangeDataToReturn(newLinks)
    }
  };

  const getSelectedIndex = () => {
    return selectedLink ? links.indexOf(selectedLink) : -1;
  }; */

  return (
    <div>
      <div
        style={{
          position: 'relative',
          width: 'auto',
          zIndex: 1,
          backgroundColor: 'transparent',
          boxShadow: '0 2px 15px rgba(0,0,0,0.3)',
          borderRadius: '3px',
        }}
      >
        <div style={{ padding: '20px', textAlign: 'center' }}>
          {/* Header */}
          <div style={{ marginBottom: '20px' }}>
            <label style={{ fontWeight: 'bold' }}>{header}</label>
          </div>
          <div className="flex" style={{ maxHeight: '350px' }}>
            {/* Links list */}
            <div style={{ display: 'flex' }}>
              {/* Vertical buttons */}
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* Add button */}
                <Button
                  style={{
                    marginBottom: '15px',
                    padding: 2,
                    width: '30px',
                    height: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={addLink}
                >
                  {ReactImages.getReactImage('add')}
                </Button>
                {/* Move first button */}
                {/* <Button
                  style={listButtonStyle}
                  disabled={getSelectedIndex() < 0}
                  onClick={() => moveToFirst(getSelectedIndex())}>
                    {ReactImages.getReactImage('doubleArrowUp')}
                </Button> */}
                {/* Move up button */}
                {/* <Button
                  style={listButtonStyle}
                  disabled={getSelectedIndex() < 0}
                  onClick={() => moveUp(getSelectedIndex())}>
                    {ReactImages.getReactImage('arrowUp')}
                </Button> */}
                {/* Move down button */}
                {/* <Button
                  style={listButtonStyle}
                  disabled={getSelectedIndex() < 0}
                  onClick={() => moveDown(getSelectedIndex())}>
                    {ReactImages.getReactImage('arrowDown')}
                </Button> */}
                {/* Move last button */}
                {/* <Button
                  style={listButtonStyle}
                  disabled={getSelectedIndex() < 0}
                  onClick={() => moveToLast(getSelectedIndex())}>
                    {ReactImages.getReactImage('doubleArrowDown')}
                </Button> */}
              </div>
              {/* Lista */}
              <ListBox
                value={selectedLink}
                onChange={(e) => selectLinkItem(e.value)}
                options={links}
                optionLabel="id"
                itemTemplate={linkTemplate}
                className="w-full md:w-14rem"
                style={{ height: '350px', minWidth: '100px', marginLeft: '40px' }}
              />
            </div>
            <Divider layout="vertical"></Divider>
            <div style={{ maxWidth: '480px' }}>{selectedLink !== null && <Treeview {...pr} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { Project } from '@api';
import { deepCopy, moveNodeInternalTree } from '@services/utils';
import { useCallback } from 'react';

export function getPathReference(
  objects: Project.ResourceTreeElement[],
  path: string[]
): Project.ResourceTreeElement | null {
  const [currentPath, ...remainingPath] = path;
  const object = objects.find(({ data }) => data.id === currentPath);
  if (remainingPath.length === 0 || !object) {
    return object;
  } else {
    return object.children ? getPathReference(object.children, remainingPath) : null;
  }
}

type Props = {
  documentData: Project.DocumentData;
  onDocumentDataChange: (document: Project.DocumentData) => void;
  documentId: string;
};

let idTagIndex = 0;

export const useTagDocumentDataUtils = ({ documentData: _documentData, onDocumentDataChange, documentId }: Props) => {
  const searchNewTagIndex = (resourceTree: Project.ResourceTreeElement[]) => {
    for (const element of resourceTree) {
      if (element.data.id.split('-')[0].substr(3) > idTagIndex) {
        idTagIndex = parseInt(element.data.id.split('-')[0].substr(3));
      }
      if (element.children) {
        searchNewTagIndex(element.children);
      }
    }
  };
  if (_documentData.resourceTree) searchNewTagIndex(_documentData.resourceTree);

  const createItem = useCallback(
    (parent: string, itemType: string) => {
      const documentData = deepCopy(_documentData);

      let id = '';
      let name = '';
      let type = Project.TagType.Numeric;

      switch (itemType) {
        case 'tag':
          id = `tag${idTagIndex + 1}-${documentId}`;
          name = 'newTag';
          break;
        case 'structure':
          id = `stct${idTagIndex + 1}-${documentId}`;
          name = 'newStructure';
          type = Project.TagType.Struct;
          break;
      }
      idTagIndex++;

      const newItem: Project.ResourceTreeElement = {
        name: name,
        data: { id },
        type: type,
      };

      // add children to the structure or pinType for the tag
      if (type === Project.TagType.Struct) {
        newItem.children = [];
      } else {
        newItem.pinType = Project.PinType.InOut;
      }

      if (parent === 'root') {
        documentData.resourceTree.push(newItem);
      } else {
        const parentRef = getPathReference(documentData.resourceTree, parent.split('.'));
        if (!parentRef.children) {
          parentRef.children = [];
        }

        parentRef.children.push(newItem);
      }

      onDocumentDataChange(documentData);

      return id;
    },
    [_documentData, documentId, onDocumentDataChange]
  );

  const removeItem = useCallback(
    (tagIdToDelete: string) => {
      const documentData = deepCopy(_documentData);
      const tagPath = tagIdToDelete.split('.');
      const leafTagPath = tagPath.pop();

      if (tagPath.length < 1) {
        documentData.resourceTree = documentData.resourceTree.filter((item) => item.data.id !== tagIdToDelete);
      } else {
        const parentRef = getPathReference(documentData.resourceTree, tagPath);
        if (parentRef) {
          parentRef.children = parentRef.children.filter((item) => item.data.id !== leafTagPath);
        }
      }

      onDocumentDataChange(documentData);
    },
    [_documentData, onDocumentDataChange]
  );

  const renameItem = useCallback(
    (tagIdToRename: string, newName: string) => {
      const documentData = deepCopy(_documentData);

      const tagPath = tagIdToRename.split('.');

      if (tagPath.length < 1) {
        documentData.resourceTree = documentData.resourceTree.map((item: Project.ResourceTreeElement) => {
          if (item.data.id === tagIdToRename) {
            return {
              ...item,
              name: newName,
            };
          } else {
            return item;
          }
        });
      } else {
        const itemRef = getPathReference(documentData.resourceTree, tagPath);
        if (itemRef) {
          itemRef.name = newName;
        }
      }

      onDocumentDataChange(documentData);
    },
    [_documentData, onDocumentDataChange]
  );

  const moveItem = useCallback(
    (nodeId: string, destinationId: string) => {
      const newDocDataResources = moveNodeInternalTree(_documentData, nodeId, destinationId);

      const newDocData = { ..._documentData, resourceTree: newDocDataResources };
      onDocumentDataChange(newDocData);
    },
    [_documentData, onDocumentDataChange]
  );

  return {
    createItem,
    removeItem,
    renameItem,
    moveItem,
  };
};

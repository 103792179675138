/** widget all'interno della toolbar */
export interface ToolbarElement {
  /** identificatore del tipo */
  type: string;

  /** Icona visualizzata */
  icon: string;

  /** Testo vislualizzato sotto l'icona */
  caption: string;
}

/** Sezione all'interno della toolbar dei widget */
export interface ToolbarSection {
  /** Testo visualizzato */
  text: string;

  /** Lista dei widget */
  list: ToolbarElement[];
}

export interface ToolbarConfig {
  caption: string;
  definition: ToolbarSection[];
}

export const widgetToolbar: ToolbarConfig = {
  caption: 'Widgets',
  definition: [
    {
      text: 'VIEW',
      list: [
        {
          type: 'view',
          caption: 'View',
          icon: 'viewIcon',
        },
      ],
    },
    {
      text: 'BUTTON',
      list: [
        {
          type: 'button',
          caption: 'Button',
          icon: 'buttonIcon',
        },
      ],
    },
    {
      text: 'TEXT',
      list: [
        {
          type: 'textBox',
          caption: 'TextBox',
          icon: 'textBoxIcon',
        },
        {
          type: 'label',
          caption: 'Label',
          icon: 'labelIcon',
        },
      ],
    },
    {
      text: 'SELECTION',
      list: [
        {
          type: 'checkbox',
          caption: 'CheckBox',
          icon: 'checkboxIcon',
        },
        {
          type: 'radio',
          caption: 'RadioButton',
          icon: 'radioIcon',
        },
      ],
    },
    {
      text: 'MEDIA',
      list: [
        {
          type: 'frameHtml',
          caption: 'FrameHTML',
          icon: 'frameHtmlIcon',
        },
        {
          type: 'image',
          caption: 'Image',
          icon: 'imageIcon',
        },
        {
          type: 'imageList',
          caption: 'ImageList',
          icon: 'imageListIcon',
        },
      ],
    },
    {
      text: 'LIST',
      list: [
        {
          type: 'listBox',
          caption: 'ListBox',
          icon: 'listBoxIcon',
        },
        {
          type: 'accordion',
          caption: 'Accordion',
          icon: 'accordionIcon',
        },
        {
          type: 'tabs',
          caption: 'Tabs',
          icon: 'tabsIcon',
        },
      ],
    },
    {
      text: 'VIDEO',
      list: [
        {
          type: 'videoPlayer',
          caption: 'VideoPlayer',
          icon: 'videoPlayerIcon',
        },
      ],
    },
    {
      text: 'SLIDER',
      list: [
        {
          type: 'slider',
          caption: 'Slider',
          icon: 'sliderIcon',
        },
      ],
    },
    {
      text: 'SVG',
      list: [
        {
          type: 'svg',
          caption: 'Svg',
          icon: 'svgIcon',
        },
        {
          type: 'svgText',
          caption: 'SvgText',
          icon: 'svgTextIcon',
        },
        {
          type: 'svgRect',
          caption: 'SvgRect',
          icon: 'svgRectIcon',
        },
        {
          type: 'svgExternal',
          caption: 'SvgExternal',
          icon: 'svgExternalIcon',
        },
      ],
    },
  ],
};

export const flowToolbar: ToolbarConfig = {
  caption: 'Blocks',
  definition: [
    {
      text: 'SECTION1',
      list: [
        {
          type: 'baseNode',
          caption: 'blocco delay',
          icon: 'graphFlowIcon',
        },
      ],
    },
    {
      text: 'SECTION2',
      list: [
        {
          type: 'ifCondition',
          caption: 'blocco If Then Else',
          icon: 'graphFlowIcon',
        },
      ],
    },
  ],
};

import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class ListBox extends SimpleWidget {
  constructor(public domElement: HTMLElement) {
    super(domElement);
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-listbox');
    super.attach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = properties[key];

      switch (key) {
        case 'Items':
          this.domElement.innerHTML = '';
          const items: string[] = data.Items;
          items.forEach((item: string) => {
            const itemDiv = document.createElement('div');
            itemDiv.textContent = item;
            this.domElement.appendChild(itemDiv);
          });
          break;

        case 'ListIsMultiline':
          if (data) {
            this.domElement.style.whiteSpace = 'normal';
            this.domElement.style.wordBreak = 'break-word';
          } else {
            this.domElement.style.whiteSpace = 'nowrap';
            this.domElement.style.wordBreak = 'normal';
          }
          this.domElement.style.overflowY = 'auto';
          break;

        default:
          if (key.includes('Font')) {
            this.setFontProperties(properties, this.domElement, 'List');
          } else {
            this.setDefaultProperties(properties);
          }
      }
    }
    super.setProperties(properties);
  }
}

import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';
export class Slider extends SimpleWidget {
  inputElement: HTMLInputElement;
  minLabel: HTMLSpanElement;
  maxLabel: HTMLSpanElement;
  valueLabel: HTMLSpanElement;
  onValueChange?: (value: number) => void;
  isInteger = false;
  isVertical = false;
  barColor = '#424b57';
  barThickness = '5px';
  thumbBorderColor = '#424b57';
  thumbBackgroundColor = '#2dd4bf';
  thumbSize = '10px';

  constructor(public domElement: HTMLElement) {
    super(domElement);
    this.inputElement = document.createElement('input');
    this.inputElement.type = 'range';
    this.inputElement.className = 'slider-input';
    this.minLabel = document.createElement('span');
    this.maxLabel = document.createElement('span');
    this.valueLabel = document.createElement('span');
    this.minLabel.className = 'slider-min-label';
    this.maxLabel.className = 'slider-max-label';
    this.valueLabel.className = 'slider-value-label';
    this.setupEvents();
  }

  attach(): void {
    this.domElement.classList.add('fast-slider');
    this.domElement.appendChild(this.minLabel);
    this.domElement.appendChild(this.inputElement);
    this.domElement.appendChild(this.maxLabel);
    this.domElement.appendChild(this.valueLabel);
    this.applyCustomStyles();
    super.attach();
  }

  detach() {
    super.detach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'MinValue':
          this.inputElement.min = data.toString();
          this.minLabel.textContent = `Min: ${data}`;
          break;
        case 'MaxValue':
          this.inputElement.max = data.toString();
          this.maxLabel.textContent = `Max: ${data}`;
          break;
        case 'Value':
          this.inputElement.value = data.toString();
          this.valueLabel.textContent = `Value: ${data}`;
          break;
        case 'Step':
          this.inputElement.step = data.toString();
          break;
        case 'IntOrFloat':
          this.isInteger = data === 'int';
          break;
        case 'BarColor':
          this.barColor = data;
          this.updateStyles();
          break;
        case 'BarThickness':
          this.barThickness = data;
          this.updateStyles();
          break;
        case 'ThumbBorderColor':
          this.thumbBorderColor = data;
          this.updateStyles();
          break;
        case 'ThumbBackgroundColor':
          this.thumbBackgroundColor = data;
          this.updateStyles();
          break;
        case 'ThumbSize':
          this.thumbSize = data;
          this.updateStyles();
          break;
        case 'Vertical':
          this.isVertical = data === true;
          this.updateStyles();
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }

    this.valueLabel.textContent = `Value: ${this.inputElement.value}`;
    super.setProperties(properties);
  }

  private setupEvents(): void {
    this.inputElement.addEventListener('input', (event) => {
      let value = Number(this.inputElement.value);
      if (this.isInteger) {
        value = Math.round(value);
      }
      if (this.onValueChange) {
        this.onValueChange(value);
      }
      this.valueLabel.textContent = `Value: ${value}`;
    });
    this.applyCustomStyles();
  }

  private updateStyles(): void {
    const orientationStyles = this.isVertical
      ? `
      .slider-input {
        transform: rotate(270deg);
        width: auto;
        height: ${this.barThickness}px;
        margin: 40px 0px;
        writing-mode: bt-lr;
      }
      .fast-slider {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 20px;
        height: auto;
        justify-content: space-between;
      }
      .slider-min-label, .slider-max-label {
        display: block;
      }
      `
      : '';

    const sliderStyle = `
      .slider-input {
        -webkit-appearance: none;
        appearance: none;
        ${this.isVertical ? 'height: auto;' : `height: ${this.barThickness}px;`}
        background: ${this.barColor};
        border-radius: 6px;
        outline: none;
      }
      .slider-input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: ${this.thumbSize}px;
        height: ${this.thumbSize}px;
        background-color: ${this.thumbBackgroundColor};
        border-radius: 50%;
        border: 2px solid ${this.thumbBorderColor};
      }
      .slider-input::-moz-range-thumb {
        width: ${this.thumbSize}px;
        height: ${this.thumbSize}px;
        background-color: ${this.thumbBackgroundColor};
        border-radius: 50%;
        border: 2px solid ${this.thumbBorderColor};
      }
      ${orientationStyles}
    `;

    let styleElement = document.getElementById('slider-custom-styles') as HTMLStyleElement;
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = 'slider-custom-styles';
      document.head.appendChild(styleElement);
    }
    styleElement.textContent = sliderStyle;
  }

  private applyCustomStyles(): void {
    this.updateStyles();
    const labelsStyle = `
      .slider-min-label, .slider-max-label, .slider-value-label {
        font-size: 14px;
        margin: 0 10px;
        color: #333;
        display: inline-block;
      }
      .slider-value-label {
        display: block;
        margin-top: 5px;
        font-weight: bold;
        text-align: center;
      }
    `;

    const labelStyleElement = document.createElement('style');
    labelStyleElement.textContent = labelsStyle;
    document.head.appendChild(labelStyleElement);
  }

  public setOnValueChange(callback: (value: number) => void): void {
    this.onValueChange = callback;
  }
}

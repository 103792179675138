import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';

interface AutoFocusDivProps {
  children: ReactNode;
  style?: CSSProperties;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
}

const AutoFocusDiv: React.FC<AutoFocusDivProps> = ({ children, style, onBlur }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.tabIndex = -1;
      divRef.current.focus();
    }
  }, []);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const relatedTarget = event.relatedTarget as Node;
    // Verifica se l'elemento che sta ricevendo il focus è un discendente del div
    if (relatedTarget && relatedTarget.parentElement.isSameNode(document.body)) {
      onBlur?.(event); // Chiama onBlur solo se il focus si sposta fuori del div
    }
  };
  return (
    <div ref={divRef} style={style} onBlur={handleBlur}>
      {children}
    </div>
  );
};

export default AutoFocusDiv;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  EdgeChange,
  MiniMap,
  NodeChange,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from '@xyflow/react';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { DropData, WidgetDragHandler, WidgetPropertyHandler, useWidgetPropsStore } from '@stores/widgetPropsStore';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useFlowDocumentDataUtils } from './useFlowDocumentDataUtils';
import CustomNode from './nodes/CustomNode';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import '@xyflow/react/dist/style.css';
import { Project } from '@api';
import { PropertiesConfig } from '@config/widget/interface';
import { ToolbarSection } from '@config/toolbar';
import { TreeLink } from '@components/tree-link/TreeLink';
import { WidgetProperties } from '@config/widget/widgetData';
import { blockData } from '@config/blockData';
import { deepCopy, deepEqual, findResourceById } from '@services/utils';
import { useToolbarStore } from '@stores/toolbarStore';
import AutoFocusDiv from '@components/common/AutoFocusDiv';

const nodeTypes = { CustomNode };

export interface NodeProperties {
  [key: string]: any;
}

export interface FlowProperties extends NodeProperties {
  type: string;
  id: string;
  children: FlowProperties[];
}

class DragHandler implements WidgetDragHandler {
  private dropData: DropData | null = null;

  startDrag(dropData: DropData): void {
    this.dropData = dropData;
  }

  getDropData(): DropData | null {
    return this.dropData;
  }
}

class PropertyHandler implements WidgetPropertyHandler {
  private editWidgetProperties: (node: FlowProperties, parentNode: FlowProperties, widgetName: string) => void;
  private documentData: Project.DocumentData;

  constructor(documentData: Project.DocumentData, private renameNode: any, private updateProperty: any) {
    this.documentData = documentData;
  }

  private findNode(id: string): [FlowProperties, FlowProperties | null, number] {
    if (this.documentData.internalMetadata && this.documentData.internalMetadata.nodes) {
      const nodeKeys = Object.keys(this.documentData.internalMetadata.nodes);
      if (nodeKeys.length > 0) {
        for (const key of nodeKeys) {
          if (key === id) {
            const originalNode = this.documentData.internalMetadata.nodes[id];

            const modifiedNode = {
              ...originalNode,
              type: originalNode.configType,
            };

            return [modifiedNode, originalNode, 0];
          }
        }
      }
    }

    return [null, null, -1];
  }

  private checkNameProperty(nodeId: string, data: string): string {
    const regex = /^[A-Za-z][A-Za-z0-9_]*$/;
    if (!regex.test(data)) {
      return "Formato del campo 'Name' non valido";
    }

    const ele = this.documentData.resourceTree.find((value) => value.name === data);
    if (ele && ele.data.id !== nodeId) {
      return "Questo nome e' gia' stato usato";
    }

    return null;
  }

  public updateDocumentData = (documentData: Project.DocumentData) => {
    this.documentData = documentData;
  };

  public manageWidgetEditRequest(id: string) {
    const [element, parent, _] = this.findNode(id);
    if (element && this.editWidgetProperties) {
      const ele = this.documentData.resourceTree.find((ele) => ele.data.id === id);
      this.editWidgetProperties(element, parent, ele?.name);
    }
  }

  onEditWidgetProperties(event: (node: FlowProperties, parentData: FlowProperties, widgetName: string) => void) {
    this.editWidgetProperties = event;
  }

  setProperty(nodeId: string, sectionId: string, fieldKey: string, value: any) {
    if (fieldKey === 'Name') {
      this.renameNode(nodeId, value);
    } else {
      this.updateProperty(nodeId, { [fieldKey]: value });
    }
  }

  checkProperty(nodeId: string, fieldKey: string, value: any): string {
    if (fieldKey === 'Name') {
      return this.checkNameProperty(nodeId, value);
    } else {
      return null;
    }
  }

  updateResourceLinks(links: Project.ResourceLink[]): void {}

  getPropertiesData(type: string, id: string): PropertiesConfig {
    // config properties. default properties
    const properties: PropertiesConfig = deepCopy(blockData[type].config);

    const currNode = findResourceById(this.documentData.resourceTree, id);
    if (currNode && currNode.children && currNode.children.length === 3) {
      const props = currNode.children[0].children;
      const ins = currNode.children[1].children;
      const outs = currNode.children[2].children;

      const getPropName = (key: string) => {
        const list = props.filter((prop) => (prop.data.id as string).split('-')[2] === key);
        if (list) return list[0].name;
      };

      const getPropNameIndex = (propName: string) => {
        const result = propName.match(/\d+/);
        return result ? parseInt(result[0], 10) : null;
      };

      // add dynamic properties for input and output
      const addDynamicProperties = (
        type: string,
        dynProps: Project.ResourceTreeElement[],
        configProps: WidgetProperties
      ) => {
        dynProps?.forEach((prop) => {
          if ((prop.data.id as string).includes('Dynamic') && configProps) {
            let dataKey = type === 'input' ? 'in' : 'out';
            dataKey += (prop.data.id as string).split('-')[2];
            const in_index = getPropNameIndex(getPropName(dataKey));
            Object.keys(configProps).forEach((key) => {
              const propertyCopy = { ...configProps[key] };
              propertyCopy.caption += `${in_index}`;
              properties.properties[key + in_index] = propertyCopy;
            });
          }
        });
      };

      addDynamicProperties('input', ins, blockData[type].inputProperties);
      addDynamicProperties('output', outs, blockData[type].outputProperties);
    }

    return properties;
  }
}

export const FlowDocumentEditor: DocumentEditorComponent = ({
  data: documentData,
  onDataChange: onDocumentDataChange,
  documentId,
}) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);

  const {
    createNode,
    removeNode,
    moveNode,
    renameNode,
    updateProperty,
    createHandlesForNode,
    getNodeHandles,
    isDynamicHandle,
    removeHandleFromNode,
    createEdge,
    removeEdge,
  } = useFlowDocumentDataUtils({
    documentData,
    onDocumentDataChange,
    documentId: documentId.domainId,
  });

  const localDocumentData = useRef<Project.DocumentData | null>(null);

  const documentToolbar = useToolbarStore((state) => state.documentToolbar);

  const linkOverlay = useRef<OverlayPanel>(null);
  const [startResourcePoint, setStartResourcePoint] = useState<Project.ResourceLinkPoint>();
  const [startResourcePinType, setStartResourcePinType] = useState<Project.PinType>();

  const dragHandlerRef = useRef(new DragHandler());
  const setWidgetDragHandler = useWidgetPropsStore((state) => state.setWidgetDragHandler);

  const propertyHandler = new PropertyHandler(documentData, renameNode, updateProperty);
  const propHandlerRef = useRef(propertyHandler);
  const setWidgetPropertyHandler = useWidgetPropsStore((state) => state.setWidgetPropertyHandler);
  const setProperties = useWidgetPropsStore((state) => state.setEditingProps);
  const stopEditingProps = useWidgetPropsStore((state) => state.stopEditingProps);

  const getBlockCaption = useCallback(
    (type: string): string => {
      if (!documentToolbar || type === '') return '';
      const findCaptionInSection = (sections: ToolbarSection[]): string => {
        for (const section of sections) {
          for (const element of section.list) {
            if (element.type === type) {
              return element.caption;
            }
          }
        }
        return '';
      };
      return findCaptionInSection(documentToolbar.definition);
    },
    [documentToolbar]
  );

  useEffect(() => {
    setWidgetDragHandler(dragHandlerRef.current);
    setWidgetPropertyHandler(propHandlerRef.current);

    propHandlerRef.current.onEditWidgetProperties((node, parentData, widgetName) => {
      setProperties(node, parentData, widgetName, documentId, localDocumentData.current?.resourceLinks || []);
    });

    return () => {
      stopEditingProps();
      setWidgetDragHandler(null);
      setWidgetPropertyHandler(null);
    };
  }, [documentId, setProperties, setWidgetDragHandler, setWidgetPropertyHandler, stopEditingProps]);

  useEffect(() => {
    if (documentData) {
      const newNodes = documentData.resourceTree
        ?.map((element) => documentData.internalMetadata?.nodes[element.data.id])
        ?.filter(Boolean);
      setNodes(newNodes || []);

      const newEdges = Object.keys(documentData.metadata?.edges || {})
        ?.map((key) => documentData.metadata.edges[key])
        ?.filter(Boolean);
      setEdges(newEdges || []);

      if (!deepEqual(documentData, localDocumentData.current)) {
        const newDocumentData = deepCopy(documentData);
        localDocumentData.current = newDocumentData;
      }

      propHandlerRef.current.updateDocumentData(documentData);
    }
  }, [documentData]);

  const onConnect = useCallback(
    (conn: Connection) => {
      setEdges((eds) => addEdge(conn, eds));
      createEdge(conn);
    },
    [createEdge]
  );

  const onNodesChange = useCallback(
    (changes: NodeChange<any>[]) => {
      if (changes[0]?.type === 'position') {
        moveNode(changes[0].id, changes[0].position);
      }
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [moveNode]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange<any>[]) => {
      if (changes[0]?.type === 'remove') {
        removeEdge(changes[0].id);
      }
      setEdges((edgs) => applyEdgeChanges(changes, edgs));
    },
    [removeEdge]
  );

  const handleDeleteNode = useCallback(
    (nodeId: string) => {
      const newNodes = nodes.filter((node) => node.id !== nodeId);
      setNodes(newNodes);
      removeNode(nodeId);
      stopEditingProps();
    },
    [nodes, removeNode, stopEditingProps]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const savedDropData = dragHandlerRef.current.getDropData();
      const labelToShow = getBlockCaption(savedDropData?.type || '');
      const newNode = createNode(
        { x: e.offsetX, y: e.offsetY },
        labelToShow,
        savedDropData?.type || '',
        blockData[savedDropData?.type].inputs,
        blockData[savedDropData?.type].outputs
      );
      setNodes((prevNodes) => [...prevNodes, newNode]);
    },
    [createNode, getBlockCaption]
  );

  const handleSelectNode = useCallback((nodeId: string) => {
    setSelectedNodeId(nodeId);
    propHandlerRef.current.manageWidgetEditRequest(nodeId);
  }, []);

  const handleCreateHandles = useCallback(
    (nodeId: string, type: string, caption: string, dynamicProperties: WidgetProperties) => {
      createHandlesForNode(nodeId, type, caption, dynamicProperties);
      setTimeout(() => {
        propHandlerRef.current.manageWidgetEditRequest(nodeId);
      }, 200);
    },
    [createHandlesForNode]
  );

  const handleLinkHandleNode = useCallback(
    (e: React.SyntheticEvent, index: string, nodeId: string) => {
      const currNode = findResourceById(localDocumentData.current?.resourceTree || [], nodeId);
      if (currNode) {
        const indexArray = index.split('-');
        if (indexArray.length > 1) {
          let containerName = '';
          let pinType = Project.PinType.None;
          switch (indexArray[0]) {
            case 'in':
              containerName = 'Ins';
              pinType = Project.PinType.In;
              break;
            case 'out':
              containerName = 'Outs';
              pinType = Project.PinType.Out;
              break;
          }

          setStartResourcePoint({
            documentId,
            path: [{ idx: containerName }, { idx: 'Handle' + indexArray[1] }],
          });
          setStartResourcePinType(pinType);

          linkOverlay.current.toggle(e);
        }
      }
    },
    [documentId]
  );

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDocumentDataChange({ ...localDocumentData.current, resourceLinks });
  };

  const handleGetNodeHandles = useCallback(
    (nodeId: string, handleKey: string, defaultHandle: string[]) => {
      const existingHandles = getNodeHandles(nodeId, handleKey);
      return existingHandles.length > 0 ? existingHandles : defaultHandle;
    },
    [getNodeHandles]
  );

  const handleIsDynamicHandle = useCallback(
    (nodeId: string, handleKey: string, index: number) => {
      return isDynamicHandle(nodeId, handleKey, index);
    },
    [isDynamicHandle]
  );

  const handleDeleteHandle = useCallback(
    (nodeId: string, handleKey: string, index: number) => {
      removeHandleFromNode(nodeId, handleKey, index);
      setTimeout(() => {
        propHandlerRef.current.manageWidgetEditRequest(nodeId);
      }, 200);
    },
    [removeHandleFromNode]
  );

  const flowElements = useMemo(() => {
    return nodes.map((nd) => ({
      ...nd,
      data: {
        ...nd.data,
        position: nd.position,
        dimensions: nd.dimensions,
        isSelected: nd.id === selectedNodeId,
        inputs: blockData[nd.configType].inputs,
        outputs: blockData[nd.configType].outputs,
        inCaption: blockData[nd.configType].inCaption,
        outCaption: blockData[nd.configType].outCaption,
        inputProperties: blockData[nd.configType].inputProperties,
        outputProperties: blockData[nd.configType].outputProperties,
        onNodeDelete: handleDeleteNode,
        onSelectNode: handleSelectNode,
        onLinkHandle: handleLinkHandleNode,
        onHandlesCreated: handleCreateHandles,
        onGetNodeHandles: handleGetNodeHandles,
        onIsDynamicHandle: handleIsDynamicHandle,
        onHandleDelete: handleDeleteHandle,
      },
      id: nd.id,
    }));
  }, [
    handleCreateHandles,
    handleDeleteHandle,
    handleDeleteNode,
    handleGetNodeHandles,
    handleIsDynamicHandle,
    handleLinkHandleNode,
    handleSelectNode,
    nodes,
    selectedNodeId,
  ]);

  return (
    <ReactFlowProvider>
      <div
        style={{ width: '100%', height: '75vh', backgroundColor: 'white', overflow: 'hidden' }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => handleDrop(e.nativeEvent)}
      >
        <ReactFlow
          nodes={flowElements}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          edges={edges}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
        >
          <Controls style={{ color: 'black' }} />
          <MiniMap nodeColor={'black'} pannable={true} zoomable={true} />
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        </ReactFlow>
      </div>

      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          <TreeLink
            startResourcePoint={startResourcePoint}
            startResourcePinType={startResourcePinType}
            resourceLinks={localDocumentData.current?.resourceLinks || []}
            onChangeData={handleLinkChange}
          />
        </AutoFocusDiv>
      </OverlayPanel>
    </ReactFlowProvider>
  );
};

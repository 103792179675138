import '@fortawesome/fontawesome-free/css/all.css';

export interface EventsCollectionItem {
  element: HTMLElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: any;
  event: string;
}

export interface DropPosition {
  elementId: string;
  isParent: boolean;
}

export class EventsCollection {
  private list: EventsCollectionItem[] = [];

  register(ele: EventsCollectionItem) {
    ele.element.addEventListener(ele.event, ele.handler);
    this.list.push(ele);
  }
  unregister() {
    this.list.forEach((ele) => {
      ele.element.removeEventListener(ele.event, ele.handler);
    });
  }
}

export class SupportDiv {
  constructor(protected element: HTMLElement, protected parentDom: HTMLElement | SVGElement) {
    this.element.style.position = 'absolute';
    this.element.classList.add('support-div');
  }

  setSize(width: number, height: number) {
    if (this.element instanceof SVGElement && 'getBBox' in this.element) {
      this.element.setAttribute('width', `${width}`);
      this.element.setAttribute('height', `${height}`);
    } else {
      this.element.style.width = `${width}px`;
      this.element.style.height = `${height}px`;
    }
  }

  positionTo(left: number, top: number) {
    if (this.parentDom) {
      const parentRect = this.parentDom.getBoundingClientRect();
      const relX = left - parentRect.left;
      const relY = top - parentRect.top;
      this.element.style.left = `${relX + this.parentDom.scrollLeft}px`;
      this.element.style.top = `${relY + this.parentDom.scrollTop}px`;
    }
  }

  attachToDom() {
    if (this.parentDom && !this.parentDom.contains(this.element)) {
      this.parentDom.appendChild(this.element);
    }
  }

  detachFromDom() {
    if (this.element.parentElement) {
      this.element.parentElement.removeChild(this.element);
    }
  }
}

export type BorderType = 'over' | 'focus' | 'drag' | 'dragging' | 'dropping' | 'selectionBorder';

export class SupportBorder extends SupportDiv {
  viewElementId(): string {
    return this.viewElement.id;
  }

  isParentOf(element: HTMLElement): boolean {
    return this.viewElement.contains(element);
  }

  getType(): BorderType {
    return this.type;
  }

  getRect(): DOMRect {
    return this.viewElement.getBoundingClientRect();
  }

  hasMouseOn(x: number, y: number): boolean {
    return this.menu?.hasMouseOn(x, y);
  }

  readonly menuDim = 24;
  private overBorderStyle = 'solid';
  private overBorderWidth = '1px';
  private type: BorderType;
  private menu: SupportMenu;
  private selected: boolean;

  elementIsFlex(): boolean {
    return window.getComputedStyle(this.viewElement).display === 'flex';
  }

  elementIsView(): boolean {
    return (
      this.viewElement.classList.contains('fast-view') ||
      this.viewElement.classList.contains('fast-page') ||
      this.viewElement.classList.contains('fast-svg')
    );
  }

  hasMenu(): boolean {
    return this.menu !== null;
  }

  constructor(
    private menuIconHandler: MenuIconHandler,
    private viewElement: HTMLElement | SVGElement,
    parentDom: HTMLElement | SVGElement,
    private documentId: string
  ) {
    const div = document.createElement('div');
    super(div, parentDom);
    div.style.borderStyle = this.overBorderStyle;
    div.style.borderWidth = this.overBorderWidth;
    div.style.transition = 'opacity 0.1s';
    div.style.opacity = '0';
    div.style.pointerEvents = 'none';
    div.style.boxShadow = '0 0 0 1px #fff';
    div.style.border = '1px solid';
    div.classList.add('fast-border');
    div.dataset.borderKey = viewElement.id;
    setTimeout(() => (div.style.opacity = '1'), 10); // Ensures the opacity animation triggers
  }

  detachFromDom() {
    this.menu?.detachFromDom();
    this.element.style.opacity = '0';
    setTimeout(() => super.detachFromDom(), 100); // Ensures the opacity animation triggers
  }

  attachToDom() {
    super.attachToDom();
  }

  // Esempio d'uso
  update() {
    const hasMenu = !['drag', 'dragging', 'dropping', 'selectionBorder'].includes(this.type);

    if (hasMenu && !this.menu) {
      this.menu = new SupportMenu(this, this.menuIconHandler, this.menuDim, this.parentDom, this.documentId);
      this.menu?.attachToDom();
    }

    if (!hasMenu && this.menu) {
      this.menu?.detachFromDom();
      this.menu = null;
    }

    const rect = this.viewElement.getBoundingClientRect();
    this.menu?.update();

    if (!this.elementIsView()) {
      this.positionTo(rect.left - 2, rect.top - 2);
      this.setSize(rect.width + 4, rect.height + 4);
    } else {
      this.positionTo(rect.left, rect.top);
      this.setSize(rect.width, rect.height);
    }

    this.element.style.border = '1px solid';

    const type: BorderType = ['over'].includes(this.type) && this.selected ? 'selectionBorder' : this.type;

    switch (type) {
      case 'dragging':
        this.element.style.borderColor = 'red';
        break;
      case 'dropping':
        this.element.style.border = '2px dashed red';
        break;
      case 'focus':
        if (this.selected) {
          this.element.style.border = '2px solid red';
        } else {
          this.element.style.borderColor = 'red';
        }
        break;
      case 'drag':
      case 'over':
        if (window.getComputedStyle(this.viewElement).display === 'flex') {
          this.element.style.borderColor = '#D4AF37';
        } else {
          this.element.style.borderColor = 'black';
        }
        break;
      case 'selectionBorder':
        this.element.style.border = '2px solid purple';
        break;
      default:
    }
  }

  setType(type: BorderType) {
    if (type !== this.type) {
      this.type = type;
      this.update();
    }
  }

  setSelected(selected: boolean) {
    if (selected !== this.selected) {
      this.selected = selected;
      this.update();
    }
  }

  isSelected(): boolean {
    return this.selected;
  }
}

type MenuIconHandler = {
  onClick?: (actionId: string, elementId: string) => void;
  icons: {
    class: string;
    actionId: string;
  }[];
  onStartDrag?: (elementId: string) => void;
};

export class SupportMenu extends SupportDiv {
  readonly toolBarDim = 24;
  private direction: 'left' | 'right';
  private container: HTMLElement;

  private iconElements: HTMLElement[] = [];
  zoomOn: boolean;
  zoomTimer: NodeJS.Timeout;
  eventsCollection: EventsCollection = new EventsCollection();

  private zoom(zoomOn: boolean) {
    this.zoomOn = zoomOn;
    this.update();
  }

  constructor(
    private border: SupportBorder,
    private menuIconHandler: MenuIconHandler,
    private originalSize: number,
    parentDom: HTMLElement | SVGElement,
    private documentId: string
  ) {
    const container = document.createElement('div');
    super(container, parentDom);
    this.container = container;
    const fontSize = (originalSize * 4) / 5;
    container.style.position = 'absolute';
    container.style.display = 'flex';
    container.style.border = '1px solid black';
    container.style.fontSize = `${fontSize}px`;
    container.style.maxWidth = `${this.originalSize * 2}px`;
    container.style.backgroundColor = '#D4AF37';
    container.style.overflow = 'hidden';
    container.style.maxWidth = `${originalSize}px`;
    container.style.transition = 'opacity 0.1s';
    container.style.opacity = '0';
    container.style.zIndex = '1000';
    container.style.cursor = 'pointer';
    container.style.boxSizing = 'border-box';
    container.setAttribute('draggable', 'true');
    container.classList.add('fast-menu');
    container.dataset.menuKey = border.viewElementId();

    setTimeout(() => (container.style.opacity = '1'), 10); // Ensures the opacity animation triggers

    this.direction = border.elementIsView() ? 'right' : 'left';

    menuIconHandler.icons.forEach((iconElem) => {
      if (border.viewElementId() !== `page-${this.documentId}` || iconElem.actionId !== 'delete') {
        const div = document.createElement('div');

        div.style.overflow = 'hidden';
        div.style.minWidth = `${this.originalSize}px`;
        div.style.minHeight = `${this.originalSize}px`;
        const icon = document.createElement('i');
        icon.style.marginTop = `${(this.originalSize - fontSize) / 2}px`;
        icon.style.marginLeft = `${(this.originalSize - fontSize) / 2}px`;
        icon.className = `${iconElem.class}`;
        icon.style.color = 'black';
        div.appendChild(icon);

        this.eventsCollection.register({
          event: 'mouseenter',
          element: div,
          handler: () => {
            (div.firstElementChild as HTMLElement).style.color = 'red';
          },
        });

        this.eventsCollection.register({
          event: 'mouseleave',
          element: div,
          handler: () => {
            (div.firstElementChild as HTMLElement).style.color = 'black';
          },
        });

        this.eventsCollection.register({
          event: 'click',
          element: div,
          handler: () => {
            this.menuIconHandler?.onClick(iconElem.actionId, border.viewElementId());
          },
        });

        container.appendChild(div);
        this.iconElements.push(div);
      }
    });

    this.eventsCollection.register({
      element: container,
      event: 'mouseenter',
      handler: () => {
        this.zoomTimer = setTimeout(() => this.zoom(true), 300);
      },
    });

    this.eventsCollection.register({
      element: container,
      event: 'dragstart',
      handler: (e: DragEvent) => {
        if (this.menuIconHandler.onStartDrag && border.viewElementId() !== `page-${this.documentId}`) {
          e.dataTransfer.setDragImage(new Image(), 0, 0);
          this.menuIconHandler.onStartDrag(border.viewElementId());
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
      },
    });

    this.eventsCollection.register({
      element: container,
      event: 'mouseleave',
      handler: () => {
        if (this.zoomTimer) {
          clearTimeout(this.zoomTimer);
          this.zoomTimer = null;
        }
        this.zoom(false);
      },
    });
  }

  positionTo(left: number, top: number) {
    let next = this.element.parentElement?.firstElementChild;
    while (next && next !== this.element) {
      if (next.classList.contains('fast-menu')) {
        const rect = next.getBoundingClientRect();
        const difLeft = left - rect.left;
        const difTop = top - rect.top;
        if (difLeft >= 0 && difLeft <= 10 && difTop >= 0 && difTop <= 10) {
          left += 10 - difLeft;
          top += 10 - difTop;
        }
      }
      next = next.nextElementSibling;
    }
    super.positionTo(left, top);
  }

  update() {
    const rect = this.border.getRect();
    this.element.style.flexFlow = 'row';
    if (!this.zoomOn) {
      this.element.style.maxWidth = `${this.originalSize}px`;
      this.element.style.transform = 'scale(1)';
      if (this.direction === 'right') {
        this.positionTo(rect.left, rect.top);
      } else {
        this.positionTo(rect.right - this.originalSize + 4, rect.top - 4);
      }
    } else {
      this.element.style.transformOrigin = 'top left';
      this.element.style.maxWidth = `${this.originalSize * this.iconElements.length}px`;
      this.element.style.transform = `scale(${this.toolBarDim / this.originalSize})`;

      if (this.direction === 'right') {
        this.positionTo(rect.left, rect.top);
      } else {
        this.positionTo(rect.right - this.originalSize * this.iconElements.length + 4, rect.top - 4);
        this.element.style.flexFlow = 'row-reverse';
      }
    }
  }

  hasMouseOn(x: number, y: number): boolean {
    const underMouse = document.elementFromPoint(x, y);
    return underMouse.isSameNode(this.container) || this.container.contains(underMouse);
  }

  detachFromDom() {
    this.element.style.opacity = '0';
    setTimeout(() => super.detachFromDom(), 100);
    this.eventsCollection.unregister();
  }
}

export class SupportDivHandler {
  private currentX: number | null;
  private currentY: number | null;
  private eventsCollection: EventsCollection = new EventsCollection();
  private draggingElement: HTMLElement | SVGElement;

  private dropIcon: HTMLDivElement = this.createDropIcon();

  private parentDom = document.getElementById('screen-root');

  private borderDict: { [key: string]: SupportBorder } = {};

  private droppingElement: string | null;
  private selectedBorder: SupportBorder | null;

  private currentDropPosition: DropPosition | null;
  private selectedBorderId: string | null;

  private resizeElement(element: HTMLElement, deltaX: number, deltaY: number) {
    const newWidth = element.offsetWidth + deltaX;
    const newHeight = element.offsetHeight + deltaY;
    element.style.width = `${newWidth}px`;
    element.style.height = `${newHeight}px`;

    const rectElement = element.querySelector('rect') as SVGRectElement;
    if (rectElement) {
      rectElement.setAttribute('width', newWidth.toString());
      rectElement.setAttribute('height', newHeight.toString());
    }
  }

  private handleResize(event: MouseEvent, handleType: string, element: HTMLElement) {
    let startX = event.clientX;
    let startY = event.clientY;

    const mouseMoveHandler = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      // Ridimensiona l'elemento (SupportDiv e rect SVG)
      this.resizeElement(element, deltaX, deltaY);

      // Aggiorna i punti di partenza
      startX = moveEvent.clientX;
      startY = moveEvent.clientY;
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  public attachResizeHandles(element: HTMLElement) {
    // Aggiungi i manici di ridimensionamento ai quattro angoli del div
    this.addResizeHandle('top-left', element, (event) => this.handleResize(event, 'top-left', element));
    this.addResizeHandle('top-right', element, (event) => this.handleResize(event, 'top-right', element));
    this.addResizeHandle('bottom-left', element, (event) => this.handleResize(event, 'bottom-left', element));
    this.addResizeHandle('bottom-right', element, (event) => this.handleResize(event, 'bottom-right', element));
  }

  private addResizeHandle(position: string, element: HTMLElement, handler: (event: MouseEvent) => void) {
    const handle = document.createElement('div');
    handle.className = `resize-handle ${position}`;
    handle.addEventListener('mousedown', (event) => {
      event.stopPropagation(); // Previene l'attivazione del drag quando si ridimensiona
      handler(event); // Avvia il ridimensionamento
    });
    element.appendChild(handle);
  }

  private selectedElementObserver: ResizeObserver = new ResizeObserver((entries) => {
    entries.forEach((element) => {
      if (element.target.id === this.selectedBorderId) {
        requestAnimationFrame(() => this.selectedBorder?.update());
      }
    });
  });

  private getFlexDirection(element: HTMLElement): 'left' | 'right' | 'up' | 'down' | 'unknown' {
    const style = window.getComputedStyle(element);
    const flexDirection = style.flexDirection;
    const display = window.getComputedStyle(element).display;

    if (display === 'flex') {
      switch (flexDirection) {
        case 'row':
          return 'right';
        case 'row-reverse':
          return 'left';
        case 'column':
          return 'down';
        case 'column-reverse':
          return 'up';
        default:
          return 'unknown'; // Non-flex or unrecognized value
      }
    } else return 'unknown';
  }

  private getAxisDirection(element: HTMLElement): 'horizontal' | 'vertical' | 'unknown' {
    switch (this.getFlexDirection(element)) {
      case 'left':
      case 'right':
        return 'horizontal';
      case 'up':
      case 'down':
        return 'vertical';
      default:
        return 'unknown';
    }
  }

  private isFlex(element: HTMLElement): boolean {
    return this.getFlexDirection(element) !== 'unknown';
  }

  private isView(element: HTMLElement | SVGElement): boolean {
    const isSvgView = element instanceof SVGElement;
    const isHtmlView = element.classList.contains('fast-view') || element.classList.contains('fast-page');
    return isHtmlView || isSvgView;
  }

  private getDropPosition(element: HTMLElement, x: number, y: number): DropPosition {
    const isSvgWidget =
      element.classList.contains('svg-text') ||
      element.classList.contains('svg-rect') ||
      element.classList.contains('svg-external');

    if (isSvgWidget) {
      // Disattiva l'ancoraggio per i widget SVG
      return;
    }

    let closestChild: HTMLElement | null = null;
    let minimumDistance = Infinity;

    let checkCorner: string;
    let oppositeCheckCorner: string;

    switch (this.getFlexDirection(element)) {
      case 'right':
        checkCorner = 'leftTop';
        oppositeCheckCorner = 'rightTop';
        break;
      case 'left':
        checkCorner = 'rightTop';
        oppositeCheckCorner = 'leftTop';
        break;
      case 'up':
        checkCorner = 'leftBottom';
        oppositeCheckCorner = 'leftTop';
        break;
      case 'down':
        checkCorner = 'leftTop';
        oppositeCheckCorner = 'leftBottom';
    }

    // Calculate the distance between the specified corner and the corresponding corner of an element
    const adjustMinDistance = (child: HTMLElement, corner: string): boolean => {
      let cornerX: number, cornerY: number;
      const childRect = child.getBoundingClientRect();

      switch (corner) {
        case 'leftTop':
          cornerX = childRect.left;
          cornerY = childRect.top;
          break;
        case 'leftBottom':
          cornerX = childRect.left;
          cornerY = childRect.bottom;
          break;
        case 'rightBottom':
          cornerX = childRect.right;
          cornerY = childRect.bottom;
          break;
        case 'rightTop':
          cornerX = childRect.right;
          cornerY = childRect.top;
          break;
      }

      // Calculate the Euclidean distance from the specified corner to the corresponding corner of the child
      const distance = Math.sqrt(Math.pow(cornerX - x, 2) + Math.pow(cornerY - y, 2));
      if (distance < minimumDistance) {
        minimumDistance = distance;
        return true;
      }
      return false;
    };

    // Examine each child to find the closest to the specified point
    element.childNodes.forEach((child) => {
      if (child instanceof HTMLElement) {
        if (adjustMinDistance(child, checkCorner)) closestChild = child;
        if (adjustMinDistance(child, oppositeCheckCorner)) {
          closestChild = child.nextElementSibling as HTMLElement;
        }
      }
    });

    if (closestChild) {
      if (element.lastChild && adjustMinDistance(element.lastChild as HTMLElement, oppositeCheckCorner))
        closestChild = null;
    }

    return {
      elementId: closestChild ? closestChild.id : element.id,
      isParent: !closestChild,
    };
  }

  private createDropIcon(): HTMLDivElement {
    const div = document.createElement('div');
    div.style.width = '3px';
    div.style.height = '0px';
    div.style.background = 'white';
    div.style.position = 'fixed';
    div.style.zIndex = '1000';
    div.style.visibility = 'hidden';
    div.style.borderLeft = '3px dashed red'; // Makes the line dashed
    div.style.boxShadow = '0 0 0 2px white'; // Adds a white border effect
    div.style.pointerEvents = 'none';
    document.body.appendChild(div);
    return div;
  }

  private moveDropIcon(dropPosition: DropPosition): void {
    let dim = 50;
    const coord = {
      x: 0,
      y: 0,
    };

    let rect: DOMRect;
    const element = document.getElementById(dropPosition.elementId);
    type RectProperties = 'left' | 'right' | 'top' | 'bottom' | 'width' | 'height';
    const _moveTo = (
      left: RectProperties,
      top: RectProperties,
      right: RectProperties,
      bottom: RectProperties,
      height: RectProperties,
      x: 'x' | 'y',
      y: 'x' | 'y'
    ) => {
      if (!dropPosition.isParent) {
        rect = element.getBoundingClientRect();
        coord[x] = rect[left];
        coord[y] = (rect[top] + rect[bottom]) / 2;
        dim = rect[height] + 40;
      } else {
        if (element.lastChild) {
          rect = (element.lastChild as HTMLElement).getBoundingClientRect();
          coord[x] = rect[right];
          coord[y] = (rect[top] + rect[bottom]) / 2;
          dim = rect[height] + 40;
        } else {
          rect = element.getBoundingClientRect();
          coord[x] = rect[left] + (['right', 'down'].includes(dir) ? 20 : -20);
          coord[y] = (rect[top] + rect[bottom]) / 2;
          dim = (rect[height] * 2.5) / 3;
        }
      }
    };

    const parent = dropPosition.isParent ? element : element.parentElement;

    const dir = this.getFlexDirection(parent);

    const isHoriz = ['left', 'right'].includes(dir);
    const orientation: string = isHoriz ? 'vertical' : 'horizontal';

    switch (dir) {
      case 'right':
        _moveTo('left', 'top', 'right', 'bottom', 'height', 'x', 'y');
        break;
      case 'left':
        _moveTo('right', 'top', 'left', 'bottom', 'height', 'x', 'y');
        break;
      case 'down':
        _moveTo('top', 'right', 'bottom', 'left', 'width', 'y', 'x');
        break;
      case 'up':
        _moveTo('bottom', 'left', 'top', 'right', 'width', 'y', 'x');
        break;
    }

    // Adjusting coordinates to center the this.dropIcon
    const newX = coord.x - 0.5;
    const newY = coord.y - dim / 2;

    this.showIcon(newX, newY, this.dropIcon);

    this.dropIcon.style.height = `${dim}px`;
    this.dropIcon.style.transform = orientation === 'horizontal' ? 'rotate(90deg)' : '';
  }

  private showIcon(x: number, y: number, icon: HTMLElement) {
    if (icon.style.visibility === 'hidden') {
      icon.style.transition = 'opacity 0.1s'; // Smooth transition for all properties
      icon.style.visibility = 'visible';
      icon.style.opacity = '1';
    } else icon.style.transition = 'transform 0.1s, opacity 0.1s, left 0.1s, top 0.1s, height 0.1s'; // Smooth transition for all properties

    icon.style.left = `${x}px`;
    icon.style.top = `${y}px`;
  }

  private hideIcon(element: HTMLElement) {
    if (element.style.visibility !== 'hidden') {
      element.style.transition = 'opacity 0.1s';
      element.style.opacity = '0';
      setTimeout(() => (element.style.visibility = 'hidden'), 100); // Hides the div after the fade-out animation
    }
  }

  private getValidElement(element: HTMLElement | SVGElement | null): HTMLElement | SVGElement | null {
    if (element && !element.classList.contains('support-div')) {
      if (element.id.length === 0) return this.getValidElement(element.parentElement);
    }
    return element;
  }

  private recursiveScreenTraverse(
    x: number,
    y: number,
    operation: (element: HTMLElement, on: boolean) => void,
    div: HTMLElement
  ) {
    if (div) {
      const rect = div.getBoundingClientRect();
      if (div.id) {
        operation(div, x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom);
      }
      const elements = Array.from(div.childNodes);
      elements.forEach((child) => {
        if (child instanceof HTMLElement) this.recursiveScreenTraverse(x, y, operation, child);
      });
    }
  }

  private screenTraverse(x: number, y: number, operation: (element: HTMLElement, on: boolean) => void) {
    this.recursiveScreenTraverse(x, y, operation, this.parentDom.firstChild as HTMLElement);
  }

  private remove(id: string) {
    const border = this.borderDict[id];
    if (border) {
      if (border.isSelected()) {
        this.add(id, 'selectionBorder');
      } else {
        border.detachFromDom();
        delete this.borderDict[id];
      }
    }
  }

  private mouseMoving(x: number, y: number) {
    this.currentX = x;
    this.currentY = y;

    const fatherHasMenuOn = (element: HTMLElement): boolean => {
      let ele = element.parentNode;
      while (ele) {
        if (ele instanceof HTMLElement && this.borderDict[ele.id]?.hasMouseOn(x, y)) return true;
        ele = ele.parentNode;
      }
      return false;
    };

    const elementOver = this.getValidElement(document.elementFromPoint(x, y) as HTMLElement);

    this.screenTraverse(x, y, (element: HTMLElement, on: boolean) => {
      if (!on || fatherHasMenuOn(element)) {
        this.remove(element.id);
      } else {
        this.add(
          element.id,
          element === elementOver || this.borderDict[element.id]?.hasMouseOn(x, y) ? 'focus' : 'over'
        );
      }
    });
  }

  private add(id: string, type: BorderType) {
    const viewElement = document.getElementById(id);

    const canAddBorder = (): boolean => {
      if (viewElement instanceof HTMLElement) {
        return viewElement && (this.isView(viewElement) || type !== 'drag');
      }
      if (viewElement && 'getBBox' in viewElement) {
        return true;
      }
      return false;
    };

    let border: SupportBorder | null = null;

    if (this.borderDict[id]) {
      if (canAddBorder()) {
        border = this.borderDict[id];
      } else {
        this.remove(id);
      }
    } else {
      if (canAddBorder()) {
        border = new SupportBorder(this.menuIconHandler, viewElement as HTMLElement, this.parentDom, this.documentId);
        this.borderDict[id] = border;
        border.attachToDom();
      }
    }

    if (border) {
      border.setType(type);
    }
  }

  constructor(private menuIconHandler: MenuIconHandler, private documentId: string) {}

  setCurrentDropPosition(dropPosition: DropPosition | null) {
    this.currentDropPosition = dropPosition;
  }

  getDraggingElement(): HTMLElement | SVGElement | null {
    return this.draggingElement;
  }

  update() {
    if (this.currentX && this.currentY) {
      this.mouseMoving(this.currentX, this.currentY);
    }
  }

  setDroppingElement(element: HTMLElement | null) {
    this.droppingElement = this.getValidElement(element)?.id;
  }
  setDraggingElement(element: HTMLElement | null) {
    this.draggingElement = this.getValidElement(element);
  }

  detachFromScreen() {
    this.eventsCollection.unregister();

    Object.values(this.borderDict).forEach((border) => border.detachFromDom());
    this.borderDict = {};
    this.selectedBorder = null;

    if (this.selectedBorderId) {
      const border = document.getElementById(this.selectedBorderId);
      if (border) {
        this.selectedElementObserver.unobserve(border);
      }
    }
  }

  hideDropIcon(): DropPosition {
    this.hideIcon(this.dropIcon);
    const ret = this.currentDropPosition;
    this.currentDropPosition = null;
    return ret;
  }

  attachToScreen() {
    this.eventsCollection.register({
      event: 'mousemove',
      element: this.parentDom,
      handler: (e: MouseEvent) => {
        const elementUnderMouse = e.target as HTMLElement | SVGElement;

        if (elementUnderMouse instanceof SVGElement) {
          // Assicurati che anche gli elementi SVG siano considerati per il support-div
          this.mouseMoving(e.clientX, e.clientY);
        } else {
          this.mouseMoving(e.clientX, e.clientY);
        }
      },
    });

    this.eventsCollection.register({
      event: 'mouseleave',
      element: this.parentDom,
      handler: () => {
        this.updateBorders();
      },
    });

    if (this.selectedBorderId) {
      if (document.getElementById(this.selectedBorderId)) {
        this.add(this.selectedBorderId, 'selectionBorder');
        this.selectedBorder = this.borderDict[this.selectedBorderId];
        this.selectedBorder.setSelected(true);
        this.selectedElementObserver.observe(document.getElementById(this.selectedBorderId));
      } else {
        this.selectedBorderId = '';
        this.selectedBorder = null;
      }
    }
    this.update();
  }

  updateBorders() {
    this.mouseMoving(-1, -1);
  }

  updateSelectionBorder() {
    this.selectedBorder?.update();
  }

  dropPosition(): DropPosition | null {
    return this.currentDropPosition;
  }

  checkDropPosition(overElement: HTMLElement, x: number, y: number) {
    const rect = overElement.getBoundingClientRect();
    const isSvgWidget =
      this.draggingElement?.classList.contains('svg-text') ||
      this.draggingElement?.classList.contains('svg-rect') ||
      this.draggingElement?.classList.contains('svg-external');

    const isSvgContainer = overElement.classList.contains('fast-svg-container');

    if (isSvgWidget && isSvgContainer) {
      const otherSvgChildren = Array.from(overElement.children).filter(
        (child) =>
          child.classList.contains('svg-text') ||
          child.classList.contains('svg-rect') ||
          child.classList.contains('svg-external')
      );

      if (otherSvgChildren.length > 0) {
        return;
      }
    }
    const dropPosition = this.getDropPosition(overElement, x, y);
    const dropElement = document.getElementById(dropPosition?.elementId);

    this.setDroppingElement(dropElement);

    if (
      !this.draggingElement?.contains(overElement) &&
      dropElement !== this.draggingElement &&
      (!dropPosition.isParent || dropElement?.lastChild !== this.draggingElement)
    ) {
      if (this.currentDropPosition == null || dropPosition !== this.currentDropPosition) {
        this.moveDropIcon(dropPosition);
        this.setCurrentDropPosition(dropPosition);
      }
    }
  }

  setSelectedElement(id: string | null) {
    let ele = this.borderDict[id];
    if (!ele) {
      this.add(id, 'selectionBorder');
      ele = this.borderDict[id];
    }
    const domEle = document.getElementById(id);
    if (ele !== this.selectedBorder && domEle) {
      this.selectedBorderId = id;
      this.selectedBorder?.setSelected(false);
      if (this.selectedBorderId) {
        this.selectedElementObserver.unobserve(document.getElementById(this.selectedBorderId));
      }
      this.selectedElementObserver.observe(domEle);
      if (this.selectedBorder?.getType() === 'selectionBorder') {
        this.remove(this.selectedBorder?.viewElementId());
      }
      ele.setSelected(true);
      this.selectedBorder = ele;
    }
  }

  dragOver(x: number, y: number) {
    const elementUnderMouse = document.elementFromPoint(x, y) as HTMLElement;

    if (
      elementUnderMouse &&
      (elementUnderMouse.classList.contains('svg-text') ||
        elementUnderMouse.classList.contains('svg-rect') ||
        elementUnderMouse.classList.contains('svg-external'))
    ) {
      return;
    }
    this.currentX = x;
    this.currentY = y;

    this.screenTraverse(x, y, (element: HTMLElement, on: boolean) => {
      if (this.draggingElement && this.draggingElement.id === element?.id) {
        this.add(element.id, 'dragging');
      } else if (
        (this.currentDropPosition?.elementId === element.id &&
          (!this.currentDropPosition.isParent || !element.firstChild)) ||
        (!element.nextSibling &&
          this.currentDropPosition?.elementId === element.parentElement?.id &&
          this.currentDropPosition.isParent)
      ) {
        this.add(element.id, 'dropping');
      } else {
        if ((!on && !this.isView(element)) || !this.droppingElement) this.remove(element.id);
        else this.add(element.id, 'drag');
      }
    });
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'primereact/button';
import { EditingProperties, WidgetPropsState, useWidgetPropsStore } from '@stores/widgetPropsStore';
import { FC, useEffect, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PrimeIcons } from 'primereact/api';
import { Project } from '@api';
import { PropertiesConfig, Property } from '@config/widget/interface';
import { TextPosition } from '@config/widget/enum';
import { TreeLink } from '@components/tree-link/TreeLink';
import { deepCopy, deepEqual, deepNormalizeNull } from '@services/utils';
import AutoFocusDiv from '@components/common/AutoFocusDiv';
import DropDownBox from '../utils/DeviceSelectComponent';

export interface EditorOptions extends Record<string, any> {
  subProperties?: PropertiesConfig;
}

export interface PropertyEditorOptions {
  widgetId: string;
  sectionId: string;
  field: string;
  property: Property;
}

interface PropertyEditorProps {
  onDataChange: (data: any) => void;
  showError: (text: string) => void;
  options: PropertyEditorOptions;
  data: any;
  defaultData: any;
  parentData: any;
  addResetChildFc?: any;
  editingProperties: EditingProperties;
}

const PropertyEditor: FC<PropertyEditorProps> = ({
  data,
  defaultData,
  parentData,
  showError,
  onDataChange,
  options,
  addResetChildFc,
  editingProperties,
}) => {
  const device = useWidgetPropsStore((state: WidgetPropsState) => state.device);
  const widgetPropertyHandler = useWidgetPropsStore((state) => state.widgetPropertyHandler);
  const setProperty = useWidgetPropsStore((state) => state.setEditingProp);
  // const [widgetCurrentValue, setWidgetCurrentValue] = useState(data ? data : defaultData);
  let newData = data === undefined ? defaultData : data;
  const setDevice = useWidgetPropsStore((state: WidgetPropsState) => state.setDevice);

  const PropertyEditorComponent = options.property.editor;
  const hasError = useRef(false);
  const linkOverlay = useRef<OverlayPanel>(null);
  const [startResourcePoint, setStartResourcePoint] = useState<Project.ResourceLinkPoint>();
  const [startResourcePinType, setStartResourcePinType] = useState<Project.PinType>();
  const isMultiProperty = useRef<boolean>(options.property?.editorOptions?.subProperties ? true : false);
  const isMultiPropertyChild = useRef<boolean>(![undefined, null].includes(addResetChildFc));
  const resetChildrenFunctions = useRef<Array<any>>([]);
  const resetFunctionAdded = useRef<boolean>(false);

  useEffect(() => {
    if (editingProperties.widgetName) {
      setStartResourcePoint({
        documentId: editingProperties.documentId,
        path: [{ idx: editingProperties.widgetName }, { idx: options.field }],
      });
    } else {
      setStartResourcePoint({
        documentId: editingProperties.documentId,
        path: [{ idx: options.field }],
      });
    }
    setStartResourcePinType(options.property.pinType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.field, options.property.pinType]);

  // useEffect(() => {
  //   const newData = data === undefined ? defaultData : data;
  //   if (!deepEqual(newData, widgetCurrentValue)) {
  //     setWidgetCurrentValue(newData);
  //   }
  // }, [data, defaultData]);

  const isTextPositionUp = options.property.captionPosition === TextPosition.Up;

  const normalizeNull = (value: any) => {
    if (value?.um === 0) {
      value.um = undefined;
    }

    return deepNormalizeNull(value);
  };

  const singleData = (data: any): any => {
    let d: any;

    if (!Array.isArray(data) || !options.property.isResponsive) {
      d = data;
    }
    if (Array.isArray(data)) {
      d = data[Math.min(device, data.length - 1)];
    }

    return d;
  };

  const rawData = (data: any): any => {
    const d = singleData(data);

    return typeof d === 'object' && 'value' in d ? d.value : d;
  };

  const removeTrailingDuplicates = (arr: any[]): any => {
    if (arr.length < 2) return arr; // Se l'array ha meno di due elementi, non c'è nulla da fare

    const lastElement = arr[arr.length - 1];
    let lastIndex = arr.length - 2;

    // Trova l'indice dell'ultimo elemento non duplicato consecutivo
    while (lastIndex >= 0 && deepEqual(arr[lastIndex], lastElement)) {
      lastIndex--;
    }

    // Rimuovi tutti i duplicati tranne l'ultimo
    const result = arr.slice(0, lastIndex + 1);
    result.push(lastElement);

    return result.length === 1 ? result[0] : result;
  };

  const composeResponsiveFromData = (newData: any): any => {
    if ((Array.isArray(data) || device > 0) && options.property.isResponsive) {
      if (!Array.isArray(data)) {
        data = [data];
      } else data = deepCopy(data);
      if (device > data.length) {
        // Copia l'ultimo elemento noto per riempire gli spazi vuoti fino all'indice
        const lastKnownElement = data[data.length - 1];
        while (data.length < device) {
          data.push(deepCopy(lastKnownElement));
        }
      }
      // Aggiungi il nuovo valore all'indice specificato
      data[device] = newData;
      data = removeTrailingDuplicates(data);
    } else data = newData;

    return data;
  };

  const manageDataChange = (field: string, data: any, um: number = null) => {
    if (um == null) {
      um = singleData(newData)?.um;
    }

    if (options.property.isResponsive) {
      data = rawData(data);
    }
    if (options.property.hasUM && data !== undefined && um) {
      data = { value: data, um };
    }

    if (typeof data !== 'boolean' && deepEqual(data, defaultData)) {
      data = undefined;
    }

    data = composeResponsiveFromData(data);

    if (widgetPropertyHandler) {
      const message = widgetPropertyHandler.checkProperty(options.widgetId, field, data);
      if (message) {
        showError(message);
        newData = data;
        // setWidgetCurrentValue(data);
        hasError.current = true;
      } else {
        if (hasError.current) {
          showError(null);
          newData = data;
          // setWidgetCurrentValue(data);
        }
        hasError.current = false;
        updateData(field, normalizeNull(data));
        onDataChange(data);
      }
    }
  };

  const updateData = (field: string, data: any) => {
    console.log('sectionId in accordion: ' + options.sectionId);
    widgetPropertyHandler.setProperty(options.widgetId, options.sectionId, field, data);
    setProperty(options.field, data, parentData);
  };

  const resetData = () => {
    if (isMultiProperty.current) {
      resetChildrenProperty();
      /*
      Object.keys(options.property.editorOptions.subProperties.properties).forEach((key) => {
        manageDataChange(key, undefined);
      }); */
    } else {
      manageDataChange(options.field, undefined);
    }
  };

  const addResetChildFunction = (handler: any) => {
    resetChildrenFunctions.current.push(handler);
  };

  const resetChildrenProperty = () => {
    resetChildrenFunctions.current.forEach((handler) => {
      handler();
    });
  };

  if (isMultiPropertyChild.current) {
    if (!resetFunctionAdded.current) {
      addResetChildFc(resetData);
      resetFunctionAdded.current = true;
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          ...(isTextPositionUp ? { flexDirection: 'column' } : { alignItems: 'center' }),
        }}
      >
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <h4 style={{}}>{options.property.caption}</h4>
          {options.property.isResponsive ? (
            <DropDownBox
              options={[
                { key: 0, icon: PrimeIcons.DESKTOP },
                { key: 1, icon: PrimeIcons.TABLET },
                { key: 2, icon: PrimeIcons.MOBILE },
              ]}
              selected={device || 0}
              onSelected={(key) => setDevice(key)}
            />
          ) : null}

          {!options.property.hasNotDefault && options.property.editor && (
            <Button
              type="button"
              style={{
                padding: 0,
                marginRight: 0,
                marginBottom: 10,
                marginTop: 10,
                width: '20px',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                /*
                if (options.property?.editorOptions?.subProperties) {
                  Object.keys(options.property.editorOptions.subProperties.properties).forEach((key) => {
                    manageDataChange(key, undefined);
                  });
                } else {
                  manageDataChange(options.field, undefined);
                }  //*/
                resetData();
              }}
              text
              aria-label="Resolution"
            />
          )}

          {options.property.pinType !== Project.PinType.None && (
            <Button
              type="button"
              style={{
                padding: 0,
                marginRight: 0,
                marginBottom: 10,
                marginTop: 10,
                width: '20px',
              }}
              icon="pi pi-link"
              onClick={(e) => {
                linkOverlay.current.toggle(e);
              }}
              text
              aria-label="Resolution"
            />
          )}

          {options.property.hasUM ? (
            <div style={{ marginLeft: 'auto' }}>
              <DropDownBox
                options={[
                  { key: 0, label: 'px' },
                  { key: 1, label: '%' },
                  { key: 2, label: 'rem' },
                ]}
                selected={singleData(newData)?.um ?? 0}
                onSelected={(key) => {
                  manageDataChange(options.field, newData, key);
                }}
              />
            </div>
          ) : null}
        </div>
        {options.property.editor && (
          <div
            id={`editor-${options.field}`}
            style={isTextPositionUp ? { alignSelf: 'stretch', marginTop: '-8px' } : { marginLeft: 'auto' }}
          >
            <PropertyEditorComponent
              data={rawData(newData)}
              {...options.property.editorOptions}
              onDataChange={(data: any) => {
                manageDataChange(options.field, data);
              }}
              addResetChildrenFc={isMultiProperty.current ? addResetChildFunction : null}
            />
          </div>
        )}
      </div>
      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          <TreeLink
            startResourcePoint={startResourcePoint}
            startResourcePinType={startResourcePinType}
            resourceLinks={editingProperties.resourceLinks}
            onChangeData={(links) => {
              widgetPropertyHandler.updateResourceLinks(links);
            }}
          />
        </AutoFocusDiv>
      </OverlayPanel>
    </>
  );
};

export default PropertyEditor;

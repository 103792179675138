import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { InputText } from 'primereact/inputtext';
import { Project } from '@api';
import { deepCopy, deepEqual } from '@services/utils';
import React, { useEffect, useRef, useState } from 'react';

export const DummyDocumentEditor: DocumentEditorComponent = ({
  data,
  status,
  onDataChange,
  onStatusChange,
  documentId,
}) => {
  // Crea un ref
  const localDocumentData = useRef<Project.DocumentData | null>(null);
  const [resConfig1, setResConfig1] = useState('');
  const [resConfig2, setResConfig2] = useState('');

  useEffect(() => {
    if (!deepEqual(data, localDocumentData.current) || !data) {
      localDocumentData.current = deepCopy(data);
      if (
        !localDocumentData.current ||
        !localDocumentData.current.metadata ||
        Object.keys(localDocumentData.current.metadata).length === 0
      ) {
        localDocumentData.current.metadata = {
          resConfig: [
            {
              config: 'config res 1',
            },
            {
              config: 'config res 2',
            },
          ],
        };
        localDocumentData.current.resourceTree = [
          {
            name: 'root',
            data: null,
            children: [
              {
                name: 'res1',
                data: 0,
              },
              {
                name: 'res2',
                data: 1,
              },
            ],
          },
        ];
      }
    }
    setResConfig1(localDocumentData.current.metadata.resConfig[0].config);
    setResConfig2(localDocumentData.current.metadata.resConfig[1].config);
  }, [data]);

  return (
    <div>
      <InputText
        id="test"
        value={resConfig1}
        onChange={(e) => {
          localDocumentData.current.metadata.resConfig[0].config = e.target.value;
          setResConfig1(e.target.value);
          onDataChange(localDocumentData.current);
        }}
      />
      <InputText
        id="test"
        value={resConfig2}
        onChange={(e) => {
          localDocumentData.current.metadata.resConfig[1].config = e.target.value;
          setResConfig2(e.target.value);
          onDataChange(localDocumentData.current);
        }}
      />
    </div>
  );
};

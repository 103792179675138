/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { EventsManager } from './events-manager';
import { PropertiesManager } from './properties-manager';
import { Widget, WidgetProperties } from './widget';
import { WidgetDictionary } from './widget-dictionary';

enum UM {
  Pixel = 0,
  Perc = 1,
  Rem = 2,
}

export enum AlignItems {
  Start = 0,
  Middle = 1,
  End = 2,
  Stretch = 3,
}

export abstract class BaseWidget implements Widget {
  updateProperties(): void {
    if (this.currentProperties) this.setProperties(this.currentProperties);
  }

  private id: string | null = null;

  private currentProperties: WidgetProperties = {};

  setCurrentProperties(properties: WidgetProperties, key: string) {
    this.currentProperties[key] = properties[key];
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      if (![undefined, null].includes(properties[key])) {
        if (key === 'id') {
          this.id = properties['id'];
          this.domElement.id = this.id;
          WidgetDictionary.registerWidget(properties['id'], this);
        } else if (key != 'type') {
          this.currentProperties[key] = properties[key];
        }
      }
    }
  }

  public sendEvent(propertyName: string, data?: any, isEvent?: boolean) {
    EventsManager.sendEvent(this.id, propertyName, data, isEvent);
  }

  setEnumProperty(data: any, field: keyof CSSStyleDeclaration, enums: Record<number, string>): void {
    if ([undefined, null].includes(data)) {
      (this.domElement.style as any)[field] = null;
    } else {
      Object.entries(enums).forEach(([value, domValue]) => {
        if (+value === data) {
          (this.domElement.style as any)[field] = domValue;
        }
      });
    }
  }

  setCompositeProperty(
    data: any,
    props: Record<string, keyof CSSStyleDeclaration> | keyof CSSStyleDeclaration,
    hasUM?: boolean,
    element: HTMLElement = this.domElement
  ): void {
    let um = '';
    if (hasUM !== undefined) {
      um = this.getUm(data);
    }
    if (typeof props === 'object') {
      Object.entries(props).forEach(([prop, domProp]) => {
        const value = (data?.value ?? data)?.[prop];
        if ([undefined, null].includes(value)) {
          (element.style as any)[domProp] = null;
        } else {
          (element.style as any)[domProp] = `${value}${um}`;
        }
      });
    } else {
      const value = data?.value ?? data;
      if ([undefined, null].includes(value)) {
        (element.style as any)[props] = null;
      } else {
        (element.style as any)[props] = `${value}${um}`;
      }
    }
  }

  setSimpleProperty(data: any, field: keyof CSSStyleDeclaration, hasUM?: boolean) {
    if ([undefined, null].includes(data)) {
      (this.domElement.style as any)[field] = null;
    } else {
      let um = '';
      if (hasUM !== undefined) {
        um = this.getUm(data);
      }
      (this.domElement.style as any)[field] = `${data}${um}`;
    }
  }

  setTransformProperty(field: string, data: any, hasUM?: boolean) {
    //gestione preliminare della trasformazione skew
    let skewX = '0deg';
    let skewY = '0deg';
    let skewProp = '';
    if (field == 'SkewX' || field == 'SkewY') {
      const transformProps0 = (this.domElement.style as any)['transform'].replace('deg, ', 'deg,'); //gestione per la proprietà skew
      const transformProps = transformProps0.split(' ');
      transformProps.forEach((prop: string) => {
        if (prop.includes('skew')) {
          const values = prop.trim().replace('skew(', '').replace(')', '').split(',');
          skewX = values[0];
          skewY = values[1];
        }
      });

      const skewValue = [undefined, null].includes(data) ? '0' : data;
      if (field == 'SkewX') skewProp = 'skew(' + skewValue + 'deg,' + skewY + ') ';
      else if (field == 'SkewY') skewProp = 'skew(' + skewX + ',' + skewValue + 'deg) ';
    }

    //gestione preliminare della trasformazione transform-origin
    let xOrigin = '';
    let yOrigin = '';
    if (field == 'XPuntoAncoraggio' || field == 'YPuntoAncoraggio') {
      const transformOrigin = (this.domElement.style as any)['transform-origin'];
      const transformProps: Array<string> = transformOrigin.trim().split(' ');
      const len = transformProps.length;

      if (len == 1) {
        if (transformProps[0] != '') xOrigin = transformProps[0];
      } else if (len == 2) {
        xOrigin = transformProps[0];
        yOrigin = transformProps[1];
      }

      if (field == 'XPuntoAncoraggio') {
        if (data == AlignItems.Start) xOrigin = 'left';
        else if (data == AlignItems.Middle) xOrigin = 'center';
        else if (data == AlignItems.End) xOrigin = 'right';
        else if (data == -1) xOrigin = 'center';
        else xOrigin = 'left';
      }

      if (field == 'YPuntoAncoraggio') {
        if (data == AlignItems.Start) yOrigin = 'top';
        else if (data == AlignItems.Middle) yOrigin = 'center';
        else if (data == AlignItems.End) yOrigin = 'bottom';
        else if (data == -1) yOrigin = 'center';
        else yOrigin = 'top';
      }
    }

    let propString = '';
    let propFunc = '';
    data = data ?? '';
    switch (field) {
      case 'OffsetX':
        propString = 'translateX(' + data + 'px) ';
        propFunc = 'translateX';
        break;

      case 'OffsetY':
        propString = 'translateY(' + data + 'px) ';
        propFunc = 'translateY';
        break;

      case 'RotateZ':
        propString = 'rotateZ(' + data + 'deg) ';
        propFunc = 'rotateZ';
        break;

      case 'RotateX':
        propString = 'rotateX(' + data + 'deg) ';
        propFunc = 'rotateX';
        break;

      case 'RotateY':
        propString = 'rotateY(' + data + 'deg) ';
        propFunc = 'rotateY';
        break;

      case 'ScaleX':
        propString = 'scaleX(' + data + ') ';
        propFunc = 'scaleX';
        break;

      case 'ScaleY':
        propString = 'scaleY(' + data + ') ';
        propFunc = 'scaleY';
        break;

      case 'SkewX':
      case 'SkewY':
        propString = skewProp;
        propFunc = 'skew';
        break;

      case 'RiflettiOrizzontale':
        propString = 'scaleX(' + (data ? -1 : 1) + ') ';
        propFunc = 'scaleX';
        break;

      case 'CapovolgiVerticale':
        propString = 'scaleY(' + (data ? -1 : 1) + ') ';
        propFunc = 'scaleY';
        break;

      //gestione ad hoc per queste due proprietà
      case 'XPuntoAncoraggio':
      case 'YPuntoAncoraggio':
        (this.domElement.style as any)['transform-origin'] = xOrigin + ' ' + yOrigin;
        return;

        break;

      case 'Perspective':
        propString = 'perspective(' + data + 'px) ';
        propFunc = 'perspective';
        break;
    }

    if (data == '' && propFunc != 'skew') {
      const transformProps0 = (this.domElement.style as any)['transform'].replace('deg, ', 'deg,'); //gestione per la proprietà skew
      const transformProps = transformProps0.split(' ');
      let transformString = '';
      transformProps.forEach((prop: string) => {
        if (!prop.includes(propFunc)) {
          transformString += prop + ' ';
        }
      });

      (this.domElement.style as any)['transform'] = transformString;
    } else {
      const transformProps0 = (this.domElement.style as any)['transform'].replace('deg, ', 'deg,'); //gestione per la proprietà skew
      const transformProps = transformProps0.split(' ');
      let transformString = '';
      let found = false;
      transformProps.forEach((prop: string) => {
        if (prop.includes(propFunc)) {
          transformString += propString + ' ';
          found = true;
        } else transformString += prop + ' ';
      });

      if (!found) transformString += propString + ' ';

      (this.domElement.style as any)['transform'] = transformString;
    }
  }

  setFontProperties(properties: WidgetProperties, element: HTMLElement, prefix: string) {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case `${prefix}_Font_Size`:
          this.setCompositeProperty(data, 'fontSize', true, element);
          break;
        case `${prefix}_Font_Family`:
          this.setSimpleProperty(data, 'fontFamily');
          break;
        case `${prefix}_Font_Weight`:
          this.setSimpleProperty(data, 'fontWeight');
          break;
        case `${prefix}_Font_Style`:
          this.setSimpleProperty(data, 'fontStyle');
          break;
        case `${prefix}_Font_TextTransform`:
          this.setSimpleProperty(data, 'textTransform');
          break;
        case `${prefix}_Font_TextDecoration`:
          this.setSimpleProperty(data, 'textDecoration');
          break;
        case `${prefix}_Font_LineHeight`:
          this.setCompositeProperty(data, 'lineHeight', true);
          break;
        case `${prefix}_Font_LetterSpacing`:
          this.setCompositeProperty(data, 'letterSpacing', true);
          break;
        case `${prefix}_Font_WordSpacing`:
          this.setCompositeProperty(data, 'wordSpacing', true);
          break;
        default:
          break;
      }
    }
  }

  setDefaultProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'Caption':
          this.domElement.innerText = data;
          break;
        case 'Dim':
          this.setCompositeProperty(data, {
            grow: 'flexGrow',
            shrink: 'flexShrink',
          });
          break;
        case 'MinHeight':
          this.setCompositeProperty(data, 'minHeight', true);
          break;
        case 'MinWidth':
          this.setCompositeProperty(data, 'minWidth', true);
          break;
        case 'MaxHeight':
          this.setCompositeProperty(data, 'maxHeight', true);
          break;
        case 'MaxWidth':
          this.setCompositeProperty(data, 'maxWidth', true);
          break;
        case 'AlignEnd':
          if (this.domElement.style.marginLeft !== 'auto' && data) this.domElement.style.marginLeft = 'auto';
          if (this.domElement.style.marginLeft === 'auto' && !data) this.domElement.style.marginLeft = null;
          break;
        case 'AlignSelf':
          this.setEnumProperty(data, 'alignSelf', {
            [AlignItems.Start]: 'flex-start',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'end',
            [AlignItems.Stretch]: 'stretch',
          });
          break;
        case 'VTextAlign':
          this.setEnumProperty(data, 'alignItems', {
            [AlignItems.Start]: 'flex-start',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'end',
          });
          break;
        case 'HTextAlign':
          this.setEnumProperty(data, 'justifyContent', {
            [AlignItems.Start]: 'flex-start',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'end',
            [AlignItems.Stretch]: 'stretch',
          });
          this.setEnumProperty(data, 'textAlign', {
            [AlignItems.Start]: 'left',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'right',
            [AlignItems.Stretch]: 'justify',
          });
          break;
        case 'Margin':
          this.setCompositeProperty(
            data,
            {
              top: 'marginTop',
              left: 'marginLeft',
              right: 'marginRight',
              bottom: 'marginBottom',
            },
            true
          );
          break;
        case 'Color':
          this.setSimpleProperty(data, 'color');
          break;
        case 'BackgroundColor':
          this.setSimpleProperty(data, 'backgroundColor');
          break;
        case 'BorderWidth':
          this.setCompositeProperty(data, 'borderWidth', true);
          break;
        case 'BorderStyle':
          this.setSimpleProperty(data, 'borderStyle');
          break;
        case 'BorderColor':
          this.setSimpleProperty(data, 'borderColor');
          break;
        case 'BorderRadius':
          this.setSimpleProperty(data, 'borderRadius', true);
          break;
        case 'OffsetX':
        case 'OffsetY':
        case 'RotateZ':
        case 'RotateX':
        case 'RotateY':
        case 'SkewX':
        case 'SkewY':
        case 'ScaleX':
        case 'ScaleY':
        case 'RiflettiOrizzontale':
        case 'CapovolgiVerticale':
        case 'XPuntoAncoraggio':
        case 'YPuntoAncoraggio':
        case 'Perspective':
          this.setTransformProperty(key, data, true);
          break;
        default:
          break;
      }
    }
    this.setFontProperties(properties, this.domElement, 'Main');
  }

  abstract domElement: HTMLElement | null;

  abstract attach(): void;

  detach(): void {
    // unregister widget
    if (this.id) WidgetDictionary.unregisterWidget(this.id);
  }

  getUm(properties: WidgetProperties): string {
    if (properties == undefined) {
      return 'px';
    } else {
      switch (properties.um) {
        case UM.Pixel:
        default:
          return 'px';
        case UM.Perc:
          return '%';
        case UM.Rem:
          return 'rem';
      }
    }
  }
}

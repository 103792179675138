import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetProperties } from '../widget';

export class ImageList extends SimpleWidget {
  displayedImage: number = null;
  imageList: Array<any> = [];

  constructor(public domElement: HTMLElement) {
    super(domElement);
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-imagelist');
    super.attach();
  }

  updateImage() {
    if (this.imageList.length > 0 && this.displayedImage !== null && this.imageList[this.displayedImage]) {
      this.domElement.textContent = `Id: ${this.imageList[this.displayedImage].id}, Name: ${
        this.imageList[this.displayedImage].name
      }`;
      this.domElement.style.backgroundImage = `url(https://foremanbrosinc.com/wp-content/uploads/2017/05/1c0d0f0cb8b7f2fb2685da9798efe42b_big-image-png-image-placeholder-clipart_2400-2400.png)`;
    } else {
      this.resetImage();
    }
  }

  resetImage() {
    this.domElement.textContent = ``;
    this.domElement.style.backgroundImage = `url(https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg)`;
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      let data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'Images':
          const images = data.Items;
          if (images.length > 0) {
            this.imageList = images;
            //immagine eliminata
            if (this.displayedImage !== null && this.displayedImage >= this.imageList.length) {
              //ultima immagine
              this.displayedImage = this.imageList.length - 1;
              this.sendEvent('ImageIndex', this.displayedImage + 1, true);
            }
            if (this.displayedImage === null) {
              this.displayedImage = 0;
              if (!properties['Index']) {
                this.sendEvent('ImageIndex', this.displayedImage + 1, true);
              }
            }
            this.updateImage();
          } else {
            this.displayedImage = null;
            this.resetImage();
          }
          break;
        case 'Index':
          data = parseInt(data) - 1;
          if (data >= 0 && data < this.imageList.length) {
            this.displayedImage = data;
            this.updateImage();
          } else {
            this.displayedImage = null;
            this.resetImage();
          }
          break;
        default:
          this.setDefaultProperties(properties);
          break;
      }
    }
    super.setProperties(properties);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown } from 'primereact/dropdown';
import { FC, useState } from 'react';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';

interface DropdownProps extends PropertyEditorComponentProps {
  options: { label: string; value: string }[];
  filterPresent: boolean;
  placeholder?: string;
  optionGroupLabel?: any;
  optionGroupChildren?: any;
  itemTemplate?: any;
  valueTemplate?: any;
  type: string;
  callback?: (value: any) => void;
  isResponsive: boolean;
}

export const EsaDropdown: FC<DropdownProps> = ({
  data,
  placeholder,
  // type,
  options,
  itemTemplate,
  valueTemplate,
  // callback,
  // isResponsive,
  onDataChange,
  filterPresent = false,
  optionGroupLabel,
  optionGroupChildren,
}) => {
  const [selectedValue, setSelectedValue] = useState(data);
  // const setWidgetProps = useWidgetPropsStore((state: WidgetPropsState) => state.setEditingProps);

  const handleStyleChange = (event: any) => {
    /*
    let formattedColor = getFormattedColor(event?.value, colorFormat)
    doUpdateColor(formattedColor)
    onDataChange(event?.value)
    updateValueExternal();  */
    setSelectedValue(event?.value);
    onDataChange(event?.value);
  };

  return (
    <Dropdown
      scrollHeight="200"
      value={selectedValue || 0}
      placeholder={placeholder}
      options={options}
      filter={filterPresent}
      optionGroupLabel={optionGroupLabel ?? null}
      optionGroupChildren={optionGroupChildren ?? null}
      itemTemplate={itemTemplate ?? null}
      valueTemplate={valueTemplate ?? null}
      onChange={handleStyleChange}
      //      onChange={(e) => callback ? callback(e.value) : setWidgetProps(e.value,{key:type,isResponsive})}
    />
  );
};

export default EsaDropdown;

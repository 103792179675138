/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Leaf } from './Leaf';
import { LeafProps, NodeProps, isLeafProps } from './Interfaces';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import React, { useEffect } from 'react';

export function Node({
  id,
  title,
  icon,
  actions,
  open,
  found,
  hidden,
  children,
  showModal,
  parentFound,
  setOpenNodes,
  sendMessageToTreeview,
  editable,
  autoedit,
  forceOpen,
  selected,
}: NodeProps) {
  const [editMode, setEditMode] = useState(autoedit);
  const [editText, setEditText] = useState(title);

  const op = useRef<OverlayPanel>(null);
  let actionToDo = '';
  let actionDataId = '';

  const handleNodeClick = (event: any) => {
    event.currentTarget.classList.toggle('caret-down');
    event.currentTarget.parentElement.parentElement.querySelector('.nested').classList.toggle('active');
    //comunichiamo al parent lo stato aperto/chiuso del nodo
    const open: boolean = event.currentTarget.classList.contains('caret-down');
    setOpenNodes(id, open);
  };

  useEffect(() => {
    setEditMode(autoedit);
    if (autoedit) {
      sendMessageToTreeview('child-click', JSON.stringify({ id: id }));
      sendMessageToTreeview('open-edit-textbox', JSON.stringify({ id: id }), closeEditText);
      setTimeout(() => {
        const editText = document.getElementById('editText-' + id) as HTMLInputElement;
        editText.select();
        editText.focus();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoedit]);

  const clickHandler = (event: any) => {
    if (selected) sendMessageToTreeview('notify-action', JSON.stringify({ action: 'unselect', id: id }));
    //selezioniamo la foglia
    else sendMessageToTreeview('notify-action', JSON.stringify({ action: 'select', id: id }));
  };

  const mouseOverHandler = (event: any) => {
    event.currentTarget.classList.add('mouseOver');
  };

  const mouseLeaveHandler = (event: any) => {
    event.currentTarget.classList.remove('mouseOver');
  };

  const iconClickHandler = (action: string, id: string, event: any = null) => {
    sendMessageToTreeview('child-click', JSON.stringify({ id: id }));

    if (action.startsWith('new')) {
      if (![undefined, null].includes(event)) {
        const nodeDom = event.currentTarget.parentElement.previousElementSibling;
        if (!nodeDom.classList.contains('caret-down')) {
          nodeDom.currentTarget = nodeDom; //in questo modo nodeDom è un input compatibile per handleNodeClick
          handleNodeClick(nodeDom);
        }
      }
    }

    if (action === 'edit') {
      setEditMode(true);
      sendMessageToTreeview('open-edit-textbox', JSON.stringify({ id: id }), closeEditText);
      //impostiamo il focus ed il select sul testo da modificare
      setTimeout(() => {
        const editText = document.getElementById('editText-' + id) as HTMLInputElement;
        editText.select();
        editText.focus();
      }, 500);
    } else if (action.startsWith('delete')) {
      actionToDo = action;
      actionDataId = id;
      op.current.show(event, event.target);
    } else sendMessageToTreeview('notify-action', JSON.stringify({ action: action, id: id }));
  };

  const saveEditText = () => {
    sendMessageToTreeview('notify-action', JSON.stringify({ action: 'edit', id: id, newTitle: editText }));
    setEditMode(false);
  };

  function closeEditText() {
    setEditMode(false);
  }

  const dragStartHandler = (event: any) => {
    sendMessageToTreeview('drag-start', JSON.stringify({ id: id, type: 'node' }));
  };

  const dragOverHandler = (event: any) => {
    event.currentTarget.classList.add('dragOver');
    event.stopPropagation();
    event.preventDefault();
  };

  const dragLeaveHandler = (event: any) => {
    event.currentTarget.classList.remove('dragOver');
  };

  const dropHandler = (event: any) => {
    event.currentTarget.classList.remove('dragOver');
    sendMessageToTreeview('drop', JSON.stringify({ id: id }));
  };

  const editTextKeyDownHandler = (event: any) => {
    if (editMode) {
      if (event.key === 'Enter') saveEditText();
      else if (event.key === 'Escape') setEditMode(false);
    }
  };

  function getItemClass(st: NodeProps, isCaret = false) {
    let classes = '';

    if (st.hidden) {
      classes += 'hidden base-style ';
      return classes;
    }

    if (isCaret) {
      if (st.forceOpen) classes += 'caret caret-down ';
      else classes += st.open ? 'caret caret-down ' : 'caret ';
    } else {
      if (st.parentFound) classes += 'parent ';
      else if (st.found) classes += 'found ';
      else classes += 'base-style ';
    }

    return classes;
  }

  const clName: any = {
    found: found,
    hidden: hidden,
    childFound: false,
    parentFound: parentFound,
    open: open,
    forceOpen: forceOpen,
  };
  const iconClassName = icon;
  const clName1 = getItemClass(clName, false);
  const clName2 = getItemClass(clName, true);
  const open2: boolean = forceOpen !== undefined ? true : open;

  actions = actions ?? [];
  return (
    <li id={id} className={clName1}>
      <div
        className={'node-container'}
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        onMouseOut={mouseLeaveHandler}
        draggable="true"
        onDragStart={dragStartHandler}
        onClick={clickHandler}
      >
        <span
          className={clName2}
          onClick={handleNodeClick}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
        >
          <i className={iconClassName} style={{ fontSize: '1rem' }}></i>
          &nbsp;<span className={clName1 + (editMode ? 'hidden' : '')}>{title}</span>
          <span className={editMode ? '' : 'hidden '}>
            <InputText
              id={'editText-' + id}
              value={editText}
              className={'p-inputtext-sm padding-1'}
              onChange={(ev) => setEditText(ev.target.value)}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
              onKeyDown={(ev) => {
                editTextKeyDownHandler(ev);
              }}
            />
            &nbsp;
            <Button
              label="Salva"
              className={'padding-2'}
              icon="pi pi-check"
              severity="success"
              rounded
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                saveEditText();
              }}
            />
            &nbsp;
            <Button
              label="Annulla"
              className={'padding-2'}
              icon="pi pi-times"
              severity="danger"
              rounded
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                closeEditText();
              }}
            />
          </span>
        </span>
        <span className={'node-actions'}>
          {' '}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {actions.map((action, index) => {
            const classExtra = action.code === 'edit' && editMode ? ' hidden' : '';
            return (
              <React.Fragment key={index}>
                <i
                  className={action.icon + classExtra}
                  style={{ fontSize: '1rem' }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    iconClickHandler(action.code, id, ev);
                  }}
                ></i>
                &nbsp;&nbsp;
              </React.Fragment>
            );
          })}
        </span>
      </div>
      <ul id={'ul-' + id} className={open2 ? 'nested active' : 'nested'}>
        {children?.map((child) => {
          return isLeafProps(child) ? (
            <Leaf
              autoedit={child.autoedit}
              id={child.id}
              found={child.found}
              hidden={child.hidden}
              selected={(child as LeafProps).selected}
              editable={child.editable}
              key={child.id}
              title={child.title}
              icon={child.icon}
              actions={child.actions}
              childFound={(child as LeafProps).childFound}
              showModal={showModal}
              sendMessageToTreeview={sendMessageToTreeview}
              hint={child.hint ? child.hint : ''}
              data={(child as LeafProps).data ? (child as LeafProps).data : ''}
            />
          ) : (
            <Node
              autoedit={child.autoedit}
              id={child.id}
              found={child.found}
              editable={child.editable}
              hidden={child.hidden}
              key={child.id}
              title={child.title}
              icon={child.icon}
              open={false}
              forceOpen={(child as NodeProps).forceOpen}
              children={(child as NodeProps).children}
              actions={child.actions}
              parentFound={(child as NodeProps).parentFound}
              setOpenNodes={setOpenNodes}
              showModal={showModal}
              sendMessageToTreeview={sendMessageToTreeview}
            />
          );
        })}
      </ul>
      <OverlayPanel ref={op}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Sei sicuro di voler procedere?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button
              label="No"
              onClick={() => {
                op.current.hide();
              }}
            />
            <Button
              label="Si"
              onClick={() => {
                op.current.hide();
                sendMessageToTreeview('notify-action', JSON.stringify({ action: actionToDo, id: actionDataId }));
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </li>
  );
}

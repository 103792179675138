import { Project } from '@api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepCopyInto(source: unknown, destination: any): void {
  // Azzera le chiavi dell'oggetto di destinazione
  Object.keys(destination).forEach((key) => {
    delete destination[key];
  });

  // Controlla se la sorgente è null o undefined, o non un oggetto
  if (source === null || source === undefined || typeof source !== 'object') {
    return;
  }

  // Se la sorgente è un array, azzera completamente la destinazione e copia gli elementi
  if (Array.isArray(source)) {
    destination.length = 0; // Azzera l'array di destinazione
    source.forEach((item, index) => {
      destination[index] = Array.isArray(item) ? [] : {};
      deepCopyInto(item, destination[index]);
    });
    return;
  }

  // Copia le proprietà dall'oggetto sorgente all'oggetto destinazione
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (source[key] !== null && typeof source[key] === 'object') {
        destination[key] = Array.isArray(source[key]) ? [] : {};
        deepCopyInto(source[key], destination[key]);
      } else {
        destination[key] = source[key];
      }
    }
  }
}

export function deepCopy<T>(obj: T): T {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepCopy) as never;
  }

  const copiedObj: unknown = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopy(obj[key]);
    }
  }
  return copiedObj as T;
}

export function deepEqual(a: object, b: object): boolean {
  if (a === b) {
    return true;
  }

  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!Object.prototype.hasOwnProperty.call(b, key) || !deepEqual(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

export function deepNormalizeNull(value: unknown) {
  // Controlla se il valore è null o undefined
  if (value === null || value === undefined) {
    return undefined;
  }

  // Se il valore è un oggetto (ma non un array)
  if (typeof value === 'object' && !Array.isArray(value)) {
    let allPropsAreNullOrUndefined = true;
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        // Utilizza la ricorsione per controllare le proprietà dell'oggetto
        const result = deepNormalizeNull(value[key]);
        if (result !== undefined) {
          // Se anche una sola proprietà non è null o undefined a qualsiasi livello, ritorna il valore
          allPropsAreNullOrUndefined = false;
          break;
        }
      }
    }
    return allPropsAreNullOrUndefined ? undefined : value;
  }

  // Se il valore non rientra nei casi sopra, ritornalo direttamente
  return value;
}

export function findResourceById(
  resourceTree: Project.ResourceTreeElement[],
  id: string
): Project.ResourceTreeElement | undefined {
  for (const element of resourceTree) {
    if (element.data.id === id) {
      return element;
    }
    if (element.children) {
      const found = findResourceById(element.children, id);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}

export function findResourceByName(
  resourceTree: Project.ResourceTreeElement[],
  name: string
): Project.ResourceTreeElement | undefined {
  for (const element of resourceTree) {
    if (element.name === name) {
      return element;
    }
    if (element.children) {
      const found = findResourceByName(element.children, name);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}

export function moveNodeInternalTree(
  docData: Project.DocumentData,
  nodeId: string,
  destinationId: string
): Project.ResourceTreeElement[] {
  try {
    const newDocData: Project.DocumentData = { ...docData };
    // search the destination folder
    if (nodeId && destinationId) {
      const nodeIds = nodeId.split('.');
      let sourceFolder: Project.ResourceTreeElement = undefined;
      let nodeToMove: Project.ResourceTreeElement = undefined;
      if (nodeIds) {
        if (nodeIds.length === 1) {
          nodeToMove = findResourceById(newDocData.resourceTree, nodeIds[0]);
        } else if (nodeIds.length > 1) {
          nodeToMove = findResourceById(newDocData.resourceTree, nodeIds[nodeIds.length - 1]);
          sourceFolder = findResourceById(newDocData.resourceTree, nodeIds[nodeIds.length - 2]);
        }

        if (nodeToMove) {
          // remove from old source
          if (sourceFolder) {
            const updatedSourceFolder = {
              ...sourceFolder,
              children: sourceFolder.children.filter((item) => item.data.id !== nodeToMove.data.id),
            };
            newDocData.resourceTree = updateResourceTree(newDocData.resourceTree, updatedSourceFolder);
          } else {
            newDocData.resourceTree = newDocData.resourceTree.filter((item) => item.data.id !== nodeToMove.data.id);
          }
        }

        // add to new folder
        const destIds = destinationId.split('.');
        let destElement: Project.ResourceTreeElement = undefined;
        if (destIds && destIds.length > 0) {
          destElement = findResourceById(newDocData.resourceTree, destIds[destIds.length - 1]);
        }
        if (destElement) {
          const updatedDestElement = {
            ...destElement,
            children: Array.isArray(destElement.children) ? [...destElement.children, nodeToMove] : [nodeToMove],
          };
          newDocData.resourceTree = updateResourceTree(newDocData.resourceTree, updatedDestElement);
        } else {
          newDocData.resourceTree = [...newDocData.resourceTree, nodeToMove];
        }
      }
    }

    return newDocData.resourceTree;
  } catch (ex) {
    console.log(ex);
  }
}

function updateResourceTree(
  resourceTree: Project.ResourceTreeElement[],
  updatedElement: Project.ResourceTreeElement
): Project.ResourceTreeElement[] {
  return resourceTree.map((item) =>
    item.data.id === updatedElement.data.id
      ? updatedElement
      : {
          ...item,
          children: item.children ? updateResourceTree(item.children, updatedElement) : item.children,
        }
  );
}

import { SelectButton } from 'primereact/selectbutton';
import { WidgetPropsState, useWidgetPropsStore } from '@stores/widgetPropsStore';
import { iconButton } from '../component/SelectButtonComponent';
import React from 'react';
import deviceIcons from '../utils/DeviceIcons';

export const customTemplate = (option: iconButton) => {
  if (option.icon) {
    return <i className={option.icon}></i>;
  } else {
    return option.svg;
  }
};
const DeviceChangeComponent: React.FC = () => {
  const device = useWidgetPropsStore((state: WidgetPropsState) => state.device);
  const setDevice = useWidgetPropsStore((state: WidgetPropsState) => state.setDevice);

  const updateValue = (device: number) => {
    if (device >= 0) {
      setDevice(device);
    }
  };

  return (
    <SelectButton
      value={device}
      onChange={(e) => updateValue(e.value)}
      itemTemplate={customTemplate}
      options={deviceIcons.map((item) => ({ icon: item.icon, value: item.key }))}
    />
  );
};

export default DeviceChangeComponent;

/* eslint-disable prettier/prettier */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Dropdown } from 'primereact/dropdown';
import { FC, useEffect, useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { TimerEvents } from './timer-events';
import { TimerProperties, emptyTimerProperties } from './timer-manager';
import { TreeLink } from '@components/tree-link/TreeLink';
import { deepCopy, deepEqual } from '@services/utils';
import { ipcClient } from '@stores/ipcStore';
import AutoFocusDiv from '@components/common/AutoFocusDiv';

interface TimerEventProps {
  documentId: Project.ProjectNodeKey;
  name: string;
  handleLinkChange: (resourceLinks: Project.ResourceLink[]) => void;
  data: Project.DocumentData;
}

const TimerEvent: FC<TimerEventProps> = ({ documentId, name, handleLinkChange, data }) => {
  const linkOverlay = useRef<OverlayPanel>(null);
  // const editingProperties = useWidgetPropsStore((state: WidgetPropsState) => state.editingProperties);
  const [startResourcePoint, setStartResourcePoint] = useState<Project.ResourceLinkPoint>();
  const [startResourcePinType, setStartResourcePinType] = useState<Project.PinType>();

  useEffect(() => {
    setStartResourcePoint({
      documentId: documentId,
      path: [{ idx: name }],
    });
    setStartResourcePinType(Project.PinType.Out);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <table style={{ borderSpacing: '10px' }}>
        <tbody>
          <tr>
            <td>
              <label htmlFor="enstart" className="mr-2 mb-2">
                {name}
              </label>
            </td>
            <td className="w-full">
              <Button
                type="button"
                className="p-0 mr-0 mx-2 w-20"
                icon="pi pi-link"
                onClick={(e) => linkOverlay.current.toggle(e)}
                text
                aria-label="Resolution"
              />
            </td>
          </tr>
        </tbody>
      </table>


      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          <TreeLink
            startResourcePoint={startResourcePoint}
            startResourcePinType={startResourcePinType}
            resourceLinks={data.resourceLinks}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChangeData={handleLinkChange}
          />
        </AutoFocusDiv>
      </OverlayPanel>
    </>
  );
};

export const TimerDocumentEditor: DocumentEditorComponent = ({
  data,
  status,
  onDataChange,
  onStatusChange,
  documentId,
}) => {
  // Crea un ref
  const divRef = useRef(null);
  const localDocumentData = useRef<Project.DocumentData | null>(null);
  const [state, setState] = useState<TimerProperties>(data.metadata ?? emptyTimerProperties);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const documentName = useRef<string>(ipcClient.findProjectNode(documentId.projectId, documentId.domainId).name);

  // Suddivide l'array in due così da poter realizzare due colonne separate per gli eventi.
  const eventsDivs: [string[], string[]] = [
    TimerEvents.slice(0, Math.ceil(TimerEvents.length / 2)),
    TimerEvents.slice(Math.ceil(TimerEvents.length / 2)),
  ];

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDataChange({
      ...data,
      resourceLinks,
    });
  };

  useEffect(() => {
    // Accedi all'HTMLElement tramite divRef.current dopo che il componente è montato
    if (divRef.current) {
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []); // L'array vuoto come seconda argomentazione significa che useEffect viene eseguito solo dopo il primo render

  const updateCanvas = (newDocumentData: Project.DocumentData) => {
    if (!newDocumentData || !newDocumentData.metadata || Object.keys(newDocumentData.metadata).length === 0) {
      newDocumentData = {
        metadata: {
          id: `timer-${documentId.domainId}`,
          type: 'timer',
          children: [],
        },
      };
    }
    localDocumentData.current = newDocumentData;
  };

  useEffect(() => {
    if (!deepEqual(data, localDocumentData.current) || !data) {
      const newDocumentData = deepCopy(data);
      updateCanvas(newDocumentData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const tickModes = [
    { name: 'Once', code: 'Once' },
    { name: 'Normal', code: 'Normal' },
    { name: 'DateTime', code: 'DateTime' },
    { name: 'Time', code: 'Time' },
  ];

  const types = [
    { name: 'Client', code: 'Client' },
    { name: 'Edge', code: 'Edge' },
  ];

  const saveTimerData = (newValue: object) => {
    onDataChange({ metadata: { ...newValue, documentType: 'timer' } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeStateProperty = (name: string, value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tmp: any = Object.assign({}, state);
    tmp[name.toString()] = value;
    saveTimerData(tmp);
    setState(tmp);
  };

  return (
    <div className="p-3">
      <Fieldset legend="Configuration" style={{ marginTop: '5px' }}>
        <div className="card flex flex-column justify-content-center gap-1 flex-direction-col">
          <div className="p-inputgroup col-12">
            <span className="p-inputgroup-addon">Description </span>
            <InputText
              value={state?.description}
              onChange={(e) => {
                changeStateProperty('description', e.target.value);
              }}
            />
          </div>
          <div className="flex flex-row items-center">
            <div className="p-inputgroup col-3">
              <span className="p-inputgroup-addon">Interval</span>
              <InputText value={state?.interval} onChange={(e) => changeStateProperty('interval', e.target.value)} />
            </div>
            <div className="p-inputgroup col-3">
              <span className="p-inputgroup-addon">Tick mode </span>
              <Dropdown
                options={tickModes}
                optionLabel="name"
                value={state?.tickMode}
                onChange={(e) => changeStateProperty('tickMode', e.value)}
                className="pt-1 pl-1"
              />
            </div>
            <div className="p-inputgroup col-3">
              <span className="p-inputgroup-addon">Type</span>
              <Dropdown
                options={types}
                optionLabel="name"
                value={state?.type}
                onChange={(e) => changeStateProperty('type', e.value)}
                className="pt-1 pl-1"
              />
            </div>
            <div className="flex flex-end col-3 ml-8">
              <div className="p-inputgroup">
                <span
                  className="p-inputgroup-addon mr-4"
                  style={{
                    borderRight: '1px solid #424b57',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                  }}
                >
                  Enable Startup
                </span>
                <Checkbox
                  checked={state?.enableStartup}
                  className="mt-2"
                  onChange={(e) => changeStateProperty('enableStartup', e.checked)}
                />
              </div>
              <div className="p-inputgroup mr-6">
                <span
                  className="p-inputgroup-addon mr-4"
                  style={{
                    borderRight: '1px solid #424b57',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                  }}
                >
                  Constant interval
                </span>
                <Checkbox
                  checked={state?.costantInterval}
                  className="mt-2"
                  onChange={(e) => changeStateProperty('costantInterval', e.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Events" style={{ marginTop: '15px', width: 50 }}>
        <div className="flex flex-row">
          {eventsDivs.map((div, index) => {
            return (
              <div  key={`column-${index}`} className="flex-col">
                {div.map((el) => {
                  return (
                    <TimerEvent documentId={documentId} name={el} handleLinkChange={handleLinkChange} data={data} key={`timer-event-${el}`}/>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Fieldset>
    </div>
  );
};

import { BorderProps, CellStyle } from '@silevis/reactgrid';

export const cellNoBorderStyle: BorderProps = {
  color: '#ffffff',
  width: '0px',
  style: 'solid',
};

export const cellBottomBorderStyle: BorderProps = {
  color: '#424b57',
  width: '1px',
  style: 'solid',
};

export const defaultCellStyle: CellStyle = {
  color: '#ffffff',
};

export const noBorderCellStyle: CellStyle = {
  color: '#ffffff',
  border: {
    top: cellNoBorderStyle,
    left: cellNoBorderStyle,
    right: cellNoBorderStyle,
    bottom: cellBottomBorderStyle,
  },
};

export const buttonStyle = {
  background: 'transparent',
  borderColor: 'transparent',
  color: 'white',
  boxShadow: '0 0 0 0px transparent',
};

export const deleteButtonStyle = {
  background: '#f87171',
  borderColor: '#f87171',
  color: 'white',
  boxShadow: '0 0 0 0px transparent',
};

/* eslint-disable react-hooks/exhaustive-deps */
import '@silevis/reactgrid/styles.css';
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import './Deploy.css';
import { Button } from 'primereact/button';
import { Cloud } from './Cloud';
import { ConnState, ConnType, DeployStatus, Panel, Project } from '@api';

import { DeployPanel, PanelState, usePanelStore } from '@stores/panelStore';
import { DomHandler } from 'primereact/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProjectState, useProjectStore } from '@stores/projectStore';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  Cell,
  CellChange,
  CellTemplate,
  Column,
  Compatible,
  DefaultCellTypes,
  ReactGrid,
  Row,
  TextCell,
  Uncertain,
} from '@silevis/reactgrid';
import { buttonStyle, deleteButtonStyle, noBorderCellStyle } from '@services/reactGridStyles';
import React, { useEffect, useRef, useState } from 'react';

interface ConnTypeCell extends Cell {
  type: 'connTypeCell';
  value: number;
}

interface IdCell extends Cell {
  type: 'idCell';
  value: DeployPanel;
}

interface DeviceLabelCell extends Cell {
  type: 'deviceLabelCell';
  text: string;
}

interface ConnStateCell extends Cell {
  type: 'connStateCell';
  value: number;
}

interface IsSelectedCell extends Cell {
  type: 'isSelectedCell';
  value: DeployPanel;
}

interface DeployStatusCell extends Cell {
  type: 'deployStatusCell';
  value: number;
}

interface ActionCell extends Cell {
  type: 'actionCell';
  value: DeployPanel;
  rowId: number;
}

type CustomCellTypes =
  | DefaultCellTypes
  | ConnTypeCell
  | IdCell
  | DeviceLabelCell
  | ConnStateCell
  | IsSelectedCell
  | DeployStatusCell
  | ActionCell;

const columns: Column[] = [
  { columnId: 'connType', width: 44 },
  { columnId: 'projectRole', width: 131 },
  { columnId: 'id', width: 90 },
  { columnId: 'connUri', width: 98 },
  { columnId: 'deviceLabel', width: 132 },
  { columnId: 'connState', width: 77 },
  { columnId: 'isSelected', width: 54 },
  { columnId: 'deployStatus', width: 84 },
  { columnId: 'events', width: 131 },
];

const headerRow: Row<CustomCellTypes> = {
  rowId: 'header',
  cells: [
    { type: 'header', text: '', style: noBorderCellStyle },
    { type: 'header', text: 'Group', style: noBorderCellStyle },
    { type: 'header', text: 'Mac', style: noBorderCellStyle },
    { type: 'header', text: 'Ip Address', style: noBorderCellStyle },
    { type: 'header', text: 'DeviceId', style: noBorderCellStyle },
    { type: 'header', text: 'Connection', style: noBorderCellStyle },
    { type: 'header', text: 'Deploy', style: noBorderCellStyle },
    { type: 'header', text: 'Deploy Status', style: noBorderCellStyle },
    { type: 'header', text: '', style: noBorderCellStyle },
  ],
};

const refreshButtonStyle = {
  color: 'white',
  boxShadow: '0 0 0 0px transparent',
};

const invalidRowClassName = 'p-row-invalid';

const pattern =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|[1-5][0-9]{4}|[1-9][0-9]{0,3})$/;
let index = 1;

export const DeployComponent = () => {
  const openedProjects = useProjectStore((state: ProjectState) => state.openedProjects);
  const openedProjectsEntries = Object.entries(openedProjects);

  const _getProjectByIndex = (index: number): Project.Project => {
    if (index >= 0 && index < openedProjectsEntries.length) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [key, project] = openedProjectsEntries[index];
      return project;
    } else {
      return null; // O gestisci l'errore come preferisci
    }
  };

  const projectInUse = _getProjectByIndex(0); // mc*** usiamo temporaneamente sempre il primo progetto

  const storePanelList = usePanelStore((state: PanelState) => state.panelList);
  const loadPanels = usePanelStore((state: PanelState) => state.loadPanels);
  const addPanelToProject = usePanelStore((state: PanelState) => state.addPanelToProject);
  const removePanelFromProject = usePanelStore((state: PanelState) => state.removePanelFromProject);
  const editProjectPanel = usePanelStore((state: PanelState) => state.editProjectPanel);
  const editPanelSelection = usePanelStore((state: PanelState) => state.editSelection);
  const retryPanelConnection = usePanelStore((state: PanelState) => state.retryPanelConnection);
  const deployToPanels = usePanelStore((state: PanelState) => state.deployToPanels);

  const [panelsList, setPanelsList] = useState<DeployPanel[]>([]);
  const [rows, setRows] = useState<Row<CustomCellTypes>[]>([]);

  const gridContainerRef = useRef(null);

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const cloudOp = useRef(null);

  const [disabledButtons, setDisabledButtons] = useState(false);
  const [panelAdded, setPanelAdded] = useState<DeployPanel | null>(null);
  const [rowNewPanel, setRowNewPanel] = useState(0);
  const [AddOperation, setAddOperation] = useState(false);
  const [deployOperation, setDeployOperation] = useState(false);

  // functions
  const _addErrorClass = (cellElement: HTMLElement) => {
    // if the class is not present, add it
    if (!cellElement.classList.contains(invalidRowClassName)) {
      cellElement.classList.add(invalidRowClassName);
    }
  };

  const _removeErrorClass = (cellElement: HTMLElement) => {
    // if the class is present, remove it
    if (cellElement.classList.contains(invalidRowClassName)) {
      cellElement.classList.remove(invalidRowClassName);
    }
  };

  const _isValidIpAddres = (ipAddress: string): boolean => {
    // check if the ip address is defined
    if (!ipAddress) return false;

    // check if the ip address is a valid address
    if (!pattern.test(ipAddress)) return false;

    return true;
  };

  const _isUniqueIpAddress = (ipAddress: string): boolean => {
    const panels = storePanelList.filter((panel: Panel) => {
      return panel.projectId !== undefined;
    });

    const ipAddressCount = panels.filter((panel: Panel) => panel.connUri === ipAddress).length;

    return ipAddressCount === 1;
  };

  const _checkCell = (cell: HTMLElement, panel: DeployPanel) => {
    if (AddOperation) {
      // show error for new panel to force the edit of the connUri
      if (
        parseInt(cell.getAttribute('data-cell-colidx')) === 3 &&
        panel.connUri === '' &&
        parseInt(cell.getAttribute('data-cell-rowidx')) === rowNewPanel
      ) {
        _addErrorClass(cell);
      } else {
        _removeErrorClass(cell);
      }
    } else {
      if (panel.isSelected) {
        const connStateEnumValue = ConnState[panel.connState];
        if (connStateEnumValue === ConnState[ConnState.Connected]) {
          switch (parseInt(cell.getAttribute('data-cell-colidx'))) {
            case 1: // projectRole
              if (panel.projectRole === '') {
                _addErrorClass(cell);
              } else {
                _removeErrorClass(cell);
              }
              break;
            case 3: // connUri
              if (!_isValidIpAddres(panel.connUri)) {
                _addErrorClass(cell);
              } else {
                // check if the ip address in unique
                if (!_isUniqueIpAddress(panel.connUri)) {
                  _addErrorClass(cell);
                } else {
                  _removeErrorClass(cell);
                }
              }
              break;
          }
        } else {
          _addErrorClass(cell);
        }
        // remove class if it is present
      } else {
        _removeErrorClass(cell);
      }
    }
  };

  const _updateAllRowsForError = (list: DeployPanel[]) => {
    setTimeout(() => {
      // search the table
      const tables = DomHandler.find(document.body, '.rg-pane');
      if (tables && tables.length > 0) {
        tables.forEach((table) => {
          const gridCells: HTMLElement[] = Array.from(table.querySelectorAll('.rg-cell'));
          // search the cells
          if (gridCells && gridCells.length > 0) {
            let lastRow = '';
            let currPanel: DeployPanel = null;
            gridCells.forEach((cell) => {
              // not header cells and no selection cells
              if (!cell.className.includes('rg-header-cell') && !cell.className.includes('rg-isSelectedCell-cell')) {
                // search the current Panel
                const currRow = cell.getAttribute('data-cell-rowidx');
                if (lastRow === '' || lastRow !== currRow) {
                  // change the current panel in use
                  currPanel = list[parseInt(currRow) - 1];
                  lastRow = currRow;
                }
                if (currPanel !== null && currPanel !== undefined) {
                  _checkCell(cell, currPanel);
                }
              }
            });
          }
        });
      }
    }, 3);
  };

  const _enableDisableButtons = (list: DeployPanel[]) => {
    setTimeout(() => {
      let invalid = 0;
      let panelSelected = 0;
      list.forEach((panel) => {
        // check if the panel is connected and selected
        const connStateEnumValue = ConnState[panel.connState];
        if (connStateEnumValue === ConnState[ConnState.Connected] && panel.isSelected) {
          panelSelected++;
          // check if the panel has a valid ip address and a valid group
          if (!_isValidIpAddres(panel.connUri) || panel.projectRole === '' || !_isUniqueIpAddress(panel.connUri)) {
            invalid++;
          }
        }
      });
      setDisabledButtons(panelSelected === 0 || invalid > 0);
    }, 3);
  };

  const _isConnUriNoNEditabled = (panel: DeployPanel): boolean => {
    return panel.connType === ConnType.Normal;
  };

  const _isAddedRow = (rowId: number): boolean => {
    // during the add, the only valid row is the last added row
    if (AddOperation) {
      return rowId !== rowNewPanel;
    } else return false;
  };

  const _getReactTooltip = (id: string) => {
    return <ReactTooltip id={id} className="custom-tooltip" place="bottom" />;
  };

  const _getImageWithTooltipStyle = (color: string): React.CSSProperties => {
    return { color, position: 'relative', display: 'inline-block' };
  };

  const _getConnStateIconProperty = (
    connStateEnumValue: string
  ): { createIcon: boolean; className: string; colorForStyle: string; tooltipContext: string } => {
    let createIcon = false;
    let className = '';
    let colorForStyle = '';
    let tooltipContext = '';
    switch (connStateEnumValue) {
      case ConnState[ConnState.Offline]:
        createIcon = true;
        className = 'pi pi-wifi';
        colorForStyle = 'red';
        tooltipContext = 'Offline';
        break;
      case ConnState[ConnState.Connected]:
        createIcon = true;
        className = 'pi pi-wifi';
        colorForStyle = 'green';
        tooltipContext = 'Connected';
        break;
      case ConnState[ConnState.Connecting]:
        createIcon = true;
        className = 'pi pi-wifi';
        colorForStyle = 'orange';
        tooltipContext = 'Connecting';
        break;
      case ConnState[ConnState.Invalid]:
        createIcon = true;
        className = 'pi pi-times';
        colorForStyle = 'red';
        tooltipContext = 'Invalid';
        break;
      case ConnState[ConnState.Unreachable]:
        createIcon = true;
        className = 'pi pi-times';
        colorForStyle = 'orange';
        tooltipContext = 'Unreachable';
        break;
      case ConnState[ConnState.Unknown]:
      default:
        break;
    }

    return { createIcon, className, colorForStyle, tooltipContext };
  };

  const _getDeployStatusIconProperty = (
    deployStatusEnumValue: string
  ): { createIcon: boolean; colorForStyle: string; tooltipContext: string } => {
    let createIcon = false;
    let colorForStyle = '';
    let tooltipContext = '';
    switch (deployStatusEnumValue) {
      case DeployStatus[DeployStatus.InProgress]:
        createIcon = true;
        colorForStyle = 'orange';
        tooltipContext = 'In progress';
        break;
      case DeployStatus[DeployStatus.Success]:
        createIcon = true;
        colorForStyle = 'green';
        tooltipContext = 'Success';
        break;
      case DeployStatus[DeployStatus.Failure]:
        createIcon = true;
        colorForStyle = 'red';
        tooltipContext = 'Failure';
        break;
      case DeployStatus[DeployStatus.Unknown]:
      default:
        break;
    }

    return { createIcon, colorForStyle, tooltipContext };
  };

  useEffect(() => {
    if (projectInUse !== null) {
      loadPanels(projectInUse.id).then((panels) => {
        const filtredList = panels.filter((panel: Panel) => panel.projectId !== undefined);
        // update list
        setPanelsList(filtredList);
        setRows(getRows(filtredList));
      });
    }
  }, []);

  useEffect(() => {
    if (storePanelList.length > 0) {
      const filtredList = storePanelList.filter((panel: Panel) => panel.projectId !== undefined);
      // update list
      setPanelsList(filtredList);
      setRows(getRows(filtredList));

      // update UI
      _updateAllRowsForError(filtredList);
      _enableDisableButtons(filtredList);
    }
  }, [storePanelList]);

  useEffect(() => {
    if (gridContainerRef.current) {
      gridContainerRef.current.scrollTop = gridContainerRef.current.scrollHeight;
    }
  }, [rows.length]);

  // Handlers
  const handleRemove = (rowData: Panel) => {
    if (projectInUse !== null) {
      removePanelFromProject(projectInUse.id, rowData.id);
    }

    if (AddOperation) {
      setAddOperation(false);
      setPanelAdded(null);
    }
  };

  const handleRefresh = (rowData: Panel) => {
    retryPanelConnection(rowData.id);
  };

  const handleAddNewPanel = () => {
    let projectId = '';
    if (projectInUse !== null) {
      projectId = projectInUse.id;
    }
    const newPanel: DeployPanel = {
      connType: ConnType.Reverse,
      projectRole: 'Main',
      id: '',
      connUri: '',
      deviceLabel: 'Device_' + index,
      connState: ConnState.Unknown,
      isSelected: false,
      serialIsValid: false,
      projectId,
      deployStatus: DeployStatus.Unknown,
    };

    // increase index
    index++;

    setAddOperation(true);
    setPanelAdded(newPanel);
    setRowNewPanel(panelsList.length + 1); // +1 for new panel
    addPanelToProject(projectInUse.id, { connUri: newPanel.connUri }, newPanel.projectRole);
  };

  const handleOnCellChange = (cellChanges: CellChange[]) => {
    if (cellChanges.length > 0) {
      const newValue = (cellChanges[0].newCell as TextCell).text;

      // search the panel in use
      const rowIndex: number = cellChanges[0].rowId as number; // 0 base
      const currPanel: DeployPanel = panelsList[rowIndex];

      if (projectInUse !== null) {
        let whatString = {};
        switch (cellChanges[0].columnId) {
          case 'connUri':
            whatString = { newConnUri: newValue, newRole: currPanel.projectRole };
            break;
          case 'projectRole':
            whatString = { newConnUri: currPanel.connUri, newRole: newValue };
            break;
        }
        editProjectPanel(projectInUse.id, currPanel.id, whatString);

        if (AddOperation && rowIndex === rowNewPanel - 1) {
          setAddOperation(false);
          setPanelAdded(null);
        }
      }
    }
  };

  const handleCloudPanels = (e: React.SyntheticEvent<Element, Event>) => {
    setIsOverlayVisible(true);
    cloudOp.current.toggle(e);
  };

  const handleOverlayHide = () => {
    setIsOverlayVisible(false);
  };

  const handleDeployPanels = async () => {
    // disable buttons
    setDeployOperation(true);

    // deploy
    try {
      if (projectInUse !== null) {
        await deployToPanels(projectInUse.id);
      }
    } finally {
      // enabled buttons
      setDeployOperation(false);
    }
  };

  // Templates
  const ConnTypeCellTemplate: CellTemplate<Compatible<ConnTypeCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<ConnTypeCell>): Compatible<ConnTypeCell> => {
      const cell = {
        ...uncertainCell,
        type: 'connTypeCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<ConnTypeCell>;
    },
    render: (cell: Compatible<ConnTypeCell>) => {
      const connTypeEnumValue = ConnType[cell.value];
      if (connTypeEnumValue === ConnType[ConnType.Normal]) {
        return <i className="pi pi-cloud" />;
      } else {
        return null;
      }
    },
    isFocusable: () => false,
  };

  const IdCellTemplate: CellTemplate<Compatible<IdCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<IdCell>): Compatible<IdCell> => {
      const cell = {
        ...uncertainCell,
        type: 'idCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<IdCell>;
    },
    render: (cell: Compatible<IdCell>) => {
      if (cell.value.serialIsValid) {
        return cell.value.id;
      } else {
        return null;
      }
    },
    isFocusable: () => false,
  };

  const DeviceLabelCellTemplate: CellTemplate<Compatible<DeviceLabelCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<DeviceLabelCell>): Compatible<DeviceLabelCell> => {
      const cell = {
        ...uncertainCell,
        type: 'deviceLabelCell',
        text: uncertainCell.text,
      };
      return cell as Compatible<DeviceLabelCell>;
    },
    render: (cell: Compatible<DeviceLabelCell>) => {
      return <span>{cell.text}</span>;
    },
    isFocusable: () => false,
  };

  const ConnStateCellTemplate: CellTemplate<Compatible<ConnStateCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<ConnStateCell>): Compatible<ConnStateCell> => {
      const cell = {
        ...uncertainCell,
        type: 'connStateCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<ConnStateCell>;
    },
    render: (cell: Compatible<ConnStateCell>) => {
      const connStateEnumValue = ConnState[cell.value];
      const iconProperty = _getConnStateIconProperty(connStateEnumValue);
      if (iconProperty.createIcon) {
        return (
          <div>
            <i
              className={iconProperty.className}
              style={_getImageWithTooltipStyle(iconProperty.colorForStyle)}
              data-tooltip-id="myConnStateTooltip"
              data-tooltip-content={iconProperty.tooltipContext}
            />
            {_getReactTooltip('myConnStateTooltip')}
          </div>
        );
      } else return null;
    },
    isFocusable: () => false,
  };

  const IsSelectedCellTemplate: CellTemplate<Compatible<IsSelectedCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<IsSelectedCell>): Compatible<IsSelectedCell> => {
      const cell = {
        ...uncertainCell,
        type: 'isSelectedCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<IsSelectedCell>;
    },
    render: (cell: Compatible<IsSelectedCell>) => {
      const connStateEnumValue = ConnState[cell.value.connState];
      if (connStateEnumValue === ConnState[ConnState.Connected]) {
        return (
          <InputSwitch
            checked={cell.value.isSelected}
            disabled={deployOperation || AddOperation}
            onChange={(e) => editPanelSelection(cell.value.projectId, cell.value.id, e.value)}
          />
        );
      } else {
        return <InputSwitch checked={cell.value.isSelected} disabled />;
      }
    },
    isFocusable: () => false,
  };

  const DeployStatusCellTemplate: CellTemplate<Compatible<DeployStatusCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<DeployStatusCell>): Compatible<DeployStatusCell> => {
      const cell = {
        ...uncertainCell,
        type: 'deployStatusCell',
        value: uncertainCell.value,
      };
      return cell as Compatible<DeployStatusCell>;
    },
    render: (cell: Compatible<DeployStatusCell>) => {
      const deployStatusEnumValue = DeployStatus[cell.value];
      const iconProperty = _getDeployStatusIconProperty(deployStatusEnumValue);
      if (iconProperty.createIcon) {
        return (
          <div>
            <i
              className="pi pi-download"
              style={_getImageWithTooltipStyle(iconProperty.colorForStyle)}
              data-tooltip-id="myDeployStatusTooltip"
              data-tooltip-content={iconProperty.tooltipContext}
            />
            {_getReactTooltip('myDeployStatusTooltip')}
          </div>
        );
      } else return null;
    },
    isFocusable: () => false,
  };

  const ActionCellTemplate: CellTemplate<Compatible<ActionCell>> = {
    getCompatibleCell: (uncertainCell: Uncertain<ActionCell>): Compatible<ActionCell> => {
      const cell = {
        ...uncertainCell,
        type: 'actionCell',
        value: uncertainCell.value,
        rowId: uncertainCell.rowId,
      };
      return cell as Compatible<ActionCell>;
    },
    render: (cell: Compatible<ActionCell>) => {
      // the buttons are not visible during the add
      if (panelAdded === null || panelAdded.id !== cell.value.id) {
        const connTypeEnumValue = ConnType[cell.value.connType];
        const connStateEnumValue = ConnState[cell.value.connState];
        return (
          <>
            <Button
              disabled={isOverlayVisible || _isAddedRow(cell.rowId) || deployOperation}
              style={deleteButtonStyle}
              icon="pi pi-trash"
              onClick={() => handleRemove(cell.value)}
            ></Button>
            {connTypeEnumValue !== ConnType[ConnType.Normal] &&
              connStateEnumValue !== ConnState[ConnState.Connected] &&
              connStateEnumValue !== ConnState[ConnState.Connecting] &&
              connStateEnumValue !== ConnState[ConnState.Invalid] && (
                <Button
                  disabled={isOverlayVisible || AddOperation || deployOperation}
                  style={refreshButtonStyle}
                  icon="pi pi-refresh"
                  onClick={() => handleRefresh(cell.value)}
                ></Button>
              )}
            {connStateEnumValue !== ConnState[ConnState.Unknown] &&
              connStateEnumValue !== ConnState[ConnState.Unreachable] && (
                <Button
                  disabled={isOverlayVisible || AddOperation || deployOperation}
                  style={buttonStyle}
                  icon="pi"
                ></Button>
              )}
          </>
        );
      } else return null;
    },
    isFocusable: () => false,
  };

  const myCellTemplates: Record<string, CellTemplate> = {
    connTypeCell: ConnTypeCellTemplate,
    idCell: IdCellTemplate,
    deviceLabelCell: DeviceLabelCellTemplate,
    connStateCell: ConnStateCellTemplate,
    isSelectedCell: IsSelectedCellTemplate,
    deployStatusCell: DeployStatusCellTemplate,
    actionCell: ActionCellTemplate,
  };

  // ReactGrid
  const getRows = (panels: DeployPanel[]): Row<CustomCellTypes>[] => [
    headerRow,
    ...panels.map<Row<CustomCellTypes>>((panel, idx) => ({
      rowId: idx,
      height: 50,
      cells: [
        { type: 'connTypeCell', value: panel.connType, nonEditable: true, style: noBorderCellStyle },
        { type: 'text', text: panel.projectRole, style: noBorderCellStyle },
        { type: 'idCell', value: panel, nonEditable: true, style: noBorderCellStyle },
        {
          type: 'text',
          text: panel.connUri,
          nonEditable: _isConnUriNoNEditabled(panel),
          style: noBorderCellStyle,
        },
        { type: 'deviceLabelCell', text: panel.deviceLabel, nonEditable: true, style: noBorderCellStyle },
        { type: 'connStateCell', value: panel.connState, nonEditable: true, style: noBorderCellStyle },
        { type: 'isSelectedCell', value: panel, style: noBorderCellStyle },
        { type: 'deployStatusCell', value: panel.deployStatus, nonEditable: true, style: noBorderCellStyle },
        { type: 'actionCell', value: panel, nonEditable: true, style: noBorderCellStyle, rowId: idx + 1 },
      ],
    })),
  ];

  return (
    <div className="card toolbar-container">
      <div style={{ marginBottom: '5px' }}>
        <Button
          disabled={AddOperation || deployOperation}
          style={{ color: 'white', marginRight: '5px' }}
          icon="pi pi-cloud"
          onClick={handleCloudPanels}
        />
        <Button
          className={`${isOverlayVisible ? 'disabled-overlay' : ''}`}
          disabled={isOverlayVisible || AddOperation || deployOperation}
          style={{ color: 'white' }}
          icon="pi pi-plus"
          onClick={handleAddNewPanel}
        />
      </div>
      <div style={{ maxHeight: '300px', overflow: 'auto' }} ref={gridContainerRef}>
        <ReactGrid
          rows={rows}
          columns={columns}
          customCellTemplates={myCellTemplates}
          onCellsChanged={handleOnCellChange}
        />
      </div>

      <div
        className={`${isOverlayVisible ? 'disabled-overlay' : ''}`}
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '35px' }}
      >
        <Button
          disabled={isOverlayVisible || disabledButtons || deployOperation || AddOperation}
          style={{ color: 'white' }}
          label="Deploy"
          onClick={handleDeployPanels}
        />
      </div>

      <OverlayPanel ref={cloudOp} onHide={handleOverlayHide}>
        <Cloud projectInUse={projectInUse} />
      </OverlayPanel>
    </div>
  );
};

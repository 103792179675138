import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import { FlexboxDirection } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/concrete-widgets/view-widget';
import { Project } from '@api';
import { PropertiesConfig } from './interface';
import { TabName, TextPosition } from './enum';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import MultiEdit from '@components/property-edit/component/MultiEditComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';
import ToggleButtonBar from '@components/property-edit/component/ToggleButtonComponent';

export const dimAndPosFields: PropertiesConfig = {
  onUpdate: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (__data: any, parentData: any, fields: any) => {
      const vertProps = [
        { icon: 'bi-align-top', value: AlignItems.Start },
        { icon: 'bi-align-middle', value: AlignItems.Middle },
        { icon: 'bi-align-bottom', value: AlignItems.End },
        { icon: 'bi-distribute-vertical', value: AlignItems.Stretch },
      ];
      const horizProps = [
        { icon: 'bi-align-start', value: AlignItems.Start },
        { icon: 'bi-align-center', value: AlignItems.Middle },
        { icon: 'bi-align-end', value: AlignItems.End },
        { icon: 'bi-distribute-horizontal', value: AlignItems.Stretch },
      ];

      if (
        [FlexboxDirection.Right, FlexboxDirection.Left].includes(parentData['FlexDirection']) ||
        parentData['FlexDirection'] === undefined
      ) {
        fields['AlignSelf'].caption = 'Allinea verticalmente rispetto al container';
        fields['AlignSelf'].editorOptions.options = vertProps;
      } else {
        fields['AlignSelf'].caption = 'Allinea orizzontalmente rispetto al container';
        fields['AlignSelf'].editorOptions.options = horizProps;
      }
    },
  ],
  properties: {
    Dim: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        options: [
          { hint: 'Grow', field: 'grow', minValue: 0 },
          { hint: 'Shrink', field: 'shrink', minValue: 0 },
        ],
      },
      caption: 'Occupazione spazio',
      editor: MultiEdit,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
    MinHeight: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Altezza minima',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    MinWidth: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Larghezza minima',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    MaxHeight: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Altezza massima',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    MaxWidth: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        minValue: 0,
        maxValue: 1000,
      },
      caption: 'Larghezza massima',
      editor: NumberSlider,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    AlignEnd: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editor: ToggleButtonBar,
      pinType: Project.PinType.None,
      caption: 'Allinea in fondo',
      captionPosition: TextPosition.Left,
    },
    Margin: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      editorOptions: {
        options: [
          { hint: 'Top', field: 'top' },
          { hint: 'Right', field: 'right' },
          { hint: 'Bottom', field: 'bottom' },
          { hint: 'Left', field: 'left' },
        ],
      },
      caption: 'Margin',
      editor: MultiEdit,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
      hasUM: true,
    },
    AlignSelf: {
      tab: TabName.Layout,
      section: 'Dimensione e posizione',
      defaultData: AlignItems.Stretch,
      editorOptions: {
        options: [
          { icon: 'bi-align-start', value: AlignItems.Start },
          { icon: 'bi-align-center', value: AlignItems.Middle },
          { icon: 'bi-align-end', value: AlignItems.End },
          { icon: 'bi-distribute-horizontal', value: AlignItems.Stretch },
        ],
      },
      caption: 'Allinea contenuto su asse ortogonale',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      isResponsive: true,
      pinType: Project.PinType.None,
    },
  },
};

import { CgWebsite } from 'react-icons/cg';
import { CiImageOn } from 'react-icons/ci';
import { CiText } from 'react-icons/ci';
import { FaImages } from 'react-icons/fa';
import { FaLink, FaLinkSlash } from 'react-icons/fa6';
import { GoWorkflow } from 'react-icons/go';
import { GrAdd, GrEdit, GrRefresh } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp, IoIosListBox, IoMdCheckboxOutline, IoMdRadioButtonOn } from 'react-icons/io';
import { MdInput, MdOutlineOndemandVideo, MdOutlineSmartButton, MdOutlineViewTimeline, MdOutput } from 'react-icons/md';
import { PiFileSvg, PiSlidersHorizontalLight, PiTabsLight, PiTextbox } from 'react-icons/pi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { RxDoubleArrowDown, RxDoubleArrowUp } from 'react-icons/rx';
import { TfiLayoutAccordionList } from 'react-icons/tfi';
import { VscArrowLeft, VscArrowRight, VscArrowSwap } from 'react-icons/vsc';
import React from 'react';

class ReactImages {
  private static iconDictionary: Record<string, React.ReactNode> = {
    labelIcon: <CiText />,
    viewIcon: <MdOutlineViewTimeline />,
    textBoxIcon: <PiTextbox />,
    buttonIcon: <MdOutlineSmartButton />,
    link: <FaLink />,
    unlink: <FaLinkSlash />,
    arrowLeft: <VscArrowLeft />,
    arrowRight: <VscArrowRight />,
    arrowRightLeft: <VscArrowSwap />,
    edit: <GrEdit />,
    delete: <RiDeleteBinLine />,
    transform: <GrRefresh />,
    add: <GrAdd />,
    doubleArrowUp: <RxDoubleArrowUp />,
    arrowUp: <IoIosArrowUp />,
    arrowDown: <IoIosArrowDown />,
    doubleArrowDown: <RxDoubleArrowDown />,
    videoPlayerIcon: <MdOutlineOndemandVideo />,
    checkboxIcon: <IoMdCheckboxOutline />,
    radioIcon: <IoMdRadioButtonOn />,
    frameHtmlIcon: <CgWebsite />,
    listBoxIcon: <IoIosListBox />,
    accordionIcon: <TfiLayoutAccordionList />,
    sliderIcon: <PiSlidersHorizontalLight />,
    svgIcon: <PiFileSvg />,
    svgTextIcon: <PiTextbox />,
    svgRectIcon: <PiTextbox />,
    svgExternalIcon: <PiFileSvg />,
    tabsIcon: <PiTabsLight />,
    graphFlowIcon: <GoWorkflow />,
    input: <MdInput />,
    output: <MdOutput />,
    imageIcon: <CiImageOn />,
    imageListIcon: <FaImages />,
  };

  static getReactImage = (type: string): React.ReactNode | undefined => {
    return ReactImages.iconDictionary[type];
  };
}

export default ReactImages;

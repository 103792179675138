/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontFieldConfig, PropertiesConfig } from './interface';
import { Project } from '@api';
import { TabName, TextPosition } from './enum';
import { merge } from './widgetData';
import EsaDropdown from '@components/property-edit/component/DropdownComponent';
import MultiPropertyEditor from '@components/property-edit/component/MultiPropertyComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';

const fontFamilySelectedItemTemplate = (option: any, props: any) => {
  if (option) {
    return <span style={{ fontFamily: option.value }}>{option.label}</span>;
  } else return <span>{props.placeholder}</span>;
};

const fontFamilyItemTemplate = (option: any) => {
  return <span style={{ fontFamily: option.value }}>{option.label}</span>;
};

export const fontField = (config: FontFieldConfig): PropertiesConfig => ({
  properties: {
    [`${config.prefix}_Font`]: {
      tab: TabName.Style,
      section: 'Font',
      editor: MultiPropertyEditor,
      pinType: Project.PinType.None,
      caption: 'Font',
      captionPosition: TextPosition.Left,
      editorOptions: {
        customOverlayPosition: true,
        subProperties: merge({
          properties: {
            [`${config.prefix}_Font_Size`]: {
              defaultData: 16,
              editorOptions: {
                minValue: 0,
                maxValue: 1000,
              },
              caption: 'Dimensione',
              editor: NumberSlider,
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },

            [`${config.prefix}_Font_Family`]: {
              tab: TabName.Style,
              section: 'Font',
              hasUM: false,
              caption: 'Famiglia',
              editor: EsaDropdown,
              captionPosition: TextPosition.Left,
              isResponsive: true,
              pinType: Project.PinType.None,
              defaultData: config.family ?? 'Arial',
              editorOptions: {
                placeholder: ' -Seleziona- ',
                filterPresent: true,
                itemTemplate: fontFamilyItemTemplate,
                valueTemplate: fontFamilySelectedItemTemplate,
                options: [
                  { label: 'Arial', value: 'Arial' },
                  { label: 'Baskerville', value: 'Baskerville' },
                  { label: 'Brush Script', value: 'Brush Script' },
                  { label: 'Calibri', value: 'Calibri' },
                  { label: 'Cambria', value: 'Cambria' },
                  { label: 'Consolas', value: 'Consolas' },
                  { label: 'Courier', value: 'Courier' },
                  { label: 'Garamond', value: 'Garamond' },
                  { label: 'Georgia', value: 'Georgia' },
                  { label: 'Helvetica', value: 'Helvetica' },
                  { label: 'Palatino', value: 'Palatino' },
                  { label: 'Tahoma', value: 'Tahoma' },
                  { label: 'Times New Roman', value: 'Times New Roman' },
                  { label: 'Trebuchet MS', value: 'Trebuchet MS' },
                  { label: 'Verdana', value: 'Verdana' },
                ],
              },
            },

            [`${config.prefix}_Font_Weight`]: {
              tab: TabName.Style,
              section: 'Font',
              hasUM: false,
              editor: EsaDropdown,
              pinType: Project.PinType.None,
              caption: 'Peso',
              captionPosition: TextPosition.Left,
              defaultData: config.weight ?? 400,
              editorOptions: {
                filterPresent: true,
                options: [
                  { label: 'Thin', value: 100 },
                  { label: 'Extra Light', value: 200 },
                  { label: 'Light', value: 300 },
                  { label: 'Normal ', value: 400 },
                  { label: 'Medium', value: 500 },
                  { label: 'Semi Bold', value: 600 },
                  { label: 'Bold', value: 700 },
                  { label: 'Extra Bold', value: 800 },
                  { label: 'Black', value: 900 },
                ],
              },
            },

            [`${config.prefix}_Font_TextTransform`]: {
              tab: TabName.Style,
              section: 'Font',
              hasUM: false,
              editor: EsaDropdown,
              pinType: Project.PinType.None,
              caption: 'Trasformazione',
              captionPosition: TextPosition.Left,
              defaultData: config.textTransform ?? 'null',
              editorOptions: {
                options: [
                  { label: 'Predefinito', value: 'null' },
                  { label: 'Maiuscolo', value: 'uppercase' },
                  { label: 'Minuscolo', value: 'lowercase' },
                  { label: 'Iniziali maiuscole', value: 'capitalize' },
                  { label: 'Normale ', value: 'none' },
                ],
              },
            },

            [`${config.prefix}_Font_Style`]: {
              tab: TabName.Style,
              section: 'Font',
              hasUM: false,
              editor: EsaDropdown,
              pinType: Project.PinType.None,
              caption: 'Stile',
              captionPosition: TextPosition.Left,
              defaultData: config.fontStyle ?? 'null',
              editorOptions: {
                options: [
                  { label: 'Predefinito', value: 'null' },
                  { label: 'Normale', value: 'normal' },
                  { label: 'Corsivo', value: 'italic' },
                  { label: 'Obliquo', value: 'oblique' },
                ],
              },
            },

            [`${config.prefix}_Font_TextDecoration`]: {
              tab: TabName.Style,
              section: 'Font',
              hasUM: false,
              editor: EsaDropdown,
              pinType: Project.PinType.None,
              caption: 'Decorazione',
              captionPosition: TextPosition.Left,
              defaultData: config.fontStyle ?? 'null',
              editorOptions: {
                options: [
                  { label: 'Predefinito', value: 'null' },
                  { label: 'Sottolineatura', value: 'underline' },
                  { label: 'Sopralineato', value: 'overline' },
                  { label: 'Linea in mezzo', value: 'line-through' },
                  { label: 'Nessuno', value: 'none' },
                ],
              },
            },

            [`${config.prefix}_Font_LineHeight`]: {
              defaultData: 20,
              editorOptions: {
                minValue: 0,
                maxValue: 1000,
              },
              caption: 'Altezza linea',
              editor: NumberSlider,
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },

            [`${config.prefix}_Font_LetterSpacing`]: {
              defaultData: 1,
              editorOptions: {
                minValue: 0,
                maxValue: 1000,
              },
              caption: 'Spaziatura carattere',
              editor: NumberSlider,
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },

            [`${config.prefix}_Font_WordSpacing`]: {
              defaultData: 1.28,
              editorOptions: {
                minValue: 0,
                maxValue: 1000,
              },
              caption: 'Spaziatura parole',
              editor: NumberSlider,
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },
          },
        }),
      },
    },
  },
});

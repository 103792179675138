import { SvgExternal } from '../concrete-widgets/svgexternal-widget';
import { SvgRect } from '../concrete-widgets/svgrect-widget';
import { SvgText } from '../concrete-widgets/svgtext-widget';
import { Widget, WidgetProperties } from '../widget';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetFactory } from '../widget-factory';

export class Svg implements Widget {
  private widgetList: Widget[] = [];
  private id: string | null = null;
  private currentProperties: WidgetProperties = {};
  public domElement: HTMLDivElement; // L'elemento principale che conterrà l'SVG
  private svgElement: SVGElement; // L'elemento SVG vero e proprio

  constructor() {
    // Contenitore HTML div
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-view');
    this.domElement.classList.add('fast-view-empty');
    this.domElement.classList.add('fast-svg-container');

    // elemento SVG all'interno del div
    this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.svgElement.classList.add('fast-svg');
    this.domElement.appendChild(this.svgElement); // Inseriamo l'SVG nel div
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }

    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('drop', (event: DragEvent) => {
      event.preventDefault();

      const svgPoint = this.getSvgCoordinates(event);

      const draggedElementId = event.dataTransfer?.getData('text/plain');
      if (draggedElementId) {
        const draggedWidget = this.widgetList.find((widget) => widget.domElement.id === draggedElementId);

        if (draggedWidget) {
          if (draggedWidget instanceof SvgText) {
            draggedWidget.setProperties({ x: svgPoint.x, y: svgPoint.y });
          } else if (draggedWidget instanceof SvgRect) {
            draggedWidget.setProperties({ x: svgPoint.x, y: svgPoint.y });
          } else if (draggedWidget instanceof SvgExternal) {
            draggedWidget.setProperties({ x: svgPoint.x, y: svgPoint.y });
          }
        }
      }
    });
  }

  private getSvgCoordinates(event: DragEvent) {
    const svgRect = this.svgElement.getBoundingClientRect();
    const x = event.clientX - svgRect.left;
    const y = event.clientY - svgRect.top;
    return { x, y };
  }

  private placeTextAt(x: number, y: number) {
    this.svgElement.setAttribute('x', `${x}`);
    this.svgElement.setAttribute('y', `${y}`);
  }

  detach(): void {
    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.id) {
      WidgetDictionary.unregisterWidget(this.id);
    }
  }

  updateProperties(): void {
    if (this.currentProperties) {
      this.setProperties(this.currentProperties);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      if (![undefined, null].includes(properties[key])) {
        const value = properties[key];

        if (key === 'id') {
          this.id = value;
          this.domElement.id = value;
          WidgetDictionary.registerWidget(value, this);
        } else if (key === 'children') {
          this.setChildren(properties['children']);
        } else {
          this.currentProperties[key] = value;
          this.applyProperty(key, value);
        }
      }
    }
  }

  setChildren(children: WidgetProperties[]): void {
    while (this.svgElement.firstChild) {
      this.svgElement.removeChild(this.svgElement.firstChild);
    }
    this.detachChildren();

    children.forEach((childProperties) => {
      const childWidget = WidgetFactory.buildWidget(childProperties.type);
      childWidget.setProperties(childProperties);
      childWidget.attach();

      if (childWidget.domElement instanceof HTMLDivElement) {
        this.domElement.appendChild(childWidget.domElement);
      } else if (childWidget.domElement instanceof SVGElement) {
        this.svgElement.appendChild(childWidget.domElement);
      }

      this.widgetList.push(childWidget);
    });
  }

  detachChildren(): void {
    this.widgetList.forEach((widget) => widget.detach());
    this.widgetList = [];
  }

  private applyProperty(key: string, value: any): void {
    switch (key) {
      case 'x':
      case 'y':
      case 'width':
      case 'height':
      case 'fill':
      case 'stroke':
      case 'strokeWidth':
        this.svgElement.setAttribute(key, value.toString());
        break;

      case 'transform':
        this.svgElement.setAttribute('transform', value);
        break;

      default:
        break;
    }
  }
}

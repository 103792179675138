import './MultiEdit.css';
import { InputNumber } from 'primereact/inputnumber';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { deepCopy, deepEqual } from '@services/utils';
import { useRef, useState } from 'react';

export type SingleEdit = {
  hint: string;
  field: string;
  minValue?: number;
  maxValue?: number;
};

export interface MultiEditProps extends PropertyEditorComponentProps {
  options: SingleEdit[];
}

export const MultiEdit = ({ data, options, onDataChange }: MultiEditProps) => {
  const [linked, setLinked] = useState(true);

  const divRef = useRef<HTMLDivElement>(null);

  const setData = (field: string, value: unknown) => {
    const newData = deepCopy(data) ?? {};
    if (linked) {
      options.forEach((ele) => (newData[ele.field] = value));
    } else {
      newData[field] = value;
    }

    if (!deepEqual(data, newData)) {
      onDataChange(newData);
    }
  };

  const setSpinVisible = (idx: number, status: string) => {
    const list = divRef.current.querySelectorAll('.p-inputnumber-button-group .p-inputnumber-button');
    list.forEach((node, index) => {
      if (node instanceof HTMLElement && Math.floor(index / 2) === idx) {
        node.style.visibility = status;
      }
    });
  };

  return (
    <div className="multi-edit-container" ref={divRef}>
      {options.map((option, index) => (
        <div className={'multi-edit-slot'} key={index}>
          <div
            className={'multi-edit'}
            onMouseEnter={() => setSpinVisible(index, 'visible')}
            onMouseLeave={() => setSpinVisible(index, 'hidden')}
          >
            <InputNumber
              value={data?.[option.field]}
              max={option.maxValue}
              min={option.minValue}
              showButtons
              useGrouping={false}
              onChange={(e) => {
                setData(option.field, e.value);
              }}
            />
          </div>
          <div className={'hint'}>{option.hint}</div>
        </div>
      ))}
      <div className={'multi-edit-slot'}>
        <div
          className={`link ${linked ? 'selected' : ''}`}
          onClick={() => {
            setLinked(!linked);
          }}
        >
          <i className={'bi-link-45deg'}></i>
        </div>
        <div className={'hint'}>&nbsp;</div>
      </div>
    </div>
  );
};

export default MultiEdit;
